<template>
  <Header2 />
  <!-- Start -->
  <section class="section" style="margin-top: 100px;">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive bg-white shadow rounded">
            <table class="table mb-0 table-center">
              <thead>
                <tr>
                  <th class="border-bottom py-3" style="min-width: 20px"></th>
                  <th
                    class="border-bottom text-start py-3"
                    style="min-width: 300px"
                  >
                    {{direction == 'en' ? translation.product.en : translation.product.ar}}
                  </th>
                  <th
                    class="border-bottom text-center py-3"
                    style="min-width: 160px"
                  >
                    {{direction == 'en' ? translation.price.en : translation.price.ar}}
                  </th>
                  <th
                    class="border-bottom text-center py-3"
                    style="min-width: 160px"
                  >
                    {{direction == 'en' ? translation.quan.en : translation.quan.ar}}
                  </th>
                  <th
                    class="border-bottom text-end py-3 pe-4"
                    style="min-width: 160px"
                  >
                    {{direction == 'en' ? translation.total.en : translation.total.ar}}
                  </th>
                  <th class="border-bottom text-end py-3 pe-4"
                    style="min-width: 160px">
                    </th>
                </tr>
              </thead>

              <tbody>
                <tr class="shop-list" v-for="item in products" v-bind:key="item" :id="'id-' + item.id">
                  <td class="h6 text-center">
                    <a href="javascript:void(0)" class="text-danger"
                      ><i class="uil uil-times"></i
                    ></a>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <img
                        :src="item.product.attachments[0].url"
                        class="img-fluid avatar avatar-small rounded shadow"
                        style="height: auto"
                        alt=""
                      />
                      <h6 class="mb-0 ms-3">{{item.product.common_name}}</h6>
                    </div>
                  </td>
                  <td class="text-center">{{item.product.price}} {{direction == 'en' ? translation.curr.en : translation.curr.ar}}</td>
                  <td class="text-center qty-icons">
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                      class="btn btn-icon btn-soft-primary minus"
                    >
                      -
                    </button>
                    <input
                      min="1"
                      name="quantity"
                      :value="item.quan"
                      type="number"
                      class="btn btn-icon btn-soft-primary qty-btn quantity"
                    />
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                      class="btn btn-icon btn-soft-primary plus"
                    >
                      +
                    </button>
                  </td>
                  <td class="text-end fw-bold pe-4">{{item.product.price * item.quan}} {{direction == 'en' ? translation.curr.en : translation.curr.ar}}</td>
                  <td><button class="btn btn-danger" v-on:click="removeThisProduct(item.id)">Remove</button></td>
                </tr>
              </tbody>
            </table>
              <div :style="products.length > 1 ? 'display:none;' : 'display:block; height: 100px; letter-spacing: 1px; margin-top: 50px;'">
                  <h5 style="text-align: center;">{{direction == 'en' ? 'There is no products in your shop cart' : 'لا يوجد منتجات في السله'}}</h5>
              </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <div class="row">
        <div class="col-lg-4 col-md-6 ms-auto mt-4 pt-2">
          <div class="table-responsive bg-white rounded shadow">
            <table class="table table-center table-padding mb-0">
              <tbody>
                <tr>
                  <td class="h6 ps-4 py-3">{{direction == 'en' ? translation.subTotal.en : translation.subTotal.ar}}</td>
                  <td class="text-end fw-bold pe-4">{{total}} {{ direction == 'en' ? translation.curr.en : translation.curr.ar}}</td>
                </tr>
                <tr>
                  <td class="h6 ps-4 py-3">{{direction == 'en' ? translation.tax.en : translation.tax.ar}}</td>
                  <td class="text-end fw-bold pe-4">0</td>
                </tr>
                <tr class="bg-light">
                  <td class="h6 ps-4 py-3">{{direction == 'en' ? translation.total.en : translation.total.ar}}</td>
                  <td class="text-end fw-bold pe-4">{{total}} {{ direction == 'en' ? translation.curr.en : translation.curr.ar}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="margin-bottom: 40px;" class="mt-4 pt-2 text-end" v-if="products.length != 0">
            <a style="background: #b51e30; border: 1px solid #b51e30" v-on:click="openUrl('checkout')" class="btn btn-primary"
              >{{direction == 'en' ? translation.proceed.en : translation.proceed.ar}}</a
            >
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <newFooter />
</template>


<script>
export default {
    
}
</script>

<style scoped>

</style>