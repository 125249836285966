<template>
  <Header />
  <div class="page-wrapper">
    <main class="main">
      <!-- Start of Page Header -->
      <div class="page-header">
        <div class="container">
          <h1 class="page-title mb-0">{{ translation.account[direction] }}</h1>
        </div>
      </div>
      <nav class="breadcrumb-nav">
        <div class="container">
          <ul class="breadcrumb">
            <li>
              <a :href="'/' + direction + '/'">{{
                translation.home[direction]
              }}</a>
            </li>
            <li>{{ translation.account[direction] }}</li>
          </ul>
        </div>
      </nav>
      <div class="page-content pt-2">
        <div class="container">
          <div class="tab tab-vertical row gutter-lg">
            <ul class="nav nav-tabs mb-6" role="tablist">
              <li class="nav-item">
                <a
                  :style="direction == 'ar' ? 'text-align:right' : ''"
                  href="#account-dashboard"
                  class="nav-link active"
                  >{{ translation.dashboard[direction] }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  :style="direction == 'ar' ? 'text-align:right' : ''"
                  href="#account-orders"
                  class="nav-link"
                  >{{ translation.orders[direction] }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  :style="direction == 'ar' ? 'text-align:right' : ''"
                  href="#account-addresses"
                  class="nav-link"
                  >{{ translation.addresses[direction] }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  :style="direction == 'ar' ? 'text-align:right' : ''"
                  href="#account-details"
                  class="nav-link"
                  >{{ translation.details[direction] }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  :style="direction == 'ar' ? 'text-align:right' : ''"
                  href="/en/logout"
                  v-on:click="logout()"
                  class="nav-link"
                  >{{ translation.logout[direction] }}</a
                >
              </li>
            </ul>

            <div class="tab-content mb-6">
              <div class="tab-pane active in" id="account-dashboard">
                <p
                  style="font-size: 18px; margin-bottom: 20px"
                  class="greeting"
                >
                  {{ translation.hello[direction] }}
                  <span class="text-dark font-weight-bold">{{user.first_name}}</span>
                </p>

                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                    <a href="#account-orders" class="link-to-tab">
                      <div class="icon-box text-center">
                        <span class="icon-box-icon icon-orders">
                          <i class="w-icon-orders"></i>
                        </span>
                        <div class="icon-box-content">
                          <p
                            style="font-size: 14px"
                            class="text-uppercase mb-0"
                          >
                            {{ translation.orders[direction] }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                    <a href="#account-addresses" class="link-to-tab">
                      <div class="icon-box text-center">
                        <span class="icon-box-icon icon-address">
                          <i class="w-icon-map-marker"></i>
                        </span>
                        <div class="icon-box-content">
                          <p
                            style="font-size: 14px"
                            class="text-uppercase mb-0"
                          >
                            {{ translation.addresses[direction] }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                    <a href="#account-details" class="link-to-tab">
                      <div class="icon-box text-center">
                        <span class="icon-box-icon icon-account">
                          <i class="w-icon-user"></i>
                        </span>
                        <div class="icon-box-content">
                          <p
                            style="font-size: 14px"
                            class="text-uppercase mb-0"
                          >
                            {{ translation.details[direction] }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                    <a href="/en/logout">
                      <div class="icon-box text-center">
                        <span class="icon-box-icon icon-logout">
                          <i class="w-icon-logout"></i>
                        </span>
                        <div class="icon-box-content">
                          <p
                            style="font-size: 14px"
                            class="text-uppercase mb-0"
                          >
                            {{ translation.logout[direction] }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="tab-pane mb-4" id="account-orders">
                <div class="icon-box icon-box-side icon-box-light">
                  <span class="icon-box-icon icon-orders">
                    <i class="w-icon-orders"></i>
                  </span>
                  <div class="icon-box-content">
                    <h4 class="icon-box-title text-capitalize ls-normal mb-0">
                      {{ translation.orders[direction] }}
                    </h4>
                  </div>
                </div>

                <table class="shop-table account-orders-table mb-6"  v-if="orders.length > 0">
                  <thead>
                    <tr >
                      <th class="order-id">
                        {{ translation.order[direction] }}
                      </th>
                      <th class="order-status">
                        {{ translation.status[direction] }}
                      </th>
                      <th class="order-total">
                        {{ translation.total[direction] }}
                      </th>
                      <th class="order-actions">
                        {{ translation.action[direction] }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in orders" :key="item">
                      <td class="order-id">#{{item.id}}</td>
                      <td class="order-status" :style="{color : item.color}">{{ item.latestStatus }}</td>
                      <td class="order-total">
                        <span class="order-price">{{item.price}} {{direction == 'en' ? ' SAR' : ' ريال سعودي'}}</span>
                      </td>
                      <td class="order-action">
                        <a
                          href="#"
                          class="
                            btn
                            btn-outline
                            btn-default
                            btn-block
                            btn-sm
                            btn-rounded
                          "
                          >{{ translation.view[direction] }}</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table v-else style="margin-bottom: 30px;">
                  <tbody>
                        <tr>
                          <th></th>
                          <td style="text-align:center;">There is no orders in your list</td>
                        </tr>
                  </tbody>
                </table>
                <a
                  :href="'/' + direction +'/categories'"
                  class="btn btn-dark btn-rounded btn-icon-right"
                  >{{ translation.shop[direction]
                  }}<i class="w-icon-long-arrow-right"></i
                ></a>
              </div>

              <div class="tab-pane" id="account-downloads">
                <div class="icon-box icon-box-side icon-box-light">
                  <span class="icon-box-icon icon-downloads mr-2">
                    <i class="w-icon-download"></i>
                  </span>
                  <div class="icon-box-content">
                    <h4 class="icon-box-title ls-normal">Downloads</h4>
                  </div>
                </div>
                <p class="mb-4">No downloads available yet.</p>
                <a
                  href="shop-banner-sidebar.html"
                  class="btn btn-dark btn-rounded btn-icon-right"
                  >Go Shop<i class="w-icon-long-arrow-right"></i
                ></a>
              </div>
              <div class="tab-pane" id="account-addresses">
                <div class="icon-box icon-box-side icon-box-light">
                  <span class="icon-box-icon icon-map-marker">
                    <i class="w-icon-map-marker"></i>
                  </span>
                  <div class="icon-box-content">
                    <h4 class="icon-box-title mb-0 ls-normal">
                      {{ translation.addresses[direction] }}
                    </h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mb-12">
                    <div class="ecommerce-address shipping-address pr-lg-8">
                      <!--<h4 class="title title-underline ls-25 font-weight-bold">
                        Shipping Address
                      </h4> -->
                      <address class="mb-4">
                        <table class="address-table">
                          <tbody v-if="billing">
                            <tr>
                              <th>{{ translation.name[direction] }}</th>
                              <td>{{ billing.first_name + ' ' + billing.last_name }} </td>
                            </tr>
                            <tr>
                              <th>{{ translation.email[direction] }}</th>
                              <td>{{ billing.email }}</td>
                            </tr>
                            <tr>
                              <th>{{ translation.address[direction] }}</th>
                              <td>{{ billing.address }}</td>
                            </tr>
                            <tr>
                              <th>{{ translation.address2[direction] }}</th>
                              <td>{{ billing.address2 }}</td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                              <th></th>
                              <td>There is no avaliable address for you</td>
                            </tbody>
                        </table>
                      </address>
                      <a
                        href="#"
                        class="
                          btn btn-link btn-underline btn-icon-right
                          text-primary
                        "
                        ><!--  --><i
                          class="w-icon-long-arrow-right"
                        ></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="account-details">
                <div class="icon-box icon-box-side icon-box-light">
                  <span class="icon-box-icon icon-account mr-2">
                    <i class="w-icon-user"></i>
                  </span>
                  <div class="icon-box-content">
                    <h4 class="icon-box-title mb-0 ls-normal">
                      {{ translation.details[direction] }}
                    </h4>
                  </div>
                </div>
                <form class="form account-details-form">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="firstname">{{
                          translation.firstName[direction]
                        }}</label>
                        <input
                          type="text"
                          id="firstname"
                          name="firstname"
                          :placeholder="user.first_name"
                          class="form-control form-control-md"
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-6">
                    <label for="email_1">{{
                      translation.email[direction]
                    }}</label>
                    <input
                      type="email"
                      id="email_1"
                      name="email_1"
                      class="form-control form-control-md"
                      :placeholder="user.email"
                      disabled
                    />
                  </div>

                  <div class="form-group mb-6">
                    <label for="email_1">{{
                      translation.phone[direction]
                    }}</label>
                    <input
                      type="email"
                      id="email_1"
                      name="email_1"
                      class="form-control form-control-md"
                      :placeholder="user.phone"
                      disabled
                    />
                  </div>

                  <h4 class="title title-password ls-25 font-weight-bold">
                    {{ translation.changePassword[direction] }}
                  </h4>
                  <div class="form-group">
                    <label class="text-dark" for="cur-password">{{
                      translation.old[direction]
                    }}</label>
                    <input
                      v-model="password"
                      type="password"
                      class="form-control form-control-md error"
                      id="cur-password"
                      name="cur_password"
                    />
                  </div>
                  <div class="form-group">
                    <label class="text-dark" for="new-password">{{
                      translation.new[direction]
                    }}</label>
                    <input
                      v-model="newPassword"
                      type="password"
                      class="form-control form-control-md error"
                      id="new-password"
                      name="new_password"
                    />
                  </div>
                  <div class="form-group mb-10">
                    <label class="text-dark" for="conf-password">{{
                      translation.confirm[direction]
                    }}</label>
                    <input
                      v-model="conPassword"
                      type="password"
                      class="form-control form-control-md error"
                      id="conf-password"
                      name="conf_password"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn btn-dark btn-rounded btn-sm mb-4"
                    v-on:click="changePassword()"
                  >
                    {{ translation.save[direction] }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <Footer />
</template>

<script>
import Header from "../components/Header2.vue";
import axios from "axios";
import Footer from "../components/newFooter.vue";
import jQuery from "jquery";
import $ from "jquery";
("use strict");
//var $ = jQuery.noConflict();
$.extend($.easing, {
  def: "easeOutQuad",
  swing: function (t, e, i, a, n) {
    return $.easing[$.easing.def](t, e, i, a, n);
  },
  easeOutQuad: function (t, e, i, a, n) {
    return -a * (e /= n) * (e - 2) + i;
  },
  easeOutQuint: function (t, e, i, a, n) {
    return a * ((e = e / n - 1) * e * e * e * e + 1) + i;
  },
}),
  (window.Wolmart = {}),
  (function (t) {
    var e, i, a, n, o, s, r;
    (Wolmart.$window = t(window)),
      (Wolmart.$body = t(document.body)),
      (Wolmart.status = ""),
      (Wolmart.isIE = navigator.userAgent.indexOf("Trident") >= 0),
      (Wolmart.isEdge = navigator.userAgent.indexOf("Edge") >= 0),
      (Wolmart.isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )),
      (Wolmart.call = function (t, e) {
        setTimeout(t, e);
      }),
      (Wolmart.parseOptions = function (t) {
        return "string" == typeof t
          ? JSON.parse(t.replace(/'/g, '"').replace(";", ""))
          : {};
      }),
      (Wolmart.parseTemplate = function (t, e) {
        return t.replace(/\{\{(\w+)\}\}/g, function () {
          return e[arguments[1]];
        });
      }),
      (Wolmart.byId = function (t) {
        return document.getElementById(t);
      }),
      (Wolmart.byTag = function (t, e) {
        return e ? e.getElementsByTagName(t) : document.getElementsByTagName(t);
      }),
      (Wolmart.byClass = function (t, e) {
        return e
          ? e.getElementsByClassName(t)
          : document.getElementsByClassName(t);
      }),
      (Wolmart.setCookie = function (t, e, i) {
        var a = new Date();
        a.setTime(a.getTime() + 24 * i * 60 * 60 * 1e3),
          (document.cookie =
            t + "=" + e + ";expires=" + a.toUTCString() + ";path=/");
      }),
      (Wolmart.getCookie = function (t) {
        for (
          var e = t + "=", i = document.cookie.split(";"), a = 0;
          a < i.length;
          ++a
        ) {
          for (var n = i[a]; " " == n.charAt(0); ) n = n.substring(1);
          if (0 == n.indexOf(e)) return n.substring(e.length, n.length);
        }
        return "";
      }),
      (Wolmart.$ = function (e) {
        return e instanceof jQuery ? e : t(e);
      }),
      (Wolmart.isOnScreen = function (t) {
        var e = window.pageXOffset,
          i = window.pageYOffset,
          a = t.getBoundingClientRect(),
          n = a.left + e,
          o = a.top + i;
        return (
          o + a.height >= i &&
          o <= i + window.innerHeight &&
          n + a.width >= e &&
          n <= e + window.innerWidth
        );
      }),
      (Wolmart.appear = function (e, i, a) {
        return (
          a &&
            Object.keys(a).length &&
            t.extend(intersectionObserverOptions, a),
          new IntersectionObserver(
            function (e) {
              for (var a = 0; a < e.length; a++) {
                var n = e[a];
                if (n.intersectionRatio > 0)
                  if ("string" == typeof i)
                    Function("return " + functionName)();
                  else i.call(t(n.target));
              }
            },
            { rootMargin: "0px 0px 200px 0px", threshold: 0, alwaysObserve: !0 }
          ).observe(e),
          this
        );
      }),
      (Wolmart.requestTimeout = function (t, e) {
        var i =
          window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame;
        if (!i) return setTimeout(t, e);
        var a,
          n = new Object();
        return (
          (n.val = i(function o(s) {
            a || (a = s), s - a >= e ? t() : (n.val = i(o));
          })),
          n
        );
      }),
      (Wolmart.requestInterval = function (t, e, i) {
        var a =
          window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame;
        if (!a)
          return i
            ? (console.log("settimeout"), setInterval(t, e))
            : (console.log("settimeout"), setTimeout(t, i));
        var n,
          o,
          s = new Object();
        return (
          (s.val = a(function r(l) {
            n || (n = o = l),
              !i || l - n < i
                ? l - o > e
                  ? (t(), (s.val = a(r)), (o = l))
                  : (s.val = a(r))
                : t();
          })),
          console.log(s),
          s
        );
      }),
      (Wolmart.deleteTimeout = function (t) {
        if (t) {
          var e =
            window.cancelAnimationFrame ||
            window.webkitCancelAnimationFrame ||
            window.mozCancelAnimationFrame;
          return e ? (t.val ? e(t.val) : void 0) : clearTimeout(t);
        }
      }),
      (Wolmart.setTab = function (e) {
        Wolmart.$body
          .on("click", ".tab .nav-link", function (e) {
            var i = t(this);
            if ((e.preventDefault(), !i.hasClass("active"))) {
              var a = t(i.attr("href"));
              a.siblings(".active").removeClass("in active"),
                a.addClass("active in"),
                i.parent().parent().find(".active").removeClass("active"),
                i.addClass("active");
            }
          })
          .on("click", ".link-to-tab", function (e) {
            var i = t(e.currentTarget).attr("href"),
              a = t(i),
              n = a.parent().siblings(".nav");
            e.preventDefault(),
              a.siblings().removeClass("active in"),
              a.addClass("active in"),
              n.find(".nav-link").removeClass("active"),
              n.find('[href="' + i + '"]').addClass("active"),
              t("html").animate({ scrollTop: a.offset().top - 150 });
          });
      }),
      (Wolmart.initCartAction = function (e) {
        Wolmart.$body
          .on("click", e, function (e) {
            t(".cart-dropdown").addClass("opened"), e.preventDefault();
          })
          .on("click", ".cart-offcanvas .cart-overlay", function (e) {
            t(".cart-dropdown").removeClass("opened"), e.preventDefault();
          })
          .on(
            "click",
            ".cart-offcanvas .cart-header, .cart-close",
            function (e) {
              t(".cart-dropdown").removeClass("opened"), e.preventDefault();
            }
          );
      }),
      (Wolmart.initScrollTopButton = function () {
        var e = Wolmart.byId("scroll-top");
        e.addEventListener("click", function (e) {
          t("html, body").animate({ scrollTop: 0 }, 600), e.preventDefault();
        });
        var i = function () {
          if (window.pageYOffset > 400) {
            e.classList.add("show");
            var i = t(document).height(),
              a = t(window).height(),
              n = (t(window).scrollTop() / (i - a)) * 214;
            t("#progress-indicator").length > 0 &&
              t("#progress-indicator").css("stroke-dasharray", n + ", 400");
          } else e.classList.remove("show");
        };
        Wolmart.call(i, 500),
          window.addEventListener("scroll", i, { passive: !0 });
      }),
      (Wolmart.stickyDefaultOptions = {
        minWidth: 992,
        maxWidth: 2e4,
        top: !1,
        hide: !1,
        scrollMode: !0,
      }),
      (Wolmart.stickyToolboxOptions = {
        minWidth: 0,
        maxWidth: 767,
        top: !1,
        scrollMode: !0,
      }),
      (Wolmart.stickyProductOptions = {
        minWidth: 0,
        maxWidth: 2e4,
        scrollMode: !0,
        top: !1,
        hide: !1,
      }),
      (Wolmart.windowResized = function (e) {
        return (
          e == Wolmart.resizeTimeStamp ||
            (void 0 === window.innerHeight &&
              (window.innerWidth =
                t(window).width() + Wolmart.getScrollbarWidth()),
            (Wolmart.resizeChanged = Wolmart.canvasWidth != window.innerWidth),
            (Wolmart.canvasWidth = window.innerWidth),
            (Wolmart.resizeTimeStamp = e)),
          Wolmart.resizeChanged
        );
      }),
      (Wolmart.getScrollbarWidth = function () {
        if (void 0 === Wolmart.scrollbarSize) {
          var t = document.createElement("div");
          (t.style.cssText =
            "width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;"),
            document.body.appendChild(t),
            (Wolmart.scrollbarSize = t.offsetWidth - t.clientWidth),
            document.body.removeChild(t);
        }
        return Wolmart.scrollbarSize;
      }),
      (Wolmart.stickyContent = (function () {
        function e(t, e) {
          return this.init(t, e);
        }
        function i() {
          Wolmart.$window.trigger("sticky_refresh.wolmart", {
            index: 0,
            offsetTop: 0,
          });
        }
        function a(t) {
          (t && !Wolmart.windowResized(t.timeStamp)) ||
            (Wolmart.$window.trigger("sticky_refresh_size.wolmart"), i());
        }
        return (
          (e.prototype.init = function (e, i) {
            (this.$el = e),
              (this.options = t.extend(
                !0,
                {},
                Wolmart.stickyDefaultOptions,
                i,
                Wolmart.parseOptions(e.attr("data-sticky-options"))
              )),
              Wolmart.$window
                .on("sticky_refresh.wolmart", this.refresh.bind(this))
                .on("sticky_refresh_size.wolmart", this.refreshSize.bind(this));
          }),
          (e.prototype.refreshSize = function (t) {
            var e =
              window.innerWidth >= this.options.minWidth &&
              window.innerWidth <= this.options.maxWidth;
            if (
              ((this.scrollPos = window.pageYOffset),
              void 0 === this.top && (this.top = this.options.top),
              window.innerWidth >= 768 && this.getTop)
            )
              this.top = this.getTop();
            else if (
              !this.options.top &&
              ((this.top = this.isWrap
                ? this.$el.parent().offset().top
                : this.$el.offset().top + this.$el[0].offsetHeight),
              this.$el.hasClass("has-dropdown"))
            ) {
              var i = this.$el.find(".category-dropdown .dropdown-box");
              i.length && (this.top += i[0].offsetHeight);
            }
            this.isWrap ? e || this.unwrap() : e && this.wrap(),
              (Wolmart.sticky_top_height = 0),
              t && setTimeout(this.refreshSize.bind(this), 50);
          }),
          (e.prototype.wrap = function () {
            this.$el.wrap('<div class="sticky-content-wrapper"></div>'),
              (this.isWrap = !0);
          }),
          (e.prototype.unwrap = function () {
            this.$el.unwrap(".sticky-content-wrapper"), (this.isWrap = !1);
          }),
          (e.prototype.refresh = function (t, e) {
            var i = window.pageYOffset + e.offsetTop,
              a = this.$el;
            i > this.top && this.isWrap
              ? ((this.height = a[0].offsetHeight),
                a.hasClass("fixed") ||
                  a.parent().css("height", this.height + "px"),
                a.hasClass("fix-top")
                  ? (a.css("margin-top", e.offsetTop + "px"),
                    (this.zIndex = this.options.max_index - e.index))
                  : a.hasClass("fix-bottom")
                  ? (a.css("margin-bottom", e.offsetBottom + "px"),
                    (this.zIndex = this.options.max_index - e.index))
                  : a.css({
                      transition: "opacity .5s",
                      "z-index": this.zIndex,
                    }),
                this.options.scrollMode
                  ? ((this.scrollPos >= i && a.hasClass("fix-top")) ||
                    (this.scrollPos <= i && a.hasClass("fix-bottom"))
                      ? (a.addClass("fixed"),
                        this.onFixed && this.onFixed(),
                        a.hasClass("product-sticky-content") &&
                          Wolmart.$body.addClass("addtocart-fixed"))
                      : (a
                          .removeClass("fixed")
                          .css("margin-top", "")
                          .css("margin-bottom", ""),
                        this.onUnfixed && this.onUnfixed(),
                        a.hasClass("product-sticky-content") &&
                          Wolmart.$body.removeClass("addtocart-fixed")),
                    (this.scrollPos = i))
                  : (a.addClass("fixed"), this.onFixed && this.onFixed()),
                a.is(".fixed.fix-top")
                  ? ((e.offsetTop += a[0].offsetHeight),
                    (Wolmart.sticky_top_height = e.offsetTop))
                  : a.is(".fixed.fix-bottom") &&
                    (e.offsetBottom += a[0].offsetHeight))
              : (a.parent().css("height", ""),
                a.removeClass("fixed").css({
                  "margin-top": "",
                  "margin-bottom": "",
                  "z-index": "",
                }),
                this.onUnfixed && this.onUnfixed(),
                a.hasClass("product-sticky-content") &&
                  Wolmart.$body.removeClass("addtocart-fixed"));
          }),
          Wolmart.$window.on("wolmart_complete", function () {
            window.addEventListener("scroll", i, { passive: !0 }),
              Wolmart.$window.on("resize", a),
              setTimeout(function () {
                a();
              }, 300);
          }),
          function (i, a) {
            Wolmart.$(i).each(function () {
              var i = t(this);
              i.data("sticky-content") || i.data("sticky-content", new e(i, a));
            });
          }
        );
      })()),
      (Wolmart.parallax = function (e, i) {
        t.fn.themePluginParallax &&
          Wolmart.$(e).each(function () {
            var e = t(this);
            e.themePluginParallax(
              t.extend(
                !0,
                Wolmart.parseOptions(e.attr("data-parallax-options")),
                i
              )
            );
          });
      }),
      (Wolmart.skrollrParallax = function () {
        Wolmart.isMobile ||
          ("undefined" != typeof skrollr &&
            Wolmart.$(".skrollable").length &&
            skrollr.init({ forceHeight: !1 }));
      }),
      (Wolmart.initFloatingParallax = function () {
        t.fn.parallax &&
          Wolmart.$(".floating-item").each(function (e) {
            var i = t(this);
            i.data("parallax") &&
              (i.parallax("disable"),
              i.removeData("parallax"),
              i.removeData("options")),
              i
                .children()
                .addClass("layer")
                .attr("data-depth", i.attr("data-child-depth")),
              i.parallax(Wolmart.parseOptions(i.data("options")));
          });
      }),
      (Wolmart.isotopeOptions = {
        itemsSelector: ".grid-item",
        layoutMode: "masonry",
        percentPosition: !0,
        masonry: { columnWidth: ".grid-space" },
      }),
      (Wolmart.isotopes = function (e, i) {
        if ("function" == typeof imagesLoaded && t.fn.isotope) {
          var a = this;
          Wolmart.$(e).each(function () {
            var e = t(this),
              n = t.extend(
                !0,
                {},
                a.isotopeOptions,
                Wolmart.parseOptions(e.attr("data-grid-options")),
                i || {}
              );
            Wolmart.lazyLoad(e),
              e.imagesLoaded(function () {
                n.customInitHeight && e.height(e.height()),
                  n.customDelay &&
                    Wolmart.call(function () {
                      e.isotope(n);
                    }, parseInt(n.customDelay)),
                  e.isotope(n);
              });
          });
        }
      }),
      (Wolmart.initNavFilter = function (e) {
        t.fn.isotope &&
          Wolmart.$(e).on("click", function (e) {
            var i = t(this),
              a = i.attr("data-filter"),
              n = i.parent().parent().attr("data-target");
            t(n || ".grid")
              .isotope({ filter: a })
              .isotope("on", "arrangeComplete", function () {
                Wolmart.$window.trigger("appear.check");
              }),
              i.parent().siblings().children().removeClass("active"),
              i.addClass("active"),
              e.preventDefault();
          });
      }),
      (Wolmart.ratingTooltip = function (t) {
        for (
          var e = Wolmart.byClass("ratings-full", t || document.body),
            i = e.length,
            a = function () {
              var t =
                parseInt(this.firstElementChild.style.width.slice(0, -1)) / 20;
              this.lastElementChild.innerText = t ? t.toFixed(2) : t;
            },
            n = 0;
          n < i;
          ++n
        )
          e[n].addEventListener("mouseover", a),
            e[n].addEventListener("touchstart", a, { passive: !0 });
      }),
      (Wolmart.setProgressBar = function (e) {
        Wolmart.$(e).each(function () {
          var e = t(this),
            i = e.parent().find("mark")[0].innerHTML,
            a = "";
          -1 != i.indexOf("%")
            ? (a = i)
            : -1 != i.indexOf("/") &&
              (a =
                (a =
                  (parseInt(i.split("/")[0]) / parseInt(i.split("/")[1])) * 100)
                  .toFixed(2)
                  .toString() + "%"),
            e.find("span").css("width", a);
        });
      }),
      (Wolmart.alert = function (e) {
        Wolmart.$body.on("click", e + " .btn-close", function (i) {
          i.preventDefault(),
            t(this)
              .closest(e)
              .fadeOut(function () {
                t(this).remove();
              });
        });
      }),
      (Wolmart.closeTopNotice = function (e) {
        Wolmart.$body.on("click", e, function (e) {
          e.preventDefault(), t(".top-banner").slideUp();
        });
      }),
      (Wolmart.accordion = function (e) {
        Wolmart.$body.on("click", e, function (e) {
          var a = t(this),
            n = a.closest(".card").find(a.attr("href")),
            o = a.closest(".accordion");
          e.preventDefault(),
            0 === o.find(".collapsing").length &&
              0 === o.find(".expanding").length &&
              (n.hasClass("expanded")
                ? o.hasClass("radio-type") || i(n)
                : n.hasClass("collapsed") &&
                  (o.find(".expanded").length > 0
                    ? Wolmart.isIE
                      ? i(o.find(".expanded"), function () {
                          i(n);
                        })
                      : (i(o.find(".expanded")), i(n))
                    : i(n)));
        });
        var i = function (t, i) {
          var a = t.closest(".card").find(e);
          t.hasClass("expanded")
            ? (a.removeClass("collapse").addClass("expand"),
              t.addClass("collapsing").slideUp(300, function () {
                t.removeClass("expanded collapsing").addClass("collapsed"),
                  i && i();
              }))
            : t.hasClass("collapsed") &&
              (a.removeClass("expand").addClass("collapse"),
              t.addClass("expanding").slideDown(300, function () {
                t.removeClass("collapsed expanding").addClass("expanded"),
                  i && i();
              }));
        };
      }),
      (Wolmart.animationOptions = {
        name: "fadeIn",
        duration: "1.2s",
        delay: ".2s",
      }),
      (Wolmart.appearAnimate = function (e) {
        Wolmart.$(e).each(function () {
          var e = this;
          Wolmart.appear(e, function () {
            if (e.classList.contains("appear-animate")) {
              var i = t.extend(
                {},
                Wolmart.animationOptions,
                Wolmart.parseOptions(e.getAttribute("data-animation-options"))
              );
              setTimeout(
                function () {
                  (e.style["animation-duration"] = i.duration),
                    e.classList.add(i.name),
                    e.classList.add("appear-animation-visible");
                },
                i.delay ? 1e3 * Number(i.delay.slice(0, -1)) : 0
              );
            }
          });
        });
      }),
      (Wolmart.countDown = function (e) {
        t.fn.countdown &&
          Wolmart.$(e).each(function () {
            var e = t(this),
              i = e.data("until"),
              a = e.data("compact"),
              n = e.data("format") ? e.data("format") : "DHMS",
              o = e.data("labels-short")
                ? ["Years", "Months", "Weeks", "Days", "Hrs", "Mins", "Secs"]
                : [
                    "Years",
                    "Months",
                    "Weeks",
                    "Days",
                    "Hours",
                    "Minutes",
                    "Seconds",
                  ],
              s = e.data("labels-short")
                ? ["Year", "Month", "Week", "Day", "Hour", "Min", "Sec"]
                : ["Year", "Month", "Week", "Day", "Hour", "Minute", "Second"];
            if (e.data("relative")) l = i;
            else
              var r = i.split(", "),
                l = new Date(r[0], r[1] - 1, r[2]);
            e.countdown({
              until: l,
              format: n,
              padZeroes: !0,
              compact: a,
              compactLabels: [" y", " m", " w", " days, "],
              timeSeparator: " : ",
              labels: o,
              labels1: s,
            });
          });
      }),
      (Wolmart.priceSlider = function (e, i) {
        "object" == typeof noUiSlider &&
          Wolmart.$(e).each(function () {
            var e = this;
            noUiSlider.create(
              e,
              t.extend(
                !0,
                {
                  start: [0, 400],
                  connect: !0,
                  step: 1,
                  range: { min: 0, max: 635 },
                },
                i
              )
            ),
              e.noUiSlider.on("update", function (i, a) {
                i = i.map(function (t) {
                  return "$" + parseInt(t);
                });
                t(e).parent().find(".filter-price-range").text(i.join(" - "));
              });
          });
      }),
      (Wolmart.stickySidebarOptions = {
        autoInit: !0,
        minWidth: 991,
        containerSelector: ".sticky-sidebar-wrapper",
        autoFit: !0,
        activeClass: "sticky-sidebar-fixed",
        top: 0,
        bottom: 0,
      }),
      (Wolmart.stickySidebar = function (e) {
        if (t.fn.themeSticky) {
          var i = 0;
          function a() {
            Wolmart.$(e).trigger("recalc.pin"),
              t(window).trigger("appear.check");
          }
          !t(".sticky-sidebar > .filter-actions").length &&
            t(window).width() >= 992 &&
            t(".sticky-content.fix-top").each(function (e) {
              if (!t(this).hasClass("sticky-toolbox")) {
                var a = t(this).hasClass("fixed");
                (i += t(this).addClass("fixed").outerHeight()),
                  a || t(this).removeClass("fixed");
              }
            }),
            Wolmart.$(e).each(function () {
              var e = t(this);
              e.themeSticky(
                t.extend(
                  {},
                  Wolmart.stickySidebarOptions,
                  { padding: { top: i } },
                  Wolmart.parseOptions(e.attr("data-sticky-options"))
                )
              );
            }),
            setTimeout(a, 300),
            Wolmart.$window.on("click", ".tab .nav-link", function () {
              setTimeout(a);
            });
        }
      }),
      (Wolmart.zoomImageOptions = {
        responsive: !0,
        borderSize: 0,
        zoomType: "inner",
        onZoomIn: !0,
        magnify: 1.1,
      }),
      (Wolmart.zoomImageObjects = []),
      (Wolmart.zoomImage = function (e) {
        t.fn.zoom &&
          e &&
          ("string" == typeof e ? t(e) : e).find("img").each(function () {
            var e = t(this);
            (Wolmart.zoomImageOptions.target = e.parent()),
              (Wolmart.zoomImageOptions.url = e.attr("data-zoom-image")),
              e.zoom(Wolmart.zoomImageOptions),
              Wolmart.zoomImageObjects.push(e);
          });
      }),
      (Wolmart.zoomImageOnResize = function () {
        Wolmart.zoomImageObjects.forEach(function (e) {
          e.each(function () {
            var e = t(this).data("zoom");
            e && e.refresh();
          });
        });
      }),
      (Wolmart.lazyLoad = function (t, e) {
        function i() {
          this.setAttribute("src", this.getAttribute("data-src")),
            this.addEventListener("load", function () {
              (this.style["padding-top"] = ""),
                this.classList.remove("lazy-img");
            });
        }
        Wolmart.$(t)
          .find(".lazy-img")
          .each(function () {
            void 0 !== e && e ? i.call(this) : Wolmart.appear(this, i);
          });
      }),
      (Wolmart.initPopup = function (e, i) {
        Wolmart.$body.hasClass("home") &&
          "true" !== Wolmart.getCookie("hideNewsletterPopup") &&
          setTimeout(function () {
            Wolmart.popup({
              items: { src: ".newsletter-popup" },
              type: "inline",
              tLoading: "",
              mainClass: "mfp-newsletter mfp-fadein-popup",
              callbacks: {
                beforeClose: function () {
                  t("#hide-newsletter-popup")[0].checked &&
                    Wolmart.setCookie("hideNewsletterPopup", !0, 7);
                },
              },
            });
          }, 7500),
          Wolmart.$body.on("click", ".btn-iframe", function (e) {
            e.preventDefault(),
              Wolmart.popup(
                {
                  items: {
                    src:
                      '<video src="' +
                      t(e.currentTarget).attr("href") +
                      '" autoplay loop controls>',
                    type: "inline",
                  },
                  mainClass: "mfp-video-popup",
                },
                "video"
              );
          }),
          Wolmart.$body
            .on("click", ".sign-in", function (e) {
              e.preventDefault(),
                Wolmart.popup(
                  { items: { src: t(e.currentTarget).attr("href") } },
                  "login"
                );
            })
            .on("click", ".register", function (e) {
              e.preventDefault(),
                Wolmart.popup(
                  {
                    items: { src: t(e.currentTarget).attr("href") },
                    callbacks: {
                      ajaxContentAdded: function () {
                        this.wrap.find('[href="#sign-up"]').click();
                      },
                    },
                  },
                  "login"
                );
            });
      }),
      (Wolmart.initNotificationAlert = function () {
        Wolmart.$body.hasClass("has-notification") &&
          setTimeout(function () {
            Wolmart.$body.addClass("show-notification");
          }, 5e3);
      }),
      (Wolmart.countTo = function (e) {
        t.fn.countTo &&
          Wolmart.$(e).each(function () {
            Wolmart.appear(this, function () {
              var e = t(this);
              setTimeout(function () {
                e.countTo({
                  onComplete: function () {
                    e.addClass("complete");
                  },
                });
              }, 300);
            });
          });
      }),
      (Wolmart.minipopupOption = {
        productClass: "",
        imageSrc: "",
        imageLink: "#",
        name: "",
        nameLink: "#",
        message: "",
        actionTemplate: "",
        isPurchased: !1,
        delay: 4e3,
        space: 20,
        template:
          '<div class="minipopup-box"><div class="product product-list-sm {{productClass}}"><figure class="product-media"><a href="{{imageLink}}"><img src="{{imageSrc}}" alt="Product" width="80" height="90" /></a></figure><div class="product-details"><h4 class="product-name"><a href="{{nameLink}}">{{name}}</a></h4>{{message}}</div></div><div class="product-action">{{actionTemplate}}</div></div>',
      }),
      (Wolmart.Minipopup =
        ((i = 0),
        (a = []),
        (n = !1),
        (o = []),
        (s = !1),
        (r = function () {
          if (!n)
            for (var t = 0; t < o.length; ++t)
              (o[t] -= 200) <= 0 && this.close(t--);
        }),
        {
          init: function () {
            var i = document.createElement("div");
            (i.className = "minipopup-area"),
              Wolmart.byClass("page-wrapper")[0].appendChild(i),
              (e = t(i)),
              (this.close = this.close.bind(this)),
              (r = r.bind(this));
          },
          open: function (n, l) {
            var c,
              d = this,
              p = t.extend(!0, {}, Wolmart.minipopupOption, n);
            (c = t(Wolmart.parseTemplate(p.template, p))), (d.space = p.space);
            var u = c.appendTo(e).css("top", -i).find("img");
            u.length &&
              u.on("load", function () {
                (i += c[0].offsetHeight + d.space),
                  c.addClass("show"),
                  c.offset().top - window.pageYOffset < 0 &&
                    (d.close(), c.css("top", -i + c[0].offsetHeight + d.space)),
                  c
                    .on("mouseenter", function () {
                      d.pause();
                    })
                    .on("mouseleave", function () {
                      d.resume();
                    })
                    .on("touchstart", function (t) {
                      d.pause(), t.stopPropagation();
                    })
                    .on("mousedown", function () {
                      t(this).addClass("focus");
                    })
                    .on("mouseup", function () {
                      d.close(t(this).index());
                    }),
                  Wolmart.$body.on("touchstart", function () {
                    d.resume();
                  }),
                  a.push(c),
                  o.length || (s = setInterval(r, 200)),
                  o.push(p.delay),
                  l && l(c);
              });
          },
          close: function (t) {
            var e = void 0 === t ? 0 : t,
              n = a.splice(e, 1)[0];
            o.splice(e, 1)[0];
            var r = n[0].offsetHeight;
            (i -= r + this.space),
              n.removeClass("show"),
              setTimeout(function () {
                n.remove();
              }, 300),
              a.forEach(function (t, i) {
                i >= e &&
                  t.hasClass("show") &&
                  t
                    .stop(!0, !0)
                    .animate(
                      { top: parseInt(t.css("top")) + r + 20 },
                      600,
                      "easeOutQuint"
                    );
              }),
              a.length || clearTimeout(s);
          },
          pause: function () {
            n = !0;
          },
          resume: function () {
            n = !1;
          },
        })),
      (Wolmart.headerToggleSearch = function (t) {
        var e = Wolmart.$(t);
        Wolmart.$body.on("click", ".hs-toggle .search-toggle", function (t) {
          t.preventDefault();
        }),
          "ontouchstart" in document
            ? (e.find(".search-toggle").on("click", function (t) {
                e.toggleClass("show");
              }),
              Wolmart.$body.on("click", function (t) {
                e.removeClass("show");
              }),
              e.on("click", function (t) {
                t.preventDefault(), t.stopPropagation();
              }))
            : e
                .find(".form-control")
                .on("focusin", function (t) {
                  e.addClass("show");
                })
                .on("focusout", function (t) {
                  e.removeClass("show");
                });
      }),
      (Wolmart.scrollTo = function (e, i) {
        var a = void 0 === i ? 0 : i;
        if ("number" == typeof e) o = e;
        else {
          var n = Wolmart.$(e);
          if (!n.length || "none" == n.css("display")) return;
          var o = n.offset().top,
            s = t("#wp-toolbar");
          window.innerWidth > 600 &&
            s.length &&
            (o -= s.parent().outerHeight()),
            t(".sticky-content.fix-top.fixed").each(function () {
              o -= this.offsetHeight;
            });
        }
        t("html,body").stop().animate({ scrollTop: o }, a);
      });
  })(jQuery),
  (function (t) {
    var e = function (t) {
        t.preventDefault(), Wolmart.$body.addClass("mmenu-active");
      },
      i = function (t) {
        t.preventDefault(), Wolmart.$body.removeClass("mmenu-active");
      },
      a = {
        init: function () {
          this.initMenu(),
            this.initCategoryMenu(),
            this.initMobileMenu(),
            this.initFilterMenu(),
            this.initCollapsibleWidget(),
            this.initSubmenu();
        },
        initMenu: function () {
          t(".menu li").each(function () {
            !this.lastElementChild ||
              ("UL" !== this.lastElementChild.tagName &&
                !this.lastElementChild.classList.contains("megamenu")) ||
              t(this).parent().hasClass("megamenu") ||
              (this.classList.add("has-submenu"),
              !this.lastElementChild.classList.contains("megamenu") &&
                this.lastElementChild.classList.add("submenu"));
          }),
            Wolmart.$window.on("resize", function () {
              t(".main-nav megamenu").each(function () {
                var e = t(this),
                  i = e.offset().left,
                  a = i + e.outerWidth() - (window.innerWidth - 20);
                a > 0 && i > 20 && e.css("margin-left", -a);
              });
            });
        },
        initCategoryMenu: function () {
          var e = t(".category-dropdown");
          if (e.length) {
            var i = e.find(".dropdown-box");
            if (i.length) {
              var a = t(".main").offset().top + i[0].offsetHeight;
              (window.pageYOffset <= a || window.innerWidth < 992) &&
                e.removeClass("show"),
                window.addEventListener(
                  "scroll",
                  function () {
                    window.pageYOffset <= a &&
                      window.innerWidth >= 992 &&
                      e.removeClass("show");
                  },
                  { passive: !0 }
                ),
                t(".category-toggle").on("click", function (t) {
                  t.preventDefault();
                }),
                e.on("mouseover", function (t) {
                  ((e.hasClass("menu-fixed") &&
                    window.pageYOffset > a &&
                    window.innerWidth >= 992) ||
                    (!e.hasClass("menu-fixed") && window.innerWidth >= 992)) &&
                    e.addClass("show");
                }),
                e.on("mouseleave", function (t) {
                  ((e.hasClass("menu-fixed") &&
                    window.pageYOffset > a &&
                    window.innerWidth >= 992) ||
                    (!e.hasClass("menu-fixed") && window.innerWidth >= 992)) &&
                    e.removeClass("show");
                });
            }
            if (e.hasClass("with-sidebar")) {
              var n = Wolmart.byClass("sidebar");
              n.length &&
                (e.find(".dropdown-box").css("width", n[0].offsetWidth - 20),
                Wolmart.$window.on("resize", function () {
                  e.find(".dropdown-box").css("width", n[0].offsetWidth - 20);
                }));
            }
          }
        },
        initMobileMenu: function () {
          t(".mobile-menu li, .toggle-menu li").each(function () {
            if (
              this.lastElementChild &&
              ("UL" === this.lastElementChild.tagName ||
                this.lastElementChild.classList.contains("megamenu"))
            ) {
              var t = document.createElement("span");
              (t.className = "toggle-btn"),
                this.firstElementChild.appendChild(t);
            }
          }),
            t(".mobile-menu-toggle").on("click", e),
            t(".mobile-menu-overlay").on("click", i),
            t(".mobile-menu-close").on("click", i),
            Wolmart.$window.on("resize", i);
        },
        initFilterMenu: function () {
          t(".search-ul li").each(function () {
            if (
              this.lastElementChild &&
              "UL" === this.lastElementChild.tagName
            ) {
              var t = document.createElement("i");
              (t.className = "la la-angle-down"),
                this.classList.add("with-ul"),
                this.firstElementChild.appendChild(t);
            }
          }),
            t(".with-ul > a i, .toggle-btn").on("click", function (e) {
              t(this);
              t(this)
                .parent()
                .next()
                .slideToggle(300)
                .parent()
                .toggleClass("show"),
                e.preventDefault();
            });
        },
        initCollapsibleWidget: function () {
          t(".widget-collapsible .widget-title").each(function () {
            var t = document.createElement("span");
            (t.className = "toggle-btn"), this.appendChild(t);
          }),
            t(".widget-collapsible .widget-title").on("click", function (e) {
              var i = t(this),
                a = i.siblings(".widget-body");
              i.hasClass("collapsed") || a.css("display", "block"),
                a.stop().slideToggle(300),
                i.toggleClass("collapsed"),
                setTimeout(function () {
                  t(".sticky-sidebar").trigger("recalc.pin");
                }, 300);
            });
        },
        initSubmenu: function () {
          t(".submenu-toggle-btn").on("click", function (e) {
            t(this).parent().parent().toggleClass("show"), e.preventDefault();
          }),
            Wolmart.$window.on("resize", function () {
              window.innerWidth >= 1200 &&
                t(".submenu-toggle-btn").parent().parent().removeClass("show");
            });
        },
      };
    Wolmart.menu = a;
  })(jQuery),
  (function (t) {
    function e(t, e) {
      return this.init(t, e);
    }
    var i = function (t) {
        var e = this.wrapperEl,
          i = e.getAttribute("class");
        if (
          (i.match(/row|gutter\-\w\w|cols\-\d|cols\-\w\w-\d/g) &&
            e.setAttribute(
              "class",
              i
                .replace(/row|gutter\-\w\w|cols\-\d|cols\-\w\w-\d/g, "")
                .replace(/\s+/, " ")
            ),
          e.classList.contains("animation-slider"))
        )
          for (var a = e.children, n = a.length, o = 0; o < n; ++o)
            a[o].setAttribute("data-index", o + 1);
      },
      a = function (t) {
        var e,
          i = this.firstElementChild.firstElementChild.children,
          a = i.length;
        for (e = 0; e < a; ++e)
          if (!i[e].classList.contains("active")) {
            var n,
              o = Wolmart.byClass("appear-animate", i[e]);
            for (n = o.length - 1; n >= 0; --n)
              o[n].classList.remove("appear-animate");
          }
      },
      n = function (e) {
        t(window).trigger("appear.check");
        var i = t(e.currentTarget),
          a = i.find(".swiper-slide.active video");
        i
          .find(".swiper-slide:not(.swiper-slide-active) video")
          .each(function () {
            this.paused || i.trigger("autoplayStart"),
              this.pause(),
              (this.currentTime = 0);
          }),
          a.length &&
            (!0 === i.data("slider").options.autoplay &&
              i.trigger("autoplayStop"),
            a.each(function () {
              this.paused && this.play();
            }));
      },
      o = function () {
        var e = this;
        t(this.wrapperEl)
          .find(".swiper-slide-active .slide-animate")
          .each(function () {
            var i = t(this),
              a = t.extend(
                !0,
                {},
                Wolmart.animationOptions,
                Wolmart.parseOptions(i.data("animation-options"))
              ),
              n = a.duration,
              o = a.delay,
              s = a.name;
            setTimeout(function () {
              if (
                (i.css("animation-duration", n),
                i.css("animation-delay", o),
                i.addClass(s),
                i.hasClass("maskLeft"))
              ) {
                i.css("width", "fit-content");
                var t = i.width();
                i
                  .css("width", 0)
                  .css(
                    "transition",
                    "width " + (n || "0.75s") + " linear " + (o || "0s")
                  ),
                  i.css("width", t);
              }
              n = n || "0.75s";
              var a = Wolmart.requestTimeout(
                function () {
                  i.addClass("show-content");
                },
                o ? 1e3 * Number(o.slice(0, -1)) + 200 : 200
              );
              e.timers.push(a);
            }, 300);
          });
      },
      s = function (e) {
        t(this.wrapperEl)
          .find(".swiper-slide-active .slide-animate")
          .each(function () {
            var e = t(this);
            e.addClass("show-content"), e.attr("style", "");
          });
      },
      r = function (e) {
        var i = this,
          a = t(this.wrapperEl);
        (i.translateFlag = 1),
          (i.prev = i.next),
          a.find(".swiper-slide .slide-animate").each(function () {
            var e = t(this),
              i = t.extend(
                !0,
                {},
                Wolmart.animationOptions,
                Wolmart.parseOptions(e.data("animation-options"))
              );
            e.removeClass(i.name);
          });
      },
      l = function (e) {
        var i = this,
          a = t(this.wrapperEl);
        if (1 == i.translateFlag) {
          if (
            ((i.next = this.slider.activeIndex),
            a.find(".show-content").removeClass("show-content"),
            i.prev != i.next)
          ) {
            if (
              (a.find(".show-content").removeClass("show-content"),
              a.hasClass("animation-slider"))
            ) {
              for (var n = 0; n < i.timers.length; n++)
                Wolmart.deleteTimeout(i.timers[n]);
              i.timers = [];
            }
            a.find(".swiper-slide-active .slide-animate").each(function () {
              var e = t(this),
                a = t.extend(
                  !0,
                  {},
                  Wolmart.animationOptions,
                  Wolmart.parseOptions(e.data("animation-options"))
                ),
                n = a.duration,
                o = a.delay,
                s = a.name;
              e.css("animation-duration", n),
                e.css("animation-delay", o),
                e.css("transition-property", "visibility, opacity"),
                e.css("transition-delay", o),
                e.css("transition-duration", n),
                e.addClass(s),
                (n = n || "0.75s");
              var r = Wolmart.requestTimeout(
                function () {
                  e.css("transition-property", ""),
                    e.css("transition-delay", ""),
                    e.css("transition-duration", ""),
                    e.addClass("show-content"),
                    i.timers.splice(i.timers.indexOf(r), 1);
                },
                o
                  ? 1e3 * Number(o.slice(0, -1)) + 500 * Number(n.slice(0, -1))
                  : 500 * Number(n.slice(0, -1))
              );
              i.timers.push(r);
            });
          } else
            a.find(".swiper-slide")
              .eq(this.slider.activeIndex)
              .find(".slide-animate")
              .addClass("show-content");
          i.translateFlag = 0;
        }
      };
    (e.defaults = { slidesPerView: 1, speed: 300 }),
      (e.presets = {
        "product-thumbs-wrap": {
          slidesPerView: 4,
          spaceBetween: 10,
          freeMode: !0,
          watchSlidesVisibility: !0,
          watchSlidesProgress: !0,
          freeModeSticky: !0,
        },
      }),
      (e.prototype.init = function (c, d) {
        (this.timers = []),
          (this.translateFlag = 0),
          (this.prev = 0),
          (this.next = 0),
          (this.container = c[0]),
          (this.wrapperEl = c.children()[0]);
        var p = c.children(".swiper-button-next"),
          u = c.children(".swiper-button-prev"),
          m = c.children(".swiper-pagination"),
          h = c.children(".custom-dots");
        if (!c.data("slider")) {
          Wolmart.lazyLoad(c, !0);
          var f = c.attr("class").split(" "),
            g = t.extend(!0, {}, e.defaults);
          f.forEach(function (i) {
            var a = e.presets[i];
            a && t.extend(!0, g, a);
          }),
            p.length && t.extend(!0, g, { navigation: { nextEl: p[0] } }),
            u.length && t.extend(!0, g, { navigation: { prevEl: u[0] } }),
            m.length &&
              t.extend(!0, g, { pagination: { el: m[0], clickable: !0 } }),
            c.find("video").each(function () {
              this.loop = !1;
            }),
            t.extend(
              !0,
              g,
              Wolmart.parseOptions(c.attr("data-swiper-options")),
              d
            ),
            i.call(this),
            (this.slider = new Swiper(this.container, g)),
            c.data("slider", this.slider),
            c.trigger("initialized.slider", this.slider),
            this.slider.on("afterInit", a).on("transitionEnd", n),
            c.hasClass("animation-slider") && o.call(this),
            c.hasClass("animation-slider") &&
              this.slider
                .on("resize", s)
                .on("transitionStart", r.bind(this))
                .on("transitionEnd", l.bind(this)),
            h.length &&
              (this.slider.on("transitionEnd", function () {
                var t = this.activeIndex;
                h.children("a:nth-child(" + ++t + ")")
                  .addClass("active")
                  .siblings()
                  .removeClass("active");
              }),
              h.children("a").on("click", function (e) {
                e.preventDefault();
                var i = t(this);
                if (!i.hasClass("active")) {
                  var a = i.index();
                  i.closest(".swiper-container").data("slider").slideTo(a),
                    i.addClass("active").siblings().removeClass("active");
                }
              }));
        }
      }),
      (Wolmart.slider = function (i, a = {}, n = !1) {
        Wolmart.$(i).each(function () {
          var i = t(this);
          n
            ? new e(i, a)
            : Wolmart.call(function () {
                new e(i, a);
              });
        });
      }),
      (Wolmart.slider.pgToggle = function () {
        t(".swiper-container:not([class*='pg-']) .swiper-pagination").each(
          function () {
            var e = t(this);
            e.find("*").length <= 1
              ? e.css("display", "none")
              : e.css("display", "block");
          }
        );
      });
  })(jQuery),
  (function (t) {
    function e(t) {
      return this.init(t);
    }
    var i = function () {
      window.innerWidth < 992 &&
        (this.$sidebar.find(".sidebar-content").removeAttr("style"),
        this.$sidebar.find(".sidebar-content").attr("style", ""),
        this.$sidebar
          .find(".toolbox")
          .children(":not(:first-child)")
          .removeAttr("style"));
    };
    (e.prototype.init = function (e) {
      var a = this;
      return (
        (a.name = e),
        (a.$sidebar = t("." + e)),
        (a.isNavigation = !1),
        a.$sidebar.length &&
          ((a.isNavigation =
            a.$sidebar.hasClass("sidebar-fixed") &&
            a.$sidebar.parent().hasClass("toolbox-wrap")),
          a.isNavigation &&
            ((i = i.bind(this)), Wolmart.$window.on("resize", i)),
          Wolmart.$window.on("resize", function (t) {
            Wolmart.windowResized(t.timeStamp) &&
              Wolmart.$body.removeClass(e + "-active");
          }),
          a.$sidebar
            .find(".sidebar-toggle, .sidebar-toggle-btn")
            .add("sidebar" === e ? ".left-sidebar-toggle" : "." + e + "-toggle")
            .on("click", function (e) {
              a.toggle(), t(this).blur(), e.preventDefault();
            }),
          a.$sidebar
            .find(".sidebar-overlay, .sidebar-close")
            .on("click", function (t) {
              Wolmart.$body.removeClass(e + "-active"), t.preventDefault();
            })),
        !1
      );
    }),
      (e.prototype.toggle = function () {
        var e = this,
          i = 992;
        if (
          (e.$sidebar.hasClass("sidebar-switch-xl") && (i = 1200),
          window.innerWidth >= i && e.$sidebar.hasClass("sidebar-fixed"))
        ) {
          var a = e.$sidebar.hasClass("closed");
          if (
            (e.isNavigation &&
              (a || e.$sidebar.find(".filter-clean").hide(),
              e.$sidebar
                .siblings(".toolbox")
                .children(":not(:first-child)")
                .fadeToggle("fast"),
              e.$sidebar
                .find(".sidebar-content")
                .stop()
                .animate(
                  { height: "toggle", "margin-bottom": a ? "toggle" : -6 },
                  function () {
                    t(this).css("margin-bottom", ""),
                      a && e.$sidebar.find(".filter-clean").fadeIn("fast");
                  }
                )),
            e.$sidebar.hasClass("shop-sidebar"))
          ) {
            var n = t(".main-content .product-wrapper");
            n.length &&
              n.hasClass("product-lists") &&
              n.toggleClass("row cols-xl-2", !a);
          }
        } else
          e.$sidebar
            .find(".sidebar-overlay .sidebar-close")
            .css(
              "margin-left",
              -(window.innerWidth - document.body.clientWidth)
            ),
            Wolmart.$body.toggleClass(e.name + "-active").removeClass("closed");
        setTimeout(function () {
          t(window).trigger("appear.check");
        }, 400);
      }),
      (Wolmart.sidebar = function (t) {
        return new e().init(t);
      });
  })(jQuery),
  (function (t) {
    var e = {
      init: function () {
        var e;
        Wolmart.call(Wolmart.ratingTooltip, 500),
          Wolmart.call(Wolmart.setProgressBar(".progress-bar"), 500),
          this.initVariation(),
          this.initProductsScrollLoad(".scroll-load"),
          Wolmart.$body
            .on("mousedown", ".select-menu", function (e) {
              var i = t(e.currentTarget),
                a = t(e.target),
                n = i.hasClass("opened");
              t(".select-menu").removeClass("opened"),
                i.is(a.parent())
                  ? (!n && i.addClass("opened"), e.stopPropagation())
                  : (a.parent().toggleClass("active"),
                    a.parent().hasClass("active")
                      ? (t(".selected-items").children().length < 2 &&
                          t(".selected-items").show(),
                        t(
                          '<a href="#" class="selected-item">' +
                            a.text().split("(")[0] +
                            '<i class="w-icon-times-solid"></i></a>'
                        )
                          .insertBefore(".selected-items .filter-clean")
                          .hide()
                          .fadeIn()
                          .data("link", a.parent()))
                      : t(".selected-items > .selected-item")
                          .filter(function (t, e) {
                            return e.innerText == a.text().split("(")[0];
                          })
                          .fadeOut(function () {
                            t(this).remove(),
                              t(".selected-items").children().length < 2 &&
                                t(".selected-items").hide();
                          }));
            })
            .on("click", ".selected-item", function (e) {
              var i = t(this),
                a = i.data("link");
              a &&
                a.removeClass("active").fadeOut(function () {
                  i.remove();
                }),
                e.preventDefault();
            }),
          t(".selected-items .filter-clean").on("click", function (e) {
            var i = t(this);
            i.siblings().each(function () {
              var e = t(this).data("link");
              e && e.removeClass("active");
            }),
              i.parent().fadeOut(function () {
                i.siblings().remove();
              }),
              e.preventDefault();
          }),
          t(".filter-clean").on("click", function (e) {
            t(".shop-sidebar .filter-items .active").removeClass("active"),
              e.preventDefault();
          }),
          Wolmart.$body.on("click", ".select-menu a", function (t) {
            t.preventDefault();
          }),
          Wolmart.$body.on("click", ".selected-item i", function (e) {
            t(e.currentTarget)
              .parent()
              .fadeOut(function () {
                var e = t(this),
                  i = e.data("link");
                i && i.toggleClass("active"),
                  e.remove(),
                  t(".select-items").children().length < 2 &&
                    t(".select-items").hide();
              }),
              e.preventDefault();
          }),
          Wolmart.$body.on("mousedown", function (e) {
            t(".select-menu").removeClass("opened");
          }),
          Wolmart.$body.on("click", ".filter-items a", function (e) {
            var i = t(this).closest(".filter-items");
            i.hasClass("search-ul") ||
              i.parent().hasClass("select-menu") ||
              (t(this).parent().toggleClass("active"), e.preventDefault());
          }),
          Wolmart.$body.on(
            "click",
            ".product:not(.product-select) .btn-cart, .product-popup .btn-cart, .home .product-single .btn-cart",
            function (e) {
              e.preventDefault();
              var i = t(this),
                a = i.closest(".product, .product-popup");
              i.hasClass("disabled")
                ? alert(
                    "Please select some product options before adding this product to your cart."
                  )
                : (i.toggleClass("added").addClass("load-more-overlay loading"),
                  setTimeout(function () {
                    i.removeClass("load-more-overlay loading"),
                      Wolmart.Minipopup.open({
                        productClass: " product-cart",
                        name: a.find(".product-name, .product-title").text(),
                        nameLink: a
                          .find(".product-name > a, .product-title > a")
                          .attr("href"),
                        imageSrc: a
                          .find(
                            ".product-media img, .product-image:first-child img"
                          )
                          .attr("src"),
                        imageLink: a.find(".product-name > a").attr("href"),
                        message: "<p>has been added to cart:</p>",
                        actionTemplate:
                          '<a href="cart.html" class="btn btn-rounded btn-sm">View Cart</a><a href="checkout.html" class="btn btn-dark btn-rounded btn-sm">Checkout</a>',
                      });
                  }, 500));
            }
          ),
          Wolmart.$body.on(
            "click",
            ".product:not(.product-single) .btn-wishlist",
            function (e) {
              e.preventDefault();
              var i = t(this);
              i.toggleClass("added").addClass("load-more-overlay loading"),
                setTimeout(function () {
                  i.removeClass("load-more-overlay loading"),
                    i
                      .toggleClass("w-icon-heart")
                      .toggleClass("w-icon-heart-full");
                }, 500);
            }
          ),
          (e = t(".product-popup")).length &&
            Wolmart.$body.on("click", ".btn-quickview", function (i) {
              i.preventDefault(),
                Wolmart.popup(
                  {
                    items: { src: e[0].outerHTML },
                    callbacks: {
                      open: function () {
                        Wolmart.productSingle(
                          t(".mfp-product .product-single")
                        );
                      },
                      close: function () {
                        t(".mfp-product .swiper-container")
                          .data("slider")
                          .destroy();
                      },
                    },
                  },
                  "quickview"
                );
            }),
          (function () {
            var e,
              i = [],
              a = t(".page-wrapper > .compare-popup");
            function n() {
              a
                .find(".title")
                .after(
                  '<p class="compare-count text-center text-light mb-0">(' +
                    e +
                    " Products)</p>"
                ),
                a.find(".compare-count").length > 1 &&
                  a.find("p:last-child").remove();
            }
            a.length ||
              document.body.classList.contains("docs") ||
              (t(".page-wrapper").append(
                '<div class="compare-popup">                    <div class="container">                        <div class="compare-title">                            <h4 class="title title-center">Compare Products</h4>                        </div>                        <ul class="compare-product-list list-style-none">                            <li></li><li></li><li></li><li></li>                        </ul>                        <a href="#" class="btn btn-clean">Clean All</a>                        <a href="compare.html" class="btn btn-dark btn-rounded">Start Compare !</a>                    </div>                </div>                <div class="compare-popup-overlay">                </div>'
              ),
              (a = t(".page-wrapper > .compare-popup"))),
              Wolmart.$body
                .on("click", ".product .btn-compare", function (o) {
                  var s = t(this);
                  s.hasClass("added") && returne(),
                    o.preventDefault(),
                    s
                      .toggleClass("added")
                      .addClass("load-more-overlay loading"),
                    setTimeout(function () {
                      s.removeClass("load-more-overlay loading"),
                        s
                          .toggleClass("w-icon-compare")
                          .toggleClass("w-icon-check-solid"),
                        s.attr("href", "compare.html"),
                        a.addClass("show");
                    }, 500);
                  var r = s.closest(".product").find("img").eq(0).attr("src");
                  i.length >= 4 && i.shift(),
                    i.push(r),
                    t(".compare-popup li").each(function (t) {
                      i[t] &&
                        (this.innerHTML =
                          '<a href="product-default.html"><figure><img src="' +
                          i[t] +
                          '"/></figure></a>                                        <a href="#" class="btn btn-remove"><i class="w-icon-times-solid"></i></a>');
                    }),
                    (e = i.length),
                    n();
                })
                .on("click", ".compare-popup .btn-remove", function (a) {
                  a.preventDefault();
                  var o = t(a.currentTarget).closest("li"),
                    s = o.index(),
                    r = o.find("img").attr("src");
                  r &&
                    t(".page-wrapper .product img").each(function () {
                      if (this.getAttribute("src") == r) {
                        var e = t(this)
                          .closest(".product")
                          .find(".btn-compare");
                        e.length &&
                          (e.removeClass("added").attr("href", "#"),
                          e
                            .toggleClass("w-icon-check-solid")
                            .toggleClass("w-icon-compare"));
                      }
                    }),
                    i.splice(s, 1),
                    3 == s && o.empty(),
                    o
                      .nextAll()
                      .each(function () {
                        t(this).prev().html(t(this).html());
                      })
                      .last()
                      .empty(),
                    (e = i.length),
                    n();
                })
                .on("click", ".compare-popup .btn-clean", function (a) {
                  a.preventDefault(),
                    t(".page-wrapper .product img").each(function () {
                      var e = t(this),
                        a = this.getAttribute("src");
                      i.forEach(function (t) {
                        if (a == t) {
                          var i = e.closest(".product").find(".btn-compare");
                          i.length &&
                            (i.removeClass("added").attr("href", "#"),
                            i
                              .toggleClass("w-icon-check-solid")
                              .toggleClass("w-icon-compare"));
                        }
                      });
                    }),
                    i.splice(0, 4),
                    (e = i.length),
                    t(this).parent().find(".compare-product-list li").empty(),
                    n();
                }),
              Wolmart.$body.on("click", ".compare-popup-overlay", function () {
                a.removeClass("show");
              });
          })(),
          Wolmart.priceSlider(".filter-price-slider");
      },
      initVariation: function (e) {
        t(".product:not(.product-single) .product-variations > a").on(
          "click",
          function (e) {
            var i = t(this),
              a = i.closest(".product").find(".product-media img");
            a.data("image-src") || a.data("image-src", a.attr("src")),
              i.toggleClass("active").siblings().removeClass("active"),
              i.hasClass("active")
                ? a.attr("src", i.data("src"))
                : (a.attr("src", a.data("image-src")), i.blur()),
              e.preventDefault();
          }
        );
      },
      initProductsScrollLoad: function (e) {
        var i,
          a = Wolmart.$(e),
          n = t(e).data("url");
        n || (n = "assets/ajax/products.html");
        var o = function (e) {
          window.pageYOffset > i + a.outerHeight() - window.innerHeight - 150 &&
            "loading" != a.data("load-state") &&
            t.ajax({
              url: n,
              success: function (e) {
                var i = t(e);
                a.data("load-state", "loading"),
                  a.next().hasClass("load-more-overlay")
                    ? a.next().addClass("loading")
                    : t(
                        '<div class="mt-4 mb-4 load-more-overlay loading"></div>'
                      ).insertAfter(a),
                  setTimeout(function () {
                    a.next().removeClass("loading"),
                      a.append(i),
                      setTimeout(function () {
                        a.find(".product-wrap.fade:not(.in)").addClass("in");
                      }, 200),
                      a.data("load-state", "loaded"),
                      Wolmart.countDown(i.find(".product-countdown"));
                  }, 500);
                var n = parseInt(
                  a.data("load-count") ? a.data("load-count") : 0
                );
                a.data("load-count", ++n),
                  n > 2 &&
                    window.removeEventListener("scroll", o, { passive: !0 });
              },
              failure: function () {
                $this.text("Sorry something went wrong.");
              },
            });
        };
        a.length > 0 &&
          ((i = a.offset().top),
          window.addEventListener("scroll", o, { passive: !0 }));
      },
    };
    Wolmart.shop = e;
  })(jQuery),
  (function (t) {
    function e(t) {
      return this.init(t);
    }
    (e.min = 1),
      (e.max = 1e6),
      (e.value = 1),
      (e.prototype.init = function (t) {
        var i = this;
        (i.$minus = !1),
          (i.$plus = !1),
          (i.$value = !1),
          (i.value = !1),
          (i.startIncrease = i.startIncrease.bind(i)),
          (i.startDecrease = i.startDecrease.bind(i)),
          (i.stop = i.stop.bind(i)),
          (i.min = parseInt(t.attr("min"))),
          (i.max = parseInt(t.attr("max"))),
          i.min || t.attr("min", (i.min = e.min)),
          i.max || t.attr("max", (i.max = e.max)),
          (i.$value = t.val((i.value = e.value))),
          (i.$minus = t
            .parent()
            .find(".quantity-minus")
            .on("mousedown", function (t) {
              t.preventDefault(), i.startDecrease();
            })
            .on("touchstart", function (t) {
              t.cancelable && t.preventDefault(), i.startDecrease();
            })
            .on("mouseup", i.stop)),
          (i.$plus = t
            .parent()
            .find(".quantity-plus")
            .on("mousedown", function (t) {
              t.preventDefault(), i.startIncrease();
            })
            .on("touchstart", function (t) {
              t.cancelable && t.preventDefault(), i.startIncrease();
            })
            .on("mouseup", i.stop)),
          Wolmart.$body
            .on("mouseup", i.stop)
            .on("touchend", i.stop)
            .on("touchcancel", i.stop);
      }),
      (e.prototype.startIncrease = function (t) {
        t && t.preventDefault();
        var e = this;
        (e.value = e.$value.val()),
          e.value < e.max && e.$value.val(++e.value),
          (e.increaseTimer = Wolmart.requestTimeout(function () {
            (e.speed = 1),
              (e.increaseTimer = Wolmart.requestInterval(function () {
                e.$value.val(
                  (e.value = Math.min(
                    e.value + Math.floor((e.speed *= 1.05)),
                    e.max
                  ))
                );
              }, 50));
          }, 400));
      }),
      (e.prototype.startDecrease = function (t) {
        t && t.preventDefault();
        var e = this;
        (e.value = e.$value.val()),
          e.value > e.min && e.$value.val(--e.value),
          (e.decreaseTimer = Wolmart.requestTimeout(function () {
            (e.speed = 1),
              (e.decreaseTimer = Wolmart.requestInterval(function () {
                e.$value.val(
                  (e.value = Math.max(
                    e.value - Math.floor((e.speed *= 1.05)),
                    e.min
                  ))
                );
              }, 50));
          }, 400));
      }),
      (e.prototype.stop = function (t) {
        Wolmart.deleteTimeout(this.increaseTimer),
          Wolmart.deleteTimeout(this.decreaseTimer);
      }),
      (Wolmart.initQtyInput = function (i) {
        Wolmart.$(i).each(function () {
          var i = t(this);
          i.data("quantityInput") || i.data("quantityInput", new e(i));
        });
      });
  })(jQuery),
  (function (t) {
    function e(t, e) {
      return this.init(t, e);
    }
    (e.defaults = {
      removalDelay: 300,
      closeOnBgClick: !1,
      callbacks: {
        open: function () {
          t("html").css("overflow-y", "hidden"),
            t("body").css("overflow-x", "visible"),
            t(".mfp-wrap").css("overflow", "hidden auto"),
            t(".sticky-header.fixed").css(
              "padding-right",
              window.innerWidth - document.body.clientWidth
            );
        },
        close: function () {
          t("html").css("overflow-y", ""),
            t("body").css("overflow-x", "hidden"),
            t(".mfp-wrap").css("overflow", ""),
            t(".sticky-header.fixed").css("padding-right", "");
        },
      },
    }),
      (e.presets = {
        quickview: {
          type: "inline",
          mainClass: "mfp-product mfp-fade",
          tLoading: "Loading...",
        },
        video: {
          type: "iframe",
          mainClass: "mfp-fade",
          preloader: !1,
          closeBtnInside: !1,
        },
        login: {
          type: "ajax",
          mainClass: "mfp-login-popup mfp-fade ",
          tLoading: "",
          preloader: !1,
        },
      }),
      (e.prototype.init = function (i, a) {
        var n = t.magnificPopup.instance;
        function o(e) {
          !t(e.target).closest(".mfp-content").length ||
          t(e.target).hasClass("mfp-content")
            ? t.magnificPopup.instance.close()
            : ((n.st.closeOnBgClick = !1), (n.st.closeOnBgContentClick = !1));
        }
        n.isOpen
          ? n.content
            ? setTimeout(function () {
                Wolmart.popup(i, a);
              }, 5e3)
            : t.magnificPopup.close()
          : t.magnificPopup.open(
              t.extend(!0, {}, e.defaults, a ? e.presets[a] : {}, i)
            ),
          Wolmart.$body.on("mousedown", ".mfp-wrap", o),
          "ontouchstart" in document &&
            document.addEventListener("touchstart", o, { passive: !0 });
      }),
      (Wolmart.popup = function (t, i) {
        return new e(t, i);
      });
  })(jQuery),
  (function (t) {
    function e(t) {
      return this.init(t);
    }
    var i = function () {
        this.$wrapper
          .find(".product-details")
          .css(
            "height",
            window.innerWidth > 767
              ? this.$wrapper.find(".product-gallery")[0].clientHeight
              : ""
          );
      },
      a = function (e) {
        var i = t(this);
        i.hasClass("added") ||
          (e.preventDefault(),
          i.addClass("load-more-overlay loading"),
          setTimeout(function () {
            i.removeClass("load-more-overlay loading")
              .toggleClass("w-icon-heart")
              .toggleClass("w-icon-heart-full")
              .addClass("added")
              .attr("href", "wishlist.html");
          }, 500));
      },
      n = function (e) {
        e.preventDefault(),
          Wolmart.scrollTo(
            t(
              '.product-tabs > .nav a[href="' + this.getAttribute("href") + '"]'
            ).trigger("click")
          );
      };
    (e.prototype.init = function (e) {
      var o = this,
        s = e.find(".product-single-swiper");
      (o.$wrapper = e),
        (o.isQuickView = !!e.closest(".mfp-content").length),
        (o._isPgVertical = !1),
        o.isQuickView && ((i = i.bind(this)), Wolmart.ratingTooltip()),
        (function (t) {
          (t.$thumbs = t.$wrapper.find(".product-thumbs")),
            (t.$thumbsWrap = t.$thumbs.parent()),
            (t.$thumbUp = t.$thumbsWrap.find(".thumb-up")),
            (t.$thumbDown = t.$thumbsWrap.find(".thumb-down")),
            (t.$thumbsDots = t.$thumbs.children()),
            (t.thumbsCount = t.$thumbsDots.length),
            (t.$productThumb = t.$thumbsDots.eq(0)),
            (t._isPgVertical = t.$thumbsWrap
              .parent()
              .hasClass("product-gallery-vertical")),
            (t.thumbsIsVertical = t._isPgVertical && window.innerWidth >= 992),
            Wolmart.slider(t.$thumbsWrap, {}, !0);
        })(o),
        document.body.classList.contains("home") ||
          (s.parent().hasClass("product-gallery-video") &&
            (o.isQuickView ||
              s.append(
                '<a href="#" class="product-gallery-btn product-degree-viewer" title="Product 360 Degree Gallery"><i class="w-icon-rotate-3d"></i></a>'
              ),
            o.isQuickView ||
              s.append(
                '<a href="#" class="product-gallery-btn product-video-viewer" title="Product Video Thumbnail"><i class="w-icon-movie"></i></a>'
              ))),
        o.$wrapper.on("click", ".btn-wishlist", a),
        o.$wrapper.on("click", ".rating-reviews", n),
        "complete" === Wolmart.status &&
          (Wolmart.slider(s, {
            thumbs: { swiper: o.$thumbsWrap.data("slider") },
          }),
          Wolmart.initQtyInput(e.find(".quantity"))),
        s.length &&
          window.addEventListener(
            "resize",
            function () {
              Wolmart.requestTimeout(function () {
                null != s.data("slider") &&
                  (s.data("slider").update(),
                  o.$thumbsWrap.data("slider").update());
              }, 100);
            },
            { passive: !0 }
          ),
        o.$wrapper
          .find(".product-single-swiper")
          .on("initialized.slider", function (e) {
            t(e.target).find(".product-image").zoom(Wolmart.zoomImageOptions);
          }),
        o.$wrapper.find(".product-thumbs-sticky").length &&
          ((o.isStickyScrolling = !1),
          o.$wrapper.on(
            "click",
            ".product-thumb:not(.active)",
            o.clickStickyThumbnail.bind(this)
          ),
          window.addEventListener(
            "scroll",
            o.scrollStickyThumbnail.bind(this),
            { passive: !0 }
          )),
        (function (e) {
          (e.$selects = e.$wrapper.find(".product-variations select")),
            (e.$items = e.$wrapper.find(".product-variations")),
            (e.$priceWrap = e.$wrapper.find(".product-variation-price")),
            (e.$clean = e.$wrapper.find(".product-variation-clean")),
            (e.$btnCart = e.$wrapper.find(".btn-cart")),
            e.variationCheck(),
            e.$selects.on("change", function (t) {
              e.variationCheck();
            }),
            e.$items.children("a").on("click", function (i) {
              t(this).toggleClass("active").siblings().removeClass("active"),
                i.preventDefault(),
                e.variationCheck(),
                e.$items.parent(".product-image-swatch") && e.swatchImage();
            }),
            e.$clean.on("click", function (t) {
              t.preventDefault(), e.variationClean(!0);
            });
        })(this);
    }),
      (e.prototype.variationCheck = function () {
        var e = this,
          i = !0;
        e.$selects.each(function () {
          return this.value || (i = !1);
        }),
          e.$items.each(function () {
            var e = t(this);
            if (e.children("a:not(.size-guide)").length)
              return e.children(".active").length || (i = !1);
          }),
          i ? e.variationMatch() : e.variationClean();
      }),
      (e.prototype.variationMatch = function () {
        var t = this;
        t.$priceWrap
          .find("span")
          .text("$" + (Math.round(50 * Math.random()) + 200) + ".00"),
          t.$priceWrap.slideDown(),
          t.$clean.slideDown(),
          t.$btnCart.removeClass("disabled");
      }),
      (e.prototype.variationClean = function (t) {
        t && this.$selects.val(""),
          t && this.$items.children(".active").removeClass("active"),
          this.$priceWrap.slideUp(),
          this.$clean.css("display", "none"),
          this.$btnCart.addClass("disabled");
      }),
      (e.prototype.clickStickyThumbnail = function (e) {
        var i = this,
          a = t(e.currentTarget),
          n = (a.parent().children(".active").index(), a.index() + 1);
        a.addClass("active").siblings(".active").removeClass("active"),
          (this.isStickyScrolling = !0);
        var o = a
          .closest(".product-thumbs-sticky")
          .find(".product-image-wrapper > :nth-child(" + n + ")");
        o.length && ((o = o.offset().top + 10), Wolmart.scrollTo(o, 500)),
          setTimeout(function () {
            i.isStickyScrolling = !1;
          }, 300);
      }),
      (e.prototype.scrollStickyThumbnail = function () {
        var e = this;
        this.isStickyScrolling ||
          e.$wrapper
            .find(".product-image-wrapper .product-image")
            .each(function () {
              if (Wolmart.isOnScreen(this))
                return (
                  e.$wrapper
                    .find(
                      ".product-thumbs > :nth-child(" +
                        (t(this).index() + 1) +
                        ")"
                    )
                    .addClass("active")
                    .siblings()
                    .removeClass("active"),
                  !1
                );
            });
      }),
      (e.prototype.swatchImage = function () {
        var t = this.$items.find(".active img").attr("src"),
          e = this.$wrapper.find(
            ".swiper-slide:first-child .product-image img"
          ),
          i = this.$wrapper.find(
            ".swiper-slide:first-child .product-thumb img"
          );
        e.attr("src", t), i.attr("src", t);
      }),
      (Wolmart.productSingle = function (i) {
        return (
          Wolmart.$(i).each(function () {
            var i = t(this);
            i.is("body > *") || i.data("product-single", new e(i));
          }),
          null
        );
      });
  })(jQuery),
  (function (t) {
    function e(e) {
      e.preventDefault();
      var i,
        a,
        n = t(e.currentTarget),
        o = n.closest(".product-single"),
        s = n.closest(".review-image");
      if (
        (i = n.closest(".review-image").length
          ? n.closest(".review-image").find("img")
          : o.find(".product-single-swiper").length
          ? o.find(
              ".product-single-swiper .swiper-slide:not(.cloned) img:first-child"
            )
          : o.find(".product-gallery-carousel").length
          ? o.find(".product-gallery-carousel .swiper-slide:not(.cloned) img")
          : o.find(".product-image img:first-child")).length
      ) {
        a = i
          .map(function () {
            var e = t(this);
            return {
              src: e.attr("data-zoom-image"),
              w: 800,
              h: 900,
              title: e.attr("alt"),
            };
          })
          .get();
        var r = o.find(".product-single-swiper").data("slider"),
          l = r
            ? r.activeIndex
            : o.find(".product-gallery .product-gallery-btn").index(n);
        if (1 == s.length) l = s.find("img").index(n);
        if ("undefined" != typeof PhotoSwipe) {
          var c = t(".pswp")[0];
          if ("rtl" == Wolmart.$body.attr("dir"))
            var d = new PhotoSwipe(c, PhotoSwipeUI_Default, a, {
              index: l,
              closeOnScroll: !1,
              showAnimationDuration: 0,
              rtl: !0,
            });
          else
            d = new PhotoSwipe(c, PhotoSwipeUI_Default, a, {
              index: l,
              closeOnScroll: !1,
              showAnimationDuration: 0,
            });
          d.init(), (Wolmart.photoSwipe = d);
        }
      }
    }
    function i(t) {
      t.preventDefault(),
        Wolmart.popup(
          {
            items: {
              src: '<video src="assets/video/memory-of-a-woman.mp4" autoplay loop controls>',
              type: "inline",
            },
            mainClass: "mfp-video-popup",
          },
          "video"
        );
    }
    function a(e) {
      var i = t(this);
      i.addClass("active").siblings().removeClass("active"),
        i.parent().addClass("selected"),
        i.closest(".rating-form").find("select").val(i.text()),
        e.preventDefault();
    }
    function n(e) {
      var i = t(this),
        a = t(".main-content > .alert, .container > .alert");
      if (i.hasClass("disabled"))
        alert(
          "Please select some product options before adding this product to your cart."
        );
      else {
        if (a.length)
          a.fadeOut(function () {
            a.fadeIn();
          });
        else {
          var n =
            '<div class="alert alert-success alert-cart-product mb-2">                            <a href="cart.html" class="btn btn-success btn-rounded">View Cart</a>                            <p class="mb-0 ls-normal">“' +
            i.closest(".product-single").find(".product-title").text() +
            '” has been added to your cart.</p>                            <a href="#" class="btn btn-link btn-close" aria-label="button"><i class="close-icon"></i></a>                            </div>';
          i.closest(".product-single").before(n);
        }
        t(".product-sticky-content").trigger("recalc.pin");
      }
    }
    Wolmart.initProductSinglePage = function () {
      t(".product-gallery").each(function () {
        var e = t(this),
          i = e.find(".product-image");
        i.length &&
          0 == e.find(".swiper-container").length &&
          i.zoom(Wolmart.zoomImageOptions);
      }),
        (function (e) {
          var i = t(e),
            a = i.closest(".product-single"),
            n =
              '<div class="product product-list-sm mr-auto">                                        <figure class="product-media">                                        <img src="' +
              a.find(".product-image img").eq(0).attr("src") +
              '" alt="Product" width="85" height="85" />                                        </figure>                                        <div class="product-details pt-0 pl-2 pr-2">                                        <h4 class="product-name font-weight-normal mb-1">' +
              a.find(".product-details .product-title").text() +
              '</h4>                                        <div class="product-price mb-0">                                        <ins class="new-price">' +
              a.find(".new-price").text() +
              '</ins><del class="old-price">' +
              a.find(".old-price").text() +
              "</del></div>                                        </div></div>";
          function o() {
            i.hasClass("fix-top") &&
              window.innerWidth > 767 &&
              i.removeClass("fix-top").addClass("fix-bottom"),
              (i.hasClass("fix-bottom") && window.innerWidth > 767) ||
                (i.hasClass("fix-bottom") &&
                  window.innerWidth < 768 &&
                  i.removeClass("fix-bottom").addClass("fix-top"),
                i.hasClass("fix-top") && window.innerWidth);
          }
          i.find(".product-qty-form").before(n),
            window.addEventListener("resize", o, { passive: !0 }),
            o();
        })(".product-sticky-content"),
        document.body.classList.contains("home") ||
          Wolmart.$body
            .on("click", ".product-image-full", e)
            .on("click", ".review-image img", e)
            .on("click", ".product-video-viewer", i)
            .on("click", ".product-degree-viewer", function (e) {
              e.preventDefault(e),
                t.fn.ThreeSixty &&
                  (function (t) {
                    t.preventDefault(),
                      Wolmart.popup({
                        type: "inline",
                        mainClass: "product-popupbox wm-fade product-360-popup",
                        preloader: !1,
                        items: {
                          src: '<div class="product-gallery-degree">\t\t\t\t\t\t<div class="w-loading"><i></i></div>\t\t\t\t\t\t<ul class="product-degree-images"></ul>\t\t\t\t\t</div>',
                        },
                        callbacks: {
                          open: function () {
                            this.container
                              .find(".product-gallery-degree")
                              .ThreeSixty({
                                imagePath: "assets/images/products/video/",
                                filePrefix: "360-",
                                ext: ".jpg",
                                totalFrames: 18,
                                endFrame: 18,
                                currentFrame: 1,
                                imgList: this.container.find(
                                  ".product-degree-images"
                                ),
                                progress: ".w-loading",
                                height: 500,
                                width: 830,
                                navigation: !0,
                              });
                          },
                          beforeClose: function () {
                            this.container.empty();
                          },
                        },
                      });
                  })(e);
            })
            .on("click", ".rating-form .rating-stars > a", a)
            .on("click", ".product-single:not(.product-popup) .btn-cart", n);
    };
  })(jQuery),
  (function (t) {
    Wolmart.initCodePopup = function () {
      t(".box-btn.showcode span").each(function () {
        t(this).text("</>");
      }),
        t(".show-code-action").each(function () {
          var e,
            i,
            a = t(this),
            n = a[0].outerHTML.replace("show-code-action", ""),
            o = n.slice(1, n.length - 1).indexOf("<"),
            s = n.indexOf(">");
          o - s > 6
            ? ((e = new RegExp("\n" + String(" ").repeat(o - s - 6), "gi")),
              (i = n.replace(e, "\n")))
            : (i = n),
            a.data("codepopup", i);
        }),
        Wolmart.$body.on("click", ".box-btn.showcode", function () {
          var e = t(this);
          e.hasClass("enabled")
            ? (e.removeClass("enabled").addClass("disabled"),
              (e.children("p")[0].innerHTML = "Show Code: Disabled"),
              t(".show-code-action").each(function () {
                var e = t(this);
                e.removeClass("show-code-added"),
                  setTimeout(function () {
                    e[0].removeChild(e[0].lastElementChild);
                  }, 300);
              }))
            : (e.removeClass("disabled").addClass("enabled"),
              (e.children("p")[0].innerHTML = "Show Code: Enabled"),
              t(".show-code-action").each(function () {
                var e = t(this),
                  i = document.createElement("span");
                (i.className = "show-code"),
                  i.appendChild(document.createTextNode("</>")),
                  this.appendChild(i),
                  setTimeout(function () {
                    e.addClass("show-code-added");
                  }, 200);
              }));
        }),
        Wolmart.$body.on("click", ".show-code", function (e) {
          e.stopPropagation();
          var i = t(this).parent();
          if (i.hasClass("show-code-added")) {
            var a = i[0].lastElementChild;
            i[0].removeChild(a),
              t(".code-popup #textareaCode").text(i.data("codepopup")),
              t(".code-copy a").html(
                '<i class="copy-icon far fa-copy"></i>Copy to Clipboard'
              ),
              Wolmart.$body.on("click", ".code-copy a", function (e) {
                e.preventDefault(),
                  t(".code-popup #textareaCode").trigger("select"),
                  document.execCommand("copy"),
                  t(this).html('<i class="copy-icon far fa-copy"></i>Copied');
              }),
              setTimeout(function () {
                Wolmart.popup({
                  items: { src: ".code-popup" },
                  type: "inline",
                  tLoading: "",
                  mainClass: "mfp-code mfp-fadein-popup",
                }),
                  t(".CodeMirror").remove(),
                  CodeMirror.fromTextArea(
                    document.getElementById("textareaCode"),
                    {
                      mode: "text/html",
                      htmlMode: !0,
                      lineWrapping: !1,
                      smartIndent: !1,
                      spellcheck: !0,
                      addModeClass: !0,
                      readOnly: !0,
                    }
                  );
              }, 100),
              i[0].appendChild(a);
          }
        }),
        Wolmart.$body.on(
          "mouseenter mouseleave",
          ".show-code-action",
          function (t) {
            t.stopPropagation();
          }
        );
    };
  })(jQuery),
  (function (t) {
    function e(t, e) {
      return this.init(t, e);
    }
    var i = function (t) {
      var e = this,
        i = e.settings.months[t.getMonth()];
      (i += e.settings.displayYear ? " " + t.getFullYear() : ""),
        e.element.find(".calendar-title").html(i);
    };
    (e.defaultOptions = {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      days: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
      displayYear: !0,
      fixedStartDay: !0,
      dayNumber: 0,
      dayExcerpt: 3,
    }),
      (e.prototype.init = function (a, n) {
        var o = this;
        (o.element = a),
          (o.settings = t.extend(
            {},
            !0,
            e.defaultOptions,
            Wolmart.parseOptions(a.attr("data-calendar-options")),
            n
          )),
          (o.today = new Date()),
          (i = i.bind(this));
        var s = t('<div class="calendar"></div>'),
          r = t(
            '<div class="calendar-header"><a href="#" class="btn-calendar btn-calendar-prev"><i class="la la-angle-left"></i></a><span class="calendar-title"></span><a href="#" class="btn-calendar btn-calendar-next"><i class="la la-angle-right"></i></a></div>'
          );
        s.append(r),
          a.append(s),
          i(o.today),
          o.render(o.today, s),
          o.bindEvents();
      }),
      (e.prototype.render = function (e, i) {
        var a = this;
        i.find("table") && i.find("table").remove();
        var n = t("<table></table>"),
          o = t("<thead></thead>"),
          s = t("<tbody></tbody"),
          r = e.getFullYear(),
          l = e.getMonth(),
          c = new Date(r, l, 1),
          d = new Date(r, l + 1, 0),
          p = c.getDay();
        if (a.settings.fixedStartDay) {
          for (p = a.settings.dayNumber; c.getDay() != p; )
            c.setDate(c.getDate() - 1);
          for (; d.getDay() != (p + 7) % 7; ) d.setDate(d.getDate() + 1);
        }
        for (var u = p; u < p + 7; u++) {
          var m = t(
            "<th>" +
              a.settings.days[u % 7].substring(0, a.settings.dayExcerpt) +
              "</th>"
          );
          u % 7 == 0 && m.addClass("holiday"), o.append(m);
        }
        for (var h = c; h < d; h.setDate(h.getDate())) {
          var f = t("<tr></tr>");
          for (u = 0; u < 7; u++) {
            var g = t(
              '<td><span class="day" data-date="' +
                h.toISOString() +
                '">' +
                h.getDate() +
                "</span></td>"
            );
            h.toDateString() == new Date().toDateString() &&
              g.find(".day").addClass("today"),
              h.getMonth() != e.getMonth() &&
                g.find(".day").addClass("disabled"),
              f.append(g),
              h.setDate(h.getDate() + 1);
          }
          s.append(f);
        }
        n.append(o), n.append(s), i.append(n);
      }),
      (e.prototype.changeMonth = function (e) {
        this.today.setMonth(this.today.getMonth() + e, 1),
          this.render(this.today, t(this.element).find(".calendar")),
          i(this.today);
      }),
      (e.prototype.bindEvents = function () {
        var e = this;
        t(e.element)
          .find(".btn-calendar-prev")
          .on("click", function (t) {
            e.changeMonth(-1), t.preventDefault();
          }),
          t(e.element)
            .find(".btn-calendar-next")
            .on("click", function (t) {
              e.changeMonth(1), t.preventDefault();
            });
      }),
      (Wolmart.calendar = function (i, a) {
        Wolmart.$(i).each(function () {
          var i = t(this);
          Wolmart.call(function () {
            new e(i, a);
          });
        });
      }),
      (Wolmart.initVendor = function (e) {
        var i = t(e),
          a = i.closest(".page-content").find(".toolbox .vendor-search-toggle"),
          n = i.find(".store-phone");
        a.on("click", function (t) {
          var e = a.closest(".vendor-toolbox").next(".vendor-search-wrapper");
          e.hasClass("open")
            ? e.removeClass("open").slideUp()
            : e.addClass("open").slideDown(),
            t.preventDefault();
        }),
          n.on("click", function () {
            alert("Always open these types of links in the associated app");
          });
      }),
      (Wolmart.slideContent = function (e) {
        var i = t(e),
          a = i.next();
        i.on("click", function (t) {
          t.preventDefault(),
            a.hasClass("open")
              ? (a.removeClass("open").slideUp(),
                i.find(".custom-checkbox").removeClass("checked"))
              : (a.addClass("open").slideDown(),
                i.find(".custom-checkbox").addClass("checked"));
        });
      }),
      (Wolmart.initLoginVendor = function (e) {
        var i = t(e),
          a = i.parent().find(".login-vendor"),
          n = i.find(".check-customer");
        i.find(".check-seller").on("click", function () {
          i.find("#check-seller").addClass("active"),
            i.find("#check-customer").removeClass("active"),
            a.slideDown();
        }),
          n.on("click", function () {
            i.find("#check-customer").addClass("active"),
              i.find("#check-seller").removeClass("active"),
              a.slideUp();
          });
      });
  })(jQuery),
  jQuery,
  (Wolmart.initLayout = function () {
    Wolmart.isotopes(".grid:not(.grid-float)"),
      Wolmart.stickySidebar(".sticky-sidebar");
  }),
  (Wolmart.init = function () {
    Wolmart.appearAnimate(".appear-animate"),
      Wolmart.setTab(".nav-tabs"),
      Wolmart.stickyContent(".sticky-header", { scrollMode: !1 }),
      Wolmart.stickyContent(".sticky-footer", {
        minWidth: 0,
        maxWidth: 767,
        top: 150,
        hide: !0,
        max_index: 2100,
        scrollMode: !0,
      }),
      Wolmart.stickyContent(".sticky-toolbox", Wolmart.stickyToolboxOptions),
      Wolmart.stickyContent(
        ".product-sticky-content",
        Wolmart.stickyProductOptions
      ),
      Wolmart.parallax(".parallax"),
      Wolmart.skrollrParallax(),
      Wolmart.initFloatingParallax(),
      Wolmart.menu.init(),
      Wolmart.initScrollTopButton(),
      Wolmart.shop.init(),
      Wolmart.alert(".alert"),
      Wolmart.closeTopNotice(".banner-close"),
      Wolmart.accordion(".card-header > a"),
      Wolmart.sidebar("sidebar"),
      Wolmart.sidebar("right-sidebar"),
      Wolmart.productSingle(".product-single"),
      Wolmart.initProductSinglePage(),
      Wolmart.initQtyInput(".quantity"),
      Wolmart.initNavFilter(".nav-filters .nav-filter"),
      Wolmart.calendar(".calendar-container"),
      Wolmart.countDown(".product-countdown, .countdown"),
      Wolmart.initPopup(),
      Wolmart.initNotificationAlert(),
      Wolmart.countTo(".count-to"),
      Wolmart.initCartAction(".cart-offcanvas .cart-toggle"),
      Wolmart.Minipopup.init(),
      Wolmart.headerToggleSearch(".hs-toggle"),
      Wolmart.initVendor(".store"),
      Wolmart.slideContent(".login-toggle"),
      Wolmart.slideContent(".coupon-toggle"),
      Wolmart.slideContent(".checkbox-toggle"),
      Wolmart.initLoginVendor(".user-checkbox"),
      Wolmart.initCodePopup(),
      Wolmart.slider(".swiper-container"),
      Wolmart.call(Wolmart.slider.pgToggle),
      Wolmart.$window.on("resize", function () {
        Wolmart.call(Wolmart.slider.pgToggle);
      });
  }),
  jQuery,
  (window.onload = function () {
    (Wolmart.canvasWidth = window.innerWidth),
      (Wolmart.resizeTimeStamp = 0),
      (Wolmart.resizeChanged = !1),
      (Wolmart.status = "loaded"),
      document.body.classList.add("loaded"),
      Wolmart.call(Wolmart.initLayout),
      Wolmart.call(Wolmart.init),
      (Wolmart.status = "complete"),
      Wolmart.$window.trigger("wolmart_complete");
  });
////
export default {
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    return {
      direction: direction,
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",
      translation: {
        home: {
          ar: "الصفحة الرئيسية",
          en: "Home",
        },
        phone: {
          ar: "رقم الجوال",
          en: "Phone number",
        },
        account: {
          ar: "حسابي",
          en: "My Account",
        },
        dashboard: {
          ar: "الصفحة الرئيسية",
          en: "Dashboard",
        },
        orders: {
          ar: "الطلبات",
          en: "ORDERS",
        },
        addresses: {
          ar: "العناوين",
          en: "ADDRESSES",
        },
        details: {
          ar: "معلومات الحساب",
          en: "ACCOUNT DETAILS",
        },
        logout: {
          ar: "تسجيل الخروج",
          en: "LOGOUT",
        },
        hello: {
          ar: "مرحبا",
          en: "Hello",
        },
        not: {
          ar: "لست",
          en: "not",
        },
        order: {
          ar: "الطلب",
          en: "Order",
        },
        date: {
          ar: "التاريخ",
          en: "Date",
        },
        status: {
          ar: "الحاله",
          en: "Status",
        },
        total: {
          ar: "المجموع",
          en: "Total",
        },
        action: {
          ar: "امر",
          en: "Action",
        },
        view: {
          ar: "بحث",
          en: "View",
        },
        shop: {
          ar: "الذهاب الي المتجر",
          en: "Go to shop",
        },
        firstName: {
          ar: "الاسم الاول",
          en: "First name *",
        },
        email: {
          ar: "البريد الالكتروني",
          en: "Email address",
        },
        changePassword: {
          ar: "تغيير كلمه السر",
          en: "Change password",
        },
        old: {
          ar: "كلمه السر الحاليه",
          en: "Current Password",
        },
        new: {
          ar: "كلمه السر الجديده",
          en: "New password",
        },
        confirm: {
          ar: "اعد كتابه كلمه السر",
          en: "Confirm password",
        },
        save: {
          ar: " حفظ التغيرات",
          en: "Save changes",
        },
        name: {
          ar: 'الاسم:',
          en: 'Name:'
        },
        email: {
          ar: 'البريد الالكتروني:',
          en: 'Email address:'
        },
        address: {
          ar: 'العنوان:',
          en: 'Address:'
        },
        address2: {
          ar: 'العنوان الاختياري:',
          en: 'Optional Address:'
        },
        phone: {
          ar: 'رقم الجوال:',
          en: 'Phone number:'
        }
      },
      user: {},
      password: "",
      newPassword: "",
      conPassword: "",
      billing: '',
      orders: ''
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    async logout() {
      return (window.location.href = "/en/logout");
    },
    async getUser() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      let res = await axios.get(this.BASEURL + "users/get-info", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      });

      console.log(res.data)

      if (res.data.user != null) {
        this.billing = res.data.user.user
      }

      if (res.data.user.locked == 0) {
        return (location.href = "/" + this.direction + "/verification");
      }

      this.orders = res.data.order;
      if (this.orders != null) {
        this.orders.map( (res) => {
          if (res.status == 0) {
             res.latestStatus = 'Reviewing!'
             res.color = 'blue'
          } else if (res.status == 1) {
             res.latestStatus = 'In Progress!'
             res.color = 'green'
          } else if (res.status == 2) {
             res.latestStatus = 'Delievered!'
             res.color = 'black'
          } else if (res.status == 3) {
             res.latestStatus = 'Canceled!'
             res.color = 'red'
          }

          return res
        })
      }

      this.user = res.data.user;
    },
    async changePassword() {
      document.getElementsByClassName("error")[0].style.border = "none";
      document.getElementsByClassName("error")[1].style.border = "none";
      document.getElementsByClassName("error")[2].style.border = "none";
      if (this.newPassword != this.conPassword) {
        alert("passwords do not match");
        document.getElementsByClassName("error")[1].style.border =
          "1px solid red!important";
        document.getElementsByClassName("error")[2].style.border =
          "1px solid red!important";
        return false;
      } else {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + sessionStorage.getItem("access");
        const body = {
          oldPassword: this.password,
          password: this.newPassword,
        };

        console.log(this.BASEURL + "users/changepassword");

        axios
          .post(this.BASEURL + "users/changepassword", body)
          .then((res) => alert("Password has been changed"))
          .catch( error => alert('Something went wrong'));
      }
    },
  },
  created() {
    this.getUser();
  },
  beforeCreate() {
    if (!sessionStorage.getItem("LoggedIn")) {
      window.location.href = "/en/login?redirectsTo=/en/my-account";
    }
  },
};
</script>

<style scoped>
.page-wrapper {
  position: relative;
  -webkit-transition: margin 0.4s, opacity 0.5s;
  transition: margin 0.4s, opacity 0.5s;
}
.loaded > .page-wrapper {
  opacity: 1;
}

@media (max-width: 991px) {
  .sidebar-active .page-wrapper {
    margin-left: 310px;
    margin-right: -310px;
  }
  .right-sidebar-active .page-wrapper {
    margin-left: -310px;
    margin-right: 310px;
  }
}
@media (max-width: 479px) {
  .sidebar-content {
    width: 27rem;
  }

  .sidebar-active .page-wrapper {
    margin-left: 27rem;
    margin-right: -27rem;
  }
  .right-sidebar-active .page-wrapper {
    margin-left: -27rem;
    margin-right: 27rem;
  }
}

.mmenu-active .page-wrapper {
  margin-left: 296px;
  margin-right: -296px;
}

.page-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 18rem;
  background-color: #eee;
  text-align: center;
  text-transform: capitalize;
}

.page-title {
  font-size: 3.4rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.1;
  margin-bottom: 0.2rem;
}

.breadcrumb-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my-account .breadcrumb-nav {
  margin-bottom: 3.2rem;
}
.my-account .tab-vertical .nav {
  width: 24.41%;
  border: none;
}
.my-account .tab-content {
  width: 75.59%;
  padding-left: 1.5rem;
  border: none;
}
.my-account .nav-link,
.my-account .link-item {
  margin-bottom: 0;
  padding: 1.7rem 0 1.6rem;
  font-size: 1.6rem;
  text-transform: none;
  border-bottom: 1px solid #eee;
}
.my-account .link-item {
  font-weight: 600;
  color: #333;
  line-height: 1;
}
.my-account .link-item a {
  color: inherit;
}
.my-account .link-item:hover {
  color: #336699;
}
.my-account .tab-pane p {
  font-size: 1.4rem;
  line-height: 1.8;
}
.my-account .icon-box.text-center i {
  display: block;
  font-size: 6rem;
  color: #333;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.my-account .text-center .icon-box-icon {
  margin-bottom: 1.9rem;
}
.my-account .icon-box.text-center:hover .icon-box-icon i {
  color: #336699;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.my-account .icon-box.text-center:hover p {
  text-decoration: underline;
}
.my-account .icon-box.icon-box-side .icon-orders,
.my-account .icon-box.icon-box-side .icon-map-marker {
  margin-right: 1rem;
}
.my-account .icon-box.icon-box-side .w-icon-download {
  font-size: 2.4rem;
}
.my-account .icon-box.icon-box-side .icon-account {
  margin-right: 0.8rem;
}
.my-account .form-control {
  -webkit-transition: border-color 0.4s;
  transition: border-color 0.4s;
}
.my-account .form-control:focus {
  border-color: #336699;
}
.my-account .icon-box-light {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.my-account .icon-box-light i {
  font-size: 2.5rem;
  color: #999;
}
.my-account .icon-box-light .icon-box-title {
  font-size: 2rem;
}
.my-account .order:not(th) {
  padding-top: 0.9rem;
}
.my-account .order:not(th) .order-table {
  padding: 1.1rem 2.9rem 0;
}

#account-dashboard.tab-pane {
  padding-top: 1.5rem;
}
#account-dashboard p.greeting {
  font-size: 1rem;
}
#account-dashboard p a:hover {
  text-decoration: underline;
}
#account-dashboard .icon-box {
  padding: 4rem 2rem;
  border: 1px solid #eee;
  border-radius: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
#account-dashboard .icon-box:hover {
  -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}
#account-dashboard .icon-box:hover p {
  color: #336699;
}
#account-dashboard .icon-box p {
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #666;
  -webkit-transition: color;
  transition: color;
}
#account-dashboard .icon-box-title {
  font-size: 1.8rem;
  text-transform: capitalize;
}

#account-downloads.tab-pane {
  padding-top: 0.9rem;
}
#account-downloads .icon-box-side .w-icon-download {
  font-size: 2.5rem;
  margin-top: -0.5rem;
}
#account-downloads .icon-box {
  margin-bottom: 1.4rem;
}

#account-orders.tab-pane {
  padding-top: 0.8rem;
}
#account-orders .icon-box {
  margin-bottom: 1.8rem;
}
#account-orders .account-orders-table th {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#account-orders .account-orders-table td {
  padding-top: 2.1rem;
  padding-bottom: 2.1rem;
}
#account-orders .account-orders-table .order-id {
  padding-left: 1rem;
  width: 20.77%;
}
#account-orders .account-orders-table .order-date {
  width: 20.1%;
}
#account-orders .account-orders-table .order-status {
  width: 20%;
}
#account-orders .account-orders-table .order-total {
  width: 25.48%;
}
#account-orders .account-orders-table .order-action {
  width: 13.78%;
  padding-right: 1rem;
}
#account-orders .account-orders-table td.order-total {
  letter-spacing: 0;
}
#account-orders .order-action .btn {
  color: #333;
  border-color: #ccc;
  -webkit-transition: color 0.4s, border-color 0.4s, background-color 0.4s;
  transition: color 0.4s, border-color 0.4s, background-color 0.4s;
}
#account-orders .order-action .btn:hover {
  background-color: #333;
  border-color: #333;
  color: #fff;
}

#account-details.tab-pane {
  padding-top: 0.9rem;
}
#account-details.tab-pane .w-icon-user {
  margin-bottom: 0.3rem;
}
#account-details .icon-box {
  margin-bottom: 1.4rem;
}
#account-details .account-details-form label {
  color: #666;
  font-size: 1rem;
  line-height: 2.3;
}
#account-details .account-details-form .form-control {
  margin-bottom: 1.6rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  color: #666;
}
#account-details .account-details-form p {
  font-size: 1.2rem;
  letter-spacing: -0.015em;
  line-height: 2.1;
}
#account-details .card {
  border: 1px solid #eee;
}
#account-details .title-password {
  font-size: 1.8rem;
}

#account-addresses.tab-pane {
  padding-top: 0.8rem;
}
#account-addresses.tab-pane p {
  margin-bottom: 2.1rem;
  line-height: 1.6;
}
#account-addresses .title {
  font-size: 1.5rem;
  margin-bottom: 1.7rem;
  padding-bottom: 1.4rem;
}
#account-addresses .title-underline::after {
  background-color: #e5e5e5;
}
#account-addresses address {
  font-style: normal;
}
#account-addresses .address-table th,
#account-addresses .address-table td {
  padding: 0.3rem 0;
}
#account-addresses .address-table th {
  font-weight: normal;
  text-align: start;
  width: 100px;
  color: #999;
}
#account-addresses .address-table td {
  color: #333;
}

@media (max-width: 767px) {
  .my-account .tab-vertical .nav-tabs,
  .my-account .tab-vertical .tab-content {
    width: 100%;
  }

  #account-orders .account-orders-table td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    word-break: break-all;
  }
  #account-orders .account-orders-table .order-id {
    width: 10%;
    padding-left: 0;
  }
  #account-orders .order-action .btn {
    padding: 0;
    border: none;
    text-align: start;
  }
  #account-orders .order-action .btn:hover,
  #account-orders .order-action .btn:active,
  #account-orders .order-action .btn:focus {
    background-color: transparent;
    border: none;
    color: #336699;
  }
}

.tab-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tab-vertical .nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 26.3%;
}
.tab-vertical .nav-item {
  margin-right: 0;
}
.tab-vertical .nav-link {
  position: relative;
  padding-left: 2.2rem;
  padding-right: 2rem;
  text-align: left;
  width: 100%;
  margin-bottom: 0.5rem;
}
.tab-vertical .tab-content {
  width: 73.7%;
  border-top: 1px solid #eee;
  padding-left: 2rem;
}
.tab-vertical .tab-pane {
  padding-top: 1.3rem;
}
.tab-vertical.tab-nav-outline3 .nav {
  width: 27%;
}
.tab-vertical.tab-nav-outline3 .nav-link {
  padding-left: 2.1rem;
}
.tab-vertical.tab-nav-outline3 .tab-content {
  padding-left: 1.7rem;
}

.gutter-lg {
  margin-left: -15px;
  margin-right: -15px;
}
.gutter-lg > * {
  padding-left: 15px;
  padding-right: 15px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item:hover .nav-link {
  color: #336699;
}

/**
 * 1. Config
 * 2. Mixins
 * 3. Plugins
 * 4. Base
 * 5. Components
 * 6. Header
 * 7. Footer
 * 8. Pages
 */
/* 1. Config */
/*----------------------------------------
    Variables
-----------------------------------------*/
/* 2. Mixins */
/* 3. Base */
/*----------------------------------------
    Base
-----------------------------------------*/
*,
::after,
::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  font-size-adjust: 100%;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  line-height: 1.6;
  color: #666;
  background: #fff;
}

main {
  display: block;
  position: relative;
}

body:not(.loaded) * {
  -webkit-transition: all 0s !important;
  transition: all 0s !important;
}

.page-wrapper {
  position: relative;
  -webkit-transition: margin 0.4s, opacity 0.5s;
  transition: margin 0.4s, opacity 0.5s;
}
.loaded > .page-wrapper {
  opacity: 1;
}

ul {
  padding-left: 1.5em;
}

.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav ul,
.breadcrumb,
.widget-body,
.widget-body ul,
.dropdown-box,
.category-list,
.product-details ul,
.pagination,
.filter-items,
.product-tabs > div ul,
.nav-filters,
.shipping-methods,
.shipping-methods ul,
.fee-description {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

input,
.btn {
  margin: 0;
}

a {
  text-decoration: none;
  color: #336699;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
a:hover {
  color: #1914fe;
}

:focus {
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin: 0.5rem 0 1.5rem;
  border: 0;
  border-top: 1px solid #eee;
}

img {
  max-width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: middle;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.scrollable {
  -webkit-overflow-scrolling: touch;
}
.scrollable::-webkit-scrollbar {
  height: 7px;
  width: 4px;
}
.scrollable::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
}

.scrollable-light::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

.scrollable:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
}

.sticky-content.fix-top {
  top: 0;
}
.sticky-content.fix-bottom {
  bottom: 0;
}
.sticky-content.fixed {
  position: fixed;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  z-index: 1051;
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}
.sticky-content.fixed.fix-top {
  -webkit-animation: fixedTopContent 0.4s;
  animation: fixedTopContent 0.4s;
}
.sticky-content.fixed.fix-bottom {
  -webkit-animation: fixedBottomContent 0.4s;
  animation: fixedBottomContent 0.4s;
}

#scroll-top {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: -4rem;
  right: 1.5rem;
  z-index: 9999;
  width: 4.6rem;
  height: 4.6rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: #222;
  opacity: 0;
  -webkit-transition: bottom 0.3s, opacity 0.3s;
  transition: bottom 0.3s, opacity 0.3s;
}

#scroll-top.show {
  opacity: 1;
  bottom: 98px;
}

#scroll-top:focus,
#scroll-top:hover {
  text-decoration: none;
}

#scroll-top:hover {
  opacity: 0.8;
}

#scroll-top i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  color: #fff;
  background-color: #333;
  border: 2px solid #333;
  border-radius: 50%;
  z-index: 1;
}

#scroll-top svg {
  position: absolute;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  top: 1px;
  left: 1px;
  z-index: -1;
}

#scroll-top svg circle {
  stroke: #2c67e7;
  stroke-width: 4px;
  stroke-linecap: round;
  stroke-dasharray: 1, 400;
}

@media (max-width: 768px) {
  #scroll-top {
    display: none;
  }
}
.loading:not(.load-more-overlay),
.load-more-overlay.loading::after {
  -webkit-animation: spin 650ms infinite linear;
  animation: spin 650ms infinite linear;
  border: 2px solid transparent;
  border-radius: 32px;
  border-top: 2px solid #336699 !important;
  border-right: 2px solid rgba(51, 102, 153, 0.4) !important;
  border-bottom: 2px solid #336699 !important;
  border-left: 2px solid rgba(51, 102, 153, 0.4) !important;
  content: "";
  display: block;
  height: 20px;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -10px;
  right: auto;
  position: absolute;
  width: 20px;
}

.load-more-overlay {
  position: relative;
}
.load-more-overlay.loading::after {
  content: "";
}
.load-more-overlay::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.8;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@media (min-width: 768px) {
  .scroll-top.show {
    visibility: visible;
    opacity: 0.7;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/*----------------------------------------
    Layout
-----------------------------------------*/
.container,
.container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 479px) {
  .container,
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.container-fluid {
  max-width: 1820px;
}

.container {
  max-width: 1280px;
}

/*----------------------------------------
    Grid
-----------------------------------------*/
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.row > * {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.gutter-lg {
  margin-left: -15px;
  margin-right: -15px;
}
.gutter-lg > * {
  padding-left: 15px;
  padding-right: 15px;
}

.gutter-sm {
  margin-left: -5px;
  margin-right: -5px;
}
.gutter-sm > * {
  padding-left: 5px;
  padding-right: 5px;
}

.gutter-xs {
  margin-left: -1px;
  margin-right: -1px;
}
.gutter-xs > * {
  padding-left: 1px;
  padding-right: 1px;
}

.gutter-no {
  margin-left: 0;
  margin-right: 0;
}
.gutter-no > * {
  padding-left: 0;
  padding-right: 0;
}

.cols-1 > * {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.cols-2 > * {
  max-width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.cols-3 > * {
  max-width: 33.3333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
}

.cols-4 > * {
  max-width: 25%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.cols-5 > * {
  max-width: 20%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}

.cols-6 > * {
  max-width: 16.6667%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6667%;
  flex: 0 0 16.6667%;
}

.cols-7 > * {
  max-width: 14.2857%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 14.2857%;
  flex: 0 0 14.2857%;
}

.cols-8 > * {
  max-width: 12.5%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
}

@media (min-width: 480px) {
  .cols-xs-1 > * {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .cols-xs-2 > * {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .cols-xs-3 > * {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .cols-xs-4 > * {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .cols-xs-5 > * {
    max-width: 20%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }

  .cols-xs-6 > * {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .cols-xs-7 > * {
    max-width: 14.2857%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.2857%;
    flex: 0 0 14.2857%;
  }

  .cols-xs-8 > * {
    max-width: 12.5%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
  }
}
@media (min-width: 576px) {
  .cols-sm-1 > * {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .cols-sm-2 > * {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .cols-sm-3 > * {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .cols-sm-4 > * {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .cols-sm-5 > * {
    max-width: 20%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }

  .cols-sm-6 > * {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .cols-sm-7 > * {
    max-width: 14.2857%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.2857%;
    flex: 0 0 14.2857%;
  }

  .cols-sm-8 > * {
    max-width: 12.5%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
  }
}
@media (min-width: 768px) {
  .cols-md-1 > * {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .cols-md-2 > * {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .cols-md-3 > * {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .cols-md-4 > * {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .cols-md-5 > * {
    max-width: 20%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }

  .cols-md-6 > * {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .cols-md-7 > * {
    max-width: 14.2857%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.2857%;
    flex: 0 0 14.2857%;
  }

  .cols-md-8 > * {
    max-width: 12.5%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
  }
}
@media (min-width: 992px) {
  .cols-lg-1 > * {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .cols-lg-2 > * {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .cols-lg-3 > * {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .cols-lg-4 > * {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .cols-lg-5 > * {
    max-width: 20%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }

  .cols-lg-6 > * {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .cols-lg-7 > * {
    max-width: 14.2857%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.2857%;
    flex: 0 0 14.2857%;
  }

  .cols-lg-8 > * {
    max-width: 12.5%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
  }
}
@media (min-width: 1200px) {
  .cols-xl-1 > * {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .cols-xl-2 > * {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .cols-xl-3 > * {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .cols-xl-4 > * {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .cols-xl-5 > * {
    max-width: 20%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }

  .cols-xl-6 > * {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .cols-xl-7 > * {
    max-width: 14.2857%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.2857%;
    flex: 0 0 14.2857%;
  }

  .cols-xl-8 > * {
    max-width: 12.5%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
  }

  .cols-xl-9 > * {
    max-width: 11.1111%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 11.1111%;
    flex: 0 0 11.1111%;
  }

  .cols-xl-10 > * {
    max-width: 10%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
  }

  .cols-xl-11 > * {
    max-width: 9.0909%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 9.0909%;
    flex: 0 0 9.0909%;
  }

  .cols-xl-12 > * {
    max-width: 8.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333%;
    flex: 0 0 8.3333%;
  }
}
.col-1 {
  max-width: 8.3333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.3333%;
  flex: 0 0 8.3333%;
}

.col-2 {
  max-width: 16.6667%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

@media (min-width: 480px) {
  .col-xs-1 {
    max-width: 8.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333%;
    flex: 0 0 8.3333%;
  }

  .col-xs-2 {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xs-3 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .col-xs-4 {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xs-5 {
    max-width: 41.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xs-6 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .col-xs-7 {
    max-width: 58.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xs-8 {
    max-width: 66.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xs-9 {
    max-width: 75%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
  }

  .col-xs-10 {
    max-width: 83.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xs-11 {
    max-width: 91.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xs-12 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 576px) {
  .col-sm-1 {
    max-width: 8.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333%;
    flex: 0 0 8.3333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 768px) {
  .col-md-1 {
    max-width: 8.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333%;
    flex: 0 0 8.3333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 992px) {
  .col-lg-1 {
    max-width: 8.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333%;
    flex: 0 0 8.3333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl-1 {
    max-width: 8.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333%;
    flex: 0 0 8.3333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl-5col {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-xl-5col2 {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-xl-5col3 {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-xl-5col4 {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (min-width: 1600px) {
  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333%;
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666%;
    flex: 0 0 16.6666%;
    max-width: 16.6666%;
  }
}
.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

@media (min-width: 576px) {
  .order-sm-auto {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
}
@media (min-width: 768px) {
  .order-md-auto {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
}
@media (min-width: 992px) {
  .order-lg-auto {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
}
.grid {
  margin: -1rem;
}
.grid .banner,
.grid .category,
.grid .category > a,
.grid figure,
.grid .banner img,
.grid .category img {
  height: 100%;
}
.grid img {
  -o-object-fit: cover;
  object-fit: cover;
}
.grid .grid-item {
  padding: 1rem;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.grid.gutter-sm {
  margin: -0.5rem;
}
.grid.gutter-sm .grid-item {
  padding: 0.5rem;
}
.grid.gutter-no {
  margin: 0;
}
.grid.gutter-no .grid-item {
  padding: 0;
}
.grid::after {
  content: "";
  display: block;
  clear: both;
}
.grid.grid-float {
  display: block !important;
}
.grid.grid-float .grid-item {
  float: left;
}

/*----------------------------------------
    Spacing
-----------------------------------------*/
.mt-0 {
  margin-top: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
  font-size: 12px;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.mt-5 {
  margin-top: 2.5rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.pt-5 {
  padding-top: 2.5rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.mt-7 {
  margin-top: 3.5rem !important;
}

.mb-7 {
  margin-bottom: 3.5rem !important;
}

.pt-7 {
  padding-top: 3.5rem !important;
}

.pb-7 {
  padding-bottom: 3.5rem !important;
}

.mt-8 {
  margin-top: 4rem !important;
}

.mb-8 {
  margin-bottom: 4rem !important;
}

.pt-8 {
  padding-top: 4rem !important;
}

.pb-8 {
  padding-bottom: 4rem !important;
}

.mt-9 {
  margin-top: 4.5rem !important;
}

.mb-9 {
  margin-bottom: 4.5rem !important;
}

.pt-9 {
  padding-top: 4.5rem !important;
}

.pb-9 {
  padding-bottom: 4.5rem !important;
}

.mt-10 {
  margin-top: 5rem !important;
}

.mb-10 {
  margin-bottom: 5rem !important;
}

.pt-10 {
  padding-top: 5rem !important;
}

.pb-10 {
  padding-bottom: 5rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pr-2 {
  padding-right: 1rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.pl-3 {
  padding-left: 1.5rem !important;
}

.pr-3 {
  padding-right: 1.5rem !important;
}

.ml-4 {
  margin-left: 2rem !important;
}

.mr-4 {
  margin-right: 2rem !important;
}

.pl-4 {
  padding-left: 2rem !important;
}

.pr-4 {
  padding-right: 2rem !important;
}

.ml-5 {
  margin-left: 2.5rem !important;
}

.mr-5 {
  margin-right: 2.5rem !important;
}

.pl-5 {
  padding-left: 2.5rem !important;
}

.pr-5 {
  padding-right: 2.5rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.ml-7 {
  margin-left: 3.5rem !important;
}

.mr-7 {
  margin-right: 3.5rem !important;
}

.pl-7 {
  padding-left: 3.5rem !important;
}

.pr-7 {
  padding-right: 3.5rem !important;
}

.ml-8 {
  margin-left: 4rem !important;
}

.mr-8 {
  margin-right: 4rem !important;
}

.pl-8 {
  padding-left: 4rem !important;
}

.pr-8 {
  padding-right: 4rem !important;
}

.ml-9 {
  margin-left: 4.5rem !important;
}

.mr-9 {
  margin-right: 4.5rem !important;
}

.pl-9 {
  padding-left: 4.5rem !important;
}

.pr-9 {
  padding-right: 4.5rem !important;
}

.ml-10 {
  margin-left: 5rem !important;
}

.mr-10 {
  margin-right: 5rem !important;
}

.pl-10 {
  padding-left: 5rem !important;
}

.pr-10 {
  padding-right: 5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0rem !important;
  }

  .mt-sm-1 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-2 {
    margin-top: 1rem !important;
  }

  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-4 {
    margin-top: 2rem !important;
  }

  .mt-sm-5 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-6 {
    margin-top: 3rem !important;
  }

  .mt-sm-7 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-8 {
    margin-top: 4rem !important;
  }

  .mt-sm-9 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-10 {
    margin-top: 5rem !important;
  }

  .mb-sm-0 {
    margin-bottom: 0rem !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0rem !important;
  }

  .pt-sm-1 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-2 {
    padding-top: 1rem !important;
  }

  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-4 {
    padding-top: 2rem !important;
  }

  .pt-sm-5 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-6 {
    padding-top: 3rem !important;
  }

  .pt-sm-7 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-8 {
    padding-top: 4rem !important;
  }

  .pt-sm-9 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-10 {
    padding-top: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0rem !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0rem !important;
  }

  .mt-md-1 {
    margin-top: 0.5rem !important;
  }

  .mt-md-2 {
    margin-top: 1rem !important;
  }

  .mt-md-3 {
    margin-top: 1.5rem !important;
  }

  .mt-md-4 {
    margin-top: 2rem !important;
  }

  .mt-md-5 {
    margin-top: 2.5rem !important;
  }

  .mt-md-6 {
    margin-top: 3rem !important;
  }

  .mt-md-7 {
    margin-top: 3.5rem !important;
  }

  .mt-md-8 {
    margin-top: 4rem !important;
  }

  .mt-md-9 {
    margin-top: 4.5rem !important;
  }

  .mt-md-10 {
    margin-top: 5rem !important;
  }

  .mb-md-0 {
    margin-bottom: 0rem !important;
  }

  .mb-md-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-2 {
    margin-bottom: 1rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-4 {
    margin-bottom: 2rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-6 {
    margin-bottom: 3rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-8 {
    margin-bottom: 4rem !important;
  }

  .mb-md-9 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-10 {
    margin-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0rem !important;
  }

  .pt-md-1 {
    padding-top: 0.5rem !important;
  }

  .pt-md-2 {
    padding-top: 1rem !important;
  }

  .pt-md-3 {
    padding-top: 1.5rem !important;
  }

  .pt-md-4 {
    padding-top: 2rem !important;
  }

  .pt-md-5 {
    padding-top: 2.5rem !important;
  }

  .pt-md-6 {
    padding-top: 3rem !important;
  }

  .pt-md-7 {
    padding-top: 3.5rem !important;
  }

  .pt-md-8 {
    padding-top: 4rem !important;
  }

  .pt-md-9 {
    padding-top: 4.5rem !important;
  }

  .pt-md-10 {
    padding-top: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0rem !important;
  }

  .pb-md-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-2 {
    padding-bottom: 1rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 2rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-6 {
    padding-bottom: 3rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-8 {
    padding-bottom: 4rem !important;
  }

  .pb-md-9 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-10 {
    padding-bottom: 5rem !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0rem !important;
  }

  .mt-lg-1 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-2 {
    margin-top: 1rem !important;
  }

  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-4 {
    margin-top: 2rem !important;
  }

  .mt-lg-5 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-6 {
    margin-top: 3rem !important;
  }

  .mt-lg-7 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-8 {
    margin-top: 4rem !important;
  }

  .mt-lg-9 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-10 {
    margin-top: 5rem !important;
  }

  .mb-lg-0 {
    margin-bottom: 0rem !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0rem !important;
  }

  .pt-lg-1 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-2 {
    padding-top: 1rem !important;
  }

  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-4 {
    padding-top: 2rem !important;
  }

  .pt-lg-5 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-6 {
    padding-top: 3rem !important;
  }

  .pt-lg-7 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-8 {
    padding-top: 4rem !important;
  }

  .pt-lg-9 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-10 {
    padding-top: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0rem !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 5rem !important;
  }

  .ml-lg-0 {
    margin-left: 0rem !important;
  }

  .mr-lg-0 {
    margin-right: 0rem !important;
  }

  .pl-lg-0 {
    padding-left: 0rem !important;
  }

  .pr-lg-0 {
    padding-right: 0rem !important;
  }

  .ml-lg-1 {
    margin-left: 0.5rem !important;
  }

  .mr-lg-1 {
    margin-right: 0.5rem !important;
  }

  .pl-lg-1 {
    padding-left: 0.5rem !important;
  }

  .pr-lg-1 {
    padding-right: 0.5rem !important;
  }

  .ml-lg-2 {
    margin-left: 1rem !important;
  }

  .mr-lg-2 {
    margin-right: 1rem !important;
  }

  .pl-lg-2 {
    padding-left: 1rem !important;
  }

  .pr-lg-2 {
    padding-right: 1rem !important;
  }

  .ml-lg-3 {
    margin-left: 1.5rem !important;
  }

  .mr-lg-3 {
    margin-right: 1.5rem !important;
  }

  .pl-lg-3 {
    padding-left: 1.5rem !important;
  }

  .pr-lg-3 {
    padding-right: 1.5rem !important;
  }

  .ml-lg-4 {
    margin-left: 2rem !important;
  }

  .mr-lg-4 {
    margin-right: 2rem !important;
  }

  .pl-lg-4 {
    padding-left: 2rem !important;
  }

  .pr-lg-4 {
    padding-right: 2rem !important;
  }

  .ml-lg-5 {
    margin-left: 2.5rem !important;
  }

  .mr-lg-5 {
    margin-right: 2.5rem !important;
  }

  .pl-lg-5 {
    padding-left: 2.5rem !important;
  }

  .pr-lg-5 {
    padding-right: 2.5rem !important;
  }

  .ml-lg-6 {
    margin-left: 3rem !important;
  }

  .mr-lg-6 {
    margin-right: 3rem !important;
  }

  .pl-lg-6 {
    padding-left: 3rem !important;
  }

  .pr-lg-6 {
    padding-right: 3rem !important;
  }

  .ml-lg-7 {
    margin-left: 3.5rem !important;
  }

  .mr-lg-7 {
    margin-right: 3.5rem !important;
  }

  .pl-lg-7 {
    padding-left: 3.5rem !important;
  }

  .pr-lg-7 {
    padding-right: 3.5rem !important;
  }

  .ml-lg-8 {
    margin-left: 4rem !important;
  }

  .mr-lg-8 {
    margin-right: 4rem !important;
  }

  .pl-lg-8 {
    padding-left: 4rem !important;
  }

  .pr-lg-8 {
    padding-right: 4rem !important;
  }

  .ml-lg-9 {
    margin-left: 4.5rem !important;
  }

  .mr-lg-9 {
    margin-right: 4.5rem !important;
  }

  .pl-lg-9 {
    padding-left: 4.5rem !important;
  }

  .pr-lg-9 {
    padding-right: 4.5rem !important;
  }

  .ml-lg-10 {
    margin-left: 5rem !important;
  }

  .mr-lg-10 {
    margin-right: 5rem !important;
  }

  .pl-lg-10 {
    padding-left: 5rem !important;
  }

  .pr-lg-10 {
    padding-right: 5rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0rem !important;
  }

  .mt-xl-1 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-2 {
    margin-top: 1rem !important;
  }

  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-4 {
    margin-top: 2rem !important;
  }

  .mt-xl-5 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-6 {
    margin-top: 3rem !important;
  }

  .mt-xl-7 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-8 {
    margin-top: 4rem !important;
  }

  .mt-xl-9 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-10 {
    margin-top: 5rem !important;
  }

  .mb-xl-0 {
    margin-bottom: 0rem !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 5rem !important;
  }
}
@media (min-width: 992px) {
  .ml-lg-auto {
    margin-left: auto !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (min-width: 768px) {
  .ml-md-auto {
    margin-left: auto !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
/*----------------------------------------
    Typography
-----------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #333;
  letter-spacing: 0;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1.3rem;
}

p {
  font-size: 1.4rem;
  line-height: 1.86;
}

.typo-grid {
  margin-bottom: 4.7rem;
}

blockquote {
  margin: 0;
}

.list-style-none {
  list-style: none;
}

.list-type-check li {
  position: relative;
  padding-left: 2rem;
  line-height: 2;
}
.list-type-check li::before {
  position: absolute;
  display: block;
  left: 0;
  top: 1px;
  content: "";
  font-family: "wolmart";
  font-weight: 600;
  font-size: 1.3rem;
}

.list-circle li {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.list-circle i {
  margin-right: 1.2rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  text-align: center;
  line-height: 3.8rem;
  font-size: 1.1rem;
}

/*----------------------------------------
    Helper Class
-----------------------------------------*/
.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-normal {
  text-transform: none !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.p-sticky {
  position: sticky !important;
}

.p-relative {
  position: relative !important;
}

.x-50 {
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}
.x-50.y-50 {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.y-50 {
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (max-width: 1199px) {
  .d-xl-show {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .d-lg-show {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .d-md-show {
    display: none !important;
  }
}
@media (max-width: 575px) {
  .d-sm-show {
    display: none !important;
  }
}
@media (max-width: 479px) {
  .d-xs-none {
    display: block !important;
  }

  .d-xs-show {
    display: none !important;
  }

  .d-xs-block {
    display: block !important;
  }

  .d-xs-inline-block {
    display: inline-block !important;
  }
}
.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: 700 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-size-md {
  font-size: 1.3rem !important;
}

.font-size-sm {
  font-size: 1.2rem !important;
}

.font-size-normal {
  font-size: 1.4rem !important;
}

.font-size-lg {
  font-size: 2.2rem !important;
}

.font-size-xl {
  font-size: 2.4rem !important;
}

.font-primary {
  font-family: Poppins, sans-serif !important;
}

.font-secondary {
  font-family: "Open Sans", sans-serif !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-white {
  color: #fff !important;
}

.text-light {
  color: #999 !important;
}

.text-lighter {
  color: #ccc !important;
}

.text-primary {
  color: #336699 !important;
}

.text-secondary {
  color: #f93 !important;
}

.text-dark {
  color: #333 !important;
}

.text-default {
  color: #666 !important;
}

.text-hover-underline::after {
  display: block;
  margin-top: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  content: "";
}
.text-hover-underline:hover::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.ls-25 {
  letter-spacing: -0.025em !important;
}

.ls-15 {
  letter-spacing: -0.015em !important;
}

.ls-10 {
  letter-spacing: -0.01em !important;
}

.ls-50 {
  letter-spacing: -0.05em !important;
}

.ls-normal {
  letter-spacing: 0 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-dark {
  background-color: #333 !important;
}

.bg-primary {
  background-color: #336699 !important;
}

.bg-secondary {
  background-color: #f93 !important;
}

.bg-grey {
  background-color: #f5f5f5 !important;
}

.bg-image {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.br-lg {
  border-radius: 1.3rem !important;
  overflow: hidden !important;
}

.br-sm {
  border-radius: 0.5rem !important;
  overflow: hidden !important;
}

.br-xs {
  border-radius: 0.3rem !important;
  overflow: hidden !important;
}

.br-50 {
  border-radius: 50% !important;
  overflow: hidden !important;
}

.border-no {
  border: none !important;
}

.bb-no {
  border-bottom: none !important;
}

.bt-no {
  border-top: none !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.order-reverse {
  -webkit-box-ordinal-group: 0 !important;
  -ms-flex-order: -1 !important;
  order: -1 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.after-none::after {
  display: none !important;
}

.before-none::before {
  display: none !important;
}

/* 4. Plugins */
/* -------------------------------------------
    Swiper Container
---------------------------------------------- */
.swiper {
  position: relative;
}
.swiper > .swiper-container {
  position: static;
}
.swiper > .swiper-container > .swiper-pagination {
  bottom: -4.5rem;
}

.swiper-pagination {
  position: static;
  margin-top: 1.5rem;
}
.swiper-pagination .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #d7d7d7;
  opacity: 1;
}
.swiper-pagination .swiper-pagination-bullet:hover {
  background-color: #ccc;
  border-color: #ccc;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #336699;
  border-color: #336699;
}

.swiper-container-rtl .swiper-button-next::after,
.swiper-container-rtl .swiper-button-prev::after {
  content: "";
}
.swiper-container-rtl .swiper-button-next::before {
  content: "";
}
.swiper-container-rtl .swiper-button-prev::before {
  content: "";
}

.swiper-button-next,
.swiper-button-prev {
  padding: 0;
  z-index: 99;
  border: none;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}
.swiper-button-next::before,
.swiper-button-prev::before {
  font-family: "Wolmart";
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  cursor: default;
  color: #ccc;
}

.swiper-button-next::before {
  content: "";
}

.swiper-button-prev::before {
  content: "";
}

.swiper-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.swiper-container:not(.swiper-container-initialized) .swiper-wrapper {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  width: auto;
}
.swiper-container:not(.swiper-container-initialized) .swiper-slide {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-container:not(.swiper-container-initialized) .swiper-wrapper.row {
  width: calc(100% + 2rem);
}
.swiper-container:not(.swiper-container-initialized) .swiper-wrapper.gutter-no {
  width: 100%;
}
.swiper-container:not(.swiper-container-initialized) .swiper-wrapper.gutter-xs {
  width: calc(100% + 2 * 1px);
}
.swiper-container:not(.swiper-container-initialized) .swiper-wrapper.gutter-sm {
  width: calc(100% + 2 * 5px);
}
.swiper-container:not(.swiper-container-initialized) .swiper-wrapper.gutter-md {
  width: calc(100% + 2 * 10px);
}
.swiper-container:not(.swiper-container-initialized) .swiper-wrapper.gutter-lg {
  width: calc(100% + 2 * 15px);
}

.swiper-theme .swiper-button-prev,
.swiper-theme .swiper-button-next {
  margin: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 400;
  color: #336699;
}
.swiper-theme .swiper-button-prev:not(.swiper-button-disabled):hover,
.swiper-theme .swiper-button-prev:not(.swiper-button-disabled):active,
.swiper-theme .swiper-button-prev:not(.swiper-button-disabled):focus,
.swiper-theme .swiper-button-next:not(.swiper-button-disabled):hover,
.swiper-theme .swiper-button-next:not(.swiper-button-disabled):active,
.swiper-theme .swiper-button-next:not(.swiper-button-disabled):focus {
  color: #fff;
  background-color: #336699;
  border-color: #336699;
}
.swiper-theme .swiper-button-prev.swiper-button-disabled,
.swiper-theme .swiper-button-next.swiper-button-disabled {
  color: #ccc;
  opacity: 0.6;
}
.swiper-theme .swiper-button-prev {
  left: -40px;
}
.swiper-theme .swiper-button-next {
  right: -40px;
}
.swiper-theme .swiper-pagination .swiper-pagination-bullet {
  margin: 5px;
}
.swiper-theme .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #336699;
  border-color: #336699;
  width: 8px;
}

@media (max-width: 1300px) {
  .swiper-theme .swiper-button-prev {
    left: 20px;
  }
  .swiper-theme .swiper-button-next {
    right: 20px;
  }
}
.nav-inner > .swiper-button-prev,
.nav-inner > .swiper-button-next {
  opacity: 0;
  visibility: hidden;
}
.nav-inner > .swiper-button-prev {
  left: 0;
}
.nav-inner > .swiper-button-next {
  right: 0;
}
.nav-inner:hover > .swiper-button-prev,
.nav-inner:hover > .swiper-button-next {
  opacity: 1;
  visibility: visible;
}
.nav-inner:hover > .swiper-button-prev {
  left: 2rem;
}
.nav-inner:hover > .swiper-button-next {
  right: 2rem;
}

.nav-top > .swiper-button-next,
.nav-top > .swiper-button-prev {
  position: absolute;
  width: 15px;
  top: -5.3rem;
  left: auto;
  color: #777;
  font-size: 1.4rem;
  font-weight: 400;
}
.nav-top > .swiper-button-next:not(.swiper-button-disabled):hover,
.nav-top > .swiper-button-next:not(.swiper-button-disabled):active,
.nav-top > .swiper-button-next:not(.swiper-button-disabled):focus,
.nav-top > .swiper-button-prev:not(.swiper-button-disabled):hover,
.nav-top > .swiper-button-prev:not(.swiper-button-disabled):active,
.nav-top > .swiper-button-prev:not(.swiper-button-disabled):focus {
  background-color: transparent;
  border-color: transparent;
  color: #336699;
}
.nav-top > .swiper-button-next.swiper-button-disabled,
.nav-top > .swiper-button-prev.swiper-button-disabled {
  color: #666;
}
.nav-top > .swiper-button-next {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  right: 3.7rem;
}
.nav-top > .swiper-button-prev {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  right: 3.5rem;
}

.nav-right .swiper-button-prev,
.nav-right .swiper-button-next {
  position: absolute;
  right: 3rem;
  top: auto;
  bottom: 2.8rem;
  left: auto;
}
.nav-right .swiper-button-prev {
  right: 5.5rem;
}

.pg-white > .swiper-pagination .swiper-pagination-bullet {
  background-color: #fff;
  border-color: #fff;
  opacity: 0.8;
}
.pg-white > .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #336699;
  border-color: #336699;
  opacity: 1;
}

.pg-grey > .swiper-pagination .swiper-pagination-bullet {
  background-color: #eee;
  border-color: #eee;
}
.pg-grey > .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #336699;
  border-color: #336699;
}

.swiper-theme > .swiper-pagination .swiper-pagination-bullet {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.swiper-theme > .swiper-pagination .swiper-pagination-bullet-active {
  width: 18px;
  border-radius: 9rem;
}

.pg-inner .swiper-pagination {
  position: absolute;
  bottom: 4.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.pg-right .swiper-pagination {
  position: absolute;
  bottom: 4.2rem;
  right: 3rem;
  left: auto;
  width: unset;
}

.swiper-img-dots {
  z-index: 1000;
}
.swiper-img-dots a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  margin: 0 1.5rem 1rem;
  border-radius: 50%;
  border: 3px solid transparent;
  -webkit-box-shadow: 0 0 0 2px #ccc;
  box-shadow: 0 0 0 2px #ccc;
  overflow: hidden;
  -webkit-transition: padding 0.4s, -webkit-box-shadow 0.4s;
  transition: padding 0.4s, -webkit-box-shadow 0.4s;
  transition: padding 0.4s, box-shadow 0.4s;
  transition: padding 0.4s, box-shadow 0.4s, -webkit-box-shadow 0.4s;
}
.swiper-img-dots a.active,
.swiper-img-dots a:hover {
  -webkit-box-shadow: 0 0 0 2px #336699;
  box-shadow: 0 0 0 2px #336699;
}
.swiper-img-dots a img {
  border-radius: 50%;
}

.brands-swiper .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.swiper-container .slide-animate {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  will-change: filter, transform, opacity;
  opacity: 0;
}
.swiper-container .slide-animate.show-content {
  opacity: 1;
}

.shadow-swiper {
  margin: -20px;
  padding: 20px;
}

@media (max-width: 575px) {
  .shadow-swiper {
    margin: -15px;
    padding: 15px;
  }
}
.swiper-nav-lg > .swiper-button-next,
.swiper-nav-lg > .swiper-button-prev,
.swiper-nav-md > .swiper-button-next,
.swiper-nav-md > .swiper-button-prev {
  background-color: transparent;
  border: none;
}
.swiper-nav-lg > .swiper-button-next:not(.swiper-button-disabled):hover,
.swiper-nav-lg > .swiper-button-next:not(.swiper-button-disabled):focus,
.swiper-nav-lg > .swiper-button-next:not(.swiper-button-disabled):active,
.swiper-nav-lg > .swiper-button-prev:not(.swiper-button-disabled):hover,
.swiper-nav-lg > .swiper-button-prev:not(.swiper-button-disabled):focus,
.swiper-nav-lg > .swiper-button-prev:not(.swiper-button-disabled):active,
.swiper-nav-md > .swiper-button-next:not(.swiper-button-disabled):hover,
.swiper-nav-md > .swiper-button-next:not(.swiper-button-disabled):focus,
.swiper-nav-md > .swiper-button-next:not(.swiper-button-disabled):active,
.swiper-nav-md > .swiper-button-prev:not(.swiper-button-disabled):hover,
.swiper-nav-md > .swiper-button-prev:not(.swiper-button-disabled):focus,
.swiper-nav-md > .swiper-button-prev:not(.swiper-button-disabled):active {
  background-color: transparent;
  border: none;
}

.swiper-nav-lg > .swiper-button-next,
.swiper-nav-lg > .swiper-button-prev {
  font-size: 4.8rem;
}

.swiper-nav-md > .swiper-button-next,
.swiper-nav-md > .swiper-button-prev {
  font-size: 3.3rem;
}

@media (max-width: 575px) {
  .swiper-img-dots a {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.pg-show > .swiper-pagination {
  display: block;
}

@media (min-width: 1200px) {
  .pg-xl-hide > .swiper-pagination {
    display: none;
  }
}
@media (min-width: 1600px) {
  .pg-xxl-hide > .swiper-pagination {
    display: none;
  }
}
.nav-hide > .swiper-button-prev,
.nav-hide > .swiper-button-next {
  display: none;
}

@media (min-width: 1200px) {
  .nav-xl-show > .swiper-button-prev,
  .nav-xl-show > .swiper-button-next {
    display: block;
  }
}
@media (min-width: 1600px) {
  .nav-xxl-show > .swiper-button-prev,
  .nav-xxl-show > .swiper-button-next {
    display: block;
  }
}
/* -------------------------------------------
    No UiSlider
---------------------------------------------- */
.noUi-target {
  background: #ccc;
  margin: 4px 5px 1.5rem 5px;
}

.noUi-connects {
  padding: 11.5px 0;
  top: -10px;
}

.noUi-connect {
  background: #336699;
  margin-top: 10px;
  height: 2px;
}

.noUi-horizontal {
  height: 2px;
  background-color: #eee;
}
.noUi-horizontal .noUi-handle {
  width: 1.1rem;
  height: 1.1rem;
  background: #336699;
}

/* 5. Component */
/*----------------------------------------
    Accordion
-----------------------------------------*/
.accordion {
  overflow: hidden;
}
.accordion .collapsed,
.accordion .expanding {
  display: none;
}

.card-header {
  color: #333;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 1.5;
}
.card-header a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: inherit;
  padding: 1.4rem 5rem 1.4rem 2rem;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
.card-header a.collapse {
  color: #336699;
}
.card-header a::after,
.card-header a::before {
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -0.1rem;
  font-family: "wolmart";
  font-size: 1.2rem;
  font-weight: 400;
  color: #333;
}
.card-header a:hover {
  color: #336699;
}
.card-header a:hover::after {
  color: #336699;
}

.expand::after,
.collapse::after {
  content: "";
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.expand::after {
  -webkit-transform: translateY(-50%) rotate(0deg);
  transform: translateY(-50%) rotate(0deg);
}

.collapse::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.card-body {
  padding: 1.2rem 2rem;
}
.card-body p {
  font-size: 1.3rem;
  line-height: 2;
}

.accordion-simple .card {
  border-top: 1px solid #eee;
}
.accordion-simple .card:last-child {
  border-bottom: 1px solid #eee;
}
.accordion-simple .card-body {
  padding-top: 0;
}

.accordion-boxed .card-header a {
  padding-bottom: 1.6rem;
}
.accordion-boxed .card {
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
.accordion-boxed .card:last-child {
  border-bottom: 1px solid #eee;
}
.accordion-boxed .card-body {
  padding-top: 0.5rem;
  padding-bottom: 1.1rem;
}
.accordion-boxed.accordion-gutter-md .card {
  border: 1px solid #eee;
}
.accordion-boxed.accordion-plus .expand::after,
.accordion-boxed.accordion-plus .expand::before,
.accordion-boxed.accordion-plus .collapse::after,
.accordion-boxed.accordion-plus .collapse::before {
  background-color: #333;
}

.accordion-bg .card-header a {
  background-color: #f5f5f5;
}
.accordion-bg.accordion-primary .card-header a {
  background-color: #336699;
  color: #fff;
}
.accordion-bg.accordion-primary .card-header a::after {
  color: #fff;
}
.accordion-bg.accordion-plus .expand::before,
.accordion-bg.accordion-plus .expand::after,
.accordion-bg.accordion-plus .collapse::before,
.accordion-bg.accordion-plus .collapse::after {
  background-color: #fff;
}

.accordion-plus .expand::before,
.accordion-plus .collapse::before {
  content: "";
  width: 1px;
  height: 1rem;
  right: 2.4rem;
  -webkit-transition: background-color 0.3s, -webkit-transform 0.3s;
  transition: background-color 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, background-color 0.3s;
  transition: transform 0.3s, background-color 0.3s, -webkit-transform 0.3s;
}
.accordion-plus .expand::after,
.accordion-plus .collapse::after {
  content: "";
  width: 1rem;
  height: 1px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.accordion-plus .expand::before {
  background-color: #333;
  -webkit-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}
.accordion-plus .expand::after {
  -webkit-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}
.accordion-plus .collapse::before {
  background-color: transparent !important;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.accordion-plus .collapse::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.accordion-plus .expand::after,
.accordion-plus .collapse::after {
  font-size: 1.6rem;
  right: 2rem;
  background-color: #333;
}

.accordion-border .card-body {
  border: 1px solid #eee;
  border-top: 0;
}

.accordion-icon .card-header i {
  font-size: 1.7rem;
  margin: 0 0.8rem 0 0;
}
.accordion-icon .card-body {
  padding-top: 0.3rem;
  padding-bottom: 1.2rem;
}
.accordion-icon.accordion a {
  padding: 1.8rem 5rem 1.8rem 2rem;
  word-break: break-word;
}

.accordion-gutter-md .card:not(:first-child) {
  margin-top: 1rem;
}

@media (max-width: 375px) {
  .without-bg-section .title::before,
  .without-bg-section .title::after {
    content: none;
  }
}
/*---------------------
    Alert
        - Default
        - Simple Alert
        - Success Alert
        - Primary Alert
----------------------*/
.alert-rounded {
  border-radius: 0.4rem;
}

.alert {
  position: relative;
  padding: 1.4rem 3.5rem 1.4rem 2rem;
  font-size: 1.4rem;
  font-family: Poppins, sans-serif;
  line-height: 1.75;
  border: 1px solid;
  letter-spacing: -0.003em;
  border-radius: 0.3rem;
}
.alert .close-icon {
  width: 1.6rem;
  height: 1.6rem;
  vertical-align: middle;
}
.alert .close-icon::before {
  height: 1px;
}
.alert .close-icon::after {
  width: 1px;
}
.alert ul {
  font-size: 1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
  padding-left: 3.9rem;
  list-style-type: none;
}
.alert ul li {
  position: relative;
  line-height: 1.9;
}
.alert ul li::before {
  position: absolute;
  content: "";
  font-family: "wolmart";
  font-weight: 600;
  font-size: 1.3rem;
  left: -1.9rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 0.1rem;
}
.alert ul a {
  color: inherit;
}
.alert ul a:hover {
  color: #336699;
}

.alert-title {
  font-size: 1.4rem;
  color: inherit;
  line-height: inherit;
  margin-bottom: 0;
  letter-spacing: 0.01em;
  font-weight: 600;
}

.btn-close .close-icon::before,
.btn-close .close-icon::after {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.btn-close:hover .close-icon::before,
.btn-close:hover .close-icon::after {
  background-color: #000;
}

.alert-success {
  border-color: #c4df9b;
  color: #799b5a;
}
.alert-success.alert-bg {
  background-color: #c4df9b;
}

.alert-primary {
  border-color: #d7e8f8;
  color: #336699;
}
.alert-primary.alert-bg {
  background-color: #d7e8f8;
}
.alert-primary .close-icon::before,
.alert-primary .close-icon::after {
  background-color: #336699;
}

.alert-warning {
  border-color: #ffcc6d;
  color: #ffa800;
}
.alert-warning.alert-bg {
  background-color: #fff1d7;
  border-color: #fff1d7;
}
.alert-warning .close-icon::before,
.alert-warning .close-icon::after {
  background-color: #f93;
}

.alert-error {
  border-color: #f7e5e5;
  color: #6d1a17;
}
.alert-error.alert-bg {
  background-color: #f7e5e5;
  color: #a94442;
}
.alert-error .close-icon::before,
.alert-error .close-icon::after {
  background-color: #6d1a17;
}

.alert-dark {
  border-color: #333;
  color: #fff;
}
.alert-dark.alert-bg {
  background-color: #333;
}
.alert-dark .close-icon::before,
.alert-dark .close-icon::after {
  background-color: #fff;
}

.alert-inline .alert-title {
  display: inline-block;
}
.alert-inline.alert-block {
  padding-top: 1.5rem;
  padding-bottom: 1.2rem;
}
.alert-inline.alert-block .alert-title {
  margin-right: 1rem;
}
.alert-inline.alert-block .alert-title i {
  font-size: 1.6rem;
  margin-right: 1rem;
}

.alert-icon {
  padding: 1.4rem 2.2rem 1.4rem 1.9rem;
}
.alert-icon i {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.8rem 0 0.1rem;
  font-size: 1.8rem;
  line-height: 1;
}
.alert-icon i.w-icon-cog {
  margin: 0 0.8rem 0rem 0.1rem;
}
.alert-icon i.fas,
.alert-icon i.far {
  margin-bottom: 0.3rem;
  margin-left: 0.1rem;
}
.alert-icon i.w-icon-exclamation-triangle {
  font-size: 1.8rem;
}

.alert-button {
  padding: 1.2rem 1.9rem 1.9rem;
  letter-spacing: 0;
}
.alert-button .btn:not(:last-child) {
  margin-right: 0.6rem;
}
.alert-button:not(.alert-block) {
  padding: 1rem 2.2rem 1rem 1rem;
}
.alert-button:not(.alert-block) .btn {
  margin-right: 1.5rem;
}
.alert-button p {
  max-width: 49rem;
  margin-bottom: 2.2rem;
  font-size: 1.3rem;
}
.alert-button .alert-title {
  margin-bottom: 0.6rem;
  letter-spacing: -0.01em;
}
.alert-button.alert-block .btn-rounded {
  padding-left: 1.9em;
  padding-right: 1.9em;
}
.alert-button .btn-error {
  background-color: #fff;
  border-color: #fff;
  color: #6d1a17;
}

.alert-block {
  display: block;
}
.alert-block .alert-title {
  letter-spacing: -0.01em;
}
.alert-block .btn-close {
  position: absolute;
  top: 1.9rem;
  right: 1.9rem;
}
.alert-block.alert-bg .alert-title i {
  margin-right: 0.8rem;
  font-size: 1.5rem;
}

.alert-cart-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem;
  border-style: dashed;
}
.alert-cart-product .btn-success {
  padding: 0.86em 1.37em;
  margin-right: 1.9rem;
}
.alert-cart-product .btn-close.btn-link {
  margin: 0 1rem 0 auto;
  padding-bottom: 0;
}
.alert-cart-product .btn-close.btn-link i::after,
.alert-cart-product .btn-close.btn-link i::before {
  background-color: #799b5a;
}

.summary-section {
  margin-bottom: 3.7rem;
}

/*----------------------------------------
    Animation
        - Sticky Content
-----------------------------------------*/
.appear-animate {
  visibility: hidden;
  opacity: 0;
}

.appear-animation-visible {
  visibility: visible;
  opacity: 1;
}

@-webkit-keyframes fixedTopContent {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: center top 0px;
    transform-origin: center top 0px;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fixedTopContent {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: center top 0px;
    transform-origin: center top 0px;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fixedBottomContent {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: center top 0px;
    transform-origin: center top 0px;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fixedBottomContent {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: center top 0px;
    transform-origin: center top 0px;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes showMsgFirst {
  from {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes showMsgFirst {
  from {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@-webkit-keyframes showMsg {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes showMsg {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@-webkit-keyframes navItemArrow {
  0% {
    position: relative;
    left: -3px;
  }
  50% {
    position: relative;
    left: 2px;
  }
  100% {
    position: relative;
    left: -3px;
  }
}
@keyframes navItemArrow {
  0% {
    position: relative;
    left: -3px;
  }
  50% {
    position: relative;
    left: 2px;
  }
  100% {
    position: relative;
    left: -3px;
  }
}
@-webkit-keyframes fadeInLeftShorter {
  from {
    opacity: 0;
    -webkit-transform: translate(50px, 0);
    transform: translate(50px, 0);
    -webkit-transform-origin: 0, 0;
    transform-origin: 0, 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftShorter {
  from {
    opacity: 0;
    -webkit-transform: translate(50px, 0);
    transform: translate(50px, 0);
    -webkit-transform-origin: 0, 0;
    transform-origin: 0, 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftShorter {
  -webkit-animation-name: fadeInLeftShorter;
  animation-name: fadeInLeftShorter;
}

@-webkit-keyframes fadeInRightShorter {
  from {
    opacity: 0;
    -webkit-transform: translate(-50px, 0);
    transform: translate(-50px, 0);
    -webkit-transform-origin: 0, 0;
    transform-origin: 0, 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightShorter {
  from {
    opacity: 0;
    -webkit-transform: translate(-50px, 0);
    transform: translate(-50px, 0);
    -webkit-transform-origin: 0, 0;
    transform-origin: 0, 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightShorter {
  -webkit-animation-name: fadeInRightShorter;
  animation-name: fadeInRightShorter;
}

@-webkit-keyframes fadeInUpShorter {
  from {
    opacity: 0;
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpShorter {
  from {
    opacity: 0;
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUpShorter {
  -webkit-animation-name: fadeInUpShorter;
  animation-name: fadeInUpShorter;
}

@-webkit-keyframes fadeInDownShorter {
  from {
    opacity: 0;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDownShorter {
  from {
    opacity: 0;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDownShorter {
  -webkit-animation-name: fadeInDownShorter;
  animation-name: fadeInDownShorter;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInY {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInY {
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  to {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
}

@keyframes flipOutY {
  0% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  to {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }
}
@-webkit-keyframes blurIn {
  from {
    opacity: 0;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  to {
    opacity: 1;
    -webkit-filter: blur(0);
    filter: blur(0);
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes blurIn {
  from {
    opacity: 0;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  to {
    opacity: 1;
    -webkit-filter: blur(0);
    filter: blur(0);
    -webkit-transform: none;
    transform: none;
  }
}
.blurIn {
  -webkit-animation-name: blurIn;
  animation-name: blurIn;
}

@-webkit-keyframes grayOut {
  from {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  15% {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  to {
    opacity: 0;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

@keyframes grayOut {
  from {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  15% {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  to {
    opacity: 0;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}
.grayOut {
  -webkit-animation-name: grayOut;
  animation-name: grayOut;
}

@-webkit-keyframes brightIn {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-filter: brightness(0%);
    filter: brightness(0%);
  }
  to {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
  }
}

@keyframes brightIn {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-filter: brightness(0%);
    filter: brightness(0%);
  }
  to {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
  }
}
.brightIn {
  -webkit-animation-name: brightIn;
  animation-name: brightIn;
}

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes slideX {
  0% {
    -webkit-transform: none;
    transform: none;
    left: 100%;
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    left: 0;
  }
}
@keyframes slideX {
  0% {
    -webkit-transform: none;
    transform: none;
    left: 100%;
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    left: 0;
  }
}
/*------------------------------------------------
    Banner
        - Default
        - Video Banner
        - Newsletter Banner
            - Simple
            - With Dark Background
        - Banner Divider
------------------------------------------------*/
.banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 1rem;
  overflow: hidden;
}
.banner figure img {
  display: block;
  width: 100%;
}
.banner .banner-content {
  position: relative;
  z-index: 1;
}
.banner .banner-content a:not(.btn) {
  color: inherit;
}
.banner .banner-content a:not(.btn):hover,
.banner .banner-content a:not(.btn).active {
  color: #336699;
}
.banner .banner-info {
  line-height: 1.45;
  margin-bottom: 1rem;
}

.banner-fixed > .container,
.banner-fixed > .container-fluid,
.banner-fixed > .banner-content {
  position: absolute;
  z-index: 1;
}
.banner-fixed > .container,
.banner-fixed > .container-fluid {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.banner-fixed.content-middle .banner-content {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.banner-fixed.content-center .banner-text {
  text-align: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.banner-fixed.content-center.content-middle .banner-content {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.banner-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner-newsletter-2 .banner-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.banner-newsletter-2 {
  font-size: 1rem;
}
.banner-newsletter-2 .banner-content {
  padding: 0 1.5rem;
  max-width: 49.8rem;
  min-width: 30rem;
}
.banner-newsletter-2 .banner-title {
  font-size: 3em;
  margin-bottom: 0.5rem;
}
.banner-newsletter-2 p {
  font-size: 1.3em;
  margin-bottom: 3.5rem;
}
.banner-newsletter-2 .form-control {
  font-size: 1.4em;
  border: 0;
  border-bottom: 2px solid #88817b;
  max-width: 37.7rem;
  margin: 0 auto 2rem auto;
  text-align: center;
}
.banner-newsletter-2 img {
  min-height: 33rem;
}
.banner-newsletter-2 .btn {
  height: 4.8rem;
}
.banner-newsletter-2 .input-wrapper {
  max-width: 38rem;
  width: 100%;
}

.banner-newsletter-4 {
  background-color: #eeeeee;
}
.banner-newsletter-4 .banner-content {
  margin: 1rem;
  padding: 3.5rem 2rem 1rem;
}
.banner-newsletter-4 input {
  border: 2px solid #cccccc;
}

.banner-newsletter-5 {
  background-color: #cccccc;
  padding: 4rem;
}
.banner-newsletter-5 .banner-content {
  max-width: 56rem;
  padding: 1.5rem 1.5rem 2rem;
  margin-left: auto;
  margin-right: auto;
}
.banner-newsletter-5 .banner-title {
  margin-bottom: 0.5rem;
}
.banner-newsletter-5 p {
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.8;
  max-width: 49rem;
  margin-bottom: 2.5rem;
}
.banner-newsletter-5 input.form-control {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: transparent;
}

.banner-border-gradient::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 69%;
  padding-top: 30%;
  background: linear-gradient(
    30deg,
    #999999 0%,
    #999999 50%,
    transparent 50.1%
  );
}

.banner-divider {
  width: 4rem;
  height: 0.4rem;
  border: 0;
  margin-left: 0;
  margin-bottom: 1.2rem;
}

.banner-video .btn-play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 6rem;
  height: 6rem;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1000;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.banner-video .btn-play-video:hover {
  background-color: #333;
}
.banner-video .btn-play-video:hover::before {
  color: #fff;
}
.banner-video .btn-play-video::before {
  content: "";
  position: absolute;
  margin-left: 0.2rem;
  font-family: "Font Awesome 5 Free";
  font-size: 2.8rem;
  font-weight: 600;
  color: #333;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  z-index: 1;
}
.banner-video video {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.banner-video.playing .btn-play-video,
.banner-video.paused .btn-play-video {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0.4s, opacity 0.4s;
  transition: visibility 0.4s, opacity 0.4s;
}
.banner-video.playing:hover .btn-play-video,
.banner-video.paused:hover .btn-play-video {
  visibility: visible;
  opacity: 1;
}
.banner-video.playing video,
.banner-video.paused video {
  display: block;
}
.banner-video.playing .btn-play-video::before {
  content: "";
}

@media (max-width: 1199px) {
  .widget-banner .banner-title {
    margin-bottom: 4rem;
  }
}
@media (max-width: 767px) {
  .banner-newsletter-2 {
    font-size: 0.8rem;
  }
}
@media (max-width: 479px) {
  .banner-newsletter-4 .input-wrapper,
  .banner-newsletter-5 .input-wrapper {
    display: block;
    text-align: center;
    height: auto;
  }
  .banner-newsletter-4 .input-wrapper .btn,
  .banner-newsletter-5 .input-wrapper .btn {
    margin: 2rem;
  }

  .banner-newsletter-4 .input-wrapper-round .form-control,
  .banner-newsletter-4 .input-wrapper-round .btn {
    border-radius: 3rem;
  }
}
/*---------------------------
    Breadcrumb
--------------------------*/
.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
  padding: 1.5rem 0.2rem 1.6rem;
}
.breadcrumb a {
  color: inherit;
}
.breadcrumb li:not(:last-child) {
  padding-right: 0.4rem;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 0.8;
}
.breadcrumb li:not(:last-child)::after {
  content: "";
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: "wolmart";
}
.breadcrumb li:not(:last-child):hover {
  opacity: 1;
}
.breadcrumb li:last-child {
  color: #333333;
}

.breadcrumb.shop-breadcrumb {
  padding: 3rem 0;
  margin: 2.5rem 0 2.3rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.breadcrumb.shop-breadcrumb li {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  opacity: 1;
  color: #666;
}
.breadcrumb.shop-breadcrumb li.active {
  color: #336699;
}
.breadcrumb.shop-breadcrumb li.passed {
  color: #333;
}
.breadcrumb.shop-breadcrumb li:not(:last-child)::after {
  margin-left: 1rem;
  font-size: 2rem;
  font-weight: 700;
  color: #999;
  bottom: 3px;
  line-height: 1;
  vertical-align: middle;
}

.breadcrumb-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*------------------------------------
    Blog
        - Default
        - List
        - Mask
        - Widget
------------------------------------*/
.post {
  font-size: 1rem;
}
.post .btn {
  font-weight: 700;
  text-transform: capitalize;
}
.post .btn i {
  font-size: 1.8rem;
  margin-left: 8px;
}
.post .btn i::before {
  margin: 0;
}
.post .post-details > *:last-child {
  margin-bottom: 0;
}

.post-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 4.1rem;
  height: 4.2rem;
  background: #fff;
  color: #333;
  font-weight: 600;
  line-height: 1;
  z-index: 3;
  border-radius: 0.3rem;
}
.post-calendar .post-day {
  display: block;
  margin-bottom: 0.1rem;
  font-size: 1.4rem;
}
.post-calendar .post-month {
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
}

.post-media {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  overflow: hidden;
}
.post-media img {
  display: block;
  width: 100%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.post-media .post-calendar {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}

.post-details {
  padding: 1.7rem 0 2.5rem;
}

.post-title {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.post-title:hover {
  color: #336699;
}
.post-title a {
  color: inherit;
}

.post-content p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.9;
  letter-spacing: -0.025em;
  margin-bottom: 1.8rem;
}

.post-meta {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999999;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  line-height: 1.7;
  margin-bottom: 0.3rem;
}
.post-meta a {
  font-weight: 600;
  font-size: 1.4rem;
  color: #333;
  white-space: nowrap;
}
.post-meta a:hover {
  color: #336699;
}
.post-meta a:hover span {
  color: inherit;
}
.post-meta .post-author {
  margin: 0 0.4rem;
}
.post-meta .post-date {
  margin: 0 2rem 0 0.4rem;
  font-weight: 400;
}
.post-meta .post-comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: inherit;
  font-weight: 400;
}
.post-meta .post-comment i {
  font-size: 1.8rem;
}
.post-meta .post-comment span {
  margin: 0 0.4rem 0 0.8rem;
  font-weight: 600;
  color: #333;
  -webkit-transition: inherit;
  transition: inherit;
}

.post-cats {
  margin-bottom: 0.6rem;
  color: #fff;
}
.post-cats a {
  color: inherit;
  font-size: 1.4rem;
}
.post-cats a:not(:first-child) {
  margin-left: 0.3rem;
}

.post-date {
  font-weight: 400;
}

.post-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.post-list .post-media {
  max-width: 43.1%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 43.1%;
  flex: 0 0 43.1%;
  margin-right: 2rem;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
.post-list img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.post-list .post-details {
  padding: 2.3rem 0 2.4rem;
}

@media (min-width: 768px) {
  .post-list .post-title {
    white-space: normal;
  }
}
.post-grid .post-details {
  padding-top: 1.6rem;
}

.post-mask {
  position: relative;
}
.post-mask::before {
  content: "";
  display: block;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.75;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(125, 185, 232, 0)),
    to(#000)
  );
  background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, #000 100%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
  z-index: 1;
}
.post-mask .post-details {
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  right: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  padding: 0 3rem;
  z-index: 2;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.post-mask .post-details-visible {
  position: absolute;
  bottom: 100%;
  left: 3rem;
  right: 3rem;
}
.post-mask .post-title {
  letter-spacing: 0;
}
.post-mask .post-meta {
  color: #fff;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.post-mask .post-meta i {
  margin-right: 0.8rem;
}
.post-mask a {
  position: relative;
  color: inherit;
}
.post-mask a:hover {
  color: #fff;
  text-decoration: underline;
}
.post-mask .post-comment {
  padding-left: 2.8rem;
}
.post-mask .post-comment::before {
  content: "";
  font-family: "wolmart";
  position: absolute;
  font-size: 1.8rem;
  left: 0;
}
.post-mask:hover .post-details {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.post-mask:hover .post-meta {
  opacity: 1;
}

.post-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.post-widget .post-media {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 28.6%;
  flex: 0 0 28.6%;
  max-width: 28.6%;
}
.post-widget .post-details {
  margin-left: 1.6rem;
  padding: 0;
}
.post-widget .post-meta {
  margin-bottom: 0.5rem;
}
.post-widget .post-date {
  margin: 0;
  font-size: 1.3rem;
  color: #666;
}
.post-widget .post-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0.1rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.45;
  white-space: normal;
}

.post-md .post-details {
  padding-top: 1.8rem;
}
.post-md .post-content p {
  -webkit-line-clamp: 3;
}

@media (min-width: 576px) {
  .post-list .post-details {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media (max-width: 991px) {
  .post-lg.text-center .post-details,
  .post-md.text-center .post-details {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .post-details {
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .post-list {
    display: block;
  }
  .post-list .post-media {
    margin-right: 0;
    max-width: none;
  }
}
/*--------------------------------------
    Buttons
        - Button
            - Default
            - Primary
            - Success
            - Secondary
        - Outline Button
            - Primary
            - Success
            - Secondary
        - Icon Button
            - Icon Right, Left
            - Reveal Right, Left
        - Rounded
        - Round
        - Other Button Styles
            - Slide Left, Right, Top, Bottom
            - Infinite Loop
--------------------------------------*/
.btn {
  display: inline-block;
  border: 2px solid #ccc;
  background-color: #fff;
  color: #666;
  outline: 0;
  border-radius: 0;
  font-family: inherit;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 0.93em 1.98em;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  -webkit-transition: color 0.3s, border-color 0.3s, background-color 0.3s,
    -webkit-box-shadow 0.3s;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s,
    -webkit-box-shadow 0.3s;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s,
    box-shadow 0.3s;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s,
    box-shadow 0.3s, -webkit-box-shadow 0.3s;
  white-space: nowrap;
  cursor: pointer;
}
.btn:hover,
.btn:active,
.btn:focus {
  color: #666;
  border-color: gainsboro;
  background-color: gainsboro;
}
.btn i {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.6rem;
  line-height: 0;
  margin-bottom: 0.2rem;
}
.btn i::before {
  margin: 0;
}
.btn.btn-icon-right i {
  margin-right: 0;
  margin-left: 0.6rem;
}
.btn.btn-icon-left i {
  margin-right: 0.6rem;
  margin-left: 0;
}
.btn svg {
  margin-right: 0.5rem;
  stroke: #fff;
  stroke-width: 3px;
  fill: #fff;
  -webkit-transition: stroke 0.4s, fill 0.4s;
  transition: stroke 0.4s, fill 0.4s;
}

.btn-outline {
  background-color: transparent;
  border: 2px solid;
}
.btn-outline.light {
  padding: 0.996em 2em;
  border: 1px solid;
}

.btn-reveal-left i {
  opacity: 0;
  margin-left: -1em;
  -webkit-transform: translate(0.5em);
  transform: translate(0.5em);
  margin-right: 0;
  -webkit-transition: opacity 0.3s, margin 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, margin 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s, margin 0.3s;
  transition: transform 0.3s, opacity 0.3s, margin 0.3s, -webkit-transform 0.3s;
}
.btn-reveal-left:hover i,
.btn-reveal-left:active i,
.btn-reveal-left:focus i {
  opacity: 1;
  margin-left: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  margin-right: 0.7rem;
}

.btn-reveal-right i {
  opacity: 0;
  margin-right: -1em;
  -webkit-transform: translateX(-0.5em);
  transform: translateX(-0.5em);
  margin-left: 0;
  -webkit-transition: opacity 0.3s, margin 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, margin 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s, margin 0.3s;
  transition: transform 0.3s, opacity 0.3s, margin 0.3s, -webkit-transform 0.3s;
}
.btn-reveal-right:hover i,
.btn-reveal-right:active i,
.btn-reveal-right:focus i {
  opacity: 1;
  margin-right: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  margin-right: 0.7rem;
}

.btn-simple::after {
  display: block;
  margin-top: 3px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-top: 3px solid;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  content: "";
}

.btn-link {
  padding: 0;
  border: 0;
  background: transparent;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn-link {
    overflow: visible;
  }
}
.btn-link:not(.btn-underline) {
  padding-bottom: 3px;
}
.btn-link:hover,
.btn-link.active,
.btn-link:focus {
  background: transparent;
}

.btn-shadow {
  -webkit-box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.1);
}
.btn-shadow:hover,
.btn-shadow:active,
.btn-shadow:focus {
  -webkit-box-shadow: 0 17px 20px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 17px 20px -7px rgba(0, 0, 0, 0.2);
}

.btn-underline:hover::after,
.btn-underline:active::after,
.btn-underline:focus::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.btn-underline::after {
  display: block;
  margin-top: 3px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-top: 3px solid;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  content: "";
}
.btn-underline.sm::after {
  width: 46%;
}
.btn-underline.lg::after {
  margin-left: -16.5%;
  margin-right: -16.5%;
  width: 133%;
}

.btn-like {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0.429em 0.874em;
  border-radius: 3px;
}

.btn-primary {
  color: #fff;
  border-color: #336699;
  background-color: #336699;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff;
  border-color: #3c78b4;
  background-color: #3c78b4;
}
.btn-primary.btn-solid {
  color: #336699;
  border-color: #fff;
  background-color: #fff;
}
.btn-primary.btn-solid:hover,
.btn-primary.btn-solid:active,
.btn-primary.btn-solid:focus {
  border-color: #336699;
  background-color: #336699;
  color: #fff;
}
.btn-primary.btn-outline {
  color: #336699;
  border-color: #336699;
  background-color: transparent;
}
.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:active,
.btn-primary.btn-outline:focus {
  background-color: #336699;
  color: #fff;
}
.btn-primary.btn-link {
  background-color: transparent;
  color: #336699;
  border-color: #336699;
}
.btn-primary.btn-link:hover,
.btn-primary.btn-link:active,
.btn-primary.btn-link:focus {
  color: #336699;
  background-color: transparent;
}
.btn-primary.btn-underline:hover,
.btn-primary.btn-underline:active,
.btn-primary.btn-underline:focus {
  color: #336699;
}

.btn-dark {
  color: #fff;
  border-color: #333;
  background-color: #333;
}
.btn-dark:hover,
.btn-dark:active,
.btn-dark:focus {
  color: #fff;
  border-color: #454545;
  background-color: #454545;
}
.btn-dark.btn-solid {
  color: #333;
  border-color: #fff;
  background-color: #fff;
}
.btn-dark.btn-solid:hover,
.btn-dark.btn-solid:active,
.btn-dark.btn-solid:focus {
  border-color: #333;
  background-color: #333;
  color: #fff;
}
.btn-dark.btn-outline {
  color: #333;
  border-color: #333;
  background-color: transparent;
}
.btn-dark.btn-outline:hover,
.btn-dark.btn-outline:active,
.btn-dark.btn-outline:focus {
  background-color: #333;
  color: #fff;
}
.btn-dark.btn-link {
  background-color: transparent;
  color: #333;
  border-color: #333;
}
.btn-dark.btn-link:hover,
.btn-dark.btn-link:active,
.btn-dark.btn-link:focus {
  color: #333;
  background-color: transparent;
}
.btn-dark.btn-underline:hover,
.btn-dark.btn-underline:active,
.btn-dark.btn-underline:focus {
  color: #333;
}

.btn-dark-light {
  color: #fff;
  border-color: #666;
  background-color: #666;
}
.btn-dark-light:hover,
.btn-dark-light:active,
.btn-dark-light:focus {
  color: #fff;
  border-color: #787878;
  background-color: #787878;
}
.btn-dark-light.btn-solid {
  color: #666;
  border-color: #fff;
  background-color: #fff;
}
.btn-dark-light.btn-solid:hover,
.btn-dark-light.btn-solid:active,
.btn-dark-light.btn-solid:focus {
  border-color: #666;
  background-color: #666;
  color: #fff;
}
.btn-dark-light.btn-outline {
  color: #666;
  border-color: #666;
  background-color: transparent;
}
.btn-dark-light.btn-outline:hover,
.btn-dark-light.btn-outline:active,
.btn-dark-light.btn-outline:focus {
  background-color: #666;
  color: #fff;
}
.btn-dark-light.btn-link {
  background-color: transparent;
  color: #666;
  border-color: #666;
}
.btn-dark-light.btn-link:hover,
.btn-dark-light.btn-link:active,
.btn-dark-light.btn-link:focus {
  color: #666;
  background-color: transparent;
}
.btn-dark-light.btn-underline:hover,
.btn-dark-light.btn-underline:active,
.btn-dark-light.btn-underline:focus {
  color: #666;
}

.btn-white {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
  color: #333;
}
.btn-white:hover,
.btn-white:active,
.btn-white:focus {
  color: #fff;
  border-color: white;
  background-color: white;
}
.btn-white.btn-solid {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}
.btn-white.btn-solid:hover,
.btn-white.btn-solid:active,
.btn-white.btn-solid:focus {
  border-color: #fff;
  background-color: #fff;
  color: #fff;
}
.btn-white.btn-outline {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}
.btn-white.btn-outline:hover,
.btn-white.btn-outline:active,
.btn-white.btn-outline:focus {
  background-color: #fff;
  color: #fff;
}
.btn-white.btn-link {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.btn-white.btn-link:hover,
.btn-white.btn-link:active,
.btn-white.btn-link:focus {
  color: #fff;
  background-color: transparent;
}
.btn-white.btn-underline:hover,
.btn-white.btn-underline:active,
.btn-white.btn-underline:focus {
  color: #fff;
}
.btn-white.btn-outline:hover,
.btn-white.btn-outline:active,
.btn-white.btn-outline:focus {
  color: #336699;
  border-color: #fff;
}
.btn-white:hover,
.btn-white:active,
.btn-white:focus {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-success {
  color: #fff;
  border-color: #799b5a;
  background-color: #799b5a;
}
.btn-success:hover,
.btn-success:active,
.btn-success:focus {
  color: #fff;
  border-color: #8bab6e;
  background-color: #8bab6e;
}
.btn-success.btn-solid {
  color: #799b5a;
  border-color: #fff;
  background-color: #fff;
}
.btn-success.btn-solid:hover,
.btn-success.btn-solid:active,
.btn-success.btn-solid:focus {
  border-color: #799b5a;
  background-color: #799b5a;
  color: #fff;
}
.btn-success.btn-outline {
  color: #799b5a;
  border-color: #799b5a;
  background-color: transparent;
}
.btn-success.btn-outline:hover,
.btn-success.btn-outline:active,
.btn-success.btn-outline:focus {
  background-color: #799b5a;
  color: #fff;
}
.btn-success.btn-link {
  background-color: transparent;
  color: #799b5a;
  border-color: #799b5a;
}
.btn-success.btn-link:hover,
.btn-success.btn-link:active,
.btn-success.btn-link:focus {
  color: #799b5a;
  background-color: transparent;
}
.btn-success.btn-underline:hover,
.btn-success.btn-underline:active,
.btn-success.btn-underline:focus {
  color: #799b5a;
}

.btn-warning {
  color: #fff;
  border-color: #ffa800;
  background-color: #ffa800;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
  color: #fff;
  border-color: #ffb424;
  background-color: #ffb424;
}
.btn-warning.btn-solid {
  color: #ffa800;
  border-color: #fff;
  background-color: #fff;
}
.btn-warning.btn-solid:hover,
.btn-warning.btn-solid:active,
.btn-warning.btn-solid:focus {
  border-color: #ffa800;
  background-color: #ffa800;
  color: #fff;
}
.btn-warning.btn-outline {
  color: #ffa800;
  border-color: #ffa800;
  background-color: transparent;
}
.btn-warning.btn-outline:hover,
.btn-warning.btn-outline:active,
.btn-warning.btn-outline:focus {
  background-color: #ffa800;
  color: #fff;
}
.btn-warning.btn-link {
  background-color: transparent;
  color: #ffa800;
  border-color: #ffa800;
}
.btn-warning.btn-link:hover,
.btn-warning.btn-link:active,
.btn-warning.btn-link:focus {
  color: #ffa800;
  background-color: transparent;
}
.btn-warning.btn-underline:hover,
.btn-warning.btn-underline:active,
.btn-warning.btn-underline:focus {
  color: #ffa800;
}

.btn-secondary {
  color: #fff;
  border-color: #f93;
  background-color: #f93;
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  color: #fff;
  border-color: #ffab57;
  background-color: #ffab57;
}
.btn-secondary.btn-solid {
  color: #f93;
  border-color: #fff;
  background-color: #fff;
}
.btn-secondary.btn-solid:hover,
.btn-secondary.btn-solid:active,
.btn-secondary.btn-solid:focus {
  border-color: #f93;
  background-color: #f93;
  color: #fff;
}
.btn-secondary.btn-outline {
  color: #f93;
  border-color: #f93;
  background-color: transparent;
}
.btn-secondary.btn-outline:hover,
.btn-secondary.btn-outline:active,
.btn-secondary.btn-outline:focus {
  background-color: #f93;
  color: #fff;
}
.btn-secondary.btn-link {
  background-color: transparent;
  color: #f93;
  border-color: #f93;
}
.btn-secondary.btn-link:hover,
.btn-secondary.btn-link:active,
.btn-secondary.btn-link:focus {
  color: #f93;
  background-color: transparent;
}
.btn-secondary.btn-underline:hover,
.btn-secondary.btn-underline:active,
.btn-secondary.btn-underline:focus {
  color: #f93;
}

.btn-error {
  color: #fff;
  border-color: #6d1a17;
  background-color: #6d1a17;
}
.btn-error:hover,
.btn-error:active,
.btn-error:focus {
  color: #fff;
  border-color: #8a211d;
  background-color: #8a211d;
}
.btn-error.btn-solid {
  color: #6d1a17;
  border-color: #fff;
  background-color: #fff;
}
.btn-error.btn-solid:hover,
.btn-error.btn-solid:active,
.btn-error.btn-solid:focus {
  border-color: #6d1a17;
  background-color: #6d1a17;
  color: #fff;
}
.btn-error.btn-outline {
  color: #6d1a17;
  border-color: #6d1a17;
  background-color: transparent;
}
.btn-error.btn-outline:hover,
.btn-error.btn-outline:active,
.btn-error.btn-outline:focus {
  background-color: #6d1a17;
  color: #fff;
}
.btn-error.btn-link {
  background-color: transparent;
  color: #6d1a17;
  border-color: #6d1a17;
}
.btn-error.btn-link:hover,
.btn-error.btn-link:active,
.btn-error.btn-link:focus {
  color: #6d1a17;
  background-color: transparent;
}
.btn-error.btn-underline:hover,
.btn-error.btn-underline:active,
.btn-error.btn-underline:focus {
  color: #6d1a17;
}

.btn-grey {
  color: #fff;
  border-color: #00baa3;
  background-color: #00baa3;
}
.btn-grey:hover,
.btn-grey:active,
.btn-grey:focus {
  color: #fff;
  border-color: #00dec2;
  background-color: #00dec2;
}
.btn-grey.btn-solid {
  color: #00baa3;
  border-color: #fff;
  background-color: #fff;
}
.btn-grey.btn-solid:hover,
.btn-grey.btn-solid:active,
.btn-grey.btn-solid:focus {
  border-color: #00baa3;
  background-color: #00baa3;
  color: #fff;
}
.btn-grey.btn-outline {
  color: #00baa3;
  border-color: #00baa3;
  background-color: transparent;
}
.btn-grey.btn-outline:hover,
.btn-grey.btn-outline:active,
.btn-grey.btn-outline:focus {
  background-color: #00baa3;
  color: #fff;
}
.btn-grey.btn-link {
  background-color: transparent;
  color: #00baa3;
  border-color: #00baa3;
}
.btn-grey.btn-link:hover,
.btn-grey.btn-link:active,
.btn-grey.btn-link:focus {
  color: #00baa3;
  background-color: transparent;
}
.btn-grey.btn-underline:hover,
.btn-grey.btn-underline:active,
.btn-grey.btn-underline:focus {
  color: #00baa3;
}

.btn.btn-rounded {
  border-radius: 3px;
}
.btn.btn-ellipse {
  border-radius: 30px;
}
.btn.btn-block {
  display: block;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.btn.btn-block svg {
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
}
.btn.btn-sm {
  font-size: 1.3rem;
  padding: 0.77em 1.4em;
}
.btn.btn-lg {
  font-size: 1.8rem;
  padding: 0.95em 1.835em;
}
.btn.btn-xl {
  font-size: 2.4rem;
  padding: 0.84em 1.8em;
}
.btn svg {
  width: 16px;
  height: 16px;
}

.btn-slide-left:hover i,
.btn-slide-left:active i,
.btn-slide-left:focus i {
  -webkit-animation: 0.5s ease slideLeft;
  animation: 0.5s ease slideLeft;
}

.btn-slide-right:hover i,
.btn-slide-right:active i,
.btn-slide-right:focus i {
  -webkit-animation: 0.5s ease slideRight;
  animation: 0.5s ease slideRight;
}

.btn-slide-up:hover i,
.btn-slide-up:active i,
.btn-slide-up:focus i {
  -webkit-animation: 0.5s ease slideUp;
  animation: 0.5s ease slideUp;
}

.btn-slide-down:hover i,
.btn-slide-down:active i,
.btn-slide-down:focus i {
  -webkit-animation: 0.5s ease slideDown;
  animation: 0.5s ease slideDown;
}

.btn-infinite:hover i {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-0.5em);
    transform: translateX(-0.5em);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-0.5em);
    transform: translateX(-0.5em);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(0.5em);
    transform: translateX(0.5em);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(0.5em);
    transform: translateX(0.5em);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-0.5em);
    transform: translateY(-0.5em);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-0.5em);
    transform: translateY(-0.5em);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(0.5em);
    transform: translateY(0.5em);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(0.5em);
    transform: translateY(0.5em);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/*----------------------------------------
    Calendar
-----------------------------------------*/
.calendar {
  font-size: 1.3rem;
  letter-spacing: 0;
  text-align: center;
}
.calendar .calendar-header {
  position: relative;
  padding: 1.7rem 1rem;
  background-color: #336699;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-transform: capitalize;
  border-radius: 0.3rem;
}
.calendar .calendar-header a {
  color: inherit;
}
.calendar thead {
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
}
.calendar th {
  padding: 1.8rem 0 0.9rem;
}
.calendar td {
  padding: 0.9rem 0;
}
.calendar .btn-calendar {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
}
.calendar .btn-calendar i {
  font-weight: 600;
}
.calendar .btn-calendar.btn-calendar-prev {
  left: 2rem;
}
.calendar .btn-calendar.btn-calendar-next {
  right: 2rem;
}
.calendar .btn-calendar:hover {
  color: #fff;
}
.calendar .disabled {
  color: #999;
}
.calendar .today {
  color: #336699;
  font-weight: 600;
}

/*------------------------------------
    Category
        - Base
            - Default
            - Group Category
                - Image
                - Icon
            - Category Banner
-------------------------------------*/
.category {
  position: relative;
  font-size: 1rem;
  color: #666;
}
.category a {
  color: inherit;
}
.category a:hover {
  color: #336699;
}
.category img {
  display: block;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.category .category-name {
  margin-bottom: 0;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.category .category-count {
  font-size: 1.4rem;
}
.category p {
  text-align: start;
  font-size: 1.4rem;
  line-height: 1.25;
  letter-spacing: 0;
  color: #666;
}

.category-content .category-list {
  color: #666666;
  text-align: start;
  font-size: 1.3em;
}
.category-content .category-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  line-height: 1.9;
}
.category-content .category-list li::before {
  content: "";
  color: inherit;
  font-family: "wolmart";
  font-size: 1.3rem;
  margin-right: 0.3rem;
  margin-left: -0.3rem;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}
.category-content .category-list li:hover::before {
  color: #336699;
  -webkit-animation: navItemArrow 0.6s linear infinite;
  animation: navItemArrow 0.6s linear infinite;
}

.category-absolute .category-content {
  position: absolute;
  cursor: pointer;
}

.category-default .category-content {
  padding-bottom: 0.2rem;
  -webkit-transition: padding-bottom 0.3s;
  transition: padding-bottom 0.3s;
}
.category-default .btn {
  position: absolute;
  margin-top: -2rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: margin-top 0.3s, opacity 0.3s, visibility 0.3s;
  transition: margin-top 0.3s, opacity 0.3s, visibility 0.3s;
}
.category-default:hover .category-content {
  padding-bottom: 3.2rem;
}
.category-default:hover .btn {
  margin-top: 1rem;
  opacity: 1;
  visibility: visible;
}

.category-default1 .category-content {
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(38, 38, 38, 0.8);
  height: 20%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}
.category-default1 .category-content .category-name {
  padding-bottom: 0;
  color: #fff;
  font-size: 1.4rem;
  letter-spacing: 0.035em;
  -webkit-transition: padding-bottom 0.3s;
  transition: padding-bottom 0.3s;
}
.category-default1 .category-content .category-count {
  position: absolute;
  font-size: 1.2rem;
  line-height: 1.3;
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
}
.category-default1:hover .category-name {
  padding-bottom: 1.7rem;
}
.category-default1:hover .category-count {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 1;
  visibility: visible;
}

.category-group-image,
.category-group-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #eee;
}
.category-group-image > *,
.category-group-icon > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.category-group-image .category-content ul,
.category-group-icon .category-content ul {
  padding-left: 0;
}

.category-group-image {
  padding: 1rem;
  font-size: 1rem;
  height: 100%;
}
.category-group-image .category-content {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: start;
  padding: 1.1rem 0 0.8rem 3rem;
}
.category-group-image .category-content ul {
  margin: 0;
}
.category-group-image .category-name {
  font-size: 1.6em;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 1.1rem;
  line-height: 1.4;
  text-align: start;
  letter-spacing: 0;
}
.category-group-image a {
  white-space: nowrap;
}

.category-ellipse .category-media {
  border-radius: 50%;
  overflow: hidden;
  border: 0px solid #fff;
  -webkit-transition: border-width 0.1s, -webkit-box-shadow 0.1s;
  transition: border-width 0.1s, -webkit-box-shadow 0.1s;
  transition: box-shadow 0.1s, border-width 0.1s;
  transition: box-shadow 0.1s, border-width 0.1s, -webkit-box-shadow 0.1s;
}
.category-ellipse .category-content {
  margin-top: 1.7rem;
}
.category-ellipse .category-name:hover {
  color: #336699;
}
.category-ellipse:hover .category-media {
  border-width: 8px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
}

.category-classic {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.category-classic .category-content {
  left: 0;
  right: 0;
  bottom: 1.5rem;
  overflow: hidden;
  text-align: center;
}
.category-classic .category-name {
  font-size: 1.4rem;
  line-height: 1.45;
  text-transform: capitalize;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.category-classic .btn {
  position: absolute;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  top: 100%;
  -webkit-transition: top 0.3s, -webkit-transform 0.3s;
  transition: top 0.3s, -webkit-transform 0.3s;
  transition: top 0.3s, transform 0.3s;
  transition: top 0.3s, transform 0.3s, -webkit-transform 0.3s;
}
.category-classic:hover {
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}
.category-classic:hover .category-name {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.category-classic:hover .btn {
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.category-icon .category-media {
  padding: 2.4rem 0.5rem;
  border: 1px solid #eee;
  width: 130px;
  height: 130px;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 5rem;
  border-radius: 50%;
  margin: 0 auto 2rem auto;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
}
.category-icon .category-name {
  font-size: 1.4rem;
  text-transform: capitalize;
  letter-spacing: 0;
}
.category-icon:hover .category-media {
  border-color: #fff;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.category-icon:hover .category-name {
  color: #336699;
}

.category-banner .category-content {
  left: 0;
  bottom: 2rem;
  background-color: rgba(38, 38, 38, 0.8);
  border-radius: 0 5rem 5rem 0;
  padding: 1.3rem 3rem;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.category-banner .category-name {
  font-size: 1.4rem;
  color: #fff;
  letter-spacing: 0.035em;
}
.category-banner:hover .category-content {
  background-color: rgba(51, 102, 153, 0.8);
}

.category-center .category-content {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.category-center .btn {
  visibility: hidden;
  opacity: 0;
  line-height: 0;
  -webkit-transition: visibility 0.3s, opacity 0.3s, line-height 0.3s;
  transition: visibility 0.3s, opacity 0.3s, line-height 0.3s;
}
.category-center:hover .btn {
  visibility: visible;
  opacity: 1;
  line-height: 1;
}

.category-lg .category-name {
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: -0.015em;
  line-height: 1.3;
  white-space: nowrap;
}
.category-lg .category-count {
  font-size: 1.8rem;
  letter-spacing: 0;
  margin-bottom: 1rem;
}

.category-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.category-image a {
  display: block;
  width: 100%;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.category-image .category-media {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.category-image .category-media img {
  display: block;
}
.category-image p {
  font-size: 1.3rem;
  line-height: 1;
  color: inherit;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.category-image.media-circle .category-media {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
}
.category-image.text-center p {
  text-align: center;
}
.category-image:hover {
  cursor: pointer;
}
.category-image:hover a {
  color: #336699;
}

@media (max-width: 767px) {
  .category-group-image .category-content {
    padding-left: 1.5rem;
  }
}
/* -----------------------------------------------
    Comments
------------------------------------------------- */
.comments .comment {
  position: relative;
  font-size: 1.3rem;
  padding: 3rem 0;
  border-top: 1px solid #eee;
  padding: 4rem 0;
}
.comments .comment:last-child {
  padding-bottom: 2rem;
}
.comments > li {
  position: relative;
  border-top: 1px solid #eee;
  font-size: 1.3rem;
  padding: 3rem 0;
}
.comments ul.children {
  margin-left: 10rem;
}

.comment-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.comment-body .comment-content p {
  margin-bottom: 1.6rem;
  line-height: 1.9;
}

.comment-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 9rem;
  flex: 0 0 9rem;
  max-width: 9rem;
  margin-right: 2rem;
}

.comment-author {
  margin: 0.5rem 0 1rem;
  font-size: 1.8rem;
  color: #333;
  line-height: 1;
}
.comment-author a {
  margin-right: 0.4rem;
  color: inherit;
}

.comment-date {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0;
  color: #999;
}

.ratings-container.comment-rating {
  margin-bottom: 1.3rem;
}

.comment-action > * {
  margin-right: 2.4rem;
}
.comment-action .btn i {
  font-size: 1.4rem;
}

.review-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.review-image figure {
  max-width: 6rem;
  margin-top: 2rem;
  margin-right: 0.6rem;
  border-radius: 0.3rem;
  overflow: hidden;
}

@media (max-width: 575px) {
  .comments .comment-action span {
    display: none;
  }
  .comments ul.children {
    margin-left: 3rem;
  }

  .comment-avatar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 7rem;
    flex: 0 0 7rem;
    max-width: 7rem;
  }
}
/*-------------------------
    - Expanded Newsletter
    - Banner Newsletter
    - Link Banner Newsletter
    - Sale Banner
-------------------------*/
.expanded-newsletter {
  padding: 2.6rem 4rem 0.5rem;
  border: 2px solid #eee;
}
.expanded-newsletter .icon-box-side {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}
.expanded-newsletter .icon-box-side .icon-box-icon {
  margin-right: 2rem;
  color: #336699;
}
.expanded-newsletter .icon-box-side .icon-box-icon i {
  font-size: 4.8rem;
}
.expanded-newsletter .icon-box-side .icon-box-title {
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-bottom: 2px;
}
.expanded-newsletter .icon-box-side p {
  font-size: 1.4rem;
  letter-spacing: -0.007em;
}
.expanded-newsletter .input-wrapper-inline .form-control {
  min-height: 4.8rem;
  border-color: #ccc;
  border-radius: 2.5rem 0 0 2.5rem;
}
.expanded-newsletter .btn {
  border-radius: 0 2.5rem 2.5rem 0;
  padding-left: 1.91em;
  padding-right: 1.91em;
}

@media (min-width: 1200px) {
  .expanded-newsletter .pl-xl-10 {
    padding-left: 5rem !important;
  }
}
.cta-section .title-separator {
  color: #ccc;
}

.banner-newsletter {
  background-color: #f5f5f5;
  padding: 6.5rem 0 5rem;
  overflow: hidden;
}
.banner-newsletter::before,
.banner-newsletter::after {
  content: "";
  position: absolute;
  height: 59.2rem;
  width: 58.8rem;
  border: 3rem solid;
  top: 53%;
  -webkit-transform: translateY(-50%) rotateZ(45deg);
  transform: translateY(-50%) rotateZ(45deg);
}
.banner-newsletter::before {
  right: 98.8%;
  border-color: #e7e7e7;
  border-radius: 2rem;
}
.banner-newsletter::after {
  left: 93.3%;
  top: 48%;
  border-color: #f1f1f1;
}
.banner-newsletter .banner-content {
  padding: 0 1.5rem;
}
.banner-newsletter .banner-title {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.banner-newsletter .banner-title strong {
  font-weight: 800;
}
.banner-newsletter p {
  margin-bottom: 2.2rem;
  max-width: 62rem;
  font-size: 1.3rem;
  line-height: 2;
}
.banner-newsletter .input-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 55.5rem;
  min-height: 4.8rem;
}
.banner-newsletter .input-wrapper::before {
  content: "";
  display: inline-block;
  position: absolute;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  font-size: 1.8rem;
  color: #666;
  line-height: 1;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: -1rem;
}
.banner-newsletter input {
  border: 0;
  border-bottom: 2px solid #ccc;
  margin-right: 1rem;
  padding-left: 2.8rem;
  min-height: 4.8rem;
}
.banner-newsletter .banner-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.banner-newsletter .btn {
  padding: 1.08em 1.9em;
}
.banner-newsletter .btn i {
  font-size: 1.8rem;
  margin-top: -0.4rem;
}

.link-banner-newsletter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5.25% 8.4% 4.1% 4.4%;
}
.link-banner-newsletter .banner-title {
  margin-bottom: 0.6rem;
  font-size: 2.6rem;
}
.link-banner-newsletter p {
  max-width: 45.1rem;
  margin-bottom: 2.2rem;
  font-size: 1.3rem;
}
.link-banner-newsletter .input-wrapper-inline {
  max-width: 44.8rem;
}
.link-banner-newsletter .input-wrapper-rounded .form-control {
  min-height: 4.6rem;
  padding: 0.8rem 1.8rem;
  font-size: 1.3rem;
  border-color: #454545;
  border-radius: 0.3rem 0 0 0.3rem;
}
.link-banner-newsletter .btn {
  padding-left: 1.95em;
  padding-right: 1.95em;
  border-radius: 0 0.3rem 0.3rem 0;
}
.link-banner-newsletter .content-right {
  margin-top: 0.3rem;
}
.link-banner-newsletter .content-right a {
  display: block;
  margin-bottom: 2rem;
}

.sale-banner::before {
  content: "";
  position: absolute;
  z-index: 2;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border: 5px dashed #fff;
  pointer-events: none;
  -webkit-clip-path: inset(4px 4px 4px 4px);
  clip-path: inset(4px 4px 4px 4px);
}
.sale-banner .banner-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.sale-banner .banner-subtitle {
  position: relative;
  margin-right: 2rem;
  padding: 2.6rem 7px 2.6rem 3.5rem;
  font-size: 4em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #444;
  line-height: 1.2;
  z-index: 1;
  white-space: nowrap;
}
.sale-banner .banner-subtitle span {
  font-size: 0.45em;
  font-weight: 400;
}
.sale-banner .banner-subtitle::before,
.sale-banner .banner-subtitle::after {
  content: "";
  position: absolute;
  right: -24px;
  top: -25px;
  bottom: -20px;
  border-right: 50px solid #454545;
  -webkit-transform: rotate(26deg);
  transform: rotate(26deg);
  z-index: -1;
}
.sale-banner .banner-subtitle::after {
  right: -35px;
  border-right-width: 5px;
}
.sale-banner .content-right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 25.7rem);
  flex: 0 0 calc(100% - 25.7rem);
  max-width: calc(100% - 25.7rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2.8rem 3.6rem 2.8rem 0;
  background-color: #eee;
}
.sale-banner .banner-title {
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 3.4em;
  font-style: italic;
}
.sale-banner .banner-title span {
  white-space: nowrap;
  -webkit-animation: slideX 60s infinite linear 1s;
  animation: slideX 60s infinite linear 1s;
  display: inline-block;
}
.sale-banner .btn {
  padding-left: 1.9em;
  padding-right: 1.9em;
  background-color: #444;
  border-color: #444;
}
.sale-banner .btn i {
  margin-left: 0.3rem;
  font-size: 1.5rem;
}

.sale-banner-section {
  padding-bottom: 0.7rem;
}

.banner-newsletter-bg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 3rem 5.9rem;
}
.banner-newsletter-bg .content-left {
  margin-right: 5.3rem;
}
.banner-newsletter-bg .content-right {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.banner-newsletter-bg .banner-title {
  margin-bottom: 0.6rem;
  font-size: 1.6rem;
  line-height: 1;
}
.banner-newsletter-bg p {
  font-size: 1.3rem;
  line-height: 1;
}
.banner-newsletter-bg .input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
}
.banner-newsletter-bg .form-control {
  margin-right: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: none;
  background-color: #fff;
}

@media (max-width: 991px) {
  .expanded-newsletter {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .expanded-newsletter .icon-box-side {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .banner-newsletter-bg {
    display: block;
    text-align: center;
  }
  .banner-newsletter-bg .content-left {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .sale-banner .banner-content {
    display: block;
  }
  .sale-banner .banner-subtitle {
    padding-bottom: 0;
  }
  .sale-banner .banner-subtitle::after,
  .sale-banner .banner-subtitle::before {
    left: -10px;
    bottom: -20px;
    top: auto;
    border-top: 42px solid #454545;
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  .sale-banner .banner-subtitle::after {
    bottom: -30px;
    border-top-width: 5px;
  }
  .sale-banner .content-right {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    max-width: none;
    margin-top: -4rem;
    padding: 4rem 0 3rem;
  }
  .sale-banner .btn {
    margin-left: 3rem;
  }

  .link-banner-newsletter {
    display: block;
    padding: 4rem 3rem 2rem;
  }
  .link-banner-newsletter .content-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .link-banner-newsletter .content-right a:first-child {
    margin-right: 2rem;
  }
}
@media (max-width: 575px) {
  .expanded-newsletter .icon-box-side {
    display: block;
    text-align: center;
  }
  .expanded-newsletter .icon-box-side .icon-box-icon {
    padding: 1rem;
    margin: 0 0 1.5rem;
  }
  .expanded-newsletter .icon-box-side .icon-box-content {
    text-align: center;
  }

  .sale-banner {
    font-size: 0.8rem;
  }
}
@media (max-width: 479px) {
  .banner-newsletter .input-wrapper-inline {
    display: block;
  }
  .banner-newsletter .input-wrapper-inline::before {
    top: 25%;
  }

  .banner-newsletter-bg {
    padding: 4rem 1.5rem;
  }
  .banner-newsletter-bg .input-wrapper {
    display: block;
  }
  .banner-newsletter-bg .form-control {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
}
/*----------------------------------------
    Element section
-----------------------------------------*/
.element-section .section-title {
  margin-bottom: 1.7rem;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0;
}
.element-section .section-desc {
  max-width: 50rem;
}
.element-section .element {
  display: block;
  padding: 3.1rem 0 3.6rem;
  background-color: #fff;
  -webkit-box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.05),
    0 0 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
}
.element-section .element svg {
  fill: #aaa;
  stroke: #aaa;
  stroke-dashoffset: 1500;
  stroke-dasharray: 1500;
  -webkit-transition: fill 0.5s, -webkit-transform 0.3s;
  transition: fill 0.5s, -webkit-transform 0.3s;
  transition: transform 0.3s, fill 0.5s;
  transition: transform 0.3s, fill 0.5s, -webkit-transform 0.3s;
}
.element-section .element:hover svg {
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
  stroke-dashoffset: 0;
  stroke: #336699;
  fill: transparent;
  -webkit-transition: fill 0.5s, stroke-dashoffset 6s, -webkit-transform 0.3s;
  transition: fill 0.5s, stroke-dashoffset 6s, -webkit-transform 0.3s;
  transition: transform 0.3s, fill 0.5s, stroke-dashoffset 6s;
  transition: transform 0.3s, fill 0.5s, stroke-dashoffset 6s,
    -webkit-transform 0.3s;
}
.element-section .element:hover p {
  color: #336699;
}
.element-section .element p {
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 0;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}
.element-section .element.element-vendor {
  padding: 2.5rem 0 3.6rem;
}
.element-section .element.element-vendor p {
  margin-top: 0.6rem;
}

@media (max-width: 479px) {
  .element-section .element {
    max-width: 23.2rem;
    margin: 0 auto;
  }
}
/*----------------------------------------
    Wolmart Font icon
-----------------------------------------*/
@font-face {
  font-family: "wolmart";
  src: url("../assets/fonts/wolmart.eot?png09e");
  src: url("../assets/fonts/wolmart.woff?png09e") format("woff"),
    url("../assets/fonts/wolmart.eot?png09e#iefix") format("embedded-opentype"),
    url("../assets/fonts/wolmart.ttf?png09e") format("truetype"),
    url("../assets/fonts/wolmart.svg?png09e#wolmart") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^="w-icon-"],
[class*=" w-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "wolmart" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.w-icon-shipping:before {
  content: "";
}

.w-icon-truck2:before {
  content: "";
}

.w-icon-comments-solid:before {
  content: "";
}

.w-icon-heart-full:before {
  content: "";
}

.w-icon-instagram:before {
  content: "";
}

.w-icon-youtube:before {
  content: "";
}

.w-icon-pinterest:before {
  content: "";
}

.w-icon-pinterest-p:before {
  content: "";
}

.w-icon-google:before {
  content: "";
}

.w-icon-twitter:before {
  content: "";
}

.w-icon-facebook:before {
  content: "";
}

.w-icon-minus:before {
  content: "";
}

.w-icon-plus:before {
  content: "";
}

.w-icon-angle-down:before {
  content: "";
}

.w-icon-angle-up:before {
  content: "";
}

.w-icon-angle-left:before {
  content: "";
}

.w-icon-angle-right:before {
  content: "";
}

.w-icon-hamburger:before {
  content: "";
}

.w-icon-star-square-full:before {
  content: "";
}

.w-icon-exclamation-triangle:before {
  content: "";
}

.w-icon-times-circle:before {
  content: "";
}

.w-icon-cog:before {
  content: "";
}

.w-icon-star-square:before {
  content: "";
}

.w-icon-exclamation-circle:before {
  content: "";
}

.w-icon-check-solid:before {
  content: "";
}

.w-icon-check:before {
  content: "";
}

.w-icon-comments:before {
  content: "";
}

.w-icon-envelop:before {
  content: "";
}

.w-icon-envelop2:before {
  content: "";
}

.w-icon-youtube-solid:before {
  content: "";
}

.w-icon-times-solid:before {
  content: "";
}

.w-icon-call:before {
  content: "";
}

.w-icon-call2:before {
  content: "";
}

.w-icon-cart:before {
  content: "";
}

.w-icon-category:before {
  content: "";
}

.w-icon-bars:before {
  content: "";
}

.w-icon-compare:before {
  content: "";
}

.w-icon-computer:before {
  content: "";
}

.w-icon-electronics:before {
  content: "";
}

.w-icon-tshirt:before {
  content: "";
}

.w-icon-account:before {
  content: "";
}

.w-icon-search2:before {
  content: "";
}

.w-icon-android:before {
  content: "";
}

.w-icon-ios:before {
  content: "";
}

.w-icon-art-class:before {
  content: "";
}

.w-icon-bag:before {
  content: "";
}

.w-icon-basketball:before {
  content: "";
}

.w-icon-bathtub:before {
  content: "";
}

.w-icon-bed:before {
  content: "";
}

.w-icon-birthday-cake:before {
  content: "";
}

.w-icon-bow:before {
  content: "";
}

.w-icon-bridge-lamp:before {
  content: "";
}

.w-icon-calendar:before {
  content: "";
}

.w-icon-camera:before {
  content: "";
}

.w-icon-cart2:before {
  content: "";
}

.w-icon-dots-circle:before {
  content: "";
}

.w-icon-chat:before {
  content: "";
}

.w-icon-desktop:before {
  content: "";
}

.w-icon-dish:before {
  content: "";
}

.w-icon-display:before {
  content: "";
}

.w-icon-download:before {
  content: "";
}

.w-icon-envelop-closed:before {
  content: "";
}

.w-icon-fax:before {
  content: "";
}

.w-icon-furniture:before {
  content: "";
}

.w-icon-gamepad:before {
  content: "";
}

.w-icon-gift:before {
  content: "";
}

.w-icon-grid:before {
  content: "";
}

.w-icon-headphone:before {
  content: "";
}

.w-icon-heartbeat:before {
  content: "";
}

.w-icon-home:before {
  content: "";
}

.w-icon-honour:before {
  content: "";
}

.w-icon-hotline:before {
  content: "";
}

.w-icon-ice-cream:before {
  content: "";
}

.w-icon-list:before {
  content: "";
}

.w-icon-mobile:before {
  content: "";
}

.w-icon-money:before {
  content: "";
}

.w-icon-motocycle:before {
  content: "";
}

.w-icon-net-world:before {
  content: "";
}

.w-icon-orders:before {
  content: "";
}

.w-icon-phone:before {
  content: "";
}

.w-icon-return:before {
  content: "";
}

.w-icon-rice-cooker:before {
  content: "";
}

.w-icon-ruby:before {
  content: "";
}

.w-icon-search:before {
  content: "";
}

.w-icon-search-minus:before {
  content: "";
}

.w-icon-search-plus:before {
  content: "";
}

.w-icon-service:before {
  content: "";
}

.w-icon-shopify:before {
  content: "";
}

.w-icon-sofa:before {
  content: "";
}

.w-icon-sport:before {
  content: "";
}

.w-icon-star:before {
  content: "";
}

.w-icon-star-full:before {
  content: "";
}

.w-icon-table:before {
  content: "";
}

.w-icon-table2:before {
  content: "";
}

.w-icon-truck:before {
  content: "";
}

.w-icon-tshirt2:before {
  content: "";
}

.w-icon-user:before {
  content: "";
}

.w-icon-wallet:before {
  content: "";
}

.w-icon-heart:before {
  content: "";
}

.w-icon-gift2:before {
  content: "";
}

.w-icon-dashboard:before {
  content: "";
}

.w-icon-power-off:before {
  content: "";
}

.w-icon-wallet2:before {
  content: "";
}

.w-icon-products:before {
  content: "";
}

.w-icon-reports:before {
  content: "";
}

.w-icon-return2:before {
  content: "";
}

.w-icon-reviews:before {
  content: "";
}

.w-icon-return3:before {
  content: "";
}

.w-icon-cog2:before {
  content: "";
}

.w-icon-ship-station:before {
  content: "";
}

.w-icon-social:before {
  content: "";
}

.w-icon-store-seo:before {
  content: "";
}

.w-icon-store:before {
  content: "";
}

.w-icon-support:before {
  content: "";
}

.w-icon-tools:before {
  content: "";
}

.w-icon-vendor-store:before {
  content: "";
}

.w-icon-verification:before {
  content: "";
}

.w-icon-visit:before {
  content: "";
}

.w-icon-withdraw:before {
  content: "";
}

.w-icon-rotate-3d:before {
  content: "";
}

.w-icon-movie:before {
  content: "";
}

.w-icon-prev:before {
  content: "";
}

.w-icon-play:before {
  content: "";
}

.w-icon-next:before {
  content: "";
}

.w-icon-map-marker:before {
  content: "";
}

.w-icon-long-arrow-down:before {
  content: "";
}

.w-icon-long-arrow-left:before {
  content: "";
}

.w-icon-long-arrow-right:before {
  content: "";
}

.w-icon-long-arrow-up:before {
  content: "";
}

.w-icon-comment:before {
  content: "";
}

.w-icon-envelop3:before {
  content: "";
}

.w-icon-logout:before {
  content: "";
}

.w-icon-envelop4:before {
  content: "";
}

.w-icon-orders2:before {
  content: "";
}

.w-icon-quote:before {
  content: "";
}

.w-icon-sale:before {
  content: "";
}

.w-icon-heart2:before {
  content: "";
}

.w-icon-zoom:before {
  content: "";
}

/*------------------------
    Form
        - Form Control
        - Input Wrapper
-------------------------*/
.form .form-control {
  margin-bottom: 2rem;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
  background-image: none !important;
  color: black !important;
}

.form-control {
  display: block;
  width: 100%;
  min-height: 4.7rem;
  padding: 0.85rem 2rem;
  border: 1px solid #eee;
  font-size: 1.4rem;
  font-family: Poppins, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: #999;
  background-color: transparent;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}
.form-control::-webkit-input-placeholder {
  color: inherit;
  font-family: inherit;
}
.form-control::-moz-placeholder {
  color: inherit;
  font-family: inherit;
}
.form-control:-ms-input-placeholder {
  color: inherit;
  font-family: inherit;
}
.form-control::-ms-input-placeholder {
  color: inherit;
  font-family: inherit;
}
.form-control::placeholder {
  color: inherit;
  font-family: inherit;
}
.form-control::-webkit-input-placeholder {
  color: inherit;
}
.form-control::-moz-placeholder {
  color: inherit;
}
.form-control.form-solid {
  background-color: #fff;
  border: 0;
  color: #8d8d8d;
}
.form-control.form-control-md {
  font-size: 1.3rem;
  min-height: 4.4rem;
}
.form-control.form-control-sm {
  font-size: 1.2rem;
  min-height: 4rem;
}

textarea {
  font-family: inherit;
  min-width: 100%;
  max-width: 100%;
}

.select-box,
.select-menu {
  position: relative;
}
.select-box select,
.select-menu select {
  position: relative;
  max-width: 14.6rem;
  min-height: auto;
  width: 100%;
  height: 100%;
  padding-left: 9px;
  padding-right: 27px;
  border: 1px solid #e3e3e3;
  color: inherit;
  background-color: transparent;
  font-size: 1.2rem;
  font-family: inherit;
  letter-spacing: inherit;
  z-index: 1;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}
.select-box select:focus,
.select-menu select:focus {
  outline: none;
}
.select-box::before,
.select-menu::before {
  content: "";
  font-family: "Wolmart";
  font-size: 9px;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  right: 2rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.select-box option {
  font-size: 1.2rem;
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  select::-ms-expand {
    display: none;
  }

  select:focus::-ms-value {
    background: transparent;
    color: currentColor;
  }
}
.input-wrapper input {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 40px;
  font-size: 1.4rem;
  font-family: Poppins, sans-serif;
  border-width: 1px 0 1px 1px;
}

.input-wrapper-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  max-width: 67.5rem;
  width: 100%;
}
.input-wrapper-inline .btn {
  line-height: 1;
}

.input-wrapper-rounded .form-control {
  border-radius: 0.4rem;
}

.input-wrapper-round .form-control {
  position: relative;
  border-radius: 3rem;
}
.input-wrapper-round .btn {
  border-radius: 3rem;
  line-height: 1;
}
.input-wrapper-round .form-control {
  border-radius: 3rem 0 0 3rem;
}
.input-wrapper-round .btn {
  border-radius: 0 3rem 3rem 0;
}

.input-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 4.4rem;
  position: relative;
  width: 100%;
}
.input-group .form-control {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 1.3rem;
  padding: 0 1.4rem 0 1.8rem;
  height: inherit;
  min-height: auto;
  max-width: 100%;
  -webkit-appearance: textfield;
  border: solid #ccc;
  border-width: 1px;
  color: #666;
}
.input-group .form-control:hover::-webkit-outer-spin-button,
.input-group .form-control:hover::-webkit-inner-spin-button,
.input-group .form-control:focus::-webkit-outer-spin-button,
.input-group .form-control:focus::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.input-group button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1.5rem;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: #eee;
  color: #666;
  font-size: 1.4rem;
  border: none;
}
.input-group button + button {
  margin-right: 3.3rem;
}
.input-group button:hover {
  cursor: pointer;
}

.custom-radio {
  position: relative;
}
.custom-radio .custom-control-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.custom-radio .custom-control-label {
  padding-left: 3rem;
}
.custom-radio .custom-control-label::after {
  content: "";
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid;
  border-radius: 2px;
  color: #999;
  font-family: "Font Awesome 5 Free";
  font-size: 1rem;
  font-weight: 900;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  -webkit-transition: color 0.3s, background-color 0.3s, content 0.3s,
    border 0.3s;
  transition: color 0.3s, background-color 0.3s, content 0.3s, border 0.3s;
}
.custom-radio .custom-control-label.round::after {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-color: #336699;
  color: #fff;
  border: 2px solid #336699;
  padding-left: 2px;
}
.custom-radio
  .custom-control-input:checked
  ~ .custom-control-label.color-dark::after {
  background-color: #333;
  border-color: #333;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  z-index: 1;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
}
.custom-checkbox + label {
  display: inline-block;
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
}
.custom-checkbox + label::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 18px;
  height: 18px;
  line-height: 15px;
  border: 1px solid #ccc;
  border-radius: 2px;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .custom-checkbox + label::before {
    line-height: 14px;
  }
}
.custom-checkbox:checked + label::before,
.custom-checkbox.checked + label::before {
  content: "";
  border-color: #222;
  background: #222;
  color: #fff;
  font-size: 9px;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  text-align: center;
}

.btn-absolute input.form-control {
  width: 100%;
  padding-left: 2rem;
  color: #999;
  font-size: 1.4rem;
}
.btn-absolute .btn {
  position: absolute;
  width: auto;
  height: 100%;
  min-width: 4.4rem;
  right: 0;
  font-size: 1.3rem;
}
.btn-absolute .btn:hover {
  color: #336699;
}

/* -----------------------------------
    Custom Icons
        - User Icon
        - Minicart Icon
        - Menu Icon
-------------------------------------*/
.user-icon {
  display: block;
  padding-bottom: 2px;
}
.user-icon::before,
.user-icon::after {
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.user-icon::before {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-bottom: 1px;
  border: 1px solid #333;
  -webkit-box-shadow: 0px 0px 1px #333, 0px 0px 1px #333, 0px 0px 2px #333,
    0px 0px 1px #333, 0px 0px 2px #333;
  box-shadow: 0px 0px 1px #333, 0px 0px 1px #333, 0px 0px 2px #333,
    0px 0px 1px #333, 0px 0px 2px #333;
}
.user-icon::after {
  width: 22px;
  height: 12px;
  border-radius: 7px 7px 0 0;
  border: 1px solid #333;
  -webkit-box-shadow: 0px 0px 2px #333, 0px 0px 1px #333, 0px 0px 1px #333,
    0px 0px 1px #333, 0px 0px 1px #333;
  box-shadow: 0px 0px 2px #333, 0px 0px 1px #333, 0px 0px 1px #333,
    0px 0px 1px #333, 0px 0px 1px #333;
}

.minicart-icon {
  position: relative;
  height: 2.167em;
  text-align: center;
}
.minicart-icon::before,
.minicart-icon::after {
  border: solid 2px;
  border-color: #333333;
}
.minicart-icon::before {
  content: "";
  display: block;
  position: absolute;
  top: -0.3em;
  left: 50%;
  -webkit-transform: translateX(-50%) scale(0.7);
  transform: translateX(-50%) scale(0.7);
  width: 1.32em;
  height: 1.2em;
  border-radius: 10px 10px 0 0;
  border-bottom: none;
}
.minicart-icon::after {
  content: "";
  display: inline-block;
  width: 29px;
  height: 24px;
  background-color: transparent;
  border-radius: 0 0 5px 5px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  margin-top: 1px;
}

.menu-icon {
  display: inline-block;
  color: #fff;
  width: 1.7rem;
}
.menu-icon::before,
.menu-icon::after {
  display: block;
  content: "";
  width: 100%;
}
.menu-icon::before {
  height: 6px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.menu-icon::after {
  height: 4px;
  border-bottom: 2px solid;
}

.icon-angle-left::before {
  content: "";
}

.icon-angle-right::before {
  content: "";
}

.icon-angle-left,
.icon-angle-right {
  font-family: "wolmart";
  font-style: normal;
}

.close-icon {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
.close-icon::before,
.close-icon::after {
  display: block;
  position: absolute;
  background-color: #ccc;
  content: "";
}
.close-icon::before {
  height: 2px;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.close-icon::after {
  height: 100%;
  width: 2px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* ---------------------------------------
    Icon Box
--------------------------------------- */
.icon-box .icon-box-title {
  font-size: 1.4rem;
  text-transform: capitalize;
  letter-spacing: -0.025em;
  margin-bottom: 0.4rem;
}
.icon-box p {
  font-size: 1.3rem;
  word-break: break-word;
  margin: 0;
  line-height: 1.2;
  color: #666;
}
.icon-box .icon-box-content {
  text-align: center;
}
.icon-box.text-center .icon-box-icon {
  margin-bottom: 1.6rem;
}
.icon-box.text-center .icon-box-icon.icon-bag {
  margin-bottom: 1.8rem;
}
.icon-box.text-center .icon-box-icon.icon-money {
  margin: 0.1rem 0 2.2rem;
}
.icon-box.text-center .icon-box-icon.icon-chat {
  margin-bottom: 1.2rem;
}
.icon-box.icon-box-lg .icon-box-title {
  font-size: 1.8rem;
}
.icon-box.icon-colored-circle .icon-box-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 0;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-color: #336699;
  color: #fff;
  margin-bottom: 2rem;
}

.icon-box-icon {
  display: inline-block;
  margin-bottom: 1.4rem;
}
.icon-box-primary .icon-box-icon i {
  color: #336699;
}
.icon-box-secondary .icon-box-icon i {
  color: #f93;
}
.icon-box-white .icon-box-icon svg {
  fill: #fff;
}
.icon-box-white .icon-box-icon i {
  color: #fff;
}
.icon-box-light .icon-box-icon svg {
  fill: #999;
}
.icon-box-icon i {
  font-size: 2.9rem;
  font-weight: 400;
  line-height: 1;
}
.icon-box-icon i.w-icon-truck {
  font-size: 3.9rem;
}
.icon-box-icon i.w-icon-bag {
  font-size: 3.7rem;
}
.icon-box-icon i.w-icon-money {
  font-size: 3.2rem;
}
.icon-box-icon i.w-icon-chat {
  font-size: 4.4rem;
}
.icon-box-lg .icon-box-icon i {
  font-size: 6rem;
}

.icon-box.icon-box-secondary .icon-box-icon i {
  font-size: 3rem;
}

.icon-box-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.icon-box-side .icon-box-content {
  text-align: start;
}
.icon-box-side .icon-box-content p {
  line-height: 1;
}
.icon-box-side .icon-box-icon {
  margin-right: 1.5rem;
  margin-bottom: 0;
}
.icon-box-side i {
  display: inline-block;
  vertical-align: middle;
}

.icon-border-circle .icon-box-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.circle-icon-section {
  margin: 0.7rem 0 5.7rem;
  padding: 5.7rem 0 3.2rem;
  background-color: #f8f8f8;
}
.circle-icon-section .iconbox-wrap {
  margin-bottom: 4.9rem;
}

.icon-box.icon-border-box {
  border: 1px solid #eeeeee;
  padding: 4rem 1rem 3.4rem;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  border-radius: 0.5rem;
}
.icon-box.icon-border-box .icon-box-icon {
  margin-bottom: 2.5rem;
}
.icon-box.icon-border-box .icon-box-title {
  margin-bottom: 0.7rem;
}
.icon-box.icon-border-box p {
  margin-bottom: 1.6rem;
  line-height: 1.9;
}
.icon-box.icon-border-box a {
  color: #336699;
  font-weight: 600;
  font-size: 1.4rem;
}
.icon-box.icon-border-box a i {
  font-size: 1.6rem;
  vertical-align: middle;
  line-height: 0;
}
.icon-box.icon-border-box:hover {
  -webkit-box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
}

.iconbox-boxed-section .row {
  margin: 0 -3rem;
}
.iconbox-boxed-section .row > * {
  padding: 0 3rem;
}
.iconbox-boxed-section .shadow-swiper {
  overflow-y: auto;
  padding: 4rem;
  margin: -4rem;
}

@media (max-width: 1320px) {
  .iconbox-boxed-section .shadow-swiper {
    padding: 4rem 2rem;
    margin: -4rem -2rem;
  }
}
@media (min-width: 768px) {
  .icon-box-side.icon-box-money {
    margin-top: 0.2rem;
  }
  .icon-box-side.icon-box-chat {
    margin-top: -0.3rem;
  }
}
@media (max-width: 767px) {
  .icon-box-side {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .icon-box-side .icon-box-icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .icon-box-side .icon-box-content {
    text-align: center;
  }
  .icon-box-side.icon-box-money {
    margin-top: 0.6rem;
  }
  .icon-box-side.icon-box-chat .icon-box-icon {
    margin-bottom: 0.4rem;
  }
}
/*--------------------------------
    Instagram
---------------------------------*/
.instagram {
  position: relative;
  overflow: hidden;
}
.instagram a {
  display: block;
  position: relative;
  height: 100%;
}
.instagram a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  z-index: 1;
}
.instagram a::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(90deg) scale(2);
  transform: translate(-50%, -50%) rotate(90deg) scale(2);
  font-size: 3rem;
  font-family: "wolmart";
  color: #fff;
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  z-index: 1;
}
.instagram img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.instagram:hover a::before {
  opacity: 0.5;
}
.instagram:hover a::after {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
  transform: translate(-50%, -50%) rotate(0deg) scale(1);
}
.instagram:hover img {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.instagram-info .instagram-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 1.6rem;
  letter-spacing: 0;
  color: #fff;
  opacity: 0;
  z-index: 2;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.instagram-info .instagram-content i {
  display: inline-block;
  margin-right: 0.8rem;
}
.instagram-info .instagram-content a {
  display: inline-block;
  color: #fff;
}
.instagram-info .instagram-content a:not(:last-child) {
  margin-right: 2rem;
}
.instagram-info .instagram-content a::before {
  content: none;
}
.instagram-info a::after {
  content: none;
}
.instagram-info:hover .instagram-content {
  opacity: 1;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: start;
  z-index: 1045;
}

.mfp-fade.mfp-wrap .mfp-content {
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: transparent;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: end;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: start;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/* -------------------------------------------
    Mini Popup
---------------------------------------------- */
.minipopup-area {
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 3000;
}

.minipopup-box {
  position: absolute;
  left: -8rem;
  width: 300px;
  padding: 2rem;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0;
  -webkit-transition: opacity 0.3s, left 0.3s ease-out, -webkit-transform 0.3s;
  transition: opacity 0.3s, left 0.3s ease-out, -webkit-transform 0.3s;
  transition: opacity 0.3s, left 0.3s ease-out, transform 0.3s;
  transition: opacity 0.3s, left 0.3s ease-out, transform 0.3s,
    -webkit-transform 0.3s;
  z-index: 3000;
}
.minipopup-box.show {
  opacity: 1;
  left: 0;
}
.minipopup-box.focus {
  -webkit-transform: scale(0.98) translateY(-100%);
  transform: scale(0.98) translateY(-100%);
}
.minipopup-box .product.product-cart {
  padding-top: 0;
  margin-bottom: 1.5rem;
}
.minipopup-box .product.product-cart .product-name {
  margin-bottom: 0.9rem;
  font-weight: 400;
}
.minipopup-box .product-details {
  padding: 0 0 0 1.2rem;
}
.minipopup-box p {
  margin-bottom: 0;
  font-size: 1.3rem;
  color: #666;
  line-height: 1.4;
}
.minipopup-box .product-action {
  position: relative;
  opacity: 1;
  visibility: visible;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.minipopup-box .product-action a:first-child {
  background-color: #eee;
  border-color: #eee;
  color: #333;
}
.minipopup-box .product-action a:first-child:hover,
.minipopup-box .product-action a:first-child:focus,
.minipopup-box .product-action a:first-child:active {
  background-color: #dcdcdc;
  border-color: #dcdcdc;
}
.minipopup-box .product-action .btn {
  padding-left: 1.9em;
  padding-right: 1.9em;
}

@media (max-width: 479px) {
  .minipopup-area {
    left: 10px;
  }
}
/*----------------------------
    Overlay
-----------------------------*/
.overlay-dark figure,
.overlay-dark .banner,
.overlay-light figure,
.overlay-light .banner {
  position: relative;
}
.overlay-dark figure::after,
.overlay-dark figure > a::after,
.overlay-dark.banner::after,
.overlay-light figure::after,
.overlay-light figure > a::after,
.overlay-light.banner::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #333;
  opacity: 0;
  -webkit-transition: opacity 0.3s, background 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, background 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, background 0.3s, transform 0.3s;
  transition: opacity 0.3s, background 0.3s, transform 0.3s,
    -webkit-transform 0.3s;
}
.overlay-dark:hover figure::after,
.overlay-dark:hover figure > a::after,
.overlay-dark.banner:hover::after,
.overlay-light:hover figure::after,
.overlay-light:hover figure > a::after,
.overlay-light.banner:hover::after {
  opacity: 0.1;
}
.overlay-dark.banner-fixed::after,
.overlay-dark.post > figure::after,
.overlay-light.banner-fixed::after,
.overlay-light.post > figure::after {
  content: none;
}
.overlay-dark.post > figure > a::after,
.overlay-light.post > figure > a::after {
  z-index: 1;
}

.overlay-light figure::after,
.banner.overlay-light::after {
  background: #ccc;
}

.overlay-zoom {
  overflow: hidden;
}
.overlay-zoom img {
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
}
.overlay-zoom:hover img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.overlay-rotate {
  overflow: hidden;
}
.overlay-rotate img {
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
}
.overlay-rotate:hover {
  -webkit-transform: rotateZ(3deg) scale(1.08);
  transform: rotateZ(3deg) scale(1.08);
}

/*-------------------------
    Page Header
        - Page Header
        - Page Title
        - Page Subtitle
--------------------------*/
.page-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 18rem;
  background-color: #eee;
  text-align: center;
  text-transform: capitalize;
}

.page-title {
  font-size: 3.4rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.1;
  margin-bottom: 0.2rem;
}

.page-subtitle {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.6;
  color: #666;
}

.cart .page-header,
.checkout .page-header {
  height: 134px;
  background: #fff;
}

/*--------------------------------
    Pagniation
---------------------------------*/
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.2rem;
  color: #666;
  letter-spacing: 0.025em;
}
.pagination > * {
  font-size: 1.4rem;
}
.pagination a {
  color: inherit;
}
.pagination .prev,
.pagination .next {
  height: 4rem;
  min-width: 8rem;
  padding: 1.2rem;
  line-height: 1;
  text-align: center;
  letter-spacing: 0;
}
.pagination .prev.disabled,
.pagination .next.disabled {
  color: #aaa;
}
.pagination .prev {
  margin-right: 0.5rem;
}
.pagination .prev i {
  margin-right: 0.5rem;
}
.pagination .next i {
  margin-left: 0.5rem;
}

.page-link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 3rem;
  height: 4rem;
  background-color: transparent;
  border: 1px solid transparent;
  -webkit-transition: background-color 0.4s, border 0.4s, color 0.4s;
  transition: background-color 0.4s, border 0.4s, color 0.4s;
  line-height: 1;
}
:hover:not(.disabled) > .page-link,
.active:not(.disabled) > .page-link {
  background-color: transparent;
  border-color: #eee;
  color: #336699;
  border-radius: 0.3rem;
}

.page-item:not(:last-child) {
  margin-right: 0.5rem;
}

.page-item-dots {
  white-space: nowrap;
}
.page-item-dots::before {
  content: "...";
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9rem;
  font-weight: 600;
  margin-right: 0.1rem;
  margin-top: -6px;
}

/*---------------------------------
    Popup
        - Popup
        - Popup Close
        - Popup Arrow
        - Popup Content
        - Popup - Login
        - Popup - Image PhotoSwipe
-----------------------------------*/
.mfp-wrap {
  z-index: 2500;
}

.mfp-bg {
  z-index: 2500;
  background: #000;
  opacity: 0.4;
  -webkit-transition: opactiy 0.3s ease-out;
  transition: opactiy 0.3s ease-out;
}
.mfp-bg.mfp-ready.mfp-fade {
  opacity: 0.4;
}
.mfp-bg.mfp-remove {
  opacity: 0;
}

.mfp-container {
  padding: 4rem 2rem;
}

.mfp-content > * {
  position: relative;
  margin: auto;
  background: #fff;
  opacity: 0;
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.mfp-ready .mfp-content > * {
  opacity: 1;
}
.mfp-remove .mfp-content > * {
  opacity: 0;
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "wolmart";
  border: 0;
  opacity: 0.5;
  right: 10%;
  left: auto;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.pswp__ui > button::before {
  background: transparent;
  position: static;
}
.pswp__ui > button:hover {
  opacity: 1;
}

.pswp__button--arrow--right::before {
  content: "";
}

.pswp__button--arrow--left {
  left: 10%;
  right: auto;
}
.pswp__button--arrow--left::before {
  content: "";
}

.pswp {
  z-index: 3010;
}
.pswp .pswp__bg {
  background-color: rgba(0, 0, 0, 0.7);
}

.pswp__scroll-wrap {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
}

.pswp__top-bar,
.pswp__caption {
  -webkit-transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1),
    background-color 0.3s 0.5s;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1),
    background-color 0.3s 0.5s;
}

.pswp:not(.pswp--animated-in) .pswp__top-bar,
.pswp:not(.pswp--animated-in) .pswp__caption {
  background-color: transparent;
}

.pswp--visible .pswp__scroll-wrap {
  -webkit-transform: none;
  transform: none;
  opacity: 1;
}

.pswp__img {
  border-radius: 3px;
}

.pswp__img--placeholder--blank {
  background-color: #f2f3f5;
}

.pswp__caption {
  min-height: auto;
}

.pswp__caption__center {
  text-align: center;
}

.pswp__counter {
  left: 0;
}

.pswp__button {
  float: right;
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
  width: 4.8rem;
  height: 4.8rem;
  margin-top: -2.4rem;
  color: #fff;
  border-radius: 50%;
  font-size: 1.6rem;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  text-align: center;
  opacity: 0.5;
  right: 10%;
  left: auto;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  border: 2px solid;
}

.pswp__ui > button::before {
  background: transparent;
  position: static;
}
.pswp__ui > button:hover {
  opacity: 1;
}

.pswp__button--arrow--right::before {
  content: "";
}

.pswp__button--arrow--left {
  left: 10%;
  right: auto;
}
.pswp__button--arrow--left::before {
  content: "";
}

.newsletter-popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 78rem;
  padding: 8.1rem 4.8rem;
  margin: auto;
  background-position: center;
  background-size: cover;
  border-radius: 1rem;
  /*background-image: url(../../assets/images/newsletter-1.jpg); */
}
.newsletter-popup .popup-media {
  margin-bottom: 1.7rem;
}
.newsletter-popup h4 {
  margin-bottom: 0.4rem;
  font-size: 2rem;
}
.newsletter-popup h4 span {
  display: inline-block;
  margin-left: 0.4rem;
  font-weight: 800;
}
.newsletter-popup h2 {
  margin-bottom: 1.1rem;
  font-weight: 800;
  font-size: 2.8rem;
  line-height: 1.2;
}
.newsletter-popup p {
  margin-bottom: 2.3rem;
  line-height: 1.75;
}
.newsletter-popup .input-wrapper-inline {
  max-width: 34rem;
  margin-bottom: 3rem;
}
.newsletter-popup .input-wrapper-inline .form-control {
  min-height: 4.4rem;
  border-color: #ccc;
  color: #666;
}
.newsletter-popup .input-wrapper-inline .btn {
  padding-top: 0.9em;
  padding-bottom: 0.9em;
}
.newsletter-popup label {
  padding-left: 2.7rem;
}
.newsletter-popup label::before {
  border-color: #999;
}

.newsletter-content {
  max-width: 36rem;
}

.mfp-product .mfp-container {
  padding: 4rem 3rem 2rem;
}
.mfp-product .mfp-content {
  max-width: 88rem;
  margin: auto;
}
.mfp-product .product-popup {
  display: block;
  margin-bottom: 3rem;
  padding: 2rem;
}
.mfp-product .product-details {
  overflow-x: hidden;
  overflow-y: auto;
}

body .product-popup {
  display: none;
}

.login-popup {
  max-width: 50rem;
  padding: 3.3rem 5rem 2.6rem;
}
.login-popup .nav.nav-tabs .nav-item {
  width: calc(50% - 0.5rem);
  margin: 0;
}
.login-popup .nav.nav-tabs .nav-item:first-child {
  margin-right: 1rem;
}
.login-popup .nav-item .nav-link {
  padding: 1.3rem 0;
  font-size: 1.6rem;
  font-weight: 700;
}
.login-popup .nav-item .nav-link::after {
  border-top-width: 2px;
  -webkit-transform: none;
  transform: none;
}
.login-popup .tab-pane {
  padding-top: 3.3rem;
  padding-bottom: 2.6rem;
}
.login-popup .tab-pane a:not(.btn):hover {
  text-decoration: underline;
}
.login-popup #sign-in {
  font-size: 1.4rem;
}
.login-popup .form-group {
  margin-bottom: 2.6rem;
}
.login-popup .form-group label {
  display: block;
  margin-bottom: 0.5rem;
}
.login-popup .form-control {
  min-height: 4.4rem;
}
.login-popup .form-checkbox {
  margin-top: 2.2rem;
  margin-bottom: 2.3rem;
}
.login-popup .form-checkbox a {
  font-size: 1.2rem;
  color: #a94442;
}
.login-popup .btn {
  display: block;
  padding-top: 0.79em;
  padding-bottom: 0.79em;
}
.login-popup p {
  margin-bottom: 1.9rem;
}
.login-popup #sign-up p {
  margin-bottom: 2.1rem;
  line-height: 1.84;
}
.login-popup .social-icon {
  margin-right: 0.8rem;
}

@media (min-width: 768px) {
  .product-popup .product-details {
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 2rem;
    width: calc(100% - 3rem);
    height: 100%;
  }
}
.mfp-close {
  width: 18px;
  height: 18px;
  -webkit-transform: rotateZ(45deg) scale(1);
  transform: rotateZ(45deg) scale(1);
  top: -2.7rem;
  right: 0;
  left: auto;
  opacity: 1;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.mfp-close span {
  display: none;
}
.mfp-close:active {
  top: -2.7rem;
}
.mfp-close::before,
.mfp-close::after {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: calc(50% - 1px);
  background-color: #fff;
}
.mfp-close::after {
  width: 100%;
  height: 2px;
  top: calc(50% - 1px);
  left: 0;
}
.mfp-close:hover {
  -webkit-transform: rotateZ(135deg) scale(1.5);
  transform: rotateZ(135deg) scale(1.5);
}

.mfp-ready.mfp-zoom-popup .mfp-content {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.mfp-zoom-popup .mfp-content,
.mfp-removing.mfp-zoom-popup .mfp-content {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.mfp-ready.mfp-fadein-popup .mfp-content {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.mfp-fadein-popup .mfp-content,
.mfp-removing.mfp-fadein-popup .mfp-content {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.mfp-video-popup .mfp-content {
  max-width: 100rem;
}
.mfp-video-popup video {
  width: 100%;
}
.mfp-video-popup .mfp-close {
  top: 2rem;
  right: 2rem;
}

@media (min-width: 768px) {
  .mfp-product .product-popup {
    padding: 3rem;
  }
  .mfp-product .product-popup .product-form-group {
    display: block;
  }
  .mfp-product .product-popup .action-group {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .newsletter-popup {
    max-width: 40rem;
    min-height: 40rem;
    padding: 0rem 2rem;
    /*background-image: url(../../assets/images/newsletter-2.jpg); */
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .newsletter-popup .form-checkbox {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .newsletter-popup .input-wrapper-inline {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 24rem;
  }
  .newsletter-popup .input-wrapper-inline .form-control {
    margin-bottom: 1rem;
    border-radius: 3rem;
    border-width: 1px;
  }
  .newsletter-popup .input-wrapper-inline .btn {
    border-radius: 3rem;
    width: 100%;
  }
}
@media (max-width: 479px) {
  .mfp-product .mfp-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .login-popup {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
/*-------------------------------------------
    Products
        - Default
        - Simple
        - Slide Up
        - Image Gap
        - Widget
        - List
--------------------------------------------*/
.product {
  position: relative;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  color: #020101;
  -webkit-transition: opacity 0.3s, -webkit-box-shadow 0.3s;
  transition: opacity 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, opacity 0.3s;
  transition: box-shadow 0.3s, opacity 0.3s, -webkit-box-shadow 0.3s;
}
.product:hover .product-action,
.product:hover .product-action-vertical,
.product:hover .product-action-horizontal {
  visibility: visible;
  opacity: 1;
}
.product:hover .product-action-horizontal {
  bottom: 1.5rem;
}
.product .product-price {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.product-media {
  position: relative;
  margin-bottom: 0;
  -webkit-transition: -webkit-box-shadow 0.35s;
  transition: -webkit-box-shadow 0.35s;
  transition: box-shadow 0.35s;
  transition: box-shadow 0.35s, -webkit-box-shadow 0.35s;
}
.product-media img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.product-media img:last-child {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.product-media img:first-child {
  position: relative;
  opacity: 1;
}
.product-media:hover img:last-child {
  opacity: 1;
}

.product-label-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: absolute;
  left: 2rem;
  top: 2rem;
  max-width: 11rem;
  z-index: 1;
}

.product-label {
  display: inline-block;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.2em;
  letter-spacing: -0.005em;
  color: #fff;
  line-height: 1;
  padding: 0.6rem 1rem;
  border-radius: 0.3rem;
  text-transform: uppercase;
}
.product-label.label-new {
  background: #336699;
  padding: 0.6rem 1rem;
}
.product-label.label-discount {
  background: #f93;
}
.product-label.label-hot {
  background: #799b5a;
}

.product-sales-wrapper {
  position: relative;
  margin-bottom: 1.4rem;
}
.product-sales-wrapper .progress-bar {
  height: 1rem;
  background: #eee;
  margin-bottom: 0.7rem;
}
.product-sales-wrapper .product-sales-count {
  color: #666;
  font-size: 1.3rem;
}
.product-sales-wrapper .product-sales-count mark {
  background-color: transparent;
  color: inherit;
}

.btn-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 1.14em 0;
  line-height: 1.2;
  background-color: #336699;
  color: #fff;
  font-size: 1.4em;
  font-weight: 600;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.btn-product:hover,
.btn-product:active {
  color: #fff;
}
.btn-product.btn-link {
  display: inline-block;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.6;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background-color: transparent;
  padding: 0;
}
.btn-product.btn-link::after {
  margin-top: 0;
}
.btn-product.btn-sm {
  padding: 0.693em 0;
}

.btn-wishlist {
  line-height: 1;
}
.btn-wishlist i::before {
  margin: 0;
}

.btn-cart {
  font-weight: 600;
}
.btn-cart.btn-link {
  color: #336699;
}
.btn-cart.btn-link:hover,
.btn-cart.btn-link:active {
  color: #336699;
}
.btn-cart:not(.btn-product-icon) i.w-icon-cart {
  font-size: 1.8rem;
  margin-bottom: 0.4rem;
}

.btn-product-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border: 1px solid #eee;
  color: #999;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 3.5rem;
  overflow: hidden;
  -webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s,
    visibility 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s,
    visibility 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s,
    visibility 0.3s, opacity 0.3s, transform 0.3s;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s,
    visibility 0.3s, opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
}
.btn-product-icon:hover,
.btn-product-icon:active {
  border-color: #336699;
  color: #fff;
  background-color: #336699;
}
.btn-product-icon.btn-cart,
.btn-product-icon.btn-quickview {
  font-size: 1.6em;
}
.btn-product-icon.btn-compare {
  font-size: 2em;
}
.product-action-vertical .btn-product-icon {
  border-radius: 50%;
}
.btn-product-icon.btn-cart {
  margin-bottom: 0.2rem;
}
.btn-product-icon.loading {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-product-icon.loading::before {
  opacity: 0.4;
}

.product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  z-index: 10;
  -webkit-transition: opacity 0.3s, visibility 0.3s, bottom 0.3s;
  transition: opacity 0.3s, visibility 0.3s, bottom 0.3s;
  opacity: 0;
  visibility: hidden;
}

.product-action-vertical {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
}

.product-action-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  -webkit-box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.08);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  width: 166px;
  max-width: 80%;
  border-radius: 3px;
  padding: 5px;
  opacity: 0;
  visibility: visible;
  -webkit-transition: opacity 0.3s, visibility 0.3s, bottom 0.3s;
  transition: opacity 0.3s, visibility 0.3s, bottom 0.3s;
}
.product-action-horizontal .btn-product-icon {
  margin-bottom: 0;
  border: none;
  color: #333;
}
.product-action-horizontal .btn-product-icon.btn-wishlist {
  font-size: 1.7em;
}
.product-action-horizontal .btn-product-icon.btn-compare {
  font-size: 1.9em;
}

.product-action-horizontal .btn-product-icon:hover,
.product-action-horizontal .btn-product-icon:active,
.product-action-horizontal .btn-product-icon:focus,
.product-hidden-details .btn-product-icon:hover,
.product-hidden-details .btn-product-icon:active,
.product-hidden-details .btn-product-icon:focus,
.product-list .product-details .btn-product-icon:hover,
.product-list .product-details .btn-product-icon:active,
.product-list .product-details .btn-product-icon:focus {
  background-color: transparent;
  color: #336699;
}

.product-details {
  position: relative;
  padding-top: 1.7rem;
  background-color: #fff;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.product-details > .btn-wishlist {
  position: absolute;
  top: 1.7rem;
  right: 0;
  color: #999999;
  font-size: 1.8em;
  z-index: 1;
}
.product-details > .btn-wishlist.loading {
  right: 1.8rem;
}
.product-details > .btn-wishlist.loading::after {
  left: 0.9rem;
  top: 0.9rem;
}
.product-details > .btn-wishlist:hover {
  color: #336699;
}
.product-details > .btn-wishlist:hover i {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.product-hidden-details .product-action {
  position: relative;
  visibility: hidden;
  opacity: 0;
}
.product-hidden-details .product-action .btn-wishlist {
  position: static;
}
.product-hidden-details .btn-cart {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 9.5rem;
  max-width: 12.3rem;
  height: 3.5rem;
  padding: 0.92em 0;
  font-size: 1.3rem;
  font-weight: 600;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  z-index: 1;
  background-color: #454545;
  border-radius: 0.3rem;
}
.product-hidden-details .btn-cart:hover {
  background-color: #336699;
  border-color: #336699;
}
.product-hidden-details .btn-cart i {
  margin: 0;
  display: none;
}
.product-hidden-details .btn-product-icon {
  visibility: hidden;
  opacity: 0;
}

.product-hidden-details .btn-product-icon,
.product-list .product-details .btn-product-icon {
  width: 3rem;
  height: 3.5rem;
  margin: 0 0 0 1rem;
  border: none;
  color: #333;
  text-align: center;
}
.product-hidden-details .btn-product-icon.btn-compare,
.product-list .product-details .btn-product-icon.btn-compare {
  margin-left: 0.8rem;
}

.product-hidden-details .btn-wishlist,
.product-list .btn-wishlist {
  font-size: 1.8rem;
}
.product-hidden-details .btn-compare,
.product-list .btn-compare {
  font-size: 2rem;
}

.product-name {
  margin-bottom: 0.7rem;
  font-size: 1.3rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-name a {
  color: inherit;
}
.product-name a:hover {
  color: #336699;
}

.product-cat {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: #999;
  line-height: 1;
  text-transform: uppercase;
}
.product-cat a {
  display: inline-block;
  color: inherit;
}
.product-cat a:hover {
  color: #336699;
}

.product-variations {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.product-variations > a {
  border: 1px solid #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 0.3rem;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.product-variations > a:not(:last-child) {
  margin-right: 0.8rem;
}
.product-variations > a:not(.color).active,
.product-variations > a:not(.color):hover {
  border: 1px solid #336699;
}

.ratings-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
  margin-bottom: 0.6rem;
  font-size: 1.2rem;
}

.ratings-full,
.ratings {
  position: relative;
  font-family: "wolmart";
  letter-spacing: 0.2em;
}

.ratings-full {
  cursor: pointer;
  margin-right: 0.5rem;
}
.ratings-full::before {
  content: "" "" "" "" "";
  color: rgba(0, 0, 0, 0.16);
  white-space: nowrap;
}
.ratings-full:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.ratings {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.ratings::before {
  content: "" "" "" "" "";
  color: #f93;
}

.rating-reviews {
  margin: 2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 1.2rem;
  color: #aaa;
}
.rating-reviews:hover {
  color: #336699;
}

.product-price {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1.6;
  -webkit-transition: visibility 0.4s, opacity 0.4s;
  transition: visibility 0.4s, opacity 0.4s;
}
.product-price .old-price {
  font-weight: 400;
  color: #aaa;
}
.product-price .new-price {
  color: #333;
  text-decoration: none;
}
.product-price .new-price:not(:last-child) {
  margin-right: 1rem;
}
.product-price:not(:last-child) {
  margin-bottom: 0.9rem;
}

.sold-by {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: #666;
}
.sold-by a {
  color: #336699;
}

.product-wrap {
  margin-bottom: 2rem;
}

.product-simple:hover .product-price {
  visibility: hidden;
  opacity: 0;
}

.product-slideup-content {
  overflow: hidden;
}
.product-slideup-content .product-hidden-details {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 1.5rem;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  background-color: #fff;
}
.product-slideup-content .product-details {
  background-color: #fff;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.product-slideup-content .btn-wishlist {
  -webkit-transform: translateX(-150%);
  transform: translateX(-150%);
}
.product-slideup-content .btn-compare {
  -webkit-transform: translateX(-300%);
  transform: translateX(-300%);
}
.product-slideup-content:hover .product-details,
.product-slideup-content:hover .product-hidden-details {
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
}
.product-slideup-content:hover .btn-product-icon {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.product-image-gap {
  padding: 0.9rem;
  border: 1px solid #eee;
  background-color: #fff;
}
.product-image-gap .product-details {
  padding: 1.8rem 1rem 1rem;
}
.product-image-gap.product-classic .product-action {
  left: 0;
  bottom: 0;
}
.product-image-gap:hover {
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
}

.product-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-widget .product-name {
  font-size: 1.3rem;
  white-space: normal;
  line-height: 1.45;
  margin-bottom: 0.6rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-widget {
  padding: 1rem 0;
}
.product-widget .product-media {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 11rem;
  flex: 0 0 11rem;
  margin: 0 1rem 0 0;
  max-width: 11rem;
  padding: 0 1rem 0 0;
}
.product-widget .product-details {
  padding: 0 1rem 0 0;
}
.product-widget .product-name {
  margin-bottom: 0.7rem;
  color: #666;
}
.product-widget .product-price {
  font-size: 1.5rem;
}
.sidebar .product-widget {
  padding: 1rem 0;
}
.sidebar .product-widget .product-media {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 35.73%;
  flex: 0 0 35.73%;
  max-width: 35.73%;
  min-width: 33.33%;
  margin-right: 1rem;
  padding: 0;
}
.sidebar .product-widget .product-name {
  margin-bottom: 0.6rem;
  color: #333;
}
.sidebar .product-widget .ratings-container {
  margin-bottom: 0.5rem;
}

.product.text-center .ratings-container {
  text-align: start;
}
.product.text-center .ratings-container,
.product.text-center .product-action {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.product .product-pa-wrapper {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.product .product-pa-wrapper .product-price {
  margin-bottom: 0;
}
.product .product-pa-wrapper .product-action {
  bottom: -1rem;
}
.product .product-pa-wrapper .load-more-overlay.loading::after {
  top: 0;
}
.product:hover .product-pa-wrapper .product-action {
  bottom: 0;
}

.product-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 2rem;
}
.product-list .product-media {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26rem;
  flex: 0 0 26rem;
  max-width: 26rem;
  margin-right: 2rem;
}
.product-list .product-details {
  padding: 0;
}
.product-list .product-name {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.product-list .ratings-container {
  margin-bottom: 0.9rem;
}
.product-list .product-price {
  margin-bottom: 0.6rem;
  font-size: 1.8rem;
}
.product-list .product-action {
  position: relative;
  visibility: visible;
  opacity: 1;
}
.product-list .product-desc {
  margin-bottom: 3.9rem;
  font-size: 1.3rem;
  color: #666;
  line-height: 1.9;
}
.product-list .product-desc li {
  position: relative;
  padding-left: 2rem;
  line-height: 1.86;
}
.product-list .product-desc li::before {
  position: absolute;
  display: block;
  left: 0;
  top: 1px;
  content: "";
  font-family: "wolmart";
  font-weight: 700;
}
.product-list .btn-product {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding: 0.86em 0.5em;
  max-width: 17.6rem;
  background-color: transparent;
  border: 2px solid #ccc;
  color: #333;
  font-size: 1.4rem;
  border-radius: 0.3rem;
  -webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.product-list .btn-product:hover {
  background-color: #336699;
  border-color: #336699;
  color: #fff;
}
.product-list .btn-product i.w-icon-cart {
  margin: 0 0.8rem 0 0;
  font-size: 1.7rem;
}

.product-absolute::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.6);
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.product-absolute .product-name {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 1rem;
  white-space: normal;
  margin-bottom: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  margin-top: 1rem;
  -webkit-transition: margin-top 0.3s, opacity 0.3s;
  transition: margin-top 0.3s, opacity 0.3s;
  opacity: 0;
  z-index: 1;
}
.product-absolute .product-name:hover a {
  text-decoration: underline;
}
.product-absolute:hover {
  -webkit-box-shadow: 0 2rem 1.6rem -1.6rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 2rem 1.6rem -1.6rem rgba(0, 0, 0, 0.5);
}
.product-absolute:hover::before {
  opacity: 1;
}
.product-absolute:hover .product-name {
  margin-top: 0;
  opacity: 1;
}
.product-absolute:hover .product-name a {
  color: #fff;
}

@media (max-width: 575px) {
  .product-list {
    display: block;
    margin-bottom: 2rem;
  }
  .product-list .product-media {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin: 0 0 2rem 0;
  }
}
@media (max-width: 479px) {
  .product:not(.product-list) {
    font-size: 0.8rem;
  }
  .product:not(.product-list) .btn-product-icon {
    width: 3rem;
    height: 3rem;
  }
  .product:not(.product-list) .product-label-group {
    top: 1rem;
    left: 1rem;
  }
  .product:not(.product-list) .product-action-vertical {
    top: 1rem;
    right: 1rem;
  }

  .product-hidden-details .btn-cart {
    max-width: 3.5rem;
    min-width: auto;
  }
  .product-hidden-details .btn-cart i {
    display: block;
  }
  .product-hidden-details .btn-cart span {
    display: none;
  }
}
/*-----------------------------------------
    Product Single Component
------------------------------------------*/
.product-nav {
  margin: 0 0 0 auto;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-nav .product-nav-prev,
.product-nav .product-nav-next {
  position: relative;
}
.product-nav .product-nav-prev a,
.product-nav .product-nav-next a {
  display: inline-block;
  padding: 1.1rem 0.4rem 1.3rem;
}
.product-nav .product-nav-prev i,
.product-nav .product-nav-next i {
  display: inline-block;
  border-radius: 50%;
  width: 2em;
  color: var(--wolmart-body-color, #666);
  border: 1px solid #eee;
  line-height: calc(2em - 2px);
  text-align: center;
  font-size: 1.3rem;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}
.product-nav .product-nav-prev:hover i,
.product-nav .product-nav-next:hover i {
  border-color: #336699;
  background-color: #336699;
  color: #fff;
}
.product-nav .product-nav-prev:hover .product-nav-popup,
.product-nav .product-nav-next:hover .product-nav-popup {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  transform: none;
}
.product-nav .product-nav-popup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 30;
  width: 120px;
  padding: 0 5px 9px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
}
.product-nav .product-nav-popup img {
  position: relative;
  width: 100%;
  padding-top: 5px;
  background-color: #fff;
}
.product-nav .product-nav-popup::before {
  content: "";
  position: absolute;
  top: -0.8rem;
  right: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
.product-nav .product-nav-prev .product-nav-popup {
  right: -3.4rem;
}
.product-nav .product-nav-prev .product-nav-popup::before {
  right: 4.2rem;
}
.product-nav .product-nav-next .product-nav-popup {
  right: 0;
}
.product-nav .product-name {
  position: relative;
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
  padding: 1.1rem 0 0;
  margin: 0;
  background: inherit;
  z-index: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.product-thumbs-wrap,
.product-single-swiper {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.product-single-swiper img {
  display: block;
}
.product-single-swiper .swiper-button-prev,
.product-single-swiper .swiper-button-next {
  background-color: transparent;
  border: 0;
  font-size: 2rem;
}

.product-image {
  position: relative;
  overflow: hidden;
}

.product-thumbs-wrap {
  position: relative;
  margin-top: 1rem;
  overflow: hidden;
}
.product-thumbs-wrap img {
  display: block;
  width: 100%;
}

.product-thumbs-wrap button,
.product-thumbs-wrap .swiper-button-prev,
.product-thumbs-wrap .swiper-button-next {
  bottom: 0;
  top: unset;
  width: 2rem;
  height: 100%;
  background-color: #fff;
  border: none;
  color: #999;
  font-size: 1.8rem;
  font-weight: 600;
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

.product-thumbs-wrap .swiper-button-prev {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.product-thumbs-wrap .swiper-button-next {
  left: auto;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.product-thumbs-wrap .swiper-button-disabled {
  opacity: 0;
}
.product-thumbs-wrap:hover .swiper-button-prev:not(.swiper-button-disabled),
.product-thumbs-wrap:hover .swiper-button-next:not(.swiper-button-disabled) {
  opacity: 0.9;
  -webkit-transform: none;
  transform: none;
}

.product-thumbs-wrap button,
.product-thumb::before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.product-thumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-gallery:not(.product-gallery-vertical) .swiper-slide img {
  width: 100%;
}
.product-gallery:not(.product-gallery-vertical)
  .product-thumbs:not(.swiper-wrapper) {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.product-gallery:not(.product-gallery-vertical)
  .product-thumbs:not(.swiper-wrapper)
  .product-thumb {
  margin: 0;
}
.product-gallery:not(.product-gallery-vertical)
  .product-thumbs:not(.swiper-wrapper)
  .product-thumb::before {
  content: none;
}

.product-thumb {
  position: relative;
  cursor: pointer;
}
.product-thumb::before {
  content: "";
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  border: 1px solid transparent;
}
.product-thumb.active::before {
  border-color: #336699;
}

.swiper-slide-thumb-active::before {
  border-color: #336699;
}

.rating-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 2.4rem;
}
.rating-form label {
  font-size: 1.4rem;
}

.rating-stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 14px;
  font-size: 1.2rem;
  margin-left: 1rem;
}
.rating-stars a {
  color: #333;
  text-indent: -9999px;
  letter-spacing: 1px;
  width: 1.4rem;
}
.rating-stars a:before {
  content: "";
  position: absolute;
  left: 0;
  height: 14px;
  line-height: 1;
  font-family: "wolmart";
  text-indent: 0;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.2em;
}
.rating-stars a.active:before,
.rating-stars a:hover:before {
  content: "";
  color: #f93;
}
.rating-stars .star-1 {
  z-index: 10;
}
.rating-stars .star-2 {
  z-index: 9;
}
.rating-stars .star-3 {
  z-index: 8;
}
.rating-stars .star-4 {
  z-index: 7;
}
.rating-stars .star-5 {
  z-index: 6;
}
.rating-stars .star-1:before {
  width: 20%;
}
.rating-stars .star-2:before {
  width: 40%;
}
.rating-stars .star-3:before {
  width: 60%;
}
.rating-stars .star-4:before {
  width: 80%;
}
.rating-stars .star-5:before {
  content: "";
}

.stock {
  margin-bottom: 3rem;
  font-size: 1.3rem;
  line-height: 1;
}

.product-single {
  color: inherit;
}
.product-single .product-categories {
  margin-bottom: 1.1rem;
}
.product-single .product-details {
  padding: 0;
}
.product-single .product-title {
  margin-bottom: 0.9rem;
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 1.6;
}
.product-single .product-title a {
  color: inherit;
}
.product-single .product-bm-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.product-single .brand {
  margin: 0 2rem 1rem 0;
  border: 1px solid #eee;
  border-radius: 0.5rem;
}
.product-single .product-meta {
  margin-bottom: 1rem;
}
.product-single .product-divider {
  margin: 1.4rem 0 2rem;
}
.product-single .social-icon {
  margin-right: 0.8rem;
}
.product-single .product-categories,
.product-single .product-sku {
  color: #666;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 0;
}
.product-single .product-categories span,
.product-single .product-sku span {
  color: #999;
}
.product-single .product-categories span:not(:last-child)::after,
.product-single .product-sku span:not(:last-child)::after {
  content: ",";
  display: inline-block;
  vertical-align: middle;
}
.product-single .product-categories a,
.product-single .product-sku a {
  color: inherit;
}
.product-single .product-categories a:hover,
.product-single .product-sku a:hover {
  color: #336699;
}
.product-single .product-price {
  margin-bottom: 1.1rem;
  font-size: 3rem;
  color: #333;
  font-weight: 700;
  line-height: 1.2;
}
.product-single .old-price {
  font-size: 2.4rem;
}
.product-single .ratings-container {
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
}
.product-single .ratings-full {
  font-weight: 600;
}
.product-single .ratings-full::before {
  color: rgba(0, 0, 0, 0.2);
}
.product-single .ratings::before {
  color: #ffb639;
}
.product-single .ratings-full,
.product-single .ratings {
  letter-spacing: 0.18em;
}
.product-single .rating-reviews {
  color: #999;
}
.product-single .product-short-desc {
  margin-bottom: 1.6rem;
  font-size: 1.3rem;
}
.product-single .product-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.product-single .product-form .product-size {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 18rem;
  flex: 0 0 18rem;
  max-width: 18rem;
}
.product-single .product-form select {
  border: 1px solid #ccc;
  color: #999;
  font-size: 1.3rem;
  max-width: none;
  width: 100%;
  padding: 0.88em 2rem;
}
.product-single .product-form label {
  display: block;
  max-width: 7.5rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7.5rem;
  flex: 0 0 7.5rem;
  padding: 0.6rem 0;
  font-size: 1.4rem;
  color: #333;
  line-height: 1;
}
.product-single .product-variations {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.product-single .product-variation-price {
  display: none;
  font-size: 2.4rem;
  font-weight: 600;
  color: #333;
  line-height: 1;
  margin-bottom: 1.2rem;
  padding-top: 2.8rem;
}
.product-single .product-color-swatch {
  margin-right: 4rem;
  margin-bottom: 2rem;
}
.product-single .product-color-swatch .color {
  display: block;
  text-align: center;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  border: none;
  margin-right: 0;
}
.product-single .product-color-swatch .color + .color {
  margin-left: 0.8rem;
}
.product-single .product-color-swatch .color::before {
  content: "";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  color: #fff;
  line-height: 2.2;
}
.product-single .product-color-swatch .color.active::before {
  content: "";
}
.product-single .product-size-swatch {
  margin-bottom: 2rem;
}
.product-single .product-size-swatch .size {
  display: block;
  text-align: center;
  margin-bottom: 0.5rem;
  padding: 0.6rem 0.8rem;
  width: auto;
  height: auto;
  line-height: 1;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #666;
  -webkit-transition: border-color 0.35s, color 0.35s;
  transition: border-color 0.35s, color 0.35s;
}
.product-single .product-size-swatch .size.active,
.product-single .product-size-swatch .size:hover {
  border-color: #336699;
  color: #336699;
}
.product-single .product-image-swatch {
  margin-bottom: 2rem;
}
.product-single .product-image-swatch .image {
  margin-right: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  overflow: hidden;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.product-single .product-image-swatch .image img {
  width: 2.6rem;
  height: 2.6rem;
}
.product-single .product-image-swatch .image.active {
  border-color: #336699;
}
.product-single .quantity,
.product-single .btn-cart,
.product-single .btn-product-icon {
  border-radius: 3px;
}
.product-single .btn-cart {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
  min-width: 14rem;
}
.product-single .btn-cart i {
  margin: 0 0.4rem 0.2rem 0;
  font-size: 1.7rem;
}
.product-single .btn-cart.disabled {
  background-color: #eee;
  border-color: #eee;
  color: #666;
  cursor: not-allowed;
}
.product-single .product-qty-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 14rem;
  flex: 0 0 14rem;
  max-width: 14rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
}
.product-single .social-links-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 1.3rem;
}
.product-single .social-links-wrapper > * {
  margin-right: 2rem;
  margin-bottom: 1rem;
}
.product-single .social-links-wrapper .btn-product-icon {
  width: 1.8rem;
  height: auto;
  margin-right: 1.6rem;
  margin-bottom: 0;
  font-size: 1.8rem;
  border: none;
  color: #333;
  overflow: initial;
  background-color: transparent;
}
.product-single .social-links-wrapper .btn-product-icon.btn-compare {
  font-size: 2rem;
}
.product-single .social-links-wrapper .btn-product-icon:hover {
  background-color: transparent;
  color: #336699;
}
.product-single .social-links-wrapper .divider {
  width: 1px;
  height: 1.8rem;
  background: #e1e1e1;
  margin-top: 1rem;
}
.product-single .social-icons {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.product-single.product-grid .product-image-full {
  right: 2.5rem;
}
.product-single .product-variation-clean {
  display: none;
  font-size: 1.2rem;
  background: #f4f4f4;
  padding: 0.3em 1em;
  color: #333;
  position: absolute;
  top: 100%;
  margin-top: 1rem;
  z-index: 1;
}

.product-thumbs-sticky .product-image-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 10rem);
  flex: 0 0 calc(100% - 10rem);
  max-width: calc(100% - 10rem);
}
.product-thumbs-sticky .product-thumbs-wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 9rem;
  flex: 0 0 9rem;
  max-width: 9rem;
  margin-right: 1rem;
  margin-top: 0;
}
.product-thumbs-sticky .product-thumbs {
  -webkit-transition: none;
  transition: none;
}
.product-thumbs-sticky .product-image {
  margin-bottom: 2rem;
}

.product-gallery-vertical.product-thumbs-sticky .product-thumbs-wrap {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.product-gallery-vertical.product-thumbs-sticky .product-thumbs {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: -1rem 0;
  max-width: 100%;
  bottom: auto;
}
.product-gallery-vertical.product-thumbs-sticky .product-thumb {
  margin: 1rem 0;
}

.single-product .nav-top > .swiper-button-prev {
  right: 0.5rem;
}
.single-product .nav-top > .swiper-button-next {
  right: 0.2rem;
}
.single-product .nav-top > .swiper-button-next,
.single-product .nav-top > .swiper-button-prev {
  width: 15px;
  top: -6.2rem;
  color: #666;
  font-size: 1.3rem;
  font-weight: 600;
}
.single-product
  .nav-top
  > .swiper-button-next:not(.swiper-button-disabled):hover,
.single-product
  .nav-top
  > .swiper-button-prev:not(.swiper-button-disabled):hover {
  color: #336699;
}

@media (min-width: 992px) {
  .product-gallery-vertical
    .product-thumbs-wrap:not(.swiper-container-initialized)
    .product-thumb {
    margin-bottom: 1rem;
  }

  .product-gallery-vertical .product-thumbs-wrap {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    max-width: 9rem;
    margin: 0 1rem 0 0;
  }
  .product-gallery-vertical
    .product-thumbs-wrap:hover
    .swiper-button-prev:not(.swiper-button-disabled),
  .product-gallery-vertical
    .product-thumbs-wrap:hover
    .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 0.9;
    -webkit-transform: none;
    transform: none;
  }
  .product-gallery-vertical .product-thumbs-wrap .swiper-button-prev,
  .product-gallery-vertical .product-thumbs-wrap .swiper-button-next {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0;
    width: 100%;
    height: 2.5rem;
  }
  .product-gallery-vertical .product-thumbs-wrap .swiper-button-prev {
    top: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .product-gallery-vertical .product-thumbs-wrap .swiper-button-prev::before {
    content: "";
  }
  .product-gallery-vertical .product-thumbs-wrap .swiper-button-next {
    bottom: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  .product-gallery-vertical .product-thumbs-wrap .swiper-button-next::before {
    content: "";
  }
  .product-gallery-vertical .product-single-swiper {
    max-width: calc(100% - 10rem);
  }
  .product-gallery-vertical .product-thumbs {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    max-width: 100%;
  }
  .product-gallery-vertical .product-thumb {
    padding: 0;
    height: auto;
  }
}
@media (max-width: 991px) {
  .product-single .product-form .product-size {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14rem;
    flex: 0 0 14rem;
    max-width: 14rem;
  }
}
@media (min-width: 768px) {
  .product-single .product-details {
    padding: 0.2rem 0 0 1rem;
  }
}
@media (max-width: 479px) {
  .product-single:not(.product-popup) .product-qty-form {
    margin-right: 1rem;
  }
}
/*-------------------------------------------
    Progress Bar
        - Rounded
--------------------------------------------*/
.progress-bar {
  background-color: #eee;
  margin-bottom: 1rem;
  height: 1rem;
  width: 100%;
}
.progress-bar span {
  display: block;
  height: 100%;
  background-color: #999;
}
.progress-bar.rounded {
  border-radius: 0.8rem;
}
.progress-bar.rounded span {
  border-radius: 0.8rem;
}
.progress-bar.progress-bar-sm {
  height: 0.8rem;
}

.progress-value mark {
  background: transparent;
}

/* ------------------------------------
    Sidebar
        - Base
        - Sidebar Elements
        - Fixed Sidebar
        - Right Sidebar
        - Tag
        - Blog Sidebar
------------------------------------- */
.sidebar-active .sidebar .sidebar-overlay,
.right-sidebar-active .right-sidebar .sidebar-overlay {
  visibility: visible;
  opacity: 0.5;
}
.sidebar-active .sidebar .sidebar-close,
.right-sidebar-active .right-sidebar .sidebar-close {
  opacity: 1;
  visibility: visible;
}
.sidebar-active .sidebar .sidebar-content,
.right-sidebar-active .right-sidebar .sidebar-content {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.sidebar-overlay,
.sidebar-toggle,
.sidebar-content,
.sidebar-close {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2200;
}

.sidebar-overlay {
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0.4s, opacity 0.4s;
  transition: visibility 0.4s, opacity 0.4s;
}

.sidebar-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 206px;
  width: 40px;
  height: 40px;
  font-size: 1.4rem;
  color: #fff;
  background-color: #222;
  opacity: 0.6;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.sidebar-toggle:hover {
  opacity: 0.8;
  color: #fff;
}

.right-sidebar .sidebar-toggle {
  border-radius: 0.3rem 0 0 0.3rem;
}

.left-sidebar .sidebar-toggle {
  border-radius: 0 0.3rem 0.3rem 0;
}

.sidebar-close {
  opacity: 0;
  visibility: hidden;
  margin: 0;
  left: calc(100vw - 50px);
  top: 25px;
  z-index: 2201;
  -webkit-transition: opacity 0.4s, visibility 0.4s;
  transition: opacity 0.4s, visibility 0.4s;
}

.sidebar-content {
  bottom: 0;
  width: 31rem;
  padding: 2rem;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  overflow: auto;
  background-color: #fff;
  opacity: 0;
  line-height: 1.3;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
}

.widget:not(.widget-collapsible) .widget-title {
  border: none;
}

.sidebar .widget:last-child .widget-body {
  padding-bottom: 0;
}

.right-sidebar {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.right-sidebar .sidebar-toggle {
  left: auto;
  right: 0;
}
.right-sidebar .sidebar-close {
  left: 10px;
  padding: 10px;
}
.right-sidebar .sidebar-content {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  left: auto;
  right: 0;
}

.right-sidebar .sidebar-content::-webkit-scrollbar {
  width: 0;
}

.filter-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.8rem 0 1.7rem;
}
.filter-actions label {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  color: #333;
}
.filter-actions .filter-clean {
  padding-bottom: 0;
  text-transform: capitalize;
  font-weight: 400;
}

.price-range {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1.3rem 0 1rem;
}
.price-range input {
  padding: 0.5rem;
  font-family: Poppins, sans-serif;
  color: #999;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 62px;
  height: 37px;
  line-height: 37px;
  vertical-align: middle;
  -webkit-appearance: textfield;
}
.price-range input::-webkit-outer-spin-button,
.price-range input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.price-range input::-webkit-input-placeholder {
  color: inherit;
}
.price-range input::placeholder,
.price-range input::-webkit-input-placeholder,
.price-range input::-moz-placeholder {
  color: inherit;
}
.price-range .delimiter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0.65rem;
  font-size: 1.9rem;
  font-weight: 600;
  color: #999;
}
.price-range .btn {
  padding: 0;
  font-size: 1.3rem;
  font-weight: 400;
  width: 4.8rem;
  height: 3.7rem;
  vertical-align: middle;
  line-height: 2.6;
  margin-left: 1rem;
  text-transform: none;
}

.filter-items {
  font-size: 1.4rem;
}
.filter-items a {
  position: relative;
  display: block;
  color: inherit;
  padding: 1rem 0 1rem 0.2rem;
}
.filter-items a:hover {
  color: #336699;
}
.filter-items.item-check a {
  padding-left: 2.8rem !important;
}
.filter-items.item-check a::before {
  content: "";
  position: absolute;
  border: 1px solid;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 2px;
  color: #999;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  text-align: center;
  text-indent: 1px;
  line-height: 1.3em;
}
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min--moz-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5) {
  .filter-items.item-check a::before {
    text-indent: 0;
  }
}
.filter-items.item-check .active > a::before {
  content: "";
  background-color: #336699;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 3px;
  left: 1px;
  padding-top: 3px;
  font-weight: 600;
}
.filter-items.search-ul a::before {
  content: none;
}
.filter-items .show > a {
  color: #336699;
}
.filter-items .show > a i {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.filter-items li span {
  position: absolute;
  right: 0;
  color: #ccc;
}
.filter-items .with-ul > a > i {
  position: absolute;
  right: 0;
  line-height: 0;
  font-size: 1.2rem;
  font-weight: 600;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  top: 50%;
}
.filter-items ul {
  display: none;
  position: relative;
  padding-left: 1.2rem;
  margin: 0.2rem 0;
}
.filter-items ul::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 7px;
  bottom: 7px;
  border-left: 1px solid #eee;
}
.filter-items ul a {
  font-size: 1.3rem;
  color: #999;
  padding: 0.45rem 0;
}

.toolbox-horizontal .sidebar-fixed {
  max-width: none;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.toolbox-horizontal.toolbox .left-sidebar-toggle {
  padding: 0.65em;
  margin-bottom: 0;
}
.toolbox-horizontal .select-menu select,
.toolbox-horizontal .toolbox-show select {
  padding-top: 0.65em;
  padding-bottom: 0.65em;
}
.toolbox-horizontal .toolbox-show select {
  padding-left: 1.4rem;
  padding-right: 3.1rem;
}
.toolbox-horizontal .select-box::before {
  right: 0.9rem;
}
.toolbox-horizontal .toolbox-layout {
  margin-left: 2rem;
}
.toolbox-horizontal .select-menu:not(.toolbox-sort) {
  display: block;
}
.toolbox-horizontal .select-menu:not(.toolbox-sort)::before {
  content: none;
}
.toolbox-horizontal .select-menu-toggle {
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  padding: 1.3rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #eee;
}
.toolbox-horizontal .filter-items {
  padding: 0.7rem 0 1rem;
}
.toolbox-horizontal .filter-items a:not(.btn) {
  padding: 1rem 0;
  color: #333;
}
.toolbox-horizontal .filter-items .btn {
  color: #fff;
}

@media (min-width: 992px) {
  .toolbox-horizontal {
    padding: 2rem;
    margin: -2rem;
  }
  .toolbox-horizontal .sidebar-fixed .sidebar-content.toolbox-left {
    padding-bottom: 0;
  }
  .toolbox-horizontal .toolbox-left .toolbox-item {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .toolbox-horizontal .toolbox-left .toolbox-item.opened .filter-items {
    opacity: 1;
    visibility: visible;
  }
  .toolbox-horizontal .filter-items {
    position: absolute;
    top: 100%;
    margin-top: 0.5rem;
    min-width: 23rem;
    padding: 1rem 2rem 2rem;
    font-size: 1.2rem;
    color: #333;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    -webkit-box-shadow: 0 5px 16px rgba(0, 0, 0, 0.12);
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.12);
    background-color: #fff;
  }
  .toolbox-horizontal .filter-items li {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 1rem 0.5rem 0 0;
  }
  .toolbox-horizontal .filter-items a:not(.btn) {
    background: #f5f5f5;
    padding: 0.5em 1em;
    border-radius: 0.3rem;
  }
  .toolbox-horizontal .filter-items .active > a {
    background-color: #336699;
    color: #fff;
  }
  .toolbox-horizontal .select-menu::before {
    font-size: 1.1rem;
    right: 1rem;
    color: #333;
  }
  .toolbox-horizontal .select-menu-toggle {
    position: relative;
    border: 1px solid #ccc;
    font-weight: 400;
    font-size: 1.2rem;
    color: #222;
    line-height: 1.5;
    padding: 0.65em 4.3em 0.65em 1.2em;
    text-transform: none;
    border-radius: 0.3rem;
    -webkit-transition: border-color 0.4s, color 0.4s;
    transition: border-color 0.4s, color 0.4s;
  }
  .toolbox-horizontal .select-menu-toggle::after {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0.9rem;
    font-family: "wolmart";
    content: "";
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1;
  }
  .toolbox-horizontal .filter-items.item-check a {
    padding-left: 1em !important;
  }
  .toolbox-horizontal .filter-items.item-check a::before {
    content: none;
  }
  .toolbox-horizontal .price-range {
    margin: 0;
  }
}
.shop-sidebar {
  position: relative;
}
.shop-sidebar .widget-body {
  margin-bottom: 1rem;
}
.shop-sidebar .filter-price-text {
  font-size: 1.3rem;
  letter-spacing: -0.025em;
}

.widget-icon-box {
  padding: 0 2rem;
  border-radius: 0.5rem;
  border: 1px solid #eee;
}
.widget-icon-box .icon-box-side {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  padding: 2rem 0;
}
.widget-icon-box .icon-box-side:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.widget-icon-box i.w-icon-truck {
  font-size: 3.4rem;
}
.widget-icon-box i.w-icon-bag,
.widget-icon-box i.w-icon-money {
  font-size: 3rem;
}
.widget-icon-box .icon-box-title {
  font-weight: 600;
}
.widget-icon-box p {
  color: #999;
}

.widget-banner .banner-content {
  left: 10.7%;
  bottom: 12.5%;
}
.widget-banner .banner-price-info {
  margin-left: -2px;
  margin-bottom: 0.2rem;
  font-size: 4.6em;
}
.widget-banner sup {
  margin-left: 1px;
  vertical-align: 12px;
  font-size: 0.652em;
}
.widget-banner sub {
  margin-left: -1.7em;
  vertical-align: 0;
  font-size: 0.26em;
}
.widget-banner .banner-subtitle {
  font-size: 1.6rem;
}

.widget.widget-products .title {
  margin-bottom: 0.7rem;
  font-size: 1.6rem;
}
.widget.widget-products .title.title-link::before {
  height: 2px;
  background-color: #666;
  bottom: -0.7rem;
}
.widget.widget-products .swiper-button-next,
.widget.widget-products .swiper-button-prev {
  top: -4.5rem;
  right: -0.5rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.widget.widget-products .swiper-button-prev {
  right: 0.3rem;
}
.widget.widget-products .swiper-button-next {
  right: 0.2rem;
}

.blog-sidebar .widget .widget-title {
  cursor: default;
}
.blog-sidebar .widget .widget-body {
  padding: 0;
}
.blog-sidebar .widget:not(:last-child) .widget-body {
  margin-bottom: 2.5rem;
}
.blog-sidebar .widget:last-child .widget-body {
  margin-bottom: 1rem;
}
.blog-sidebar .widget .widget-title {
  margin-bottom: 1rem;
}

.widget-search-form .form-control {
  border-radius: 0.3rem 0 0 0.3rem;
  font-size: 1.2rem;
  color: #777;
}
.widget-search-form .btn-search {
  padding: 0.9em 1.07em 1em;
  border-width: 1px 1px 1px 0;
  border-color: #eee;
  border-radius: 0 0.3rem 0.3rem 0;
}
.widget-search-form .btn-search i {
  font-size: 1.8rem;
}
.widget-search-form .btn-search:hover,
.widget-search-form .btn-search:active,
.widget-search-form .btn-search:focus {
  background-color: #fff;
  color: #336699;
}

.widget-posts .swiper-button-next,
.widget-posts .swiper-button-prev {
  top: -4.2rem;
  border: none;
  width: auto;
  height: auto;
  font-size: 1.5rem;
  font-weight: 600;
}
.widget-posts .swiper-button-next:not(.swiper-button-disabled):hover,
.widget-posts .swiper-button-prev:not(.swiper-button-disabled):hover {
  background-color: #fff;
  color: #336699;
}
.widget-posts .swiper-button-prev {
  right: 0.4rem;
}
.widget-posts .swiper-button-next {
  right: 0.3rem;
}

.widget-custom-block p {
  line-height: 1.6;
}

.tags .tag {
  display: inline-block;
  margin: 0.5rem 1rem 0.5rem 0;
  padding: 0.5rem 0.9rem;
  font-size: 1.3rem;
  border: 1px solid #ccc;
  line-height: 1.35;
  text-transform: capitalize;
  color: #333;
  border-radius: 0.3rem;
  -webkit-transition: color 0.3s, border-color 0.3s;
  transition: color 0.3s, border-color 0.3s;
}
.tags .tag:hover {
  border-color: #336699;
  color: #336699;
}

.vendor-sidebar .widget-collapsible .widget-body {
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.vendor-sidebar .widget-categories .widget-body {
  padding: 0.7rem 0 2rem;
}
.vendor-sidebar .widget-time .widget-body {
  padding: 0.3rem 0 2rem;
}
.vendor-sidebar .widget.widget-products .widget-body {
  padding: 1rem 0 2rem;
}
.vendor-sidebar .product-widget .product-media {
  padding-left: 0.2rem;
}
.vendor-sidebar .product-widget .product-details {
  padding-bottom: 0.8rem;
}
.vendor-sidebar .product-widget .product-name {
  margin-bottom: 0.6rem;
}
.vendor-sidebar .product-widget .ratings-container {
  margin-bottom: 0.4rem;
}
.vendor-sidebar .product-widget .product-price {
  font-size: 1.6rem;
}

.widget-contact .form-control {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.3rem;
  border-color: #e1e1e1;
  border-radius: 0.3rem;
}
.widget-contact input {
  min-height: 4.4rem;
  padding-top: 0.9rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.widget-contact textarea {
  min-height: 17.4rem;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  margin-bottom: 2rem;
}

.widget-time li {
  padding: 1rem 0;
}
.widget-time label::after {
  content: "-";
  display: inline-block;
  margin-left: 0.4rem;
}
.widget-time .widget-body span {
  display: inline-block;
  width: 11rem;
  font-weight: 600;
  color: #333;
}

.widget-wcmp-contact .form-control {
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  color: #666;
}
.widget-wcmp-contact input {
  min-height: 4rem;
}
.widget-wcmp-contact textarea {
  max-height: 12.4rem;
}

.widget-search-products input {
  min-height: 4rem;
  margin-right: 0.5rem;
  color: #666;
  border-radius: 0.3rem;
}
.widget-search-products .btn {
  padding: 0.86em 1em;
  background-color: #eee;
  border-color: #eee;
  color: #333;
}
.widget-search-products .btn:hover,
.widget-search-products .btn:focus,
.widget-search-products .btn:active {
  background-color: #333;
  border-color: #333;
  color: #fff;
}

@media (min-width: 992px) {
  .sidebar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 31rem;
    flex: 0 0 31rem;
    max-width: 31rem;
  }

  .main-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .row .main-content {
    max-width: calc(100% - 31rem);
  }

  .shop-sidebar.sidebar-fixed .sidebar-content {
    padding: 0 0 2rem;
  }

  .sidebar-fixed .sidebar-toggle,
  .sidebar-fixed .sidebar-overlay {
    display: none;
  }
  .sidebar-fixed .sidebar-content {
    position: relative;
    overflow: visible;
    padding: 0;
    opacity: 1;
    z-index: 2;
    width: auto;
    -webkit-transform: none;
    transform: none;
  }

  .shop-content {
    overflow: hidden;
  }
}
@media (max-width: 991px) {
  .sidebar-active .page-wrapper {
    margin-left: 310px;
    margin-right: -310px;
  }
  .right-sidebar-active .page-wrapper {
    margin-left: -310px;
    margin-right: 310px;
  }
}
@media (max-width: 479px) {
  .sidebar-content {
    width: 27rem;
  }

  .sidebar-active .page-wrapper {
    margin-left: 27rem;
    margin-right: -27rem;
  }
  .right-sidebar-active .page-wrapper {
    margin-left: -27rem;
    margin-right: 27rem;
  }

  .widget-icon-box .icon-box-side {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .widget-icon-box .icon-box-icon {
    margin: 0 0 0.5rem;
  }
  .widget-icon-box .icon-box-content {
    text-align: center;
  }
}
/*-------------------------------
    Social Links
        - Default Style
        - Other Styles
            - No Border
            - Inline Style
--------------------------------*/
.social-icons {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-icon {
  font-size: 1.4rem;
  width: 32px;
  height: 32px;
  margin: 2px 10px 2px 0;
  line-height: 28px;
  border: 2px solid #ccc;
  border-radius: 50%;
  color: inherit;
  -webkit-transition: color 0.4s, border 0.4s, background-color 0.4s;
  transition: color 0.4s, border 0.4s, background-color 0.4s;
  text-align: center;
}
.border-thin .social-icon {
  border-width: 1px;
  line-height: 3rem;
}
.social-icon:last-child {
  margin-right: 0;
}
.social-icon.social-facebook {
  color: #1b4f9b;
}
.social-icon.social-twitter {
  color: #00adef;
}
.social-icon.social-instagram {
  color: #cc0001;
}
.social-icon.social-youtube {
  color: #2c567e;
}
.social-icon.social-pinterest {
  color: #f96a02;
}
.social-icon.social-google {
  color: #dd4b39;
}
.social-icon.social-linkedin {
  color: #0073b2;
}
.social-icon.social-email {
  color: #dd4b39;
}
.social-icon.social-whatsapp {
  color: #3c8a38;
}

.social-icons-colored .social-icon {
  color: #fff;
}
.social-icons-colored .social-icon.social-facebook {
  border-color: #1b4f9b;
  background-color: #1b4f9b;
}
.social-icons-colored .social-icon.social-twitter {
  border-color: #00adef;
  background-color: #00adef;
}
.social-icons-colored .social-icon.social-instagram {
  border-color: #cc0001;
  background-color: #cc0001;
}
.social-icons-colored .social-icon.social-youtube {
  border-color: #2c567e;
  background-color: #2c567e;
}
.social-icons-colored .social-icon.social-pinterest {
  border-color: #f96a02;
  background-color: #f96a02;
}
.social-icons-colored .social-icon.social-google {
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.social-icons-colored .social-icon.social-linkedin {
  border-color: #0073b2;
  background-color: #0073b2;
}
.social-icons-colored .social-icon.social-email {
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.social-icons-colored .social-icon.social-whatsapp {
  border-color: #3c8a38;
  background-color: #3c8a38;
}

.social-no-color .social-icon {
  border-color: #cdcdcd;
  color: #666;
}
.social-no-color .social-icon:hover,
.social-no-color .social-icon:focus {
  color: #fff;
}
.social-no-color .social-icon:hover.social-facebook,
.social-no-color .social-icon:focus.social-facebook {
  background-color: #1b4f9b;
  border-color: #1b4f9b;
}
.social-no-color .social-icon:hover.social-twitter,
.social-no-color .social-icon:focus.social-twitter {
  background-color: #00adef;
  border-color: #00adef;
}
.social-no-color .social-icon:hover.social-instagram,
.social-no-color .social-icon:focus.social-instagram {
  background-color: #cc0001;
  border-color: #cc0001;
}
.social-no-color .social-icon:hover.social-youtube,
.social-no-color .social-icon:focus.social-youtube {
  background-color: #2c567e;
  border-color: #2c567e;
}
.social-no-color .social-icon:hover.social-pinterest,
.social-no-color .social-icon:focus.social-pinterest {
  background-color: #f96a02;
  border-color: #f96a02;
}
.social-no-color .social-icon:hover.social-google,
.social-no-color .social-icon:focus.social-google {
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.social-no-color .social-icon:hover.social-linkedin,
.social-no-color .social-icon:focus.social-linkedin {
  border-color: #0073b2;
  background-color: #0073b2;
}
.social-no-color .social-icon:hover.social-email,
.social-no-color .social-icon:focus.social-email {
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.social-no-color .social-icon:hover.social-whatsapp,
.social-no-color .social-icon:focus.social-whatsapp {
  border-color: #3c8a38;
  background-color: #3c8a38;
}

.social-icon-border-color .social-icon {
  background-color: #fff;
  border-width: 1px;
}
.social-icon-border-color .social-icon.social-facebook {
  color: #1b4f9b;
  border-color: #1b4f9b;
}
.social-icon-border-color .social-icon.social-twitter {
  color: #00adef;
  border-color: #00adef;
}
.social-icon-border-color .social-icon.social-instagram {
  color: #cc0001;
  border-color: #cc0001;
}
.social-icon-border-color .social-icon.social-youtube {
  color: #2c567e;
  border-color: #2c567e;
}
.social-icon-border-color .social-icon.social-pinterest {
  color: #f96a02;
  border-color: #f96a02;
}
.social-icon-border-color .social-icon.social-google {
  border-color: #dd4b39;
  color: #dd4b39;
}
.social-icon-border-color .social-icon.social-linkedin {
  border-color: #0073b2;
  color: #0073b2;
}
.social-icon-border-color .social-icon.social-email {
  border-color: #dd4b39;
  color: #dd4b39;
}
.social-icon-border-color .social-icon.social-whatsapp {
  border-color: #3c8a38;
  color: #3c8a38;
}
.social-icon-border-color .social-icon:hover {
  color: #fff;
}
.social-icon-border-color .social-icon:hover.social-facebook {
  background-color: #1b4f9b;
}
.social-icon-border-color .social-icon:hover.social-twitter {
  background-color: #00adef;
}
.social-icon-border-color .social-icon:hover.social-instagram {
  background-color: #cc0001;
}
.social-icon-border-color .social-icon:hover.social-youtube {
  background-color: #2c567e;
}
.social-icon-border-color .social-icon:hover.social-pinterest {
  background-color: #f96a02;
}
.social-icon-border-color .social-icon:hover.social-google {
  background-color: #dd4b39;
}
.social-icon-border-color .social-icon:hover.social-linkedin {
  background-color: #0073b2;
}
.social-icon-border-color .social-icon:hover.social-email {
  background-color: #dd4b39;
}
.social-icon-border-color .social-icon:hover.social-whatsapp {
  background-color: #3c8a38;
}

/* -------------------------------------------
    Tab
---------------------------------------------- */
.tab-content {
  background-color: #fff;
}

.nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /*border-bottom: 1px solid #eee;*/
  color: #333;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item:hover .nav-link {
  color: #336699;
}

.nav-link {
  display: block;
  position: relative;
  font-size: 0.8rem;
  font-weight: 600;
  color: inherit;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
  border: 0;
  padding: 1.5rem 2.85rem;
  -webkit-transition: border 0.3s, color 0.3s, background-color 0.3s;
  transition: border 0.3s, color 0.3s, background-color 0.3s;
}

.nav-item:not(:last-child) {
  margin-right: 3rem;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tab-content {
  position: relative;
}
.tab-content > .tab-pane {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
  -webkit-transition: visibility 0.3s, opacity 0.3s;
  transition: visibility 0.3s, opacity 0.3s;
  overflow: hidden;
  visibility: hidden;
}
.tab-content > .active {
  visibility: visible;
  opacity: 1;
  height: auto;
  position: relative;
  z-index: auto;
}

.tab-pane {
  font-size: 1.3rem;
  line-height: 1.7;
  padding: 1.3rem 0;
  color: #666;
}
.tab-pane p {
  margin-bottom: 0;
  font-size: inherit;
  letter-spacing: 0;
  line-height: 2;
}

.tab-nav-boxed .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}

.tab-nav-solid .nav-tabs {
  border-bottom: 0;
}
.tab-nav-solid .nav-item.show .nav-link,
.tab-nav-solid .nav-item .nav-link.active,
.tab-nav-solid .nav-item:hover .nav-link {
  color: red;
  background-color: #336699;
}
.tab-nav-solid .nav-link {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  margin-bottom: 0.5rem;
}
.tab-nav-solid .tab-pane {
  padding: 0.8rem 0 1.4rem;
}

.tab-nav-solid2 .nav-item.show .nav-link,
.tab-nav-solid2 .nav-item .nav-link.active,
.tab-nav-solid2 .nav-item:hover .nav-link {
  color: #336699;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.tab-nav-solid2 .nav-item:not(:last-child) {
  margin-right: 2rem;
}
.tab-nav-solid2 .nav-link {
  color: #333;
}
.tab-nav-solid2 .tab-content {
  border: 1px solid #eee;
}

.tab-boxed .nav-tabs {
  border-bottom: 0;
}
.tab-boxed .nav-link {
  padding: 1.45rem 2.95rem 1.55rem;
  margin-bottom: -0.1rem;
  z-index: 1;
}
.tab-boxed .tab-pane {
  padding: 1.3rem 2rem 0;
}
.tab-boxed .tab-pane.active p {
  margin-bottom: 1.3rem;
}

.tab-nav-outline .nav-tabs {
  border-bottom: 0;
}
.tab-nav-outline .nav-item.show .nav-link,
.tab-nav-outline .nav-item .nav-link.active,
.tab-nav-outline .nav-item:hover .nav-link {
  border: 2px solid #336699;
  border-radius: 0.3rem;
}
.tab-nav-outline .nav-link {
  color: #333;
  border: 2px solid transparent;
  padding: 1.3rem 2.85rem;
}

.tab-nav-outline2 .nav {
  position: relative;
  z-index: 1;
}
.tab-nav-outline2 .nav-item.show .nav-link,
.tab-nav-outline2 .nav-item .nav-link.active,
.tab-nav-outline2 .nav-item:hover .nav-link {
  border: 1px solid #eee;
  border-bottom-color: #fff;
}
.tab-nav-outline2 .tab-content {
  border: 1px solid #eee;
}
.tab-nav-outline2 .nav-item:not(:last-child) {
  margin-right: 0.7rem;
}
.tab-nav-outline2 .nav-link {
  border: 1px solid transparent;
  padding: 1.4rem 2.85rem;
  margin-bottom: -1px;
}

.tab-nav-outline3 .nav-tabs {
  border-bottom: 0;
}
.tab-nav-outline3 .nav-item .nav-link::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 3px;
  height: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  background-color: transparent;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 1;
}
.tab-nav-outline3 .nav-item.show .nav-link::before,
.tab-nav-outline3 .nav-item .nav-link.active::before,
.tab-nav-outline3 .nav-item:hover .nav-link::before {
  background-color: #336699;
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}
.tab-nav-outline3 .nav-link {
  color: #333;
  border: 1px solid #eee;
  padding: 1.4rem 0 1.4rem 2rem;
}

.tab-nav-underline .nav-item:not(:last-child) {
  margin-right: 3rem;
}
.tab-nav-underline .nav-link {
  padding: 1.4rem 0;
}
.tab-nav-underline .nav-link::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  border-top: 3px solid transparent;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: border-color 0.3s, -webkit-transform 0.3s;
  transition: border-color 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, border-color 0.3s;
  transition: transform 0.3s, border-color 0.3s, -webkit-transform 0.3s;
}
.tab-nav-underline .nav-item.show .nav-link::after,
.tab-nav-underline .nav-item .nav-link.active::after,
.tab-nav-underline .nav-item:hover .nav-link::after {
  border-color: #336699;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.tab-nav-underline.type2 .nav-item {
  margin-right: 0;
}
.tab-nav-underline.type2 .nav-link {
  padding: 1.4rem 1.5rem;
}

.tab-nav-center .nav {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.tab-nav-center .nav .nav-item {
  /*margin: 0 1.5rem;*/
}
.tab-nav-center.tab-nav-outline2 .nav-item {
  margin: 0 0.5rem;
}

.tab-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tab-vertical .nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 26.3%;
}
.tab-vertical .nav-item {
  margin-right: 0;
}
.tab-vertical .nav-link {
  position: relative;
  padding-left: 2.2rem;
  padding-right: 2rem;
  text-align: left;
  width: 100%;
  margin-bottom: 0.5rem;
}
.tab-vertical .tab-content {
  width: 73.7%;
  border-top: 1px solid #eee;
  padding-left: 2rem;
}
.tab-vertical .tab-pane {
  padding-top: 1.3rem;
}
.tab-vertical.tab-nav-outline3 .nav {
  width: 27%;
}
.tab-vertical.tab-nav-outline3 .nav-link {
  padding-left: 2.1rem;
}
.tab-vertical.tab-nav-outline3 .tab-content {
  padding-left: 1.7rem;
}

.tab-line-grow .tab-pane {
  padding: 1.7rem 0;
}

.nav-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  color: #666;
  line-height: 1;
}
.nav-filters li:not(:last-child) {
  margin-right: 1rem;
}

.nav-filter {
  display: inline-block;
  position: relative;
  padding: 0.6rem 0 0.7rem;
  color: inherit;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}
.nav-filter.active,
.nav-filter:hover {
  color: #336699;
}
.nav-filter span {
  color: #aaa;
  margin-left: 0.6rem;
}

.filter-underline .nav-filter::after {
  content: "";
  display: block;
  height: 2px;
  border: 1px solid;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.filter-underline .nav-filter.active::after,
.filter-underline .nav-filter:hover::after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.filter-boxed li {
  margin-bottom: 0.5rem;
}
.filter-boxed .nav-filter {
  padding: 1.1rem 1.9rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  -webkit-transition: color 0.3s, border-color 0.3s;
  transition: color 0.3s, border-color 0.3s;
}
.filter-boxed .nav-filter.active,
.filter-boxed .nav-filter:hover {
  color: #336699;
  border-color: #336699;
}

@media (max-width: 991px) {
  .filter-boxed {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .filter-boxed li {
    margin: 0 0.5rem 0.5rem !important;
  }
}
@media (max-width: 767px) {
  .tab-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  .tab-vertical .tab-content,
  .tab-vertical .nav-tabs,
  .tab-vertical.tab-nav-outline3 .nav {
    width: 100%;
  }

  .tab-line-grow .tab-content {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (max-width: 575px) {
  .tab-nav-outline2 .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.link-section {
  margin-bottom: 2.8rem;
}
.link-section .title {
  margin-bottom: 0.9rem;
}

.side-tab-section {
  margin-bottom: 1.1rem;
}

/*-----------------------------------
    Testimonial
------------------------------------*/
.testimonial {
  font-family: Poppins, sans-serif;
  padding: 0 3rem 1rem;
}
.testimonial cite {
  color: #333;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
}
.testimonial cite span {
  display: block;
  text-align: center;
  margin-top: 0.6rem;
  font-size: 1.3rem;
  color: #999;
  line-height: 1.3;
  letter-spacing: 0.003em;
  font-weight: 400;
}
.testimonial blockquote {
  position: relative;
  line-height: 1.86;
  text-align: center;
}

.testimonial.swiper-slide {
  margin: 0;
}

.testimonial-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0.9rem;
}
.testimonial-info .testimonial-author-thumbnail {
  margin-bottom: 2.5rem;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
}
.testimonial-info img {
  width: 100%;
  height: auto;
}
.testimonial-info .ratings-container {
  margin-bottom: 0.9rem;
  font-size: 1.1rem;
}
.testimonial-info .ratings-container .ratings-full {
  margin-right: 0;
}
.testimonial-info .ratings-full::before,
.testimonial-info .ratings::before {
  letter-spacing: 0.28em;
}

.testimonial-title {
  font-size: 1.4rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.testimonial-shadow {
  background-color: #f8f8f8;
  padding: 3rem 3rem 2.3rem;
  -webkit-transition: background-color 0.4s, -webkit-box-shadow 0.4s;
  transition: background-color 0.4s, -webkit-box-shadow 0.4s;
  transition: background-color 0.4s, box-shadow 0.4s;
  transition: background-color 0.4s, box-shadow 0.4s, -webkit-box-shadow 0.4s;
}
.testimonial-shadow .testimonial-info {
  margin-bottom: 0;
}
.testimonial-shadow blockquote {
  margin-top: -0.1rem;
  margin-bottom: 0.6rem;
}
.testimonial-shadow:hover {
  -webkit-box-shadow: 1px 8px 9px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 8px 9px rgba(0, 0, 0, 0.1);
}

.testimonial-blockquote {
  padding-left: 0;
  padding-right: 0;
}
.testimonial-blockquote blockquote {
  overflow: visible;
  padding: 2.1rem 3rem;
  margin-bottom: 2rem;
  text-align: start;
  border: 1px solid #eee;
}
.testimonial-blockquote blockquote::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 5.3rem;
  display: block;
  width: 13px;
  height: 13px;
  background-color: #fff;
  border: 1px solid #eee;
  border-left: 0;
  border-top: 0;
  -webkit-transform: rotate(45deg) translateY(70%);
  transform: rotate(45deg) translateY(70%);
}
.testimonial-blockquote .testimonial-info {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0 2rem;
}
.testimonial-blockquote .testimonial-author-thumbnail {
  margin-bottom: 0;
  margin-right: 1.5rem;
  width: 7rem;
  height: 7rem;
}
.testimonial-blockquote cite {
  margin-bottom: 0.2rem;
}
.testimonial-blockquote cite span {
  margin-top: 0.2rem;
  text-align: start;
}
.testimonial-blockquote .testimonial-action {
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0;
  color: #999;
  margin-left: auto;
}
.testimonial-blockquote .testimonial-action a {
  color: inherit;
}
.testimonial-blockquote .testimonial-action a:hover {
  color: #336699;
}
.testimonial-blockquote .testimonial-action i {
  margin-left: 0.5rem;
}

.testimonial-inversed .testimonial-info {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.testimonial-inversed .testimonial-author-thumbnail {
  margin: 0 0 0 1.5rem;
}
.testimonial-inversed cite span {
  text-align: end;
}
.testimonial-inversed blockquote::after {
  left: auto;
  right: 4.1rem;
}

.testimonial-centered {
  text-align: center;
}
.testimonial-centered blockquote {
  margin-left: auto;
  margin-right: auto;
}

.testimonial-bg {
  padding: 2rem;
}
.testimonial-bg .testimonial-info {
  margin-bottom: 2.6rem;
}
.testimonial-bg blockquote {
  max-width: 87.5rem;
  color: #cecece;
  margin-bottom: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.testimonial-bg .testimonial-author-thumbnail {
  margin-bottom: 0;
  width: 10rem;
  height: 10rem;
}
.testimonial-bg cite {
  color: #fff;
}
.testimonial-bg cite span {
  margin: 0.3rem 0 1.6rem;
  color: #cecece;
}

.slider-section {
  margin-bottom: 3.6rem;
}

.parallax-testimonial-section {
  margin-bottom: 4.7rem;
}

/*-------------------------------------
    Timeline
        - Timeline Body
        - Timeline Box
        - Timeline Content
        - Timeline Node
        - Timeline Date
        - Timeline Box Arrow
--------------------------------------*/
.timeline {
  width: 100%;
  position: relative;
}
.timeline::before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  width: 3px;
  height: 100%;
  background-color: #999;
}

.timeline-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
}
.timeline-box.left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.timeline-box.left .timeline-content {
  text-align: end;
  margin-right: 2rem;
}
.timeline-box.right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.timeline-box.right .timeline-content {
  text-align: start;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  margin-left: 2rem;
}

.timeline-content {
  padding: 1rem;
  width: calc(50% - 3.5rem);
}

.timeline-node {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 3px solid #999;
  background-color: #fff;
}

@media (max-width: 767px) {
  .timeline::before {
    content: none;
  }
  .timeline .timeline-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .timeline .timeline-box .timeline-content {
    text-align: center;
    width: 100%;
  }
  .timeline .timeline-box p,
  .timeline .timeline-box .timeline-node {
    max-width: none;
    margin: auto;
  }
  .timeline .timeline-box.left .timeline-node {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}
/*----------------------------
    Titles
        - Default
        - Simple
            - Link icon
            - Tab boxed
            - Tab link
        - Center
-----------------------------*/
/*  Default */
.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1.7rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}
.title.title-link::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  left: 0;
  bottom: -0.8rem;
  background-color: #336699;
  z-index: 1;
}
.title.title-sm {
  font-size: 1.6rem;
}
.title.title-md {
  font-size: 2rem;
}
.title.title-lg {
  font-size: 3rem;
}

/* Simple */
.title-underline {
  position: relative;
  padding-bottom: 1.6rem;
}
.title-underline::before {
  content: none;
}
.title-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: 0;
  width: 100%;
  height: 1px;
  background-color: #eee;
}

.title-cross::before,
.title-cross::after {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  content: "";
  height: 2px;
  background-color: #eeeeee;
}
.title-cross::before {
  margin-right: 3rem;
}
.title-cross::after {
  margin-left: 3rem;
}

@media (max-width: 479px) {
  .title-cross::before {
    margin-right: 1.5rem;
  }
  .title-cross::after {
    margin-left: 1.5rem;
  }
}
.title-link-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1.3rem;
}
.title-link-wrapper .title {
  position: relative;
  margin-bottom: 0.8rem;
  padding: 0.8rem 0;
}
.title-link-wrapper a {
  margin-bottom: 0.7rem;
  margin-left: auto;
  text-transform: capitalize;
}
.title-link-wrapper a:hover {
  color: #336699;
}
.title-link-wrapper a i {
  vertical-align: middle;
  margin-left: 0.5rem;
  font-size: 1.35rem;
}
.title-link-wrapper::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.title-link-wrapper.no-link .title-link {
  margin-bottom: 0.8rem;
}

.tab-with-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.tab-with-title .nav-tabs {
  border-bottom: 0;
}
.tab-with-title .nav-item:not(:last-child) {
  margin-right: 2rem;
}
.tab-with-title .nav-link {
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0;
  color: #333;
  text-transform: capitalize;
  padding: 0;
}
.tab-with-title.tab-nav-boxed {
  padding: 0.3rem 0;
}
.tab-with-title.tab-nav-boxed .title {
  margin-bottom: 0;
  margin-right: 2rem;
}
.tab-with-title.tab-nav-boxed .nav-item:not(:last-child) {
  margin-right: 1rem;
}
.tab-with-title.tab-nav-boxed .nav-item:last-child {
  margin: 0;
}
.tab-with-title.tab-nav-boxed .nav-link {
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  padding: 0.85em 1.42em;
  font-weight: 600;
  text-transform: uppercase;
}
.tab-with-title.tab-nav-boxed .nav-link.active,
.tab-with-title.tab-nav-boxed .nav-link:hover {
  border-color: #336699;
}
.tab-with-title.tab-nav-link {
  padding: 1.6rem 2rem 0.6rem;
  background: #f5f5f5;
}
.tab-with-title.tab-nav-link .title,
.tab-with-title.tab-nav-link .nav-link {
  margin-bottom: 1rem;
}
.tab-with-title.tab-nav-link .title {
  margin-right: 2rem;
}
.tab-with-title.tab-nav-underline .nav-link::after {
  bottom: -1px;
}
.tab-with-title .nav-item.show .nav-link,
.tab-with-title .nav-item .nav-link.active,
.tab-with-title .nav-item:hover .nav-link {
  color: #336699;
}

.title-separator {
  margin: 0 0.5rem;
}

/* Center */
.title-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tab-with-title.tab-nav-center {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 1.2rem;
}
.tab-with-title.tab-nav-center .nav-tabs {
  margin: 0;
}
.tab-with-title.tab-nav-center .nav-item {
  margin: 0 1rem;
}
.tab-with-title.tab-nav-center .nav-link {
  padding: 0.8rem 0;
}
.tab-with-title.tab-nav-center .nav-link::after {
  bottom: 0;
  height: 0.3rem;
  border: none;
  background-color: #336699;
}

.filter-with-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.filter-with-title .title {
  margin-bottom: 0.5rem;
}

.title-section p {
  font-size: 1.3rem;
  color: #666;
  line-height: 2;
  letter-spacing: 0;
}

@media (max-width: 991px) {
  .tab-with-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .tab-with-title .nav-tabs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .tab-with-title.tab-nav-boxed .title {
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .tab-with-title.tab-nav-boxed .nav-item {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .tab-with-title.tab-nav-boxed .nav-link {
    margin-bottom: 0.5rem;
  }
  .tab-with-title.tab-nav-link .nav-item {
    margin: 0 1rem !important;
  }
  .tab-with-title.tab-nav-link .title {
    margin-right: 0;
  }

  .filter-with-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .filter-with-title .title {
    margin-bottom: 1rem;
  }
  .filter-with-title .nav-filters {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .filter-with-title .nav-filters li {
    margin: 0 1.5rem;
  }
}
@media (max-width: 575px) {
  .title-link-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .title-link-wrapper .title-link {
    margin-bottom: 2rem;
  }
  .title-link-wrapper a {
    margin-left: 0;
  }
}
/*-----------------------------
    Tooltip
-----------------------------*/
.tooltiptext {
  position: absolute;
  padding: 1rem 0.7rem;
  bottom: 125%;
  left: 50%;
  background-color: #333;
  color: #fff;
  font: 400 1.3rem Poppins, sans-serif;
  letter-spacing: 0.01em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 96%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

/*-------------------------------------
    Widget
-------------------------------------*/
.widget-title {
  margin-bottom: 2rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: -0.025em;
}

.widget-products .widget-title {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  padding-bottom: 0.8rem;
}
.widget-products .widget-body {
  padding: 0 2rem;
}
.widget-products.widget-products-bordered .widget-body {
  border: 1px solid #eee;
}
.widget-products.widget-products-bordered .product-widget:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.widget-products .swiper-button-prev,
.widget-products .swiper-button-next {
  top: -1.7rem;
  right: -1rem;
  border: 0;
  color: #747474;
  font-weight: normal;
  width: 15px;
  height: 15px;
}
.widget-products .swiper-button-prev:not(.swiper-button-disabled):hover,
.widget-products .swiper-button-prev:not(.swiper-button-disabled):focus,
.widget-products .swiper-button-prev:not(.swiper-button-disabled):active,
.widget-products .swiper-button-next:not(.swiper-button-disabled):hover,
.widget-products .swiper-button-next:not(.swiper-button-disabled):focus,
.widget-products .swiper-button-next:not(.swiper-button-disabled):active {
  background: transparent;
  border: 0;
  color: #336699;
}
.widget-products .swiper-button-prev {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.widget-products .swiper-button-next {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.sidebar .widget-products .widget-body {
  border: 0;
  padding: 0;
}

.widget .widget-title {
  position: relative;
  margin: -0.1rem 0;
  padding: 1.4rem 2.5rem 1.4rem 0;
  font-weight: 600;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0;
  border-bottom: 1px solid #eee;
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.widget .widget-body {
  padding: 0.7rem 0 1rem;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  color: #333;
}

.widget-collapsible .widget-title label {
  position: relative;
  padding: 0 0.2rem;
}
.widget-collapsible .widget-title label::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1.2rem;
  width: 100%;
  height: 2px;
  background-color: #666;
}
.widget-collapsible .toggle-btn {
  right: 0.4rem;
  margin-top: -0.1rem;
  width: 10px;
  padding: 0;
  opacity: 1;
}
.widget-collapsible .toggle-btn::before,
.widget-collapsible .toggle-btn::after {
  content: "";
  position: absolute;
  border-top: 2px solid #666;
  width: 12px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.widget-collapsible > .collapsed .toggle-btn::before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.widget-collapsible > .collapsed .toggle-btn::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.widget-collapsible .collapsed + .widget-body {
  display: none;
}
.widget-collapsible .collapsed ~ .widget-body {
  opacity: 0.1;
}

/*----------------------
    Vendor
----------------------*/
.vendor-widget {
  margin-bottom: 2rem;
}
.vendor-widget .vendor-name {
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: capitalize;
  letter-spacing: -0.025em;
  line-height: 1.4;
}
.vendor-widget .vendor-name a {
  color: inherit;
}
.vendor-widget .vendor-name:hover {
  color: #336699;
}

.vendor-products.grid-type {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(100% / 3));
  margin: -2.5px;
  width: calc(100% + 5px);
  grid-auto-rows: 1fr;
}
.vendor-products.grid-type > * {
  padding: 2.5px;
}
.vendor-products .lg-item {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}
.vendor-products .sm-item {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}
.vendor-products .product-media {
  height: 100%;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  overflow: hidden;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.vendor-products .product-media img {
  height: 100%;
}

.vendor-logo {
  max-width: 7rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7rem;
  flex: 0 0 7rem;
  margin-right: 1rem;
}

.vendor-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.8rem 0;
}

.vendor-product-count {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.5rem;
}

.vendor-banner {
  min-height: 18rem;
}
.vendor-banner a,
.vendor-banner img {
  min-height: inherit;
}
.vendor-banner a {
  display: block;
}

.visit-vendor-btn {
  display: block;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #ff9933;
}
.visit-vendor-btn:hover {
  color: #336699;
}

.vendor-widget-1 .product-media:hover {
  border-color: #336699;
}
.vendor-widget-1 .vendor-personal {
  margin-top: 0.2rem;
}
.vendor-widget-1 .ratings-container {
  margin-bottom: 0;
}

.vendor-widget-2 {
  padding: 2rem;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  overflow: hidden;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.vendor-widget-2:hover {
  border-color: #336699;
}
.vendor-widget-2 .vendor-details {
  padding-top: 0;
  padding-bottom: 2rem;
}
.vendor-widget-2 .product-media {
  border: none;
}
.vendor-widget-2 .ratings-container {
  margin-bottom: 0;
}

.vendor-widget-3 .vendor-logo {
  border-radius: 0.5rem;
  overflow: hidden;
}
.vendor-widget-3 .vendor-details {
  padding: 2rem 0;
}
.vendor-widget-3 .vendor-personal {
  padding-top: 0.3rem;
}
.vendor-widget-3 .ratings-container {
  margin-bottom: 0;
}
.vendor-widget-3 .ratings-container:not(:last-child) {
  margin-bottom: 0.5rem;
}

.vendor-widget-banner {
  border: 1px solid #eee;
  border-radius: 0.5rem;
  overflow: hidden;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.vendor-widget-banner .product-media {
  border: none;
}
.vendor-widget-banner .vendor-details {
  display: block;
  text-align: center;
  padding-top: 0;
  margin-top: -4.5rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
}
.vendor-widget-banner .vendor-logo {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  max-width: 9rem;
  margin: 0 auto 1.3rem auto;
  border-radius: 50%;
  background-color: #336699;
}
.vendor-widget-banner .vendor-logo img {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  border-radius: 50%;
}
.vendor-widget-banner .vendor-name {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 0;
}
.vendor-widget-banner .ratings-container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.vendor-widget-banner .ratings-container .ratings-full {
  margin-right: 0;
}
.vendor-widget-banner .vendor-product-count {
  display: block;
  margin-bottom: 0.6rem;
  font-size: 1.4rem;
}
.vendor-widget-banner:hover {
  -webkit-box-shadow: 5px 0 20px rgba(0, 0, 0, 0.08);
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.08);
  border-color: transparent;
}
.vendor-widget-banner:hover .vendor-logo img {
  -webkit-transform: translateY(-1rem);
  transform: translateY(-1rem);
}

.store {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
}
.store .ratings-container {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.store .store-banner img {
  height: 100%;
  width: 100%;
}

.store-content {
  position: absolute;
}

.store-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1.9rem;
  color: #fff;
}
.store-title a {
  display: inline-block;
  text-transform: capitalize;
  font-size: 2rem;
  line-height: 1;
  color: inherit;
  text-shadow: rgba(0, 0, 0, 0.8) 0 1px 0;
  font-weight: 600;
  letter-spacing: -0.025em;
}

.featured-label {
  background: #2d54a3;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.2rem 1rem;
  -webkit-box-shadow: 0px 0px 25px -5px #afafaf;
  box-shadow: 0px 0px 25px -5px #afafaf;
  border-radius: 0.3rem;
  margin-left: 1.4rem;
  display: inline-block;
}

.seller-info-list {
  margin: 0;
  padding-left: 1.6em;
}
.seller-info-list .store-address,
.seller-info-list .store-phone {
  font-size: 1.4rem;
}
.seller-info-list > * {
  line-height: 1.6;
  letter-spacing: -0.025em;
  margin-bottom: 0.8rem;
  color: #fff;
  opacity: 0.8;
}
.seller-info-list > * a {
  color: inherit;
}
.seller-info-list > * i {
  margin-left: -2.2rem;
  margin-right: 0.3rem;
  vertical-align: middle;
  font-size: 1.5rem;
  line-height: 0;
}
.seller-info-list .store-phone:hover,
.seller-info-list .store-email:hover {
  color: #f93;
}

.store-address {
  font-size: 1.3rem;
  color: #fff;
  opacity: 0.8;
  line-height: 1.8;
}

.store-rating,
.store-open {
  color: #fff;
  opacity: 0.8;
}

.store-footer {
  position: relative;
  padding: 2.3rem 2rem;
}

.btn.btn-icon-right.btn-visit i {
  font-size: 2rem;
  margin-left: 0.4rem;
  margin-bottom: 0.4rem;
}

.seller-brand {
  border-radius: 50%;
  background-color: #ff9742;
  width: 8rem;
  height: 8rem;
}
.seller-brand img {
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.vendor-brand {
  max-width: 15rem;
  margin: 0 auto;
  text-align: center;
}
.vendor-brand .brand {
  border: 1px solid #eee;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 2.2rem;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.vendor-brand .vendor-name {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.vendor-brand .vendor-name:hover {
  color: #336699;
}
.vendor-brand:hover .brand {
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
}

.store-grid {
  -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
}
.store-grid .store-banner img {
  min-height: 19.4rem;
}
.store-grid .store-content {
  top: 2.5rem;
  left: 3rem;
  max-width: 19.2rem;
}
.store-grid .seller-brand {
  right: 1.9rem;
  top: -58%;
}
.store-grid:hover .seller-brand img {
  -webkit-transform: translateY(-1rem);
  transform: translateY(-1rem);
}
.store-grid .store-title:hover {
  color: #336699;
}

.store-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #eee;
}
.store-list .store-header {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40rem;
  flex: 0 0 40rem;
  max-width: 40rem;
}
.store-list .store-header a {
  display: block;
  height: 100%;
}
.store-list .store-banner {
  height: 100%;
}
.store-list .store-content {
  position: relative;
}
.store-list .featured-label {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  margin-left: 0;
}
.store-list .store-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2rem 2rem 2rem 6rem;
}
.store-list .store-title {
  color: #333;
}
.store-list .store-title a {
  text-shadow: none;
}
.store-list .ratings-container {
  margin-bottom: 0.3rem;
}
.store-list .store-address {
  color: #666;
  opacity: 1;
  margin-bottom: 0.9rem;
}
.store-list .seller-brand {
  top: 50%;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.store-list:hover .seller-brand img {
  -webkit-transform: translateX(-1rem);
  transform: translateX(-1rem);
}

.store-grid .seller-brand,
.store-list .seller-brand {
  position: absolute;
}
.store-grid .seller-brand img,
.store-list .seller-brand img {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  max-width: calc(100% + 2px);
  margin: -1px;
  -webkit-box-shadow: 0px 0px 0px 1px #eee;
  box-shadow: 0px 0px 0px 1px #eee;
}

.store.store-banner .store-media img {
  min-height: 44rem;
  width: 100%;
}
.store.store-banner .store-content {
  top: 0;
  left: 0;
  max-width: 34.5rem;
  padding: 5rem 4rem 5rem 5rem;
  background-color: rgba(37, 38, 42, 0.9);
  height: 100%;
}
.store.store-banner .seller-brand {
  margin-bottom: 2.3rem;
}
.store.store-banner .store-title {
  margin-bottom: 1.6rem;
  font-size: 2rem;
  letter-spacing: -0.025em;
}
.store.store-banner .social-icon {
  margin-right: 0.5rem;
  color: #fff;
  border-color: #ccc;
  opacity: 0.8;
}

.store-wcmp .store-content {
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-color: rgba(34, 34, 40, 0.85);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2rem;
}
.store-wcmp .seller-brand {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8rem;
  flex: 0 0 8rem;
  max-width: 8rem;
  margin-right: 2.2rem;
}
.store-wcmp .seller-date {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 10rem);
  flex: 0 0 calc(100% - 10rem);
  max-width: calc(100% - 10rem);
}
.store-wcmp .store-title {
  margin: 0.4rem 0 1.1rem;
}
.store-wcmp .store-title a {
  font-size: 1.8rem;
}
.store-wcmp .store-title a:hover {
  text-decoration: underline;
}
.store-wcmp .seller-info-list {
  margin: 0;
}
.store-wcmp .seller-info-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  letter-spacing: 0;
  opacity: 0.7;
}
.store-wcmp .seller-info-list li p {
  margin-bottom: 0;
  line-height: 2;
}
.store-wcmp .seller-info-list .store-address p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.store-wcmp .seller-info-list .store-rating p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.store-wcmp .seller-info-list i {
  margin-top: 1.3rem;
  margin-right: 0.8rem;
  font-size: 1.4rem;
}
.store-wcmp .ratings-container {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.store-wcmp-banner .store-media img {
  width: 100%;
}
.store-wcmp-banner .store-content {
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 40%;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.6);
}
.store-wcmp-banner .seller-brand {
  margin: -7rem auto 2rem auto;
}
.store-wcmp-banner .social-icons {
  position: absolute;
  right: 2rem;
  top: -5rem;
}
.store-wcmp-banner .store-title {
  margin-bottom: 1rem;
  font-size: 2rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.store-wcmp-banner .seller-info-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
}
.store-wcmp-banner .seller-info-list > * {
  margin: 0 0.6rem;
  opacity: 0.6;
  text-align: center;
}
.store-wcmp-banner .seller-info-list > * i {
  margin-right: 0.4rem;
  margin-left: 0;
}

.store-wcmp-banner .seller-brand,
.store-wcfm-banner .seller-brand {
  width: 10rem;
  height: 10rem;
}
.store-wcmp-banner .seller-brand img,
.store-wcfm-banner .seller-brand img {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  max-width: calc(100% + 2px);
  margin: -1px;
}
.store-wcmp-banner .social-icon:not(:last-child),
.store-wcfm-banner .social-icon:not(:last-child) {
  margin-right: 0.5rem;
}
.store-wcmp-banner .social-icon,
.store-wcfm-banner .social-icon {
  border-radius: 0.3rem;
}

.store-wcfm-banner .store-content {
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(34, 34, 34, 0.8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 16rem;
  padding: 2.3rem 3rem 2.6rem;
}
.store-wcfm-banner .store-content-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.store-wcfm-banner .personal-info {
  margin-right: 4rem;
}
.store-wcfm-banner .seller-brand {
  margin: -7.2rem 0 2rem;
}
.store-wcfm-banner .ratings-container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.2rem;
}
.store-wcfm-banner .ratings-full {
  margin: 0;
}
.store-wcfm-banner .store-title {
  margin-bottom: 0.9rem;
  font-size: 2rem;
  font-weight: 600;
}
.store-wcfm-banner .seller-info-list {
  padding-left: 2.4rem;
}
.store-wcfm-banner .seller-info-list li {
  margin-bottom: 0.4rem;
  letter-spacing: 0;
}
.store-wcfm-banner .seller-info-list li i {
  margin: 0 0.4rem 0 -2.4rem;
  font-size: 1.4rem;
}
.store-wcfm-banner .store-content-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.store-wcfm-banner .btn-inquiry {
  margin-bottom: 3rem;
}

.btn-inquiry,
.btn-visit:not(.btn-link) {
  padding: 0.8em 1.6em;
  font-size: 1.3rem;
  background-color: #eee;
  border-color: #eee;
  color: #333;
  margin-right: 0.6rem;
}
.btn-inquiry:hover,
.btn-inquiry:active,
.btn-inquiry:focus,
.btn-visit:not(.btn-link):hover,
.btn-visit:not(.btn-link):active,
.btn-visit:not(.btn-link):focus {
  background-color: #333;
  border-color: #333;
  color: #fff;
}

.vendor-toolbox .vendor-search-toggle {
  margin-right: 1.5rem;
  padding: 0.73em 0.9em 0.86em;
  font-weight: 700;
}
.vendor-toolbox .vendor-search-toggle i {
  margin: 0 0.8rem 0 0;
  font-size: 1.5rem;
  font-weight: 600;
}
.vendor-toolbox .toolbox-left label {
  font-weight: 400;
  color: #333;
}
.vendor-toolbox .toolbox-layout {
  margin-left: 1.5rem;
}
.vendor-toolbox .btn-layout {
  color: #ccc;
}
.vendor-toolbox .btn-layout.icon-mode-list i {
  font-size: 2rem;
}

.vendor-search-wrapper {
  display: none;
  padding: 2rem;
  border-radius: 5px;
  -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  margin-top: 3.2rem;
}
.vendor-search-wrapper .vendor-search-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.vendor-search-wrapper input {
  min-height: 3.8rem;
  height: 3.8rem;
  color: #777;
  border-radius: 0.3rem;
}
.vendor-search-wrapper input:focus {
  border-color: #8080809e;
}
.vendor-search-wrapper .btn {
  padding: 0.7em 1.65em;
}

.vendor-product-title {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.9rem 1.5rem;
  border: 1px solid #eee;
  border-radius: 0.3rem;
}
.vendor-product-title a {
  color: #333;
}
.vendor-product-title a:hover {
  color: #336699;
}

.vendor-map {
  border: 1px solid #eee;
}

.google-map {
  height: 35rem;
  background-color: #98c3e5;
}

@media (max-width: 991px) {
  .store-wcmp {
    height: 100%;
    -webkit-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  }
  .store-wcmp .store-content {
    display: block;
    position: relative;
    background: #fff;
  }
  .store-wcmp .seller-brand {
    margin-bottom: 2rem;
    -webkit-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  }
  .store-wcmp .seller-brand img {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    max-width: calc(100% + 2px);
    margin: -1px;
    border: 1px solid #eee;
  }
  .store-wcmp .seller-date {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    max-width: 100%;
  }
  .store-wcmp .store-title a {
    color: #336699;
    text-shadow: none;
  }
  .store-wcmp .store-address,
  .store-wcmp .store-rating {
    color: #333;
  }

  .store-wcfm-banner .store-content {
    display: block;
  }
  .store-wcfm-banner .store-content-right {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 1rem 0 0 14rem;
  }
  .store-wcfm-banner .store-content-right .btn {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 767px) {
  .toolbox.vendor-toolbox {
    display: block;
  }

  .store-list .store-header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30rem;
    flex: 0 0 30rem;
    max-width: 30rem;
  }

  .store.store-banner .store-content {
    background: none;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 27rem;
  }

  .store-wcmp-banner .social-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    top: auto;
    right: auto;
    margin-top: 1rem;
  }
}
@media (max-width: 575px) {
  .store-list {
    display: block;
  }
  .store-list .store-header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .store-list .store-content {
    padding-left: 2rem;
  }
  .store-list .seller-brand {
    left: auto;
    -webkit-transform: none;
    transform: none;
    right: 2rem;
    top: -27%;
  }

  .store-wcfm-banner .store-content-left {
    display: block;
    text-align: center;
  }
  .store-wcfm-banner .personal-info {
    margin-right: 0;
  }
  .store-wcfm-banner .seller-brand {
    margin-right: auto;
    margin-left: auto;
  }
  .store-wcfm-banner .store-title {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .store-wcfm-banner .store-content-right {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0;
  }
}
@media (max-width: 479px) {
  .store-list .seller-brand {
    top: -22%;
    width: 7rem;
    height: 7rem;
  }
}
/*------------------------------------
    Code Popup
        - show code wrapper
            - show code button
        - boxed button
            - box-btn
        - code popup
            - code-copy
            - code-content
-------------------------------------*/
.show-code {
  position: absolute !important;
  padding: 3px 1rem;
  width: auto !important;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #26c;
  color: #fff !important;
  border-radius: 3px;
  font-size: 1.4rem;
  font-family: monospace;
  font-weight: 600;
  line-height: 1.6;
  opacity: 0;
  z-index: 1000;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  white-space: nowrap;
  cursor: pointer;
}
.show-code:hover {
  background: #37d;
  opacity: 1;
}

.show-code-action {
  position: relative;
}
.show-code-action:hover > .show-code {
  opacity: 1;
}
.show-code-action > .btn-copy:hover {
  background: #37d;
}

.show-code-added > .show-code {
  opacity: 0.35;
}

.boxed-button {
  position: fixed;
  top: 26.8%;
  right: 5px;
  z-index: 1;
}
.boxed-button .box-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #33363b;
  border-radius: 3px;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.boxed-button .box-btn.enabled {
  background: #26c;
}
.boxed-button .box-btn span {
  font-family: monospace;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 38px;
  height: 32px;
  font-size: 16px;
  line-height: 0;
}
.boxed-button .box-btn p {
  display: inline-block;
  margin-bottom: 0;
  width: 0;
  font-size: 1.3rem;
  line-height: 1.4;
  -webkit-transition: width 0.3s, padding 0.3s;
  transition: width 0.3s, padding 0.3s;
  overflow: hidden;
  white-space: nowrap;
}
.boxed-button .box-btn:hover p {
  width: 15rem;
}

.code-popup {
  border-radius: 5px;
  width: 1000px;
  max-width: 100%;
  z-index: 4000;
}
.code-popup .mfp-close {
  top: 2rem;
  right: 2rem;
}
.code-popup .mfp-close:active {
  top: 2rem;
}

.code-content {
  border-radius: 5px;
  background: #1e1e1e;
}
.code-content .CodeMirror {
  background-color: transparent;
  margin: 1rem 2rem 2rem;
  height: 58rem;
  color: #d4d4d4;
}
.code-content .code-text {
  display: block !important;
  opacity: 0;
  padding: 0;
  border: none;
  height: 1px;
}
.code-content .code-copy {
  background: #3c3c3c;
  font-weight: 600;
  border-radius: 5px 5px 0 0;
  padding: 2rem;
  -webkit-box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.1);
}
.code-content .code-copy a {
  color: #ccc;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.code-content .code-copy a:hover {
  color: #fff;
}
.code-content .CodeMirror-vscrollbar::-webkit-scrollbar {
  width: 5px;
}
.code-content .CodeMirror-vscrollbar::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.code-content .CodeMirror-hscrollbar::-webkit-scrollbar {
  height: 5px;
}
.code-content .CodeMirror-hscrollbar::-webkit-scrollbar-thumb {
  margin-bottom: 2px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.code-content .CodeMirror-scrollbar-filler {
  left: 0;
  bottom: 0;
  width: 100% !important;
  background: #1e1e1e;
}
.code-content .CodeMirror-hscrollbar {
  bottom: 1.7rem;
}
.code-content .copy-icon {
  font-size: 2rem;
  vertical-align: middle;
  margin-right: 0.6rem;
  line-height: 0;
}

/* 6. Header */
/*--------------------------------
    Header
        -Header
        -Welcom-msg
        -Header Search
        -Main Menu
        -Other
            - Login
            - Wishlist
        -Stick Header
        -Other Options
--------------------------------*/
.header {
  font-family: Poppins, sans-serif;
  background: #fff;
  color: #333;
}
.header .container,
.header .container-fluid,
.header .inner-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header .inner-wrap {
  width: 100%;
}
.header a:not(.btn) {
  color: inherit;
}
.header a:not(.btn):hover {
  color: #336699;
}

.header-top,
.header-middle,
.header-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-left,
.header-center,
.header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-right {
  margin-left: auto;
}

.has-center .header-right {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.has-center .header-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.has-center .header-center {
  margin-left: 2rem;
  margin-right: 2rem;
}

.header-top {
  background: #f5f6f8;
  font-size: 1.1rem;
  letter-spacing: -0.027em;
  text-transform: capitalize;
}
.header-top .header-right {
  padding-right: 0.2rem;
}
.header-top .header-right > .dropdown:first-child {
  margin-left: 0;
}
.header-top .header-right > .dropdown:hover .dropdown-box::before {
  visibility: visible;
  opacity: 1;
  top: auto;
  bottom: 100%;
}
.header-top .header-right .dropdown-box::before {
  content: "";
  position: absolute;
  z-index: 1002;
  left: 50%;
  top: -9999px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 11px solid transparent;
  border-bottom: 11px solid #fff;
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
}
.header-top .header-right a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-top .header-right i {
  margin-right: 0.6rem;
  font-size: 1.5rem;
}
.header-top .header-right > * {
  margin-left: 2rem;
}
.header-top .header-right .delimiter {
  margin: 0 0.3rem;
  color: #aaa;
}
.header-top .header-right .divider {
  width: 1px;
  height: 1.5rem;
  background-color: #ccc;
}

.logo {
  min-width: 14.4rem;
  margin-right: 2rem;
}
.logo img {
  display: block;
}

.header h1 {
  line-height: 0;
  margin-bottom: 0;
}

.header-bottom {
  color: #333;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.025em;
}
.header-bottom .main-nav {
  margin-left: 2.1rem;
}
.header-bottom .header-right {
  padding-right: 0.2rem;
}
.header-bottom .header-right > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.4rem 0 1.3rem;
}
.header-bottom .header-right > a i {
  vertical-align: middle;
}
.header-bottom .header-right > a i.w-icon-map-marker {
  font-size: 2.2rem;
  margin-top: -0.5rem;
}
.header-bottom .header-right > a i.w-icon-sale {
  font-size: 2.4rem;
  margin-right: 0.8rem;
}
.header-bottom .header-right > a:first-child {
  margin-right: 3.2rem;
}
.header-bottom:not(.fixed) .show-dropdown .dropdown-box {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  transform: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  margin-top: 2rem;
}

.welcome-msg {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: -0.022em;
  line-height: 1.5;
  white-space: nowrap;
  padding: 14px 0;
  text-transform: uppercase;
}

.header-middle {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  color: #333;
  background: #fff;
  font-size: 1.2rem;
  border-bottom: 1px solid #eee;
}
.header-middle .header-left {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.account {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 2rem;
}
.account .login {
  font-size: 1.3rem;
  line-height: 1.5em;
  color: #999;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.account .w-icon-account {
  color: white;
  width: 5.3rem;
  height: 5.3rem;
  border: 1px solid rgba(238, 238, 238, 0.5);
  font-size: 2.6rem;
  -webkit-transition: border-color 0.3s, color 0.3s;
  transition: border-color 0.3s, color 0.3s;
}
.account span {
  color: #ccc;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.account b {
  color: white;
  font-size: 1.5rem;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.account:hover .login,
.account:hover span,
.account:hover b {
  color: #336699;
}
.account:hover .w-icon-account {
  color: #336699;
  border-color: #336699;
}

.header-search {
  position: relative;
  width: 100%;
  max-width: 38rem;
}
.header-search input.form-control {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 40px;
  padding: 0.9rem;
  border: 2px solid #336699;
  border-right: 0;
  font-size: 1.2rem;
  letter-spacing: 0;
  font-family: Poppins, sans-serif;
}
.header-search input.form-control::-webkit-input-placeholder {
  color: inherit;
}
.header-search input.form-control::-moz-placeholder {
  color: inherit;
}
.header-search input.form-control:-ms-input-placeholder {
  color: inherit;
}
.header-search input.form-control::-ms-input-placeholder {
  color: inherit;
}
.header-search input.form-control::placeholder {
  color: inherit;
}
.header-search .btn-search {
  margin: 0;
  padding: 0;
  min-width: 5.1rem;
  color: #333;
  border-left: 0;
  border-color: #336699;
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: -0.025em;
  text-transform: none;
}
.header-search .btn-search i {
  font-size: 1.7rem;
  line-height: 1;
  letter-spacing: -0.01em;
  font-weight: 500;
}
.header-search .btn-search:hover,
.header-search .btn-search:active,
.header-search .btn-search:focus {
  color: #336699;
  background-color: transparent;
}
.header-search .select-box::before {
  right: 1.2rem;
  margin-top: -0.1rem;
  font-weight: 400;
}
.header-search .select-box select {
  position: relative;
  padding: 0 2rem 0 1.5rem;
  border: 0;
  font-weight: 400;
  z-index: 1;
}
.header-search.hs-toggle .input-wrapper {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: -2.5rem;
  top: 100%;
  min-width: 30rem;
  padding: 1.5rem;
  height: auto;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
  z-index: 100;
  -webkit-transition: visibility 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: visibility 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
  transition: transform 0.3s, visibility 0.3s, opacity 0.3s,
    -webkit-transform 0.3s;
}
.header-search.hs-toggle:hover {
  color: #336699;
}
.header-search.hs-toggle .form-control {
  padding: 1.4rem 2rem;
  font-size: 1.3rem;
  color: #777;
  background-color: #f4f4f4;
  border: none;
  height: 5rem;
}
.header-search.hs-toggle .btn-search {
  background-color: #f4f4f4;
  min-width: 5rem;
  font-size: 1.8rem;
  color: #222;
  border: none;
}
.header-search.hs-toggle .btn-search i {
  margin-bottom: 0.3rem;
  font-size: inherit;
}
.header-search.hs-toggle.show .input-wrapper {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.header-search.hs-toggle.show .search-toggle::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
}
.header-search.hs-expanded {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 auto;
  min-width: auto;
  max-width: 55.6rem;
}
.header-search.hs-expanded .select-box {
  width: 14.6rem;
  border: 2px solid #336699;
  border-right: none;
  color: #666666;
}
.header-search.hs-expanded .select-box::after {
  content: "";
  height: 35px;
  width: 1px;
  background: #e5e5e5;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header-search.hs-expanded input.form-control {
  color: #666;
  border-right: none;
  border-left: none;
}
.header-search.hs-expanded .btn-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: opacity 0.4s, color 0.4s;
  transition: opacity 0.4s, color 0.4s;
}
.header-search.hs-expanded .btn-search i {
  margin: 0;
}
.header-search.hs-expanded .btn-search span {
  margin-right: 0.5rem;
  letter-spacing: -0.03em;
}
.header-search.hs-simple {
  min-width: 35rem;
}
.header-search.hs-simple input.form-control {
  border: none;
  background-color: #f5f5f5;
}
.header-search.hs-simple .btn-search {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 4.5rem;
  background: transparent;
  color: #666;
}
.header-search.hs-rounded .select-box,
.header-search.hs-rounded .form-control {
  border-radius: 0.5rem 0 0 0.5rem;
}
.header-search.hs-rounded .btn-search {
  border-radius: 0 0.5rem 0.5rem 0;
}
.header-search.hs-rounded.hs-simple input.form-control {
  border-radius: 5rem;
}
.header-search.hs-rounded.hs-expanded .form-control {
  border-radius: 0;
}
.header-search.hs-round .select-box,
.header-search.hs-round .form-control {
  border-radius: 0.3rem 0 0 0.3rem;
}
.header-search.hs-round .btn-search {
  border-radius: 0 0.3rem 0.3rem 0;
}
.header-search.hs-round.hs-simple input.form-control {
  border-radius: 3rem;
}
.header-search.hs-round.hs-expanded .form-control {
  border-radius: 0;
}

.dir-up.hs-toggle .input-wrapper {
  top: auto;
  bottom: 100%;
  margin-bottom: 2rem;
}

.main-nav .menu > .has-submenu {
  padding-right: 1.5rem;
}

.mobile-menu-toggle {
  display: none;
  font-size: 2.4rem;
  line-height: 1;
}

.compare {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: -0.4rem 3rem 0 0;
  color: #333;
}
.compare span.compare-label {
  cursor: pointer;
  margin-top: 0.1rem;
  font-weight: inherit;
  color: inherit;
}
.compare i {
  font-size: 3.3rem;
  color: inherit;
}
.compare:hover {
  color: #336699;
}

.wishlist {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  margin-right: 2.3rem;
  color: #333;
}
.wishlist span {
  margin-top: 0.3rem;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}
.wishlist i {
  font-size: 2.7rem;
  color: inherit;
}
.wishlist:hover {
  color: #336699;
}

.header-call {
  position: relative;
  margin-right: 4.8rem;
}
.header-call .w-icon-call {
  margin: 0 0.6rem 0.2rem 0;
  font-size: 3rem;
}
.header-call .call-info {
  margin-top: 0.4rem;
}
.header-call .phone-number {
  font-size: 1.6rem;
  line-height: 1.7;
}
.header-call .chat a {
  color: #333;
}
.header-call .chat a:hover {
  color: #336699;
}
.header-call:hover .w-icon-call,
.header-call:hover .phone-number {
  color: #336699;
}
.header-call::after {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -2.5rem;
  width: 1px;
  height: 4.1rem;
  background-color: #e5e5e5;
}

.cart-dropdown {
  color: #333;
}
.cart-dropdown > a i {
  font-size: 2.6rem;
  color: inherit;
}

.label-down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.label-down i {
  display: inline-block;
}
.label-down span {
  display: block;
}
.label-down i + span {
  margin-top: 0.3rem;
  letter-spacing: -0.03em;
  line-height: 1;
}

.header-border .header-bottom {
  border-bottom: 1px solid #eee;
}

@media (max-width: 991px) {
  .header .main-nav {
    display: none;
  }
  .header .sticky-content-wrapper {
    display: none;
  }

  .header-top .header-left {
    margin-right: 2rem;
  }

  .header-middle .header-right > *:not(:last-child) {
    margin-right: 2rem;
  }

  .header-bottom {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .header-call {
    margin-right: 1rem !important;
  }
  .header-call::after {
    content: none;
  }
}
@media (max-width: 991px) and (min-width: 480px) {
  .header-middle .logo {
    margin-left: 2rem;
  }
}
@media (max-width: 479px) {
  .header-top .header-left {
    position: relative;
    overflow: hidden;
  }

  .welcome-msg {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation: 6s linear 2s 1 showMsgFirst,
      12s linear 8s infinite showMsg;
    animation: 6s linear 2s 1 showMsgFirst, 12s linear 8s infinite showMsg;
  }

  .header-middle .logo {
    margin: 0 auto;
  }
  .header-middle .header-right {
    margin-left: 0 !important;
  }
}
/*------------------------
    Dropdowns
        - Dropdown
        - Dropdown-expandex
        - Cart Dropdown
        - Category Dropdown
-------------------------*/
.dropdown {
  position: relative;
}
.dropdown:hover .dropdown-box,
.dropdown.show .dropdown-box {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.dropdown:hover::after,
.dropdown.show::after {
  visibility: visible;
  opacity: 1;
  top: calc(100% - 20px);
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0);
}
.dropdown:hover .dropdown-box,
.dropdown.show .dropdown-box {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.dropdown:hover > a,
.dropdown.show > a {
  color: #336699;
}
.dropdown a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dropdown a .dropdown-image {
  max-width: 1.4rem;
  margin-right: 0.6rem;
  margin-top: -1px;
  height: auto;
}
.dropdown > a {
  line-height: 1.1;
  padding: 11px 0;
}
.dropdown > a::after {
  display: inline-block;
  margin-left: 6px;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-size: 8px;
  font-weight: 900;
}
.dropdown li.active > a,
.dropdown li:hover > a {
  color: #336699;
}

.dropdown-box {
  position: absolute;
  right: 0;
  top: -9999px;
  padding: 0.5rem 0;
  color: #666;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 20px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 20px -8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}
.dropdown-box a {
  padding: 0.6rem 1rem;
  font-size: inherit;
  line-height: 1.1;
}

.cart-dropdown .cart-toggle {
  padding: 0;
}
.cart-dropdown .cart-toggle::after {
  content: none;
}
.cart-dropdown .cart-label {
  display: block;
  margin: 0.5rem 0 0;
  font-weight: inherit;
  letter-spacing: -0.025em;
}
.cart-dropdown .cart-count {
  position: absolute;
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  font-style: normal;
  z-index: 1;
  right: -8px;
  top: -5px;
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.8rem;
  background: #336699;
  color: #fff;
  text-align: center;
}
.cart-dropdown .cart-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 1.4rem;
}
.cart-dropdown .cart-header span {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  color: #333;
}
.cart-dropdown .cart-header a {
  padding: 0;
  font-size: 1.4rem;
  font-weight: 400;
  color: #666;
  text-transform: capitalize;
}
.cart-dropdown .cart-header i {
  margin-left: 0.6rem;
  font-size: 1.8rem;
}
.cart-dropdown .cart-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cart-dropdown .cart-action .btn {
  width: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.3rem;
  line-height: 1;
  padding: 1rem;
}
.cart-dropdown .cart-action .btn:last-child {
  margin-left: 1rem;
}
.cart-dropdown .cart-action .btn-dark:hover,
.cart-dropdown .cart-action .btn-dark:active,
.cart-dropdown .cart-action .btn-dark:focus {
  border-color: #336699;
  background-color: #336699;
  color: #fff;
}
.cart-dropdown .cart-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1.8rem 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: #333333;
  line-height: 1;
  text-transform: capitalize;
  letter-spacing: -0.025em;
}
.cart-dropdown .cart-total .price {
  font-size: 1.6rem;
}
.cart-dropdown .dropdown-box {
  padding: 1rem 3rem 3rem;
  min-width: 30rem;
  margin-top: 0.5rem;
}
.cart-dropdown .product-price {
  color: #336699;
  overflow: visible;
}
.cart-dropdown .products {
  max-height: 28rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 2.1rem;
}
.cart-dropdown.cart-offcanvas .dropdown-box {
  position: fixed;
  top: 0;
  right: -34rem;
  max-width: 34rem;
  margin-top: 0;
  padding: 2.4rem 2rem 2rem;
  width: 100%;
  height: 110vh;
  min-width: auto;
  padding: 2.9rem 3rem;
  opacity: 1;
  visibility: visible;
  -webkit-transition: right 0.3s;
  transition: right 0.3s;
  -webkit-transform: none;
  transform: none;
  z-index: 2499;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cart-dropdown.cart-offcanvas .products {
  border-top: 1px solid #eee;
}
.cart-dropdown.cart-offcanvas.opened .dropdown-box {
  right: 0;
}
.cart-dropdown.cart-offcanvas.opened .cart-overlay {
  opacity: 1;
  visibility: visible;
}
.cart-dropdown .cart-overlay {
  position: fixed;
  left: 0;
  width: 100vw;
  top: -10vh;
  height: 120vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2498;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.product.product-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 2rem 0 0;
}
.product.product-cart .product-media {
  max-width: 8rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8rem;
  flex: 0 0 8rem;
}
.product.product-cart .product-media a {
  display: block;
  padding: 0;
}
.product.product-cart .product-detail {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.product.product-cart .product-name {
  white-space: normal;
  padding: 0;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}
.product.product-cart .price-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product.product-cart .product-quantity {
  margin-right: 0.7rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: #999;
}
.product.product-cart .product-quantity::after {
  content: "x";
  font-size: 1.8rem;
  font-weight: 400;
  margin-left: 0.7rem;
  text-transform: none;
  line-height: 0;
}
.product.product-cart .btn-close {
  position: absolute;
  top: 0.9rem;
  right: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  -webkit-box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.2);
  color: #333;
  background-color: #fff;
  padding-bottom: 0;
}
.product.product-cart .btn-close:hover {
  color: #336699;
}
.product.product-cart .btn-close i {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.7rem;
}

.category-dropdown > a {
  background: #fff;
  min-width: 28rem;
  padding: 1.9rem 1.8rem 1.7rem 2rem;
  letter-spacing: -0.025em;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: uppercase;
}
.category-dropdown > a i {
  margin-bottom: 0.1rem;
  font-size: 1.7rem;
  line-height: 0;
}
.category-dropdown > a span {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 0.7rem;
}
.category-dropdown > a::after {
  font-family: "wolmart";
  content: "";
  font-weight: 600;
  font-size: 0.9rem;
}
.category-dropdown > a:not(.btn):hover {
  color: #fff;
}
.category-dropdown:hover > a,
.category-dropdown.show > a {
  color: #fff;
  background-color: #336699;
}
.category-dropdown .dropdown-box {
  padding: 0;
  left: 0;
  min-width: 24rem;
  color: #333;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  -webkit-transition: z-index 0.3s, -webkit-transform 0.3s;
  transition: z-index 0.3s, -webkit-transform 0.3s;
  transition: z-index 0.3s, transform 0.3s;
  transition: z-index 0.3s, transform 0.3s, -webkit-transform 0.3s;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate3d(0, -1rem, 0);
  transform: translate3d(0, -1rem, 0);
  top: 100%;
}
.category-dropdown::before,
.category-dropdown::after {
  left: 25px;
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0);
}
.category-dropdown::after {
  border-bottom-color: #f4f4f4;
  visibility: hidden;
  opacity: 0;
  top: calc(100% - 20px);
}
.category-dropdown.menu-fixed .dropdown-box,
.category-dropdown.menu-fixed::after {
  visibility: hidden;
  opacity: 0;
}
.category-dropdown.dropdown.show .dropdown-box {
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.category-dropdown.dropdown.show .dropdown-box,
.category-dropdown.dropdown.show::after {
  visibility: visible;
  opacity: 1;
}
.category-dropdown.has-border .category-toggle {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
.category-dropdown.show-dropdown .dropdown-box {
  border-radius: 0.5rem;
}
.category-dropdown.show-dropdown .dropdown-box::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 1.7rem;
  border: 10px solid transparent;
  border-bottom: 10px solid #f5f5f5;
}
.category-dropdown.show-dropdown .dropdown-box .category-menu {
  background-color: #f5f5f5;
  border-radius: 0.5rem;
}

@media (max-width: 1199px) {
  .category-dropdown > a {
    min-width: 24rem;
  }
}
@media (max-width: 991px) {
  .cart-dropdown .cart-label {
    display: none;
  }
}
@media (max-width: 479px) {
  .cart-dropdown.cart-offcanvas .dropdown-box {
    max-width: 28rem;
  }
}
/*------------------------------------------
    Menu
        - Default
        - Menu(Main Menu)
            - Megamenu
            - Menu Banner
            - Tips

        -Mobile Menu
            - MobileMenuWrapper
            - MobileMenuContainer
            - MobileMenu
            - MobileMenuOverlay
            - MobileMenuCloseButtonn

        - Vertical Menu
        - Category Menu
-----------------------------------------*/
.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.menu a {
  display: inline-block;
  color: inherit;
}
.menu a:hover {
  color: #336699;
}
.menu .menu-title {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.025em;
  text-transform: uppercase;
}
.menu .submenu {
  padding: 2rem 0;
  background: #fff;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  color: #666666;
}
.menu .submenu .has-submenu > a::after {
  content: "";
  font-weight: 600;
  font-size: 0.9rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.menu li {
  position: relative;
  line-height: 1;
}
.menu li a {
  padding: 0.7rem 0 0.8rem 0;
}
.menu li ul,
.menu li .megamenu {
  position: absolute;
  top: -9999px;
  left: 100%;
  margin: 0;
  -webkit-box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  visibility: hidden;
  opacity: 1;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}
.menu li .megamenu {
  padding: 0 1rem;
}
.menu li > .submenu {
  min-width: 21.5rem;
}
.menu li > .submenu li {
  padding: 0 1.9rem;
}
.menu li:hover > .submenu,
.menu li:hover .megamenu,
.menu .show > .submenu,
.menu .show .megamenu {
  visibility: visible;
  top: -2rem;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.menu > li {
  margin-right: 3rem;
}
.menu > li:last-child {
  margin-right: 0;
}
.menu > li > a {
  position: relative;
  padding: 1.8rem 0 1.7rem 0;
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: -0.009em;
  line-height: 1.1;
  text-transform: capitalize;
}
.menu > li > a::after {
  margin-left: 0.6rem;
}
.menu > li > .submenu,
.menu > li .megamenu {
  left: -1.5rem;
}
.menu > li:hover > a:not(.menu-title),
.menu > li.active > a:not(.menu-title) {
  color: #336699;
}
.menu > li:hover > .submenu,
.menu > li:hover .megamenu,
.menu > li.show > .submenu,
.menu > li.show .megamenu {
  top: 100%;
}
.menu .has-submenu > a {
  display: block;
  position: relative;
}
.menu > .has-submenu > a::after {
  margin-top: 1px;
  right: -16px;
}
.menu.no-arrow > li > a::after {
  content: none;
}
.menu .megamenu ul {
  position: static;
  -webkit-box-shadow: none;
  box-shadow: none;
  visibility: visible;
  opacity: 1;
}

.active-underline > li > a::before {
  content: "";
  position: absolute;
  left: 0;
  margin-top: 17px;
  width: 100%;
  border-top: 3px solid;
  border-color: inherit;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.active-underline > li:hover > a::before,
.active-underline > li.active > a::before {
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.has-submenu > a::after {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  line-height: 0;
  vertical-align: middle;
  font-family: "Font Awesome 5 Free";
  font-size: 1rem;
  color: inherit;
  content: "";
}

/* Mega Menu */
.megamenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 80rem;
  background: #fff;
}
.megamenu > li {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 2.7rem 1rem 0;
}
.megamenu > li.row:last-child {
  padding: 0 2rem 2rem 0;
}
.megamenu ul {
  font-size: 1.4rem;
  font-weight: 400;
  color: #666;
  padding: 0;
  letter-spacing: 0;
}
.megamenu ul:last-child {
  margin-bottom: 1rem;
}

.megamenu-sm {
  min-width: 40rem;
}

/* Menu Baner */
.megamenu .menu-banner {
  height: calc(100% + 2.7rem);
  margin: -2.7rem -1rem 0;
  overflow: hidden;
}
.megamenu .menu-banner figure {
  height: 100%;
}
.megamenu .menu-banner img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.megamenu .menu-banner .btn:not(.btn-outline) {
  padding: 0;
  letter-spacing: 0.005em;
}

.menu-banner1 .banner-content {
  left: 10%;
  bottom: 5%;
}
.menu-banner1 .banner-subtitle {
  font-size: 7.187rem;
  letter-spacing: -0.05em;
  -webkit-transform: rotateZ(-90deg) translate(2%, -50%);
  transform: rotateZ(-90deg) translate(2%, -50%);
  margin-bottom: 35px;
}
.menu-banner1 .banner-title {
  position: relative;
  margin-bottom: 1.1rem;
  font-size: 2.4rem;
  letter-spacing: -0.05em;
  line-height: 1.3;
}
.menu-banner1 .banner-title span {
  display: block;
  font-size: 2.6rem;
  color: #547ca5;
}

.menu-banner2 .banner-content {
  top: 8%;
  left: 12.7%;
}
.menu-banner2 .banner-price-info {
  font-weight: 500;
}
.menu-banner2 .banner-title {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 1.4rem;
}

.menu-banner3 .banner-content {
  bottom: 2.8rem;
  left: 12.8%;
}
.menu-banner3 .banner-subtitle {
  font-size: 1.6rem;
}
.menu-banner3 .banner-title {
  margin-bottom: 0.1rem;
  font-size: 2.4rem;
  line-height: 1.25;
}
.menu-banner3 .banner-price-info {
  margin-bottom: 2.3rem;
  font-size: 2rem;
}

.menu-banner4 .banner-content {
  top: 6.9%;
  left: 12.7%;
}
.menu-banner4 .banner-subtitle {
  margin-bottom: 1.1rem;
  font-size: 1.4rem;
  color: #ccc;
}
.menu-banner4 .banner-title {
  margin-bottom: 0.3rem;
  font-size: 2.2rem;
  line-height: 1.1;
}
.menu-banner4 .banner-price-info {
  margin-bottom: 1.6rem;
  font-size: 2.2rem;
}
.menu-banner4 .btn-outline {
  padding: 0.75em 1.45em;
  letter-spacing: 0;
}
.menu-banner4 .btn-outline:hover,
.menu-banner4 .btn-outline:active,
.menu-banner4 .btn-outline:focus {
  color: #336699;
  background-color: #fff;
  border-color: #fff;
}

.menu-banner5 .banner-content {
  margin-top: -0.2rem;
  left: 2rem;
}
.menu-banner5 .banner-content.text-right {
  right: 2rem;
}
.menu-banner5 .banner-subtitle {
  font-size: 1.3rem;
  margin-bottom: 0.4rem;
}
.menu-banner5 .banner-title {
  margin-bottom: 1.4rem;
  font-size: 2rem;
}
.menu-banner5 .banner-price-info {
  font-size: 1.3rem;
}

.tip {
  display: inline-block;
  position: relative;
  top: -1px;
  left: 7px;
  padding: 0.3rem 0.5rem 0.2rem;
  border-radius: 0.2rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
}
.tip.tip-new {
  background-color: #336699;
}
.tip.tip-hot {
  background-color: #ff9742;
}

.mobile-menu-wrapper {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2400;
  font-family: Poppins, sans-serif;
  -webkit-transition: visibility 0.4s;
  transition: visibility 0.4s;
}

.mobile-menu-container {
  max-width: 296px;
  padding: 2rem 1.5rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #222;
  -webkit-box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
  -webkit-transform: translateX(-296px);
  transform: translateX(-296px);
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.mobile-menu-container .input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mobile-menu-container .input-wrapper .form-control {
  min-height: 4rem;
  padding-top: 0.9rem;
  padding-bottom: 0.8rem;
  color: #777;
  border: 1px solid;
  border-color: #333;
  border-right: 0;
  background-color: transparent;
  font-size: 1.2rem;
  border-radius: 0.3rem 0 0 0.3rem;
}
.mobile-menu-container .input-wrapper .btn-search {
  padding: 0;
  min-width: 4.8rem;
  background-color: transparent;
  color: #fff;
  font-size: 2rem;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  border: 1px solid #333;
  border-left: 0;
  border-radius: 0 0.3rem 0.3rem 0;
}
.mobile-menu-container .input-wrapper .btn-search i {
  margin: 0 0 0.4rem 0;
}
.mobile-menu-container .mobile-menu {
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
  background: #222;
}
.mobile-menu-container .tab-content {
  background-color: transparent;
}
.mobile-menu-container .nav {
  border: none;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.mobile-menu-container .nav .nav-item {
  margin: 0;
  width: 50%;
}
.mobile-menu-container .nav-link {
  margin-bottom: -2px;
  padding: 1rem;
  font-size: 1.3rem;
  color: #fff;
  border-bottom: 2px solid;
}
.mobile-menu-container .nav-item.show .nav-link,
.mobile-menu-container .nav-item .nav-link.active {
  color: #336699;
  border-color: #336699;
}
.mobile-menu-container.scrollable::-webkit-scrollbar-thumb,
.mobile-menu-container .sidebar-content::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 7px;
  cursor: pointer;
}
.mobile-menu-container .tab-pane {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
}

.mobile-menu {
  line-height: 1.5;
  color: #eeeeee;
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
}
.mobile-menu ul {
  display: none;
  width: 100%;
}
.mobile-menu > li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mobile-menu > li a svg {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  stroke: #fff;
  fill: #fff;
  vertical-align: middle;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.mobile-menu > li:hover svg,
.mobile-menu > li.active svg {
  stroke: #f93;
  fill: #f93;
}
.mobile-menu > li:last-child {
  padding-bottom: 0.5rem;
}
.mobile-menu > li:not(:last-child) {
  border-bottom: 1px solid #2e3237;
}
.mobile-menu li a {
  display: block;
  position: relative;
  padding: 1.3rem 0.6rem 1.3rem 1rem;
  color: inherit;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.mobile-menu li i {
  display: inline-block;
  margin-top: -0.3rem;
  margin-right: 1rem;
  font-size: 1.8rem;
  line-height: 0;
  vertical-align: middle;
}
.mobile-menu li li a {
  padding-left: 2.4rem;
}
.mobile-menu li li li a {
  padding-left: 3.6rem;
}
.mobile-menu li.expanded > a,
.mobile-menu li.show > a,
.mobile-menu li:hover > a {
  color: #336699;
}

.mobile-menu-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}

.mobile-menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.mobile-menu-close {
  position: fixed;
  left: calc(100vw - 35px);
  top: 25px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 0;
  z-index: 1;
}
.mobile-menu-close i {
  font-size: 2.8rem;
  color: #e1e1e1;
}

.mmenu-active {
  overflow: hidden;
}
.mmenu-active .mmenu-anim > li,
.mmenu-active .mmenu-anim {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.mmenu-active .page-wrapper {
  margin-left: 296px;
  margin-right: -296px;
}
.mmenu-active .mobile-menu-wrapper {
  visibility: visible;
}
.mmenu-active .mobile-menu-container {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.mmenu-active .mobile-menu-overlay {
  opacity: 0.5;
}
.mmenu-active .mobile-menu-close {
  opacity: 1;
}

.toggle-btn {
  display: block;
  position: absolute;
  padding: 6px;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0.6;
}
.toggle-btn::before {
  content: "";
  display: block;
  font-size: 0.8rem;
  font-weight: 600;
  font-family: "wolmart";
  line-height: 1;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.show > a .toggle-btn::before {
  -webkit-transform: rotate(630deg);
  transform: rotate(630deg);
}

/* Category Menu */
.category-menu {
  padding: 1rem 0;
  background: #fff;
}
.category-menu i {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  margin-right: 0.7rem;
  font-size: 1.7rem;
  width: 1.7rem;
}
.category-menu > li > a {
  display: block;
  padding: 1.3rem 1rem;
  text-transform: capitalize;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -0.007em;
  color: #666666;
}
.category-menu > li > a::before {
  content: none;
}
.category-menu > li:not(:last-child) > a {
  border-bottom: 1px solid #eeeeee;
}
.category-menu > li:last-child > a i {
  margin: 0 0 0 0.4rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.category-menu li .megamenu {
  padding: 0 0 0 2rem;
}
.category-menu li:last-child {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.category-menu li:hover > a:not(.menu-title) {
  color: #336699;
}
.category-menu li:hover > a:not(.menu-title) i {
  color: inherit;
}
.category-menu > .has-submenu:hover > a::after {
  right: 0.5rem;
}
.category-menu .megamenu {
  min-width: 68.5rem;
}
.category-menu .megamenu > li:not(:last-child) {
  padding: 2.8rem 2rem 0 0rem;
}
.category-menu .megamenu .menu-title {
  font-size: 1.4rem;
  margin-bottom: 1.3rem;
}
.category-menu .megamenu .divider {
  border: 1px solid #eeeeee;
  border-bottom: 0px;
  margin: 0.5rem 0 2rem;
}
.category-menu .megamenu li a:not(.btn) {
  padding-top: 0.8rem;
}
.category-menu .megamenu.type2 {
  display: block;
}
.category-menu .megamenu.type2 figure {
  text-align: center;
}

/* Vertical Menu */
.vertical-menu {
  display: block;
  min-width: 24rem;
}
.vertical-menu i.w-icon-electronics,
.vertical-menu i.w-icon-furniture {
  font-size: 1.5rem;
  text-align: center;
}
.vertical-menu > li {
  margin-right: 0;
  padding: 0 1rem;
}
.vertical-menu > li > ul,
.vertical-menu > li .megamenu {
  -webkit-transform: translate3d(-15px, 0, 0);
  transform: translate3d(-15px, 0, 0);
}
.vertical-menu > li > a {
  display: block;
}
.vertical-menu > li > a::after {
  content: none;
}
.vertical-menu > li:hover > ul,
.vertical-menu > li:hover .megamenu,
.vertical-menu > li.active > ul,
.vertical-menu > li.active .megamenu {
  left: 100%;
  top: 0rem;
}
.vertical-menu > li:first-child:hover > ul,
.vertical-menu > li:first-child:hover .megamenu,
.vertical-menu > li:first-child.active > ul,
.vertical-menu > li:first-child.active .megamenu {
  top: -1rem;
}
.vertical-menu > .has-submenu > a::after {
  content: "";
  position: absolute;
  right: 1rem;
  line-height: 0;
  font-weight: 600;
  font-size: 1.2rem;
  -webkit-transition: right 0.3s;
  transition: right 0.3s;
}

/* Horizontal Menu */
.horizontal-menu {
  width: 100%;
  padding: 1rem 3.2rem;
  background-color: #333;
  border-radius: 0.5rem;
  color: #fff;
  overflow-x: auto;
}
.horizontal-menu > li {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 3.05rem;
  text-align: center;
}
.horizontal-menu > li:not(:last-child) > a {
  border-bottom: none;
}
.horizontal-menu > li > a {
  padding: 0.8rem 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0;
}
.horizontal-menu > li:hover i {
  -webkit-transform: translateY(-0.5rem);
  transform: translateY(-0.5rem);
}
.horizontal-menu > li:last-child > a i {
  margin: 0 0 0.9rem;
  font-size: 3rem;
  font-weight: 400;
}
.horizontal-menu i {
  display: block;
  width: auto;
  height: 3rem;
  margin: 0 0 0.9rem;
  font-size: 3rem;
  line-height: inherit;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.horizontal-menu::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.horizontal-menu::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

@media (min-width: 1200px) {
  .category-menu .megamenu.type2 {
    min-width: 88rem;
  }
}
@media (max-width: 1199px) {
  .main-nav .menu .megamenu {
    left: -18rem;
  }

  .category-menu .megamenu.type2 {
    min-width: 70rem;
  }
}
/* 7. Footer */
/*-------------------------------------
    Footer
        - Footer top
        - Footer middle
        - Footer bottom
        - Copyright
        - Widget newsletter
        - Sticky Footer
--------------------------------------*/
.footer {
  font-family: Poppins, sans-serif;
  font-size: 1.3rem;
  background: #f8f8f8;
}
.footer a {
  color: #666;
}
.footer a:hover,
.footer a:active,
.footer a.active {
  color: #336699;
}
.footer.footer-dark a:hover,
.footer.footer-dark a:active,
.footer.footer-dark a.active {
  color: #fff;
}
.footer p {
  font-size: inherit;
}
.footer .category-name {
  font-weight: 600;
  font-size: 1.4rem;
  color: #666;
  line-height: 1;
  letter-spacing: 0;
  padding-right: 2rem;
  margin-bottom: 1rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.footer .category-box {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer .category-box:not(:last-child) {
  margin-bottom: 1.4rem;
}
.footer .category-box a {
  font-weight: normal;
  font-size: 1.3rem;
  letter-spacing: 0;
  color: #666;
  line-height: 1;
  margin-bottom: 1rem;
  position: relative;
}
.footer .category-box a:hover,
.footer .category-box a:active,
.footer .category-box a:focus {
  color: #336699;
}
.footer .category-box a:last-child::before {
  width: 100%;
}
.footer .category-box a::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 13px;
  border-left: 1px solid #ccc;
  margin: 0px 0.9rem 0 1rem;
  vertical-align: bottom;
}
.footer .category-box a:last-child::after {
  content: none;
}
.footer .social-icons-colored .social-icon {
  color: #fff;
}

.logo-footer,
.logo-footer img {
  display: block;
}

.footer-newsletter {
  padding: 2.8rem 0;
}
.footer-newsletter .icon-box-side {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}
.footer-newsletter .icon-box-title {
  margin-bottom: 0.2rem;
  font-size: 1.6rem;
}
.footer-newsletter .icon-box-icon {
  margin-right: 2rem;
  margin-bottom: 0.4rem;
}
.footer-newsletter .icon-box-icon i {
  font-size: 4.7rem;
}
.footer-newsletter .icon-box-content p {
  line-height: 1.6;
}
.footer-newsletter .input-wrapper-inline {
  max-width: 100%;
}
.footer-newsletter .btn {
  padding: 0.93em 1.85em;
}
.footer-newsletter .btn i {
  margin: -0.2rem 0 0 0.9rem;
  font-size: 1.6rem;
}

.footer-top {
  padding: 6.8rem 0 0.8rem;
  border-bottom: 1px solid #eee;
}
.footer-top .widget {
  margin-bottom: 4rem;
}
.footer-top .widget-title {
  padding: 0 0 0.6rem 0;
  margin-bottom: 2rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1;
  text-transform: uppercase;
  color: #333;
  border: none;
  cursor: text;
}
.footer-top .widget-body {
  padding: 0;
  color: #666;
}
.footer-top .widget-body li {
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

.footer-middle {
  padding: 4.5rem 0 3.8rem;
  border-bottom: 1px solid #eee;
}

.footer-bottom {
  padding: 3rem 0;
  color: #666;
}
.footer-bottom,
.footer-bottom .container,
.footer-bottom .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-bottom .footer-left,
.footer-bottom .footer-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-bottom .footer-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.footer .widget-about {
  margin-top: 0.2rem;
}
.footer .widget-about .logo-footer {
  margin-bottom: 1rem;
}
.footer .widget-about .widget-about-title {
  line-height: 2.1;
  margin-bottom: 1rem;
  color: #333;
  font-weight: 500;
}
.footer .widget-about .widget-about-call {
  display: block;
  color: #333;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.9rem;
}
.footer .widget-about .widget-about-call:hover {
  color: #336699;
}
.footer .widget-about .widget-about-desc {
  line-height: 2.15;
  margin-bottom: 2.1rem;
  max-width: 28rem;
}

.footer .copyright {
  font-size: 1.3rem;
  line-height: 1.92;
  margin-bottom: 0;
  text-align: center;
}

.payment img {
  display: block;
}

.payment-label {
  margin-right: 2rem;
}

.sticky-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 4rem;
  background: #fff;
}
.sticky-footer > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.sticky-footer .cart-dropdown {
  position: relative;
}
.sticky-footer .cart-dropdown .dropdown-box {
  top: auto;
  bottom: 100%;
  right: 0;
  min-width: 34rem;
  padding: 2rem 3rem 3rem;
  margin-bottom: 2.1rem;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s,
    -webkit-transform 0.3s;
}
.sticky-footer .cart-dropdown:hover .dropdown-box {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.sticky-footer .products {
  border: none;
}
.sticky-footer .product-name a {
  padding: 0;
}
.sticky-footer .cart-total {
  padding-top: 1rem;
}

.sticky-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #666;
  margin: 1.4rem 0 1.3rem;
}
.sticky-link i,
.cart-dropdown .sticky-link i {
  font-size: 2.3rem;
  color: #666;
}
.sticky-link p {
  margin-top: 0.9rem;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}
.sticky-link.search-toggle p {
  margin-top: 1rem;
}
.sticky-link:hover {
  color: #336699;
}
.sticky-link:hover i {
  color: inherit;
}

.footer-dark {
  background-color: #222;
}
.footer-dark .footer-top,
.footer-dark .footer-middle,
.footer-dark .category-box a::after {
  border-color: #454545;
}
.footer-dark a,
.footer-dark .widget-about .widget-about-title,
.footer-dark .category-name {
  color: #999;
}
.footer-dark .widget-about .widget-about-call,
.footer-dark .widget-title {
  color: #fff;
}

@media (min-width: 992px) {
  .footer-top .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22.22%;
    flex: 0 0 22.22%;
    max-width: 22.22%;
  }
}
@media (min-width: 768px) {
  .sticky-footer {
    display: none;
  }
}
@media (max-width: 991px) {
  .footer-top {
    padding-bottom: 2.7rem;
  }

  .footer-middle .category-box a:first-child {
    margin-left: 0;
  }

  .footer-newsletter .icon-box-side {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .footer-newsletter .icon-box-icon {
    margin-right: 0;
  }

  .footer-bottom,
  .footer-bottom > .container {
    display: block;
  }
  .footer-bottom .footer-left,
  .footer-bottom .footer-right {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footer-bottom .footer-left {
    margin-bottom: 0.2rem;
  }
  .footer-bottom .footer-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-bottom .payment-label {
    margin: 0 0 1rem 0;
  }
}
@media (max-width: 479px) {
  .sticky-footer {
    padding: 0;
  }
  .sticky-footer .hs-toggle .input-wrapper {
    right: 1rem;
  }
  .sticky-footer .cart-dropdown .dropdown-box {
    min-width: 30rem;
    right: -5.4rem;
  }

  .footer-newsletter .icon-box-side {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-newsletter .icon-box-icon {
    margin: 0 0 2rem;
  }
  .footer-newsletter .icon-box-content {
    text-align: center;
  }
  .footer-newsletter .form-control {
    text-align: center;
  }
  .footer-newsletter .input-wrapper-inline {
    display: block;
    text-align: center;
  }
  .footer-newsletter .btn {
    margin-top: 2rem;
  }
}
/* 8. Pages */
/*----------------------------------
    About Us
        - Icon Box
        - Timeline
-----------------------------------*/
.about-us .introduce .title {
  margin-bottom: 1.3rem;
  font-size: 4rem;
}
.about-us .introduce p {
  margin-bottom: 4.3rem;
  max-width: 57rem;
}
.about-us .introduce img {
  min-height: 20rem;
}
.about-us .customer-service .card-header {
  font-size: 1.8rem;
}
.about-us .customer-service p {
  font-size: 1.4rem;
  color: #333;
}
.about-us .customer-service img,
.about-us .boost-section img {
  width: 100%;
}
.about-us .customer-service .title,
.about-us .boost-section .title,
.about-us .member-section .title {
  font-size: 3.4rem;
}
.about-us .counter span {
  font-size: 6rem;
  font-weight: 800;
  color: #ccc;
  letter-spacing: -0.05em;
  line-height: 1.54;
}
.about-us .counter .title {
  margin-bottom: 1.2rem;
}
.about-us .boost-section {
  background-color: #fafafb;
}
.about-us .boost-section .title {
  line-height: 1.25;
  margin-bottom: 1.3rem;
}
.about-us .boost-section .btn {
  padding: 1.23em 2.35em;
}
.about-us .awards-wrapper .title {
  font-size: 3rem;
}
.about-us .awards-wrapper img {
  width: auto;
  margin: 0 auto 2rem;
}
.about-us .awards-wrapper p {
  margin-bottom: 0;
  font-size: 2rem;
  color: #333;
  line-height: 1.6;
  letter-spacing: -0.025em;
}
.about-us .member-section figure {
  position: relative;
  max-width: 29.5rem;
  margin: 0 auto 2.5rem;
}
.about-us .member-section figure:hover .overlay {
  padding-top: 0;
  opacity: 1;
}
.about-us .member-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 10rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 1.3rem;
  opacity: 0;
  border-radius: 1.3rem;
  -webkit-transition: opacity 0.3s, padding-top 0.3s;
  transition: opacity 0.3s, padding-top 0.3s;
  cursor: pointer;
}
.about-us .member-section .overlay p {
  margin-bottom: 0;
  text-transform: uppercase;
}
.about-us .member-section .member-name {
  margin-bottom: 0;
}
.about-us .member-section .social-icon {
  width: 4.6rem;
  height: 4.6rem;
  font-size: 2.4rem;
  line-height: 4.1rem;
  border-color: #fff;
  color: #fff;
}
.about-us .member-section .social-icon:hover.social-facebook {
  background-color: #1b4f9b;
  border-color: #1b4f9b;
}
.about-us .member-section .social-icon:hover.social-twitter {
  background-color: #00adef;
  border-color: #00adef;
}
.about-us .member-section .social-icon:hover.social-instagram {
  background-color: #cc0001;
  border-color: #cc0001;
}

@media (max-width: 991px) {
  .about-us .customer-service .title,
  .about-us .boost-section .title {
    font-size: 2.5rem;
  }
}
@media (max-width: 767px) {
  .about-us .introduce .title {
    font-size: 3rem;
  }
}
/*----------------------------------------
    Accordion
-----------------------------------------*/
.color-accordion-section .accordion-primary .card-body {
  background-color: #f8f8f8;
  padding-bottom: 1.3rem;
}

.icon-accordion-section {
  margin-bottom: 3.7rem;
}

/*--------------------------------------
    Blog Page
---------------------------------------*/
.post-classic .post-details,
.post-grid-type .post-details,
.post-masonry .post-details {
  padding: 1.4rem 2px 2.4rem;
}

.post-content .btn {
  padding-top: 0.3rem;
  text-transform: lowercase;
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: -0.025em;
}
.post-content .btn:hover,
.post-content .btn:active,
.post-content .btn:focus {
  color: #333;
}

.post-classic .post-cats,
.post-listing .post-cats,
.post-grid-type .post-cats,
.post-masonry .post-cats {
  margin-bottom: 0.5rem;
}
.post-classic .post-content,
.post-listing .post-content,
.post-grid-type .post-content,
.post-masonry .post-content {
  margin-bottom: 1.5rem;
}
.post-classic .post-content p,
.post-listing .post-content p,
.post-grid-type .post-content p,
.post-masonry .post-content p {
  display: inline;
  margin-right: 1.1rem;
}

.post-listing .post-details {
  padding-top: 1rem;
  padding-bottom: 1.2rem;
}

.post-masonry .post-title {
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}

.blog-filters {
  font-weight: 600;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #333;
  line-height: 1.3;
}
.blog-filters.nav-filters li {
  margin: 0 1.5rem;
}
.blog-filters.filter-underline .nav-filter::after {
  border-top: 3px solid;
  border-bottom: 0;
}
.blog-filters .nav-filter.active,
.blog-filters .nav-filter:hover {
  color: #333;
}

.post-single .post-details {
  padding: 3.6rem 0 2.6rem;
}
.post-single .post-meta {
  margin-bottom: 0.8rem;
}
.post-single .post-title {
  margin-bottom: 1.9rem;
  white-space: normal;
}
.post-single .post-content p {
  display: block;
  margin-bottom: 1.4rem;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.86;
}
.post-single .post-content a:hover {
  color: #336699;
  text-decoration: underline;
}

.post-single-content .post-single .post-title {
  font-size: 3rem;
}
.post-single-content blockquote {
  padding: 4.2rem 1rem;
  background-color: #f8f8f8;
}
.post-single-content blockquote i {
  font-size: 3rem;
  color: #336699;
}
.post-single-content blockquote p {
  font-size: 2.6rem;
}
.post-single-content blockquote cite {
  font-size: 1.2rem;
  font-style: normal;
}
.post-single-content .tags {
  margin-bottom: 2.6rem;
}
.post-single-content .tags label {
  font-weight: 500;
}
.post-single-content .tag {
  margin-right: 0.5rem;
}
.post-single-content .post-author-detail {
  padding: 4rem 4rem;
  background-color: #f8f8f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.post-single-content .author-media {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10.5rem;
  flex: 0 0 10.5rem;
  max-width: 10.5rem;
}
.post-single-content .author-name-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.post-single-content .author-name-wrapper span {
  font-size: 1.3rem;
}
.post-single-content .author-details .author-name {
  white-space: nowrap;
}
.post-single-content .author-details .btn:hover {
  text-decoration: underline;
}
.post-single-content .post-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 6rem 0 5.2rem;
  border-bottom: 1px solid #eee;
}
.post-single-content .post-navigation .nav {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  font-size: 1.6rem;
}
.post-single-content .post-navigation .nav a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #333;
  line-height: 1.2;
}
.post-single-content .post-navigation .nav .nav-content {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: inline-block;
  max-width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1.3rem;
}
.post-single-content .post-navigation .nav .nav-content::after {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #333;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.post-single-content .post-navigation .nav i {
  margin-bottom: 0.4rem;
  font-weight: 600;
}
.post-single-content .post-navigation .nav:hover .nav-content::after {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.post-single-content .post-navigation .nav-prev i {
  margin-right: 1rem;
}
.post-single-content .post-navigation .nav-next i {
  margin-left: 1rem;
}
.post-single-content .btn-underline::after {
  border-top-width: 2px;
}
.post-single-content .title-lg {
  font-size: 2.4rem;
}
.post-single-content .post-details {
  padding-bottom: 2.5rem;
}
.post-single-content .post-title {
  font-size: 1.5rem;
}
.post-single-content .swiper-button-prev,
.post-single-content .swiper-button-next {
  top: -4.9rem;
  font-size: 1.6rem;
  font-weight: 600;
  border: none;
}
.post-single-content .swiper-button-prev:not(.swiper-button-disabled),
.post-single-content .swiper-button-next:not(.swiper-button-disabled) {
  color: #666;
}
.post-single-content .swiper-button-prev:not(.swiper-button-disabled):hover,
.post-single-content .swiper-button-next:not(.swiper-button-disabled):hover {
  color: #336699;
  background-color: #fff;
}
.post-single-content .swiper-button-prev {
  right: 0.2rem;
}
.post-single-content .swiper-button-next {
  right: 1.1rem;
}
.post-single-content .comments {
  padding-bottom: 3.3rem;
  border-bottom: 1px solid #eee;
}
.post-single-content .comment {
  padding: 2rem 0;
  border: none;
}
.post-single-content .comment:nth-child(2) {
  margin-left: 9rem;
}
.post-single-content .comment-date {
  font-size: 1.3rem;
  color: #666;
}
.post-single-content .btn-reply {
  text-transform: capitalize;
  font-weight: 400;
}
.post-single-content .btn-reply:hover,
.post-single-content .btn-reply:focus,
.post-single-content .btn-reply:active {
  color: #336699;
}
.post-single-content .form-control {
  font-size: 1.3rem;
  border-radius: 0.3rem;
}
.post-single-content input {
  min-height: 4.4rem;
}
.post-single-content textarea {
  height: 15rem;
  padding: 1.6rem 2rem;
}
.post-single-content .btn-comment i {
  margin-bottom: 0.4rem;
}

.post-slider {
  border-bottom: 1px solid #eee;
}

.post-mask-section {
  padding-bottom: 2.7rem;
}

@media (max-width: 575px) {
  .post-single-content .post-single .post-title {
    font-size: 2rem;
  }
  .post-single-content ul {
    padding-left: 0;
  }
  .post-single-content .post-navigation p {
    display: none;
  }
}
@media (max-width: 479px) {
  .post-single-content .post-author-detail {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .post-single-content .post-author-detail .author-media {
    max-width: 8rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8rem;
    flex: 0 0 8rem;
  }
  .post-single-content .comment:nth-child(2) {
    margin-left: 4rem;
  }
}
/*----------------------------------------
    Become a Vendor page
-----------------------------------------*/
.become-a-vendor img {
  width: 100%;
}
.become-a-vendor .title {
  font-size: 3.4rem;
}

/* Create Store */
.create-store h4 {
  margin-bottom: 1.6rem;
}
.create-store .title {
  margin-bottom: 1.3rem;
  font-size: 4rem;
}
.create-store p {
  margin-bottom: 3.3rem;
}
.create-store .btn {
  margin-bottom: 0.6rem;
  padding: 1.2em 2.44em;
}

@media (min-width: 992px) {
  .create-store .row > div:last-child {
    padding: 0 9rem 0 1.1rem;
  }
  .create-store .title {
    max-width: 47rem;
  }
}
@media (min-width: 768px) {
  .create-store img {
    min-height: 43.5rem;
  }
}
/* How to Trade */
.how-trade h4 {
  margin-bottom: 1.7rem;
}
.how-trade .title {
  margin-bottom: 1.3rem;
  line-height: 1.25;
}
.how-trade .stage-item p {
  margin-left: 1.2rem;
  font-size: 1.6rem;
  line-height: 1.5;
}
.how-trade svg {
  fill: #336699;
  min-width: 6.1rem;
  width: 6.1rem;
  height: 6.1rem;
}
.how-trade img {
  max-height: 52rem;
}

.stage-get .stage-item p {
  margin-left: 0.7rem;
}

/* Few Fees */
.few-fees {
  padding-bottom: 2.3rem;
}

.listing-fee .counter {
  margin: 0 5.8rem 0 auto;
}

.final-fee .counter {
  margin: 0 auto 0 5.8rem;
}

.become-a-vendor .counter {
  max-width: 29rem;
}
.become-a-vendor .counter span {
  font-size: 9rem;
  font-weight: 800;
  color: #ccc;
  line-height: 1.26;
  letter-spacing: -0.06em;
}
.become-a-vendor .counter h4 {
  margin-bottom: 1.2rem;
  font-size: 2rem;
}

/* Wolmart Sellers */
.become-a-vendor .testimonial-boxed {
  padding-top: 4rem;
  padding-bottom: 3.7rem;
}
.become-a-vendor .testimonial-boxed blockquote {
  margin-bottom: 1.2rem;
}
.become-a-vendor .swiper-theme .swiper-pagination-bullet {
  border-color: #999;
  background-color: #999;
}
.become-a-vendor .swiper-theme .swiper-pagination-bullet:hover {
  border-color: #336699;
  background-color: #336699;
}
.become-a-vendor .swiper-theme .swiper-pagination-bullet-active {
  width: 1.8rem;
  background-color: #336699;
  border-color: #336699;
}
.become-a-vendor .swiper-theme .swiper-pagination {
  margin-top: 2.5rem;
}

.wolmart-sellers h4 {
  margin: 4.7rem 0 1.9rem;
}
.wolmart-sellers h2 {
  margin-bottom: 1.4rem;
  line-height: 1.22;
}
.wolmart-sellers p {
  margin-bottom: 2.6rem;
}

/* Questions */
.questions {
  padding-top: 4.7rem;
}
.questions h4 {
  margin-bottom: 1.8rem;
}
.questions .title {
  margin-bottom: 3.8rem;
}
.questions p {
  margin-bottom: 4.8rem;
}

/* Banner */
.become-a-vendor .banner {
  padding: 6.2rem 6.2rem 6.9rem;
}
.become-a-vendor .banner .banner-content {
  max-width: 60rem;
  margin: 0 auto;
}
.become-a-vendor .banner .title {
  margin-bottom: 3.1rem;
  line-height: 1.25;
}
.become-a-vendor .banner .btn {
  padding: 1.22em 2.1em;
}

/* Responsive */
@media (max-width: 991px) {
  .become-a-vendor .title {
    font-size: 2.8rem;
  }

  .questions p {
    margin-bottom: 3rem;
  }
}
@media (max-width: 767px) {
  .few-fees .counter {
    margin: 0 auto;
  }

  .become-a-vendor .banner {
    padding: 5rem 2rem;
  }
}
/*----------------------------
    Element Button Page
        - Page Header
        - Button Section
            - Title
----------------------------*/
.btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -2rem;
  margin-top: 0.5rem;
}
.btn-group .btn-wrap {
  min-width: 16.9rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}
.btn-group .btn-wrap .btn:not(.btn-simple) {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.btn-section {
  margin-bottom: 3.6rem;
}
.btn-section p {
  position: relative;
  font-size: 1.3rem;
  padding-right: 2rem;
  line-height: 1.9;
}
.btn-section p::after {
  content: "";
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  background: #eee;
  height: 44px;
  width: 2px;
}
.btn-section .title-line::after {
  position: absolute;
  right: 0;
  width: calc(75% + 1.5rem);
}

.btn-block-section .btn-group {
  margin-left: -1rem;
  margin-top: 0.6rem;
}

.btn-size-section {
  margin-bottom: 2.6rem;
}
.btn-size-section .btn-group {
  margin-top: -0.5rem;
}
.btn-size-section .btn {
  margin-left: 2rem;
  margin-bottom: 2rem;
}
.btn-size-section p::after {
  top: 0.7rem;
}

.btn-simple-section {
  margin-bottom: 3.2rem;
}
.btn-simple-section .btn-group {
  margin-top: 1.6rem;
}
.btn-simple-section .btn-group .btn-link:not(.btn-underline) {
  padding-bottom: 6px;
}
.btn-simple-section .btn-group .btn-wrap {
  min-width: 13.7rem;
}
.btn-simple-section .btn-group .btn-wrap .btn {
  width: auto;
}

.btn-parallax-section {
  padding: 5.6rem 0 4.2rem;
  margin-bottom: 0;
}
.btn-parallax-section p {
  opacity: 0.4;
}
.btn-parallax-section p::after {
  opacity: 0.6;
}
.btn-parallax-section .btn-group::before {
  background-color: #484443;
}

@media (max-width: 991px) {
  .btn-section .title-line::after {
    position: relative;
    margin-left: 3.3rem;
  }
  .btn-section p::after {
    content: none;
  }
}
/* ----------------------------------------
    Element Category Page
------------------------------------------*/
.category-default img {
  min-height: 20rem;
}
.category-default .category-content {
  left: 3rem;
}
.category-default .category-title {
  margin-bottom: 0;
  font-size: 2.2em;
}
.category-default .category-count {
  opacity: 0.6;
  line-height: 1.2;
}

.category-ellipse-section {
  margin-bottom: 5.2rem;
}

.category-classic-section {
  background-color: #f8f8f8;
}

.category-masonry-section {
  padding-bottom: 3.7rem;
}
.category-masonry-section .category-grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(100% / 4));
  margin: -1rem;
}
.category-masonry-section .category-grid > * {
  padding: 1rem;
}
.category-masonry-section .grid-item1 {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}
.category-masonry-section .grid-item2 {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}
.category-masonry-section .grid-item3 {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}
.category-masonry-section .grid-item3 .category-banner,
.category-masonry-section .grid-item3 figure,
.category-masonry-section .grid-item3 img {
  height: 100%;
}

@media (max-width: 991px) and (min-width: 768px) {
  .category-masonry-section .category-grid {
    grid-template-columns: repeat(auto-fill, calc(100% / 3));
  }
  .category-masonry-section .grid-item3 {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-row-span: 3;
    grid-row-end: span 3;
  }
}
@media (max-width: 767px) {
  .category-masonry-section .category-grid {
    grid-template-columns: repeat(auto-fill, calc(100% / 2));
  }
}
@media (max-width: 575px) {
  .page-title {
    font-size: 3rem;
  }

  .page-subtitle {
    font-size: 1.2rem;
  }
}
@media (max-width: 479px) {
  .category-masonry-section .grid-item2,
  .category-masonry-section .grid-item3 {
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}
/*-------------------------------
    Cart Page
--------------------------------*/
.cart .cart-summary {
  padding: 2.3rem 3rem 3rem 3rem;
  border: 1px solid #eee;
  border-radius: 4px;
  line-height: 1;
}
.cart .cart-summary .cart-title {
  margin-bottom: 2.1rem;
  font-size: 2rem;
  letter-spacing: -0.01em;
}
.cart .cart-summary label {
  display: block;
}
.cart .cart-summary .btn-checkout {
  letter-spacing: 0.025em;
  padding-top: 1.07em;
  padding-bottom: 1.07em;
}
.cart .form-control {
  width: 100%;
  max-width: 100%;
  border-radius: 3px;
}
.cart .select-box::before {
  font-size: 1.1rem;
  color: #333;
  right: 1rem;
}
.cart .accordion {
  border-bottom: 1px solid #eee;
}
.cart .card-header {
  text-transform: uppercase;
}
.cart .card-header a {
  padding-left: 0;
  padding-right: 0;
}
.cart .card-header a::after {
  right: 0.5rem;
  font-size: 1.4rem;
  color: #333;
}
.cart .card-body {
  margin-bottom: 2rem;
  padding: 0;
}
.cart .cart-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2rem 0;
}
.cart .cart-action input {
  border-color: #ccc;
  font-size: 1.4rem;
  font-weight: 600;
  padding-left: 1.3rem;
  max-width: 200px;
}
.cart .cart-action .btn {
  margin-bottom: 1rem;
}
.cart .cart-action .btn-shopping i {
  font-size: 1.9rem;
}
.cart .cart-action .btn-clear,
.cart .cart-action .btn-update {
  padding: 1em 2em;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
}
.cart .cart-action .btn-clear:hover,
.cart .cart-action .btn-clear:focus,
.cart .cart-action .btn-clear:active,
.cart .cart-action .btn-update:hover,
.cart .cart-action .btn-update:focus,
.cart .cart-action .btn-update:active {
  border-color: #e1e1e1;
  background-color: #e1e1e1;
}
.cart .cart-action .btn-clear {
  margin-right: 1rem;
}
.cart .cart-action .btn-update.disabled {
  border-color: #eee;
  background-color: #eee;
  color: #666;
  cursor: not-allowed;
}
.cart .coupon-title {
  font-size: 1.6rem;
}
.cart .coupon .form-control {
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
}
.cart .coupon .form-control:focus {
  border-color: #999;
}
.cart .coupon .btn {
  padding: 1em 2em;
  border-width: 1px;
}
.cart .order-total {
  color: #333;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  margin-bottom: 2.5rem;
}

.cart-total {
  text-align: end;
}
.cart-total table {
  margin-top: -3px;
}
.cart-total td,
.cart-total th {
  border-top: 1px solid #eee;
  text-transform: uppercase;
}

.cart-subtotal {
  line-height: 1;
  font-size: 1.6rem;
  color: #333;
  font-weight: 600;
  padding-bottom: 0.8rem;
}

.shipping-methods .shipping-title {
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: -0.025em;
  padding: 1.1rem 0 0.3rem;
}
.shipping-methods .custom-radio {
  line-height: 2.7;
}
.shipping-methods .custom-radio label {
  letter-spacing: 0;
  padding-left: 2.5rem;
}
.shipping-methods .custom-radio .custom-control-label::after {
  color: #ccc;
  padding-top: 1px;
}

.shipping-destination {
  font-size: 1.5rem;
  margin-bottom: 2.2rem;
}

.shipping-calculator-form .form-group {
  margin-bottom: 1.5rem;
}
.shipping-calculator-form .form-group .form-control {
  padding-left: 1rem;
  font-size: 1.4rem;
  color: #666;
  border-color: #eee;
}
.shipping-calculator-form button {
  margin-bottom: 2.5rem;
  border-width: 1px;
}

.shipping-address .form-control,
.shipping-address .select-box {
  background-color: #fff;
  margin-bottom: 2rem;
}
.shipping-address .form-control {
  padding: 1.15rem 1.2rem;
  font-family: Poppins, sans-serif;
  font-size: 1.3rem;
  border-color: #eee;
  color: #999;
}
.shipping-address label {
  color: #333;
  text-transform: uppercase;
}

.shop-table.cart-table th {
  padding: 1rem 0 1.2rem;
  letter-spacing: -0.025em;
}
.shop-table.cart-table .product-price {
  width: 17.3%;
}
.shop-table.cart-table .product-quantity {
  width: 25.7%;
}
.shop-table.cart-table .product-subtotal {
  width: 16%;
}
.shop-table.cart-table .product-name {
  color: #333;
}
.shop-table.cart-table .product-quantity .input-group {
  max-width: 120px;
}
.shop-table.cart-table .btn-close {
  top: -8px;
  right: -7px;
}
.shop-table.cart-table td.product-subtotal {
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  letter-spacing: -0.05em;
}
.shop-table.cart-table td.product-price {
  font-weight: 400;
  font-size: 1.6rem;
}

@media (max-width: 767px) {
  .cart-table td.product-subtotal {
    text-align: center;
  }
  .cart-table.shop-table .product-quantity {
    width: auto;
  }
  .cart-table.shop-table .product-subtotal {
    width: auto;
  }
}
@media (max-width: 575px) {
  .cart .breadcrumb {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
/*------------------------------------
    Checkout Page
-------------------------------------*/
.checkout .shop-breadcrumb {
  margin-bottom: 2.2rem;
}
.checkout .login-toggle,
.checkout .coupon-toggle {
  line-height: 1.1;
}
.checkout .login-toggle {
  margin-bottom: 1.5rem;
}
.checkout .coupon-toggle {
  margin-bottom: 2.8rem;
}
.checkout .show-login:hover,
.checkout .show-coupon:hover {
  text-decoration: underline;
}
.checkout .login-content,
.checkout .coupon-content,
.checkout .checkbox-content {
  display: none;
  margin-bottom: 2.4rem;
  padding: 3rem;
  border: 1px solid #eee;
  border-top-width: 3px;
}
.checkout .login-content p {
  font-size: 1.3rem;
  margin-bottom: 1.6rem;
}
.checkout .login-content .form-group label {
  margin-bottom: 0.2rem;
}
.checkout .login-content .form-control {
  margin-bottom: 1.7rem;
}
.checkout .login-content .checkbox {
  margin-bottom: 1.7rem;
}
.checkout .login-content .checkbox label {
  padding-left: 2.6rem;
}
.checkout .login-content .checkbox a {
  color: #336699;
}
.checkout .login-content .checkbox a:hover {
  text-decoration: underline;
}
.checkout .btn-login {
  padding-left: 1.88em;
  padding-right: 1.88em;
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #333;
}
.checkout .coupon-content {
  padding-bottom: 2rem;
}
.checkout .coupon-content p {
  margin-bottom: 1.9rem;
  line-height: 1.7;
}
.checkout .coupon-content .input-wrapper-inline {
  max-width: 40rem;
}
.checkout .btn-coupon {
  border-color: #eee;
  background-color: #eee;
  color: #333;
}
.checkout .btn-login:hover,
.checkout .btn-login:active,
.checkout .btn-login:focus,
.checkout .btn-coupon:hover,
.checkout .btn-coupon:active,
.checkout .btn-coupon:focus {
  background-color: #336699;
  border-color: #336699;
  color: #fff;
}
.checkout .form-control {
  margin-bottom: 1.3rem;
  padding-left: 2rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
}
.checkout .form-group label {
  display: inline-block;
  margin-bottom: 0.1rem;
  line-height: 2.1;
}
.checkout .form-group .select-box::before {
  font-size: 1.1rem;
  right: 1.4rem;
  margin-top: -0.2rem;
  color: #999;
}
.checkout .form-group select {
  padding-left: 1.4rem;
  max-width: 100%;
}
.checkout .custom-checkbox + label {
  padding-left: 2.8rem;
}
.checkout select {
  -webkit-appearance: none;
}
.checkout textarea {
  padding-top: 1.5rem;
  min-height: 13rem;
}
.checkout .order-summary-wrapper {
  padding: 2.5rem 3rem 3rem;
  border: 1px solid #eee;
  border-radius: 3px;
}
.checkout .order-summary-wrapper > .title {
  margin-bottom: 0.9rem;
  padding-bottom: 0;
}
.checkout .checkbox-content {
  border: none;
  padding: 0;
}
.checkout .form-control:focus {
  border-color: #999;
}

.order-table tr {
  border-bottom: 1px solid #eee;
}
.order-table th {
  text-align: start;
  padding: 0.9rem 0;
}
.order-table td {
  padding-left: 0;
  padding-right: 0;
}
.order-table b {
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  letter-spacing: 0;
  margin-bottom: 0;
}
.order-table td {
  padding-top: 1.5rem;
}
.order-table td:last-child {
  text-align: end;
}
.order-table .product-name {
  font-size: 1.4rem;
  font-weight: 400;
}
.order-table .product-name i {
  font-size: 11px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.order-table .product-total {
  font-size: 1.4rem;
  letter-spacing: -0.05em;
}
.order-table .shipping-methods .title {
  font-size: 1.5rem;
}
.order-table .cart-subtotal td {
  padding-top: 2rem;
  padding-bottom: 0.9rem;
}
.order-table .order-total th,
.order-table .order-total td {
  padding-top: 1.8rem;
  padding-bottom: 1.9rem;
}

.order-summary .payment-methods {
  padding-top: 2.5rem;
  border-bottom: 1px solid #eee;
}
.order-summary .payment-methods .title {
  font-size: 1.6rem;
}
.order-summary .card-header a {
  padding: 1rem 2.7rem;
  font-size: 1.4rem;
  color: #666;
  font-weight: 400;
}
.order-summary .card-header a::before {
  content: "";
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ccd;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.order-summary .card-header a::after {
  content: "";
  position: absolute;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #333;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.order-summary .card-header a.collapse::after {
  opacity: 1;
}
.order-summary .card-body {
  padding: 0 0 1.5rem;
}
.order-summary .card-body p {
  padding-left: 2.7rem;
  font-size: 1.4rem;
  line-height: 1.7;
  letter-spacing: -0.025em;
}

.payment-accordion {
  margin-bottom: 1.7rem;
}

.paypal-que {
  position: absolute;
  top: 1rem;
  left: 8rem;
}
.paypal-que:hover {
  text-decoration: underline;
}

@media (max-width: 479px) {
  .coupon-content .input-wrapper-inline {
    display: block;
  }
}
/*--------------------------
    Contact US
        - Icon Box
        - Contact Section
            - Contact Us Form
----------------------------*/
.contact-us .icon-box-content .icon-box-title {
  font-size: 1.8rem;
  line-height: 1.3;
  margin-bottom: 0.5rem;
  text-transform: none;
}
.contact-us .icon-box-content p {
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.contact-us .icon-box .icon-email {
  margin: 0.3rem 0 1.5rem;
}
.contact-us .icon-box .icon-headphone {
  margin-bottom: 2.3rem;
}
.contact-us .icon-box .icon-map-marker {
  margin-bottom: 1.9rem;
}
.contact-us .icon-box .icon-fax {
  margin: 0.4rem 0 2.2rem;
}
.contact-us .icon-box .w-icon-envelop-closed {
  font-size: 5.2rem;
}
.contact-us .icon-box .w-icon-headphone {
  font-size: 4.8rem;
}
.contact-us .icon-box .w-icon-map-marker {
  font-size: 5.1rem;
}
.contact-us .icon-box .w-icon-fax {
  font-size: 4.5rem;
}
.contact-us .description {
  margin-bottom: 4.9rem;
}

.contact-information-section {
  padding-bottom: 0.9rem;
}

.contact-section .card-header {
  font-size: 1.4rem;
  letter-spacing: 0;
}
.contact-section .card-header a {
  padding-top: 1.5rem;
}

.contact-us-form .form-group label {
  color: #333;
  line-height: 2;
}
.contact-us-form .form-group .form-control {
  margin-bottom: 1.4rem;
  min-height: 4.6rem;
  border-radius: 0.3rem;
}
.contact-us-form .form-group textarea.form-control {
  margin-bottom: 2.1rem;
}

.contact-google-map {
  background-color: #ddd;
}

/*------------------------
    Coming Soon page
--------------------------*/
.coming-content-wrapper {
  min-height: 100vh;
}

.coming-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 1rem;
  font-size: 1rem;
}
.coming-content .logo {
  display: inline-block;
  margin-bottom: 2.5rem;
}
.coming-content .coming-title {
  margin-bottom: 1rem;
  font-size: 8em;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
}
.coming-content .coming-title span {
  color: #1b1c25;
  -webkit-text-stroke: 1px #f79645;
}
.coming-content p {
  color: #999;
  font-size: 1.3rem;
  letter-spacing: 0;
  margin-bottom: 4.5rem;
  max-width: 55rem;
}
.coming-content p.copyright {
  font-size: 1.4rem;
}
.coming-content .countdown-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.coming-content .countdown-section {
  position: relative;
  letter-spacing: -0.025em;
}
.coming-content .countdown-section:not(:first-child) {
  margin-left: 3.5rem;
}
.coming-content .countdown-section:not(:first-child) .countdown-amount::before {
  content: ":";
  position: absolute;
  left: -2.2rem;
  color: #999;
  font-size: 3rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.coming-content .countdown-amount {
  display: inline-block;
  position: relative;
  padding: 0.62em 0.583em;
  border-radius: 3px;
  font-size: 3.6em;
  font-weight: 600;
  background-color: #333;
  color: #fff;
  line-height: 1;
  margin-bottom: 0.6rem;
}
.coming-content .countdown-period {
  display: block;
  position: absolute;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.4rem;
  color: #bbb;
  text-align: center;
}
.coming-content .social-icons {
  margin-bottom: 1.4rem;
}
.coming-content .social-icon {
  margin-right: 1rem;
}

.countdown-coming {
  margin-bottom: 6.8rem;
}

.coming-soon-bg {
  min-height: 100vh;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .coming-soon-bg {
    height: 1px;
  }
}

@media (max-width: 991px) {
  .coming-content {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    max-width: 100%;
    padding: 0 2rem;
    font-size: 0.9rem;
    text-align: center;
  }
  .coming-content .countdown-row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .coming-content p {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  .coming-content {
    font-size: 0.7rem;
  }
}
@media (max-width: 479px) {
  .coming-content {
    font-size: 0.5rem;
  }
}
/*-------------------------
   Compare page
-------------------------*/
.compare-popup {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  padding: 2rem 0;
  background: #fff;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 2400;
}
.compare-popup .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 2.1rem;
  padding-bottom: 3.1rem;
}
.compare-popup .btn-clean {
  margin-right: 1.8rem;
  padding: 0;
  border: none;
  text-transform: capitalize;
  font-weight: 400;
}
.compare-popup .btn-clean:hover,
.compare-popup .btn-clean:active,
.compare-popup .btn-clean:focus {
  color: #336699;
  background-color: #fff;
}
.compare-popup.show {
  -webkit-transform: translateY(-99%);
  transform: translateY(-99%);
}
.compare-popup.show + .compare-popup-overlay {
  opacity: 0.7;
  visibility: visible;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.compare-title {
  margin-top: 0.8rem;
}
.compare-title .title {
  margin-bottom: 0.3rem;
  font-size: 2.4rem;
  font-weight: 700;
  white-space: nowrap;
}

.compare-product-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0.9rem 2rem 0;
  padding: 0;
}
.compare-product-list li {
  position: relative;
  width: 8vw;
  max-width: 138px;
  min-height: 8vw;
}
.compare-product-list li:not(:first-child) {
  margin-left: 3rem;
}
.compare-product-list li:empty::before {
  content: "+";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 5rem;
  font-size: 3rem;
  color: #eee;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  -webkit-box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.12);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.12);
}
.compare-product-list li::after {
  content: "";
  position: absolute;
  border: 1px solid #eee;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.compare-product-list .btn-remove {
  position: absolute;
  padding: 0;
  right: -1rem;
  top: -1rem;
  line-height: 1.2;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  border-radius: 50%;
  border: none;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.compare-product-list .btn-remove i {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0;
}
.compare-product-list .btn-remove:hover,
.compare-product-list .btn-remove:active,
.compare-product-list .btn-remove:focus {
  background-color: #fff;
}

.compare-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 2399;
}

.compare-table {
  overflow-x: auto;
}
.compare-table > div:nth-child(2n) .compare-col {
  background-color: #fbfbfb;
}
.compare-table .compare-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.compare-product .product-media {
  border: 1px solid #f5f5f5;
}

.compare-col {
  padding: 2.5rem 0 2.5rem 2rem;
}
.compare-col.compare-product {
  text-align: center;
}
.compare-col.compare-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
  padding-right: 5rem;
  font-weight: 400;
  color: #999;
}
.compare-col.compare-value {
  padding-right: 1rem;
}
.compare-col .remove-product {
  margin-bottom: 2.4rem;
  padding: 0;
  border: none;
  color: #333;
}
.compare-col .remove-product i {
  font-size: 2.2rem;
}
.compare-col .remove-product:hover,
.compare-col .remove-product:focus,
.compare-col .remove-product:active {
  background-color: transparent;
  color: #336699;
}
.compare-col .product-details {
  padding: 1.6rem 0 0.7rem;
}
.compare-col .product-price {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0;
}
.compare-col .new-price {
  margin-right: 0;
  color: #336699;
}
.compare-col .old-price {
  color: #999;
}

.compare-availability .compare-value {
  color: #ffa800;
}

.compare-description ul {
  margin: 0;
  padding: 0;
}
.compare-description li {
  color: #333;
  line-height: 1.6;
}
.compare-description li::before {
  font-size: 1.4rem;
}

.compare-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.compare-rating .ratings-container {
  width: 100%;
  margin-bottom: 0;
  font-size: 1.4rem;
}

.compare-category .compare-value,
.compare-meta .compare-value,
.compare-size .compare-value,
.compare-brand .compare-value {
  color: #333;
}

.compare-color .swatch {
  vertical-align: middle;
  margin-right: 0.4rem;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  border-radius: 50%;
}

@media (min-width: 1921px) {
  .compare-product-list li {
    max-height: 15.5rem;
    max-width: none;
  }
  .compare-product-list li figure,
  .compare-product-list li img {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 991px) {
  .compare-popup .container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .compare-product-list {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 2rem 0;
  }
  .compare-product-list li {
    width: 18vw;
    min-height: 11rem;
  }
}
@media (max-width: 991) and (min-width: 480px) {
  .compare-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 767px) {
  .compare-col.compare-field {
    max-width: 15rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (max-width: 575px) {
  .compare-product-list li {
    min-height: 6rem;
  }
  .compare-product-list li:not(:first-child) {
    margin-left: 1.5rem;
  }
}
@media (max-width: 479px) {
  .compare-product-list li:empty::before {
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
    font-size: 2rem;
  }
}
/*--------------------------
    Error 404
--------------------------*/
.error-404 {
  padding-top: 14.5rem;
  padding-bottom: 10rem;
  background-color: #f7f7f7;
}
.error-404 figure {
  margin: 0 auto;
  max-width: 82rem;
}
.error-404 .banner-content {
  margin-top: 5.5rem;
}
.error-404 .banner-title {
  margin-bottom: 0.8rem;
}
.error-404 p {
  margin-bottom: 3.3rem;
}

/*-----------------------------------
    FAQs
------------------------------------*/
.faq .title {
  letter-spacing: 0.02em;
}
.faq .card-header {
  letter-spacing: -0.0125em;
}
.faq .card-body p {
  letter-spacing: -0.005em;
}

.content-title-section .subtitle {
  font-size: 1.4rem;
  letter-spacing: -0.025em;
  margin-bottom: 0.5rem;
}
.content-title-section .title {
  max-width: 55.5rem;
  margin: 0 auto 0.7rem;
  font-size: 2.4rem;
}
.content-title-section .description {
  margin-bottom: 4.8rem;
  font-size: 1.3rem;
}

/*----------------------------------------
    Icons
-----------------------------------------*/
.wolmart-icons {
  color: #333;
  font-size: 1.6rem;
  line-height: 2.5;
}
.wolmart-icons i,
.wolmart-icons .icon-name {
  display: inline-block;
  vertical-align: middle;
}
.wolmart-icons i {
  margin-right: 0.5rem;
}

.icon-page .element-section {
  padding-top: 0.7rem;
}

/*---------------------------------------
    Element Instagram Page
---------------------------------------*/
.instagram-masonry-grid .height-x1 {
  height: 240px;
}
.instagram-masonry-grid .height-x2 {
  height: 480px;
}
.instagram-masonry-grid .grid-item {
  padding: 1rem;
}
.instagram-masonry-grid img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.instagram-masonry-grid .instagram {
  height: 100%;
}

@media (max-width: 767px) {
  .instagram-masonry-grid .height-x1 {
    height: 200px;
  }
  .instagram-masonry-grid .height-x2 {
    height: 400px;
  }
}
/*---------------------------------
    My Account Page
----------------------------------*/
.my-account .breadcrumb-nav {
  margin-bottom: 3.2rem;
}
.my-account .tab-vertical .nav {
  width: 24.41%;
  border: none;
}
.my-account .tab-content {
  width: 75.59%;
  padding-left: 1.5rem;
  border: none;
}
.my-account .nav-link,
.my-account .link-item {
  margin-bottom: 0;
  padding: 1.7rem 0 1.6rem;
  font-size: 1.6rem;
  text-transform: none;
  border-bottom: 1px solid #eee;
}
.my-account .link-item {
  font-weight: 600;
  color: #333;
  line-height: 1;
}
.my-account .link-item a {
  color: inherit;
}
.my-account .link-item:hover {
  color: #336699;
}
.my-account .tab-pane p {
  font-size: 1.4rem;
  line-height: 1.8;
}
.my-account .icon-box.text-center i {
  display: block;
  font-size: 6rem;
  color: #333;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.my-account .text-center .icon-box-icon {
  margin-bottom: 1.9rem;
}
.my-account .icon-box.text-center:hover .icon-box-icon i {
  color: #336699;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.my-account .icon-box.text-center:hover p {
  text-decoration: underline;
}
.my-account .icon-box.icon-box-side .icon-orders,
.my-account .icon-box.icon-box-side .icon-map-marker {
  margin-right: 1rem;
}
.my-account .icon-box.icon-box-side .w-icon-download {
  font-size: 2.4rem;
}
.my-account .icon-box.icon-box-side .icon-account {
  margin-right: 0.8rem;
}
.my-account .form-control {
  -webkit-transition: border-color 0.4s;
  transition: border-color 0.4s;
}
.my-account .form-control:focus {
  border-color: #336699;
}
.my-account .icon-box-light {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.my-account .icon-box-light i {
  font-size: 2.5rem;
  color: #999;
}
.my-account .icon-box-light .icon-box-title {
  font-size: 2rem;
}
.my-account .order:not(th) {
  padding-top: 0.9rem;
}
.my-account .order:not(th) .order-table {
  padding: 1.1rem 2.9rem 0;
}

#account-dashboard.tab-pane {
  padding-top: 1.5rem;
}
#account-dashboard p.greeting {
  font-size: 1rem;
}
#account-dashboard p a:hover {
  text-decoration: underline;
}
#account-dashboard .icon-box {
  padding: 4rem 2rem;
  border: 1px solid #eee;
  border-radius: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
#account-dashboard .icon-box:hover {
  -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}
#account-dashboard .icon-box:hover p {
  color: #336699;
}
#account-dashboard .icon-box p {
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #666;
  -webkit-transition: color;
  transition: color;
}
#account-dashboard .icon-box-title {
  font-size: 1.8rem;
  text-transform: capitalize;
}

#account-downloads.tab-pane {
  padding-top: 0.9rem;
}
#account-downloads .icon-box-side .w-icon-download {
  font-size: 2.5rem;
  margin-top: -0.5rem;
}
#account-downloads .icon-box {
  margin-bottom: 1.4rem;
}

#account-orders.tab-pane {
  padding-top: 0.8rem;
}
#account-orders .icon-box {
  margin-bottom: 1.8rem;
}
#account-orders .account-orders-table th {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#account-orders .account-orders-table td {
  padding-top: 2.1rem;
  padding-bottom: 2.1rem;
}
#account-orders .account-orders-table .order-id {
  padding-left: 1rem;
  width: 20.77%;
}
#account-orders .account-orders-table .order-date {
  width: 20.1%;
}
#account-orders .account-orders-table .order-status {
  width: 20%;
}
#account-orders .account-orders-table .order-total {
  width: 25.48%;
}
#account-orders .account-orders-table .order-action {
  width: 13.78%;
  padding-right: 1rem;
}
#account-orders .account-orders-table td.order-total {
  letter-spacing: 0;
}
#account-orders .order-action .btn {
  color: #333;
  border-color: #ccc;
  -webkit-transition: color 0.4s, border-color 0.4s, background-color 0.4s;
  transition: color 0.4s, border-color 0.4s, background-color 0.4s;
  font-size: 1rem;
}
#account-orders .order-action .btn:hover {
  background-color: #333;
  border-color: #333;
  color: #fff;
}

#account-details.tab-pane {
  padding-top: 0.9rem;
}
#account-details.tab-pane .w-icon-user {
  margin-bottom: 0.3rem;
}
#account-details .icon-box {
  margin-bottom: 1.4rem;
}
#account-details .account-details-form label {
  color: #666;
  font-size: 1rem;
  line-height: 2.3;
}
#account-details .account-details-form .form-control {
  margin-bottom: 1.6rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  color: #666;
}
#account-details .account-details-form p {
  font-size: 1rem;
  letter-spacing: -0.015em;
  line-height: 2.1;
}
#account-details .card {
  border: 1px solid #eee;
}
#account-details .title-password {
  font-size: 1.8rem;
}

#account-addresses.tab-pane {
  padding-top: 0.8rem;
}
#account-addresses.tab-pane p {
  margin-bottom: 2.1rem;
  line-height: 1.6;
}
#account-addresses .title {
  font-size: 1.5rem;
  margin-bottom: 1.7rem;
  padding-bottom: 1.4rem;
}
#account-addresses .title-underline::after {
  background-color: #e5e5e5;
}
#account-addresses address {
  font-style: normal;
}
#account-addresses .address-table th,
#account-addresses .address-table td {
  padding: 0.3rem 0;
}
#account-addresses .address-table th {
  font-weight: normal;
  text-align: start;
  width: 100px;
  color: #999;
}
#account-addresses .address-table td {
  color: #333;
}

.account-order-table {
  font-size: 10px !important;
}

@media (max-width: 767px) {
  .my-account .tab-vertical .nav-tabs,
  .my-account .tab-vertical .tab-content {
    width: 100%;
  }

  #account-orders .account-orders-table td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    word-break: break-all;
  }
  #account-orders .account-orders-table .order-id {
    width: 10%;
    padding-left: 0;
  }
  #account-orders .order-action .btn {
    padding: 0;
    border: none;
    text-align: start;
  }
  #account-orders .order-action .btn:hover,
  #account-orders .order-action .btn:active,
  #account-orders .order-action .btn:focus {
    background-color: transparent;
    border: none;
    color: #336699;
  }
}
.login-page .login-popup {
  margin: 4.2rem auto 5rem;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-page .checkbox-round + label::before {
  border-radius: 50%;
  border: 1px solid #ccc;
  background: transparent;
}
.login-page .checkbox-round + label::after {
  content: "";
  width: 0.8rem;
  height: 0.8rem;
  background-color: #333;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5px;
  opacity: 0;
}
.login-page .checkbox-round.active + label::after {
  opacity: 1;
}
.login-page .login-vendor {
  display: none;
}

/*-------------------------
    Order Page
-------------------------*/
.order .shop-breadcrumb {
  margin-bottom: 2.2rem;
}
.order .order-table {
  padding: 0.6rem 3rem 3rem;
  border: 1px solid #e1e1e1;
  border-collapse: separate;
}
.order .order-table thead th {
  padding-bottom: 1.7rem;
  font-size: 1.8rem;
}
.order .order-table thead th,
.order .order-table tfoot th,
.order .order-table tfoot td {
  border-bottom: 1px solid #e1e1e1;
}
.order .order-table td {
  border: none;
}
.order .order-table tbody td:first-child {
  min-width: 16rem;
  padding-top: 3rem;
  padding-bottom: 0;
}
.order .order-table tbody a {
  color: #336699;
}
.order .order-table tbody a:hover {
  text-decoration: underline;
}
.order .order-table tbody strong {
  color: #333;
}
.order .order-table tfoot {
  font-size: 1.6rem;
}
.order .order-table tfoot th,
.order .order-table tfoot td {
  padding-top: 1.5rem;
  padding-bottom: 1.8rem;
  font-weight: 600;
}
.order .order-table tfoot th {
  color: #333;
}
.order .order-table .total td {
  font-weight: 700;
  font-size: 2rem;
  color: #333;
}
.order .alert {
  border: 1px dashed #e5e5e5;
}
.order .alert i {
  color: #799b5a;
}
.order .address-table td {
  color: #666 !important;
}
.order #billing-account-addresses {
  border-bottom: 1px solid #e1e1e1;
}
.order #billing-account-addresses .email td {
  padding-top: 3.5rem;
}
.order .btn-back {
  padding: 0.85em 1.4em;
}

.order-success {
  padding: 3.6rem 1.5rem;
  border: 2px solid #e1e1e1;
  font-size: 2.4rem;
}
.order-success i {
  font-size: 28px;
  vertical-align: middle;
  margin-right: 0.8rem;
}

.order-view {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 3.2rem 0;
}
.order-view li {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 1rem;
  text-align: center;
  font-size: 1.8rem;
}
.order-view strong {
  color: #333;
  display: block;
}

.order-subtable thead tr {
  border-bottom: 1px solid #e1e1e1;
}
.order-subtable thead th {
  padding-bottom: 2rem;
  font-size: 1.8rem;
  color: #333;
  text-align: start;
  font-weight: 600;
}
.order-subtable td {
  white-space: nowrap;
}
.order-subtable tbody td {
  border: none;
  padding: 1.5rem 0;
}
.order-subtable strong {
  display: block;
  color: #333;
}
.order-subtable .order {
  width: 12.5%;
}
.order-subtable .date {
  width: 21.13%;
}
.order-subtable .status {
  width: 14.11%;
}
.order-subtable .total {
  width: 32%;
  color: #333;
}
.order-subtable .action {
  text-align: end;
}
.order-subtable tbody tr:first-child td {
  padding-top: 3.4rem;
}
.order-subtable tbody .order {
  color: #336699;
}
.order-subtable tbody .btn {
  padding: 0.72em 1.2em;
  color: #333;
  border-color: #eee;
  background-color: #eee;
}
.order-subtable tbody .btn:hover,
.order-subtable tbody .btn:active,
.order-subtable tbody .btn:focus {
  color: #fff;
  border-color: #333;
  background-color: #333;
}
.order-subtable thead th:not(:last-child),
.order-subtable tbody td:not(:last-child) {
  padding-right: 1rem;
}

@media (max-width: 767px) {
  .order-view {
    display: block;
  }
  .order-view li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: start;
  }
  .order-view label {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    padding-right: 1rem;
  }
}
@media (max-width: 767px) {
  .order-subtable {
    display: block;
    overflow-x: auto;
    min-width: 100%;
  }
}
@media (max-width: 479px) {
  .order-view li {
    font-size: 1.5rem;
  }

  .order .order-table {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
/*------------------------------------
    Element Product Page
-------------------------------------*/
.product-list-section {
  margin-bottom: 4.2rem;
}
.product-list-section .title-link-wrapper .title::after {
  height: 2px;
  background-color: #666;
}

.product-wrapper.row [class*="col-"] {
  margin-bottom: 2rem;
}

.product:not(.product-single) .product-countdown-container {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  white-space: nowrap;
  overflow-x: auto;
  background-color: rgba(34, 34, 34, 0.8);
  color: #fff;
  letter-spacing: -0.025em;
  text-align: center;
  border-radius: 0.3rem;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.product:not(.product-single) .countdown-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.product:not(.product-single) .countdown-section {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.9em 0 0.8em;
}
.product:not(.product-single) .countdown-amount,
.product:not(.product-single) .countdown-period {
  display: inline-block;
}
.product:not(.product-single) .countdown-amount {
  font-size: 1.4em;
  font-weight: 700;
  line-height: 1.3;
}
.product:not(.product-single) .countdown-period {
  font-size: 1em;
  font-weight: 500;
  opacity: 0.9;
  letter-spacing: -0.035em;
}
.product:not(.product-single):hover .product-countdown-container {
  opacity: 0;
  visibility: hidden;
}
.product:not(.product-single) .product-list .product-desc {
  margin-bottom: 3.9rem;
  line-height: 1.9;
}

.product-sticky-content .product-list-sm {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-sticky-content .product-media {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.5rem;
  flex: 0 0 8.5rem;
  max-width: 8.5rem;
  height: 8.5rem;
}
.product-sticky-content .product-media img {
  height: 100%;
}
.product-sticky-content .product-name {
  font-size: 1.4rem;
}
.product-sticky-content .product-price {
  font-size: 1.6rem;
}
.product-sticky-content .old-price {
  font-size: 1.6rem;
}
.product-sticky-content .product-form.container {
  padding: 0;
}
.product-sticky-content.fixed .product-list-sm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.product-sticky-content.fixed .product-form {
  padding: 2rem;
}
.product-sticky-content.fixed .product-qty-form,
.product-sticky-content.fixed .btn-cart {
  margin-bottom: 0;
}
.product-sticky-content.fixed .product-qty-form {
  margin-right: 0.8rem;
  max-width: 14rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 14rem;
  flex: 0 0 14rem;
}
.product-sticky-content.fixed .product-qty-form label {
  display: none;
}
.product-sticky-content.fixed .btn-cart {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 21rem;
}
.product-sticky-content.fix-top.fixed .product-form {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.product-sticky-content.fix-top.fixed .product-media {
  max-width: 4.4rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 4.4rem;
  flex: 0 0 4.4rem;
  height: 4.4rem;
}
.product-sticky-content.fix-top.fixed .product-name {
  display: none;
}
.product-sticky-content.fix-top.fixed .btn-cart {
  min-width: 14rem;
}

.product-single .col-md-6 .product-bm-wrapper {
  margin-bottom: 0.8rem;
}

.sticky-both-content .product-single {
  margin-top: 0.4rem;
}
.sticky-both-content .product-title {
  margin-bottom: 1.2rem;
  line-height: 1.4;
}
.sticky-both-content .product-color-swatch {
  margin-top: 0.3rem;
}
.sticky-both-content .product-size-swatch {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.product-qty-form.with-label {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 21.5rem;
  flex: 0 0 21.5rem;
  max-width: 21.5rem;
}

@media (max-width: 1199px) {
  .shop-content .product-list .product-desc {
    margin-bottom: 2rem;
  }
}
@media (max-width: 575px) {
  .shop-content .product-list {
    display: block;
    margin-bottom: 2rem;
  }
  .shop-content .product-list .product-media {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 2rem 0;
  }
}
@media (max-width: 479px) {
  .product-sticky-content.fix-top.fixed .product-media,
  .product-sticky-content.fix-top.fixed .product-details {
    display: none;
  }
  .product-sticky-content.fix-top.fixed .btn-cart {
    max-width: none;
  }
  .product-sticky-content.fix-top.fixed .product-form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .product-sticky-content.fixed .product-list-sm {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
}
/*--------------------------------------------
    Single Product Page
----------------------------------------------*/
.product-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: sticky;
}
.product-gallery.row > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-gallery.product-gallery-sticky {
  top: 2rem;
}

.product-gallery-btn {
  position: absolute;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  right: 1.5rem;
  bottom: 1.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  border-radius: 50%;
  color: #999;
  background: #fff;
  font-size: 2rem;
  line-height: 1;
  opacity: 0.7;
  -webkit-transition: opacity 0.3s, color 0.3s;
  transition: opacity 0.3s, color 0.3s;
  z-index: 1;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.product-gallery-btn.product-degree-viewer {
  bottom: 6.5rem;
}
.product-gallery-btn.product-video-viewer {
  bottom: 11.5rem;
}
.product-gallery-btn:hover {
  opacity: 1;
  color: #333;
}

.product-sticky-content.fixed {
  background-color: #fff;
}

.bought-together-products > * {
  width: auto;
}
.bought-together-products .product-wrap {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15.8rem;
  flex: 0 0 15.8rem;
  max-width: 15.8rem;
}
.bought-together-products .product-wrap:not(:first-child) {
  margin-left: 3rem;
}
.bought-together-products
  .product-wrap:not(:first-child)
  .product-media::before,
.bought-together-products
  .product-wrap:not(:first-child)
  .product-media::after {
  content: "";
  position: absolute;
  left: -2.5rem;
  top: 50%;
  background-color: #999;
}
.bought-together-products
  .product-wrap:not(:first-child)
  .product-media::before {
  width: 17px;
  height: 1px;
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}
.bought-together-products
  .product-wrap:not(:first-child)
  .product-media::after {
  width: 1px;
  height: 17px;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
.bought-together-products .product-details {
  padding: 1.5rem 0.2rem 0;
}
.bought-together-products .product-button {
  position: relative;
  margin: 1.3rem 0 2.5rem 10rem;
}
.bought-together-products .product-button::before,
.bought-together-products .product-button::after {
  content: "";
  position: absolute;
  left: -5rem;
  top: 70px;
  width: 17px;
  height: 1px;
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
  background-color: #999;
}
.bought-together-products .product-button::before {
  margin-top: -12px;
}
.bought-together-products .bought-price {
  font-size: 2.4rem;
}
.bought-together-products .bought-count {
  margin-bottom: 2.7rem;
  line-height: 1.3;
}
.bought-together-products .product-media:hover img {
  opacity: 1;
}
.bought-together-products .product-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bought-together-products .product-checkbox label {
  position: static;
  padding-left: 0;
}
.bought-together-products .custom-checkbox {
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.bought-together-products .custom-checkbox + label::before {
  top: 1.5rem;
  left: 1.5rem;
  -webkit-transform: none;
  transform: none;
}

.product-tabs .nav-item:not(:last-child) {
  margin-right: 4rem;
}
.product-tabs .nav-link {
  padding: 1.5rem 0 1.3rem;
  font-size: 2rem;
  font-weight: 700;
  color: #999;
  text-transform: capitalize;
}
.product-tabs .nav-link::after {
  border-top: 2px solid transparent;
}
.product-tabs .nav-tabs .nav-link:hover,
.product-tabs .nav-tabs .nav-link.active {
  color: #333;
}
.product-tabs .tab-pane {
  padding: 3.5rem 0 2.7rem;
}
.product-tabs ul {
  font-size: 1.4rem;
  color: #666;
}
.product-tabs .product-video img {
  min-height: 20rem;
}

#product-tab-description .sub-title {
  margin: 0.4rem 0 0.4rem;
  font-size: 1.4rem;
}
#product-tab-description .sub-title span {
  margin-right: 1.2rem;
}
#product-tab-description .detail {
  line-height: 1.6;
}
#product-tab-description ul {
  font-size: 1.3rem;
}
#product-tab-description.card-body .col-md-6 {
  margin-bottom: 1.7rem;
}

#product-tab-specification ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 0rem 0 1rem;
}
#product-tab-specification ul label {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20rem;
  flex: 0 0 20rem;
  max-width: 20rem;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  z-index: 1;
  color: #333;
}
#product-tab-specification ul p {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 0;
}

#product-tab-vendor .vendor-banner img {
  min-height: 29.5rem;
  width: 100%;
}
#product-tab-vendor .vendor-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1.7rem;
}
#product-tab-vendor .vendor-logo img {
  border: 1px solid #eee;
  border-radius: 5px;
}
#product-tab-vendor .vendor-name {
  margin: 0.3rem 0;
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
}
#product-tab-vendor .vendor-name a {
  color: inherit;
}
#product-tab-vendor .ratings-full {
  margin-right: 0.8rem;
}
#product-tab-vendor .rating-reviews {
  font-size: 1.4rem;
  color: #666;
}
#product-tab-vendor .vendor-info {
  margin-bottom: 3.1rem;
  font-size: 1.4rem;
}
#product-tab-vendor .vendor-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75rem 0;
}
#product-tab-vendor .vendor-info label {
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12rem;
  flex: 0 0 12rem;
  max-width: 12rem;
  padding-right: 1rem;
  color: #333;
}
#product-tab-vendor .store-address {
  font-size: 1.4rem;
  color: #666;
  opacity: 1;
}
#product-tab-vendor .store-phone a {
  color: #333;
}
#product-tab-vendor .store-phone a:hover {
  color: #336699;
}
#product-tab-vendor p {
  font-size: 1.4rem;
  line-height: 1.75;
}

#product-tab-description.card-body,
#product-tab-vendor.card-body,
#product-tab-specification.card-body,
#product-tab-reviews.card-body {
  padding-top: 2.2rem;
  padding-bottom: 2rem;
}

.accordion-simple:not(.accordion-icon) .card-header a::after {
  font-size: 1.6rem;
}

@media (min-width: 768px) {
  #product-tab-vendor .pl-md-6 {
    padding-left: 3rem !important;
  }
}
#product-tab-reviews {
  border-bottom-width: 0;
}
#product-tab-reviews p {
  font-size: 1.4rem;
}
#product-tab-reviews .avg-rating-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 0.2rem;
}
#product-tab-reviews .avg-mark {
  font-size: 6em;
  font-weight: 600;
  color: #336699;
  letter-spacing: -0.025em;
  line-height: 1;
  margin-bottom: 0;
}
#product-tab-reviews .avg-rating {
  margin-left: 1.8rem;
}
#product-tab-reviews .avg-rating p {
  font-size: 1.4rem;
  line-height: 1.4;
}
#product-tab-reviews .avg-rating .ratings-container {
  margin-bottom: 0.3rem;
}
#product-tab-reviews .avg-rating .ratings-full {
  margin-right: 0.8rem;
}
#product-tab-reviews .ratings-value {
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
}
#product-tab-reviews .ratings-value span:first-child {
  margin-right: 2.1rem;
  font-size: 2.5rem;
}
#product-tab-reviews .ratings-value .count {
  margin-left: 0.6rem;
  font-size: 1.2rem;
  color: #aaa;
}
#product-tab-reviews .ratings-list .ratings-container {
  margin-bottom: 1.5rem;
}
#product-tab-reviews .ratings-list .progress-bar {
  max-width: 20rem;
  margin-left: 1.3rem;
  margin-bottom: 0;
  border-radius: 0.4rem;
}
#product-tab-reviews .ratings-list .progress-bar span {
  border-radius: 0.4rem;
}
#product-tab-reviews .ratings-list .progress-value {
  margin-left: 2rem;
  min-width: 4rem;
}
#product-tab-reviews .ratings-list .progress-value mark {
  font-size: 1.2rem;
  color: #666;
}
#product-tab-reviews .title-link {
  font-size: 1.4rem;
}
#product-tab-reviews .review-form {
  padding-top: 0.3rem;
}
#product-tab-reviews .review-form .form-control {
  min-height: 4rem;
  margin-bottom: 2rem;
  font-family: Poppins, sans-serif;
  font-size: 1.3rem;
  border-color: #eee;
  color: #777;
}
#product-tab-reviews .review-form .form-control::-webkit-input-placeholder {
  color: inherit;
}
#product-tab-reviews .review-form .form-control::-moz-placeholder {
  color: inherit;
}
#product-tab-reviews .review-form .form-control:-ms-input-placeholder {
  color: inherit;
}
#product-tab-reviews .review-form .form-control::-ms-input-placeholder {
  color: inherit;
}
#product-tab-reviews .review-form .form-control::placeholder {
  color: inherit;
}
#product-tab-reviews .review-form textarea {
  height: 13.3rem;
}
#product-tab-reviews .review-form .btn {
  background-color: #454545;
  border-color: #454545;
}
#product-tab-reviews .form-group {
  margin: 0.4rem 0 4.1rem;
  font-size: 1.4rem;
}
#product-tab-reviews .form-group label {
  padding-left: 2.7rem;
}
#product-tab-reviews .form-group .custom-checkbox + label::before {
  top: 0;
  -webkit-transform: none;
  transform: none;
  margin-top: 0.2rem;
}
#product-tab-reviews .tab-nav-boxed .nav-item {
  margin: 0 1rem 0.5rem 1rem;
}
#product-tab-reviews .tab-nav-boxed .nav-link {
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  border-width: 1px;
  padding: 0.9em 1.5em;
  border-radius: 2rem;
}
#product-tab-reviews .tab-nav-boxed .nav-link::after {
  content: none;
}
#product-tab-reviews .tab-nav-boxed .nav-link:hover,
#product-tab-reviews .tab-nav-boxed .nav-link.active {
  color: #336699;
}
#product-tab-reviews .tab-nav-boxed .tab-pane {
  padding: 1.5rem 0 0;
}

.vendor-product-section {
  margin-bottom: 4.2rem;
}

.vendor-product-section .ratings-container,
.related-product-section .ratings-container {
  margin-bottom: 0.3rem;
}

.product-single .product-countdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -0.7rem 0 1.3rem;
  font-size: 1.5rem;
}
.product-single .product-countdown-container .countdown-amount {
  font-weight: 700;
  color: #336699;
}

.description-section .title.title-link::before,
.review-section .title.title-link::before {
  height: 0.2rem;
}

aside .service-list {
  padding: 0 2rem;
  border: 1px solid #eee;
}
aside .service-list > * {
  padding: 1.6rem 0;
}
aside .service-list > *:not(:last-child) {
  border-bottom: 1px solid #eee;
}
aside .service-list .icon-box svg {
  width: 3rem;
  height: 3rem;
}
aside .service-list .icon-box-title {
  font-size: 1.3rem;
  line-height: 1.3;
  letter-spacing: 0;
}
aside .service-list p {
  font-size: 1.2rem;
  line-height: 1.2;
}
aside .sp-sidebar-banner .banner-content {
  top: 9%;
  left: 6.5%;
}
aside .sp-sidebar-banner .banner-title,
aside .sp-sidebar-banner .banner-subtitle {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  margin-bottom: 0.5rem;
}
aside .sp-sidebar-banner span {
  font-size: 2.4rem;
  margin-right: 0.5rem;
}

@media (min-width: 992px) {
  .sticky-both-content .product-details-1 {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}
.hotspot-banner {
  overflow: visible;
}

.hotspot-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.hotspot-product .product {
  position: absolute;
  opacity: 0;
  width: 20em;
  padding: 1rem;
  visibility: hidden;
  background: #fff;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  overflow: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s, z-index 0.3s,
    -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, z-index 0.3s,
    -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s, z-index 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s, z-index 0.3s,
    -webkit-transform 0.3s;
  z-index: -1;
}
.hotspot-product .product .product-action-vertical {
  opacity: 1;
  visibility: visible;
}
.hotspot-product:hover {
  background-color: #333;
}
.hotspot-product:hover .btn-hotspot {
  color: #fff;
}
.hotspot-product:hover .product {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
.hotspot-product.hotspot-product-1 {
  left: 22.6%;
  top: 10.9%;
}
.hotspot-product.hotspot-product-2 {
  left: 23.8%;
  top: 82%;
}
.hotspot-product.hotspot-product-3 {
  left: 71.1%;
  top: 36.1%;
}

.hotspot-right .product {
  left: 100%;
  top: -6rem;
}
.hotspot-right:hover .product {
  -webkit-transform: translateX(1rem);
  transform: translateX(1rem);
}

.hotspot-top .product {
  bottom: 100%;
  left: -40px;
}
.hotspot-top:hover .product {
  -webkit-transform: translateY(-1rem);
  transform: translateY(-1rem);
}

.hotspot-left .product {
  right: 100%;
  top: -60px;
}
.hotspot-left:hover .product {
  -webkit-transform: translateX(-1rem);
  transform: translateX(-1rem);
}

.btn-hotspot {
  font-size: 1.2em;
  line-height: 1;
  color: #333;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.pswp img {
  border-radius: 0.3rem;
}

@media (min-width: 768px) {
  body.addtocart-fixed .footer {
    padding-bottom: 12.5rem;
  }
}
@media (max-width: 767px) {
  .hotspot-banner {
    font-size: 0.8rem;
  }
}
@media (max-width: 575px) {
  .bought-together-products .product-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12rem;
    flex: 0 0 12rem;
    max-width: 12rem;
  }
  .bought-together-products .product-button {
    margin: -1rem 0 3rem 3rem;
  }
  .bought-together-products .product-button::before,
  .bought-together-products .product-button::after {
    left: -1.5rem;
  }
}
@media (max-width: 479px) {
  .hotspot-banner {
    font-size: 0.6rem;
  }
}
@media (max-width: 375px) {
  #product-tab-specification ul li::before {
    width: calc(44% - 1rem);
  }
  #product-tab-specification ul label {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 44%;
    flex: 0 0 44%;
    max-width: 44%;
  }
}
/*-----------------------------------------------
    Shop Page
        - Default Shop Banner
        - List
        - Category Wrapper
        - Toolbox
            (toolbox toolbox-left toolbox-right select-box)
        - Toolbox Item
        - Select Menu
        - Selected Items
        - Toolbox Pagination
        - Toolbox Horizontal
        - Toolbox Navigation
------------------------------------------------*/
.shop-default-banner {
  padding: 7.4em 8.7em 7.6em;
}
.shop-default-banner .banner-subtitle {
  margin-bottom: 0.2rem;
  font-size: 2.8em;
}
.shop-default-banner .banner-title {
  margin-bottom: 1.9rem;
  font-size: 4em;
}
.shop-default-banner .btn {
  padding: 1.25em 1.98em;
  font-size: 1.4em;
}
.shop-default-banner .btn i {
  font-size: 1.7rem;
}

.shop-boxed-banner {
  padding-left: 4.8em;
  padding-right: 4.8em;
}
.shop-boxed-banner .banner-subtitle {
  letter-spacing: -0.02em;
}

.shop-default-brands {
  padding: 0.5rem 0;
}

.shop-default-category {
  border-bottom: 1px solid #eee;
  padding-bottom: 3.5rem;
}
.shop-default-category .category-name {
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0;
}
.shop-default-category .category-media {
  border: none;
  -webkit-transition: -webkit-box-shadow 0.3s, -webkit-transform 0.3s;
  transition: -webkit-box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, -webkit-box-shadow 0.3s,
    -webkit-transform 0.3s;
}
.shop-default-category .category-ellipse:hover .category-media {
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.shop-default-category .category-ellipse:hover .category-name {
  color: #336699;
}

.product-lists .product-list {
  border-top: 1px solid #eee;
}
.product-lists .product-list .product-short-desc {
  margin-bottom: 1.5rem;
}
.product-lists .product-wrap:last-child .product-list {
  margin-bottom: 0;
}

.toolbox,
.toolbox-right,
.toolbox-left,
.toolbox-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.toolbox label,
.toolbox-right label,
.toolbox-left label,
.toolbox-item label {
  font-weight: 600;
  margin-right: 1rem;
  line-height: 1.5;
}

.toolbox-right {
  margin-left: auto;
}

.toolbox {
  padding-bottom: 1rem;
}
.toolbox.toolbox-pagination {
  padding: 2.5rem 0;
  border-top: 1px solid #f4f4f4;
}
.toolbox > :not(.toolbox-pagination)*:not(:last-child),
.toolbox-left > :not(.toolbox-pagination)*:not(:last-child),
.toolbox-right > :not(.toolbox-pagination)*:not(:last-child) {
  margin-right: 1rem;
}
.toolbox .showing-info {
  font-size: 1.3rem;
  color: #aaa;
}
.toolbox .showing-info span {
  margin: 0 0.9rem;
  color: #333;
}
.toolbox .select-box {
  color: #333;
}
.toolbox .select-box::before {
  font-size: 1.1rem;
  right: 1rem;
  margin-top: 0.1rem;
}
.toolbox select {
  max-width: none;
  width: 15.4rem;
  padding: 0.75em 2.7rem 0.75em 1.4rem;
  border-radius: 0.3rem;
  cursor: pointer;
  border: 1px solid #d7d7d7;
}
.toolbox .toolbox-show select {
  padding-left: 1.3rem;
  padding-right: 2.8rem;
  width: 9.4rem;
}
.toolbox .item-found {
  color: #a9a9a9;
  font-size: 1.4rem;
  letter-spacing: 0;
}
.toolbox .item-found span {
  color: #333;
  margin-right: 0.4rem;
}
.toolbox .left-sidebar-toggle,
.toolbox .right-sidebar-toggle {
  padding: 0.78em 1.05em;
  font-size: 1.3rem;
}
.toolbox .left-sidebar-toggle i,
.toolbox .right-sidebar-toggle i {
  margin-right: 0.7rem;
  margin-left: 0.3rem;
  font-size: 1.4rem;
}
.toolbox .left-sidebar-toggle {
  margin: 0 2rem 1rem 0;
}
.toolbox .right-sidebar-toggle {
  margin-left: 2rem;
}

.toolbox-item {
  margin-bottom: 1rem;
}

.sticky-toolbox.fixed {
  background: #fff;
  padding: 1rem 2rem 0 !important;
}

.selected-items {
  display: none;
}
.toolbox + .selected-items {
  font-size: 1.2rem;
}
.selected-items > * {
  display: inline-block;
  margin: 0 0.8rem 0.5rem 0;
}
.selected-items .filter-clean {
  margin-left: 1.2rem;
}

.selected-item {
  line-height: 1;
  letter-spacing: 0;
  background-color: #f5f5f5;
  color: #666;
  padding: 8px 1rem;
}
.selected-item i {
  margin-left: 1.1rem;
  vertical-align: middle;
}

.toolbox-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 1.9rem;
}

.btn-layout {
  line-height: 1;
  color: #dadada;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}
.btn-layout + .btn-layout {
  margin-left: 1rem;
}
.btn-layout i {
  font-size: 1.9rem;
  color: inherit;
}
.btn-layout.active,
.btn-layout:hover {
  color: #333;
}

@media (max-width: 991px) {
  .shop-content .main-content {
    width: 100%;
  }

  .toolbox .sidebar-fixed {
    margin-right: 0;
  }
  .toolbox .sidebar-content {
    display: block;
  }
}
@media (max-width: 767px) {
  .shop-default-banner {
    padding: 5em;
    font-size: 0.9rem;
  }
}
@media (max-width: 575px) {
  .shop-default-banner {
    padding: 4rem 3rem;
    font-size: 0.8rem;
  }

  .toolbox-left label,
  .toolbox-right .toolbox-layout {
    display: none;
  }

  .toolbox-right .toolbox-item.toolbox-show {
    margin-right: 0;
  }

  .toolbox-pagination {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .product-lists .product-list {
    border-top: 0;
  }

  .toolbox .left-sidebar-toggle {
    padding: 0.79em 0.83em;
  }
  .toolbox .left-sidebar-toggle i {
    margin: 0;
  }
  .toolbox .left-sidebar-toggle span {
    display: none;
  }
}
@media (max-width: 479px) {
  .shop-default-banner {
    font-size: 0.7rem;
  }

  .sticky-toolbox.fixed {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .toolbox .left-sidebar-toggle {
    margin-right: 1rem;
  }
  .toolbox select {
    max-width: 13.6rem;
  }
}
/*----------------------------------------
    Wishlist Page
----------------------------------------*/
.wishlist-page .wishlist-title {
  margin-bottom: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.015em;
}
.wishlist-page .social-links {
  margin-bottom: 2.8rem;
  padding: 2.2rem 0;
}
.wishlist-page .social-links label {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
  margin-right: 1.6rem;
  letter-spacing: -0.01em;
}
.wishlist-page .social-icon {
  margin-right: 0.8rem;
}

.shop-table {
  text-align: start;
}
.shop-table tbody {
  border-bottom: 1px solid #eee;
}
.shop-table td {
  padding: 2rem 0 2rem 0;
  border-top: 1px solid #eee;
  font-size: 1rem;
}
.shop-table tbody td:not(:first-child),
.shop-table thead th:not(:first-child) {
  padding-left: 1rem;
}
.shop-table .product-thumbnail {
  width: 11rem;
  padding-right: 1rem;
}
.shop-table .product-name {
  white-space: normal;
  word-break: break-word;
}
.shop-table .product-thumbnail a {
  position: relative;
}
.shop-table th {
  padding: 1.7rem 0 1.3rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  text-transform: capitalize;
  line-height: 1.6;
  letter-spacing: 0;
}
.shop-table img {
  display: block;
  max-width: 100px;
}
.shop-table .product-thumbnail > div {
  display: block;
  max-width: 10rem;
  width: 100%;
}
.shop-table .btn-close {
  position: absolute;
  padding: 0;
  background: #fff;
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  top: -14px;
  right: -8px;
}
.shop-table .btn-close i {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  margin-left: 0;
}
.shop-table .btn-close:hover {
  background-color: #fff;
}
.shop-table .btn-close:hover i {
  color: #336699;
}

.wishlist-table .new-price {
  color: #333;
}
.wishlist-table .wishlist-in-stock {
  color: #336699;
}
.wishlist-table .wishlist-out-stock {
  color: #f93;
}
.wishlist-table .btn-sm {
  padding: 0.77em 2.07em;
}
.wishlist-table .btn-quickview {
  padding-top: 0.85em;
  padding-bottom: 0.85em;
  border: 1px solid #ccc;
  color: #333;
}
.wishlist-table .btn-quickview:hover {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.wishlist-table .product-price {
  width: 15.17%;
}
.wishlist-table .product-stock-status {
  width: 19.84%;
}
.wishlist-table .wishlist-action {
  width: 24.19%;
}

@media (max-width: 767px) {
  .shop-table:not(.account-orders-table) {
    text-align: center;
  }
  .shop-table:not(.account-orders-table) thead {
    display: none;
  }
  .shop-table:not(.account-orders-table) tbody {
    border: 1px solid #eee;
  }
  .shop-table:not(.account-orders-table) tbody,
  .shop-table:not(.account-orders-table) tr,
  .shop-table:not(.account-orders-table) td {
    display: block;
  }
  .shop-table:not(.account-orders-table) tr {
    position: relative;
    padding: 4.2rem 2rem 4rem;
  }
  .shop-table:not(.account-orders-table) tr + tr {
    border-top: 1px solid #eee;
  }
  .shop-table:not(.account-orders-table) td {
    padding: 0;
    border: none;
  }
  .shop-table:not(.account-orders-table) td.product-thumbnail,
  .shop-table:not(.account-orders-table) .product-quantity {
    margin-bottom: 1rem;
    width: auto;
  }
  .shop-table:not(.account-orders-table) td.product-thumbnail {
    padding-right: 0;
  }
  .shop-table:not(.account-orders-table) .product-name,
  .shop-table:not(.account-orders-table) .product-price,
  .shop-table:not(.account-orders-table) .product-stock-status {
    margin-bottom: 1rem;
  }
  .shop-table:not(.account-orders-table) .product-thumbnail > div,
  .shop-table:not(.account-orders-table) td {
    margin-left: auto;
    margin-right: auto;
  }
  .shop-table:not(.account-orders-table) .product-thumbnail > div {
    max-width: 30rem;
  }
  .shop-table:not(.account-orders-table) .product-thumbnail > div img {
    max-width: 100%;
  }
  .shop-table:not(.account-orders-table) .product-stock-status,
  .shop-table:not(.account-orders-table) .wishlist-action {
    width: auto;
  }
  .shop-table:not(.account-orders-table) tbody td:not(:first-child),
  .shop-table:not(.account-orders-table) thead th:not(:first-child) {
    padding-left: 0;
  }
}
/*----------------------------------------
    Vendor Dokan Store Grid Page
-----------------------------------------*/
.vendor-toolbox .toolbox-sort select {
  width: 15.2rem;
  padding: 0.61em 2.5rem 0.61em 1.5rem;
  font-size: 1.4rem;
  border-color: #edecec;
}
.vendor-toolbox .select-box::before {
  right: 1rem;
  margin-top: -0.1rem;
}

.vendor-map-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #f5f5f5;
  padding: 2rem 2rem 1rem;
}
.vendor-map-filter .form-control {
  margin-right: 1rem;
  background: #fff;
  border: none;
  min-height: 4.4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.vendor-map-filter input {
  width: 58.9rem;
}
.vendor-map-filter .select-box::before {
  z-index: 2;
  font-size: 1.2rem;
  right: 2.3rem;
}
.vendor-map-filter select {
  min-width: 22rem;
  font-size: 1.4rem;
  color: #333;
}
.vendor-map-filter select option {
  font-size: 1.4rem;
}
.vendor-map-filter .btn {
  padding: 0.93em 2.97em;
}

.vendor-filter,
.vendor-filter-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.vendor-filter {
  padding: 3rem 0 1rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #eee;
}
.vendor-filter .select-box::before {
  font-weight: 400;
  right: 1.4rem;
  margin-top: -0.1rem;
}
.vendor-filter select {
  font-size: 1.3rem;
  border-color: #ccc;
  border-radius: 0.3rem;
  color: #333;
  padding-left: 1.5rem;
  min-width: 19rem;
  max-width: none;
}
.vendor-filter select option {
  font-size: 1.4rem;
}
.vendor-filter .btn {
  padding: 0.81em 1.4em;
  background-color: #eee;
  border-color: #eee;
  font-size: 1.3rem;
  color: #333;
}
.vendor-filter .btn:hover,
.vendor-filter .btn:focus,
.vendor-filter .btn:active {
  background-color: #dcdcdc;
  border-color: #dcdcdc;
}

.store-wcmp .store-banner img {
  min-height: 31.8rem;
}

.store-wcmp-banner {
  margin-bottom: 3.5rem;
}
.store-wcmp-banner .store-media img {
  min-height: 39rem;
}

.tab-vendor-products.tab-nav-underline .nav-link {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.tab-vendor-products.tab-nav-underline .nav-link::after {
  border-top-width: 2px;
}
.tab-vendor-products.tab-nav-underline .nav-item:not(:last-child) {
  margin-right: 1rem;
}
.tab-vendor-products .toolbox {
  padding: 0.7rem 0 1rem;
}
.tab-vendor-products .toolbox label {
  font-size: 1.4rem;
}
.tab-vendor-products .comments {
  margin: 0;
  padding: 0;
}
.tab-vendor-products .comment {
  border: none;
  padding-top: 2rem;
  font-size: 1.4rem;
}
.tab-vendor-products .comment p {
  margin-bottom: 0;
}
.tab-vendor-products .ratings-container {
  font-size: 1.4rem;
}
.tab-vendor-products .ratings-container .ratings::before {
  color: #ff7272;
}
.tab-vendor-products .comment-author {
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
}
.tab-vendor-products .comment-date {
  font-size: 1.4rem;
  color: #666;
}

.review-title {
  font-size: 1.8rem;
}

.widget-search-products .input-wrapper-inline .form-control {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 9.3rem);
  flex: 0 0 calc(100% - 9.3rem);
  max-width: calc(100% - 9.3rem);
}

.store-wcfm-banner {
  margin-bottom: 2.7rem;
}
.store-wcfm-banner .store-media img {
  min-height: 46rem;
}

.widget-coupons .coupon {
  padding: 1.2rem 2rem;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 3px;
  font-weight: 600;
  display: inline-block;
  border: 1px dashed #666;
}
.widget-coupons .coupon:hover .coupon-tip {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, 2rem);
  transform: translate(-50%, 2rem);
}
.widget-coupons .coupon-tip {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 15.8rem;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #17a2b8;
  background-color: #fff;
  border-radius: 0.5rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s,
    -webkit-transform 0.3s;
  z-index: 1;
}
.widget-coupons .coupon-tip::before,
.widget-coupons .coupon-tip::after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.widget-coupons .coupon-tip::after {
  border: 3px solid transparent;
  border-bottom: 7px solid #fff;
}
.widget-coupons .coupon-tip::before {
  border: 4px solid transparent;
  border-bottom: 8px solid #17a2b8;
}

.vendor-sidebar .widget-title i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-top: -0.5rem;
  line-height: 0;
}
.vendor-sidebar .widget-title i.w-icon-truck {
  font-size: 2rem;
}

.widget-location .google-map {
  height: 28rem;
  background-color: #ddd;
}

.policies-area {
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #eee;
}
.policies-area .title {
  font-weight: 600;
  margin-bottom: 3rem;
}
.policies-area p {
  padding-bottom: 2.1rem;
}

.tab-vendor-wcfm .nav-link {
  padding: 1.5rem 0;
  font-size: 1.5rem;
  text-transform: capitalize;
}
.tab-vendor-wcfm .tab-pane {
  padding: 3rem 0;
}

.review-area {
  margin-bottom: 3rem;
  padding: 3rem;
  border: 1px solid #eee;
  border-radius: 0.3rem;
}
.review-area .form-control {
  margin-bottom: 2.2rem;
  background: #fff
    /*url(../images/vendor/wcfm/write.jpg) no-repeat 10px center;*/;
  min-width: 4.6rem;
  padding-left: 3.5rem;
  color: #777;
}
.review-area .btn {
  padding-left: 1.35em;
  padding-right: 1.35em;
  border-color: #eee;
  background-color: #eee;
  color: #333;
}
.review-area .btn:hover,
.review-area .btn:focus,
.review-area .btn:active {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.reviewers-picture figure {
  width: 3.6rem;
  height: 3.6rem;
  border: 2px solid #fff;
  border-radius: 50%;
}
.reviewers-picture figure:not(:first-child) {
  margin-left: -1.5rem;
}

.reviewer-name {
  font-size: 1.6rem;
}
.reviewer-name a {
  color: #333;
}
.reviewer-name a:hover {
  color: #336699;
}

.review-ratings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 2.6rem;
  margin-bottom: 3.5rem;
}
.review-ratings .ratings-container {
  margin-bottom: 1.3rem;
  font-size: 1.4rem;
}
.review-ratings .ratings-full {
  margin-right: 0.8rem;
}
.review-ratings label {
  white-space: nowrap;
}
.review-ratings .review-ratings-right {
  margin-right: 6rem;
}

.average-rating {
  display: inline-block;
  background: #4e4e4e;
  color: #fff;
  font-size: 2.6rem;
  padding: 0.1rem 1.3rem;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
}

.user-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.user-wrap .review-ratings {
  display: block;
  margin-right: 1.5rem;
  margin-bottom: 0;
  border: none;
  padding: 0;
}

.user-photo {
  margin-right: 3.6rem;
}
.user-photo figure {
  width: 70px;
  margin-bottom: 1.5rem;
}
.user-photo label {
  display: block;
  margin-bottom: 0.3rem;
}
.user-photo .score {
  background: #666;
  color: #fff;
  padding: 0.1rem 1.2rem;
  border-radius: 0.3rem;
}

.user-info {
  max-width: 49.4rem;
  margin-right: auto;
  padding-right: 2rem;
}
.user-info p {
  font-size: 1.4rem;
  line-height: 1.78;
}

.vendor-wcfm-map .google-map {
  background-color: #ebebeb;
}

.widget-filter .form-control {
  height: 4.6rem;
  max-width: 100%;
  padding-left: 1.5rem;
  border-color: #eee;
  border-radius: 0.3rem;
  font-size: 1.3rem;
  color: #666;
  margin-bottom: 1rem;
}
.widget-filter .widget-body {
  padding: 0.4rem 0 2.3rem;
}

.vendor-sidebar .widget-search-form .widget-body {
  padding: 0 0 3.5rem;
}

.wcfm-toolbox {
  padding-bottom: 1rem;
}
.wcfm-toolbox select {
  font-size: 1.3rem;
  border-color: #eee;
  width: 24rem;
  max-width: none;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
}
.wcfm-toolbox option {
  font-size: 1.3rem;
}
.wcfm-toolbox .showing-info {
  color: #333;
  font-weight: 400;
}

.store-wcfm .store-content {
  max-width: none;
}

.default-section {
  margin-bottom: 1.7rem;
}

.vendors-section {
  background-color: #f8f8f8;
}
.vendors-section .vendor-widget {
  margin-bottom: 0;
}

@media (max-width: 1036px) {
  .store-wcfm .store-footer {
    padding-top: 5rem;
  }
  .store-wcfm .seller-brand {
    right: auto;
    left: 2rem;
    top: -39%;
  }
}
@media (max-width: 991px) {
  .store-wcmp .store-banner img {
    min-height: auto;
    max-height: 20rem;
  }

  .review-area {
    padding: 2rem;
  }

  .default-section {
    margin-bottom: 5rem;
  }
}
@media (max-width: 767px) {
  .review-ratings .review-ratings-right {
    margin-right: 0;
  }

  .user-wrap .review-ratings {
    display: none;
  }

  .user-photo {
    margin-right: 2rem;
  }

  .user-info {
    padding-right: 0;
  }
}
@media (max-width: 575px) {
  .store-list:hover .seller-brand img {
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem);
  }
}
@media (max-width: 479px) {
  .wcfm-toolbox {
    display: block;
  }

  .review-ratings {
    display: block;
  }
  .review-ratings .ratings-container {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .average-rating {
    margin-top: 1rem;
  }

  .tab-vendor-products .nav-tabs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
</style>