<template>
  <div class="container-fluid" style="padding-bottom: 50px">
    <div class="box">
      <h2>{{ direction == 'en' ? 'Join our NewsLetter' : 'انضم الي الرساله الاخباريه لدينا' }}</h2>
      <p>{{direction == 'en' ? 'Get 15% off your first purchase!' : 'احصل علي ١٥٪ خصم عند الشرا اول مره'}}</p>
      <input :class="direction == 'en' ? 'input' : 'input-ar'" type="email" 
      :placeholder="direction == 'en' ? 'Please insert your email' : 'ادخل البريد الاكتروني'" />
        <Button
          :class="direction == 'en' ?  '' : 'NewsButton'"
          :title="direction == 'en' ? 'Submit' : 'ادخال'"
          width="80px"
          height="30px"
          color="#fff"
          style="
            border: 1px solid #000000;
            background-color: #242b42;
            position: relative;
            font-size: 13px;
            left: 149px;
            top: 8px;
            border-radius: 0;
          "
        />
      </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import bg from "../assets/bg-news.png";

export default {
  name: "NewsLetter",
  props: {},
  data() {
     var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    }  
    return {
      bg: bg,
      Seen: false,
      direction: direction
    };
  },
  components: {
    Button,
  },
};
</script>

<style scoped>
.box {
  width: 600px;
  border: 1px solid rgb(219, 215, 215);
  height: 200px;
  margin: 0 auto;
  background-image: url("../assets/bg-news.png");
}
.container-fluid {
  margin-bottom: 40px;
}

h2 {
  /* Style for "Join our N" */
  color: #242b42;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 32px;
  text-align: center;
  /* Text style for "Join our N" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-top: 30px;
}

p {
  /* Style for "Get 15% of" */

  color: #4f516a;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 32px;
  text-align: center;
  /* Text style for "Get 15% of" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.input {
  width: 250px;
  height: 30px;
  border: 1px solid #242b42;
  background-color: #ffffff;
  position: relative;
  left: 150px;
  top: 10px;
}

.input-ar {
  width: 250px;
  height: 30px;
  border: 1px solid #242b42;
  background-color: #ffffff;
  position: relative;
  right: 150px;
  top: 10px;
}

.input::placeholder {
  font-size: 13px;
  padding-left: 15px;
}

.input-ar::placeholder {
  font-size: 13px;
  padding-right: 15px;
}

.input:focus {
  outline: none;
}

.input-ar:focus {
  outline: none;
}

.NewsButton {
  position: relative!important;
  bottom: 1200px!important;  
  right: 70px;
}

@media only screen and (max-width: 800px) {
  .container-fluid {
    display: none;
  }
  .NewsButton {
    display: none;
  }

}
</style>