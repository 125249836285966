<template sltye="overflow: hidden;">
<loading class="loader" />
  <div class="col-lg-12 first-nav">
    <ul class="lango">
      <li v-on:click="changelang('ar')">
        {{ translation.lang_AR["" + direction + ""] }}
      </li>
      <li v-on:click="changelang('en')">
        {{ translation.lang_EN["" + direction + ""] }}
      </li>
    </ul>
    <ul class="lango">
      <li>{{ translation.terms["" + direction + ""] }} |</li>
      <li>{{ translation.contact["" + direction + ""] }}</li>
    </ul>
  </div>
  <div class="col-lg-12" style="margin-top: 20px">
    <div class="row">
      <div class="col-lg-3">
        <ul class="images-nav">
          <li v-on:click="openSideBar(direction)">
            <img style="width: 20px; height: 10px" :src="img" />
          </li>
          <li>
            <img
              v-on:click="goToHome()"
              style="width: 150px"
              :style="direction == 'en' ? 'margin-left: 15px' : ''"
              :src="img1"
            />
          </li>
          <li><img v-on:click="goToHome()" style="width: 50px" :src="img2" /></li>
        </ul>
      </div>
      <div
        class="col-lg-7"
        :class="direction == 'en' ? 'search-input' : 'search-input-ar'"
      >
        <input
          style="background: #fff"
          class="for-input"
          type="text"
          :placeholder="translation.placeHolder['' + direction + '']"
          v-model="search"
        />
        <button
          type="button"
          style="
            width: 50px;
            height: 39px;
            box-shadow: 0 1px 5px #000000;
            background-color: #b51e30;
            border-radius: 0 !important;
            font-size: 12px;
            position: relative;
            bottom: 2px;
            right: 2px;
            border: none;
          "
          :class="direction == 'en' ? 'button-en' : 'button-ar'"
          v-on:click="searchResult()"
        >
          <img style="width: 20px" :src="searchIcon" alt="" />
        </button>
      </div>
      <div class="col-lg-2 carts">
        <ul class="cart-list">
          <a style="position: relative; bottom: 20px; " v-if="isLogged == false" v-on:click="openUrl('login?redirectsTo=/en/cart')"
            ><li >{{ translation.guest["" + direction + ""] }}</li></a
          >
          <a style="position: relative; bottom: 20px; "  v-else
             v-on:click="openUrl('my-account')"
            ><li class="custom-name user-drop-down" >{{ translation.account["" + direction + ""] }}
             <!-- <ul id="showProfile" style="display: none; position: absolute; width: 150px;
              height: 100px; top: 20px; right: 5px;">
                  <li style="display: block; padding-top: 20px; padding-bottom: 20px;">{{direction == 'en' ? 'My account' : 'حسابي'}}</li>
                  <li  v-on:click="logout()" style="display: block">{{direction == 'en' ? 'Log out' : 'تسجيل الخروج'}}</li>
              </ul> -->
              </li></a
          >
    
          <li v-on:click="openUrl('cart')">
            <!--<span :class="direction == 'en' ? 'counter' : 'counter-ar'">0</span> -->
            <img  style="width: 50px;" :src="cart" alt="" />
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div :class="direction == 'en' ? 'side-bar' : 'side-bar-ar'">
    <div :class="direction == 'en' ? 'head' : 'head-ar'">
      <h6 v-if="isLogged == false" :style="direction == 'en' ? '' : 'font-size: 20px;'">
        {{ direction == "en" ? "Hello , Sign In" : "مرحبا سجل دخولك!" }}
      </h6>
      <h6 v-else :style="direction == 'en' ? '' : 'font-size: 20px;'"> {{ direction == 'en' ? 'Hello, ' + userInfo.name : '  مرحبا ' + userInfo.name }}</h6>
      <span class="x" :style="direction == 'en' ? 'float: right; margin-right: 20px;' : 'float: left; margin-left: 20px;' ">X</span>
    </div>
    <div :class="direction == 'en' ? 'categories' : 'categories-ar'">
      <h5 :style="direction == 'en' ? '' : 'font-size: 24px;'">
        {{ direction == "en" ? "Categories" : "الاقسام" }}
      </h5>
        <div
          v-for="item in categories"
          :key="item"
          v-on:click="
            goToShop(item.id, direction == 'en' ? item.name_en : item.name_ar)
          "
        >
          {{ direction == "en" ? item.name_en : item.name_ar }}
          <span
            ><img
              :style="
                direction == 'en'
                  ? 'width: 20px; float: right; margin-right: 20px'
                  : 'width: 20px; float: left; margin-left: 20px;'
              "
              :src="arrow"
              alt=""
          /></span>
        </div>
      <hr />
    </div>
    <div :class="direction == 'en' ? 'help' : 'help-ar'">
      <h5>
        {{ direction == "en" ? "Help & Settings" : "الاعدادات و المساعده" }}
      </h5>
      <div v-on:click="openUrl(isLogged == false ? 'login' : 'profile')">{{ direction == "en" ? "Your Account" : "الحساب الشخصي" }}</div>
      <div v-on:click="changelang(direction)">
        {{ direction == "en" ? "Arabic" : "English" }}
      </div>
      <div v-on:click="openUrl('shop/all-products?vendor=retail')">{{ direction == 'en' ? 'Retail' : 'الافراد'}}</div>
      <div v-on:click="openUrl('shop/all-products?vendor=corporate')">{{direction == 'en' ? 'Corporate' : 'الشركات'}}</div>
      <div>{{ direction == "en" ? "Contact us" : "اتصل بنا" }}</div>
      <div>{{ direction == "en" ? "Sign In" : "تسجيل الدخول" }}</div>
    </div>
  </div>
  <div
    :class="direction == 'en' ? 'other-side' : 'other-side-ar'"
    v-on:click="closeSideBar(direction)"
  ></div>
</template>

<script>
//import SideBar from "../components/SideBar.vue"
import img from "../assets/Menu_Icon.png";
import img1 from "../assets/garden-logo.png";
import img2 from "../assets/logo.png";
import Button from "../components/Button.vue";
import cart from "../assets/shop-cart.png";
import searchIcon from "../assets/searchs.png";
import user from "../assets/userIcon.png";
import arrow from "../assets/arrow1.png";
import axios from "axios";
import loading from "../components/loading.vue"
export default {
  props: {},
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    return {
      img: img,
      img1: img1,
      img2: img2,
      cart: cart,
      searchIcon: searchIcon,
      user: user,
      arrow: arrow,
      direction: direction,
      translation: {
        lang_AR: {
          ar: "العربيه |",
          en: "ARB |",
        },
        lang_EN: {
          ar: "الانجليزيه",
          en: "ENG",
        },
        terms: {
          en: "TERMS OF USE",
          ar: "شروط الاستخدام",
        },
        contact: {
          en: "CONTACT US",
          ar: "اتصل بنا",
        },
        placeHolder: {
          en: "What are you looking for?",
          ar: "ما الذي تبحث عنه؟",
        },
        account: {
          en: "My Account",
          ar: "حسابي",
        },
        guest: {
          en: 'SIGN IN',
          ar: 'تسجيل دخول'
        }
      },
      BASEURL: window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost') ? "http://127.0.0.1:3000/" : "https://nourdiaa.com:3000/",
      isLogged: false,
      userInfo: {
        id: '',
        name: '',
        email: '',
        phone: '',
        token: ''
      },
      categories: {},
      search: ''
    };
  },
  components: {
    Button,
    loading,
  },
  methods: {
    isLoggedIn() {
      let decodedCookie = decodeURIComponent(document.cookie);
      if (!decodedCookie.includes('LoggedIn')) return this.isLogged = false;
      let ca = decodedCookie.split(";");
      this.getCookies(['loggedIn', 'access'], ca)
    },
    getCookies(value, array) {
      let isLogged = array[0].split("=")
      if (isLogged[1].includes('true')) {
        this.isLogged = true
      } else {
        this.isLogged = false
      }
      let token = array[1].split("=")
      this.userInfo.token = token[1]

    },
    getUserInfo () {
      if (this.isLogged == true) {
        this.userInfo.name = sessionStorage.getItem('fullName')
        this.userInfo.email = sessionStorage.getItem('email')
      }
    },
    openSideBar(direction) {
      if (direction == "en") {
        var elem = document.getElementsByClassName("side-bar");
        elem[0].style.cssText = "left: 0%;";
        var openOtherSide = document.getElementsByClassName("other-side");
        openOtherSide[0].style.cssText = "right: 0%;";
      } else {
        var elem = document.getElementsByClassName("side-bar-ar");
        elem[0].style.cssText = "right: 0%;";
        var openOtherSide = document.getElementsByClassName("other-side-ar");
        openOtherSide[0].style.cssText = "left: 0%;";
      }
    },
    closeSideBar(direction) {
      if (direction == "en") {
        var elem = document.getElementsByClassName("side-bar");
        elem[0].style.cssText = "left: -100%";
        var openOtherSide = document.getElementsByClassName("other-side");
        openOtherSide[0].style.cssText = "right: -100%;";
      } else {
        var elem = document.getElementsByClassName("side-bar-ar");
        elem[0].style.cssText = "right: -100%";
        var openOtherSide = document.getElementsByClassName("other-side-ar");
        openOtherSide[0].style.cssText = "left: -100%;";
      }
    },
    openUrl(value) {
      if (value == 'login') {
        if ("LoggedIn" in sessionStorage) {
          //return window.history.back()
          return;
        } 
      }      
      window.location =
        window.location.origin + "/" + this.direction + "/" + value;
    },
    goToHome() {
      window.location.href = window.location.origin + "/" + this.direction + "/"
    },
    changelang(value) {
      if (value == "en") {
        if (window.location.href.includes("/en/")) return;
        window.location.href = window.location.href.replace("/ar/", "/en/");
      } else {
        if (window.location.href.includes("/ar/")) return;
        window.location.href = window.location.href.replace("/en/", "/ar/");
      }
    },
    async getCategories() {
      let res = await axios.get(this.BASEURL + "categories", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
        params: this.getParamsofCategories,
      });
      this.categories = res.data;
    },
    goToShop(id, name) {
      window.location =
        window.location.origin +
        "/" +
        this.direction +
        "/shop/" +
        name +
        "/" +
        id + '?page=1&current_is_sub=false';
    },
    finishedLoading() {
      setTimeout(function() {
        document.getElementsByClassName('loader')[0].style.display = 'none';
      }, 1000);
    },
    logout() {
      window.location.href = "/" + this.direction + "/logout"
    },
    searchResult() {
      return location.href = '/' + this.direction + '/result?search=' + this.search
    }         
  },
  mounted() {
    this.finishedLoading()
  },
  beforeCreate() {
    if (window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost')) {
      this.BASEURL = "http://127.0.0.1:3000"
    } else {
      this.BASEURL = "http://188.166.112.177:3000"
    }
  },
  created() {
    this.isLoggedIn();
    this.getUserInfo();
    this.getCategories();
  },
  computed: {
    getParamsofCategories() {
      const params = new URLSearchParams();
      params.append("direction", this.direction);
      params.append("login", this.isLogged);
      return params;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Yaldevi:wght@200&display=swap");
.first-nav {
  width: 100%;
  height: 30px;
  background-color: #1c1e23;
}

.customCart-loggedIn {
  position: relative;
  left: 120px;
  bottom: 30px;
}

.customfont-loggedIn { 
  position: relative;
  left: 20px;
}

.customCart-loggedIn-ar {
    position: relative;
    bottom: 30px;
}

.customfont-loggedIn-ar { 
  position: relative;
}

.lango li {
  /* Style for "ENG|ARB" */

  color: #ffffff;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 346.75px;
  text-align: right;
  text-transform: uppercase;
  /* Text style for "ENG|ARB" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  display: inline;
  margin-top: 4px;
  margin-left: 5px;
}

.lango {
  display: inline;
}

.images-nav li {
  display: inline;
}

.for-input {
  outline: none;
  width: 600px;
  height: 40px;
  margin-top: 10px;
  border: none;
  display: inline;
  box-shadow: 1px 1px 15px rgb(216, 214, 214);
}

.for-input::placeholder {
  font-size: 12px;
  color: black;
  padding-left: 30px;
}

.cart-list li {
  font-family: Helvetica;
  color: #343e5c;
  font-size: 14px;
  list-style: none;
  text-decoration: none;
  display: inline-flex;
  cursor: pointer;
}

li {
  cursor: pointer;
}

.cart-list {
  position: relative;
  top: 10px;
  right: 30px;
}

.counter {
  background: #e3e8f0;
  padding: 5px;
  font-size: 11px;
  border-radius: 250px;
  position: absolute;
  top: 10px;
  right: 5px;
}

.counter-ar {
  background: #e3e8f0;
  padding: 5px;
  font-size: 11px;
  border-radius: 250px;
  position: absolute;
  top: 13px;
  left: 70px;
  z-index: 9999;
}

.side-bar {
  width: 28%;
  background: #fff;
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: -50%;
  top: 0;
  transition: 0.5s ease;
  margin: 0;
  padding: 0;
  overflow: scroll;
}

.other-side {
  width: 72%;
  height: 100%;
  background: grey;
  transition: 0.5s ease;
  opacity: 0.7;
  position: fixed;
  right: -80%;
  top: 0;
  z-index: 9999;
  overflow: hidden;
}

.side-bar-ar {
  width: 28%;
  background: #fff;
  height: 100%;
  position: fixed;
  z-index: 9999 !important;
  right: -100%;
  top: 0;
  transition: 0.5s ease;
  margin: 0;
  padding: 0;
  overflow: scroll;
}

.other-side-ar {
  width: 72%;
  height: 100%;
  background: grey;
  transition: 0.5s ease;
  opacity: 0.7;
  position: fixed;
  left: -100%;
  top: 0;
  z-index: 9999 !important;
  overflow: hidden;
}

.head {
  width: 100%;
  height: 60px;
  background: #c32222;
  color: #fff;
}

.head-ar {
  width: 100%;
  height: 60px;
  background: #c32222;
  color: #fff;
}

.head h6,
.help h5 {
  margin-left: 50px;
  position: relative;
  top: 40%;
}

.head-ar h6,
.help-ar h5 {
  margin-right: 50px;
  position: relative;
  top: 40%;
}

.categories div,
.help div {
  width: 100%;
  padding-left: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  list-style: none;
  font-size: 15px;
  font-family: "Open-sans";
  letter-spacing: 1px;
}

.categories-ar div,
.help-ar div {
  width: 100%;
  padding-right: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  list-style: none;
  font-size: 15px;
  font-family: "Open-sans";
  letter-spacing: 1px;
}

.categories div:hover,
.help div:hover {
  transition: 0.2s ease;
  background: #f0ebeb;
  cursor: pointer;
}

.categories-ar div:hover,
.help-ar div:hover {
  transition: 0.2s ease;
  background: #f0ebeb;
  cursor: pointer;
}

.categories h5 {
  margin-left: 40px;
  margin-top: 20px;
  font-weight: bold;
}

.categories-ar h5 {
  margin-right: 40px;
  margin-top: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .first-nav {
    display: none;
  }

  .carts {
    display: none;
  }

  .search-input input {
    position: relative;
    left: 25px;
    width: 300px;
  }

  .search-input-ar input {
    position: relative;
    right: 25px;
    width: 300px;
  }

  .button-en {
    position: absolute;
    bottom: 7px;
  }

  .side-bar {
    width: 80%;
    left: -100%;
  }

  .side-bar-ar {
    width: 80%;
    right: -100%;
  }

  .other-side {
    width: 20%;
    right: -100%;
  }

  .other-side-ar {
    width: 20%;
    left: -100%;
  }

  .x {
    display:block!important;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
  .search-input input {
    position: relative;
    left: 25px;
    width: 600px;
  }

  .search-input-ar input {
    position: relative;
    right: 65px;
    width: 600px;
  }

  .button-en {
    position: absolute;
    bottom: 7px;
  }

  .button-ar {
    position: absolute;
    bottom: 7px;
    left: 100px;
  }
  .carts {
    display: none;
  }

  .side-bar {
    width: 70%;
    left: -70%;
  }

  .side-bar-ar {
    width: 70%;
    right: -70%;
  }

  .other-side {
    width: 30%;
    right: -30%;
  }

  .other-side-ar {
    width: 30%;
    left: -30%;
  }  .x {
    display:block!important;
  }  
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
  .carts {
    display: none;
  }
  .x {
    display:block!important;
  }  
}

.user-drop-down:hover #showProfile{
  display: block!important;
  box-shadow: 0 0 10px rgb(243, 241, 241);
        transition: 0.5s ease;  
}

.x {
    display: block;
    cursor: pointer;
}
</style>