<template>
  <Header2 />
  <!-- Start -->
  <section class="section" style="margin-top: 100px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div>
              <h1 style="text-align: center; padding-bottom: 40px; word-spacing: 2px;">{{direction == 'en' ? 'Shopping Cart' : 'عربه التسوق'}}</h1>
          </div>          
        </div>
        <div class="col-12">
          <div class="table-responsive bg-white shadow rounded">
            <table class="table mb-0 table-center">
              <thead>
                <tr>
                  <th class="border-bottom py-3" style="min-width: 20px"></th>
                  <th
                    class="border-bottom text-start py-3"
                    style=""
                    :style="direction == 'ar' ? '' : 'min-width: 300px'"
                  >
                    {{direction == 'en' ? translation.product.en : translation.product.ar}}
                  </th>
                  <th
                    class="border-bottom text-center py-3"
                    style="min-width: 160px"
                  >
                    {{direction == 'en' ? translation.price.en : translation.price.ar}}
                  </th>
                  <th
                    class="border-bottom text-center py-3"
                    style="min-width: 160px"
                  >
                    {{direction == 'en' ? translation.quan.en : translation.quan.ar}}
                  </th>
                  <th
                    class="border-bottom text-end py-3 pe-4"
                    style="min-width: 160px"
                  >
                    {{direction == 'en' ? translation.total.en : translation.total.ar}}
                  </th>
                  <th class="border-bottom text-end py-3 pe-4"
                    style="min-width: 160px">
                    </th>
                </tr>
              </thead>

              <tbody>
                <tr class="shop-list" v-for="item in products" v-bind:key="item" :id="'id-' + item.id">
                  <td class="h6 text-center">
                    <a href="javascript:void(0)" class="text-danger"
                      ><i class="uil uil-times"></i
                    ></a>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">

                      <img v-if="item.product.attachments.length >= 1"
                        :src="item.product.attachments[0].url"
                        class="img-fluid avatar avatar-small rounded shadow"
                        style="height: auto"
                        alt=""
                      />
                      <img v-else
                        :src="efault"
                        class="img-fluid avatar avatar-small rounded shadow"
                        style="height: auto"
                        alt=""
                      />
                      <h6 class="mb-0 ms-3" :style="direction == 'ar' ? 'margin-right: 15px;' : ''">{{item.product.common_name}}</h6>
                    </div>
                  </td>
                  <td class="text-center">{{item.product.price}} {{direction == 'en' ? translation.curr.en : translation.curr.ar}}</td>
                  <td class="text-center qty-icons">
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                      class="btn btn-icon btn-soft-primary minus"
                      v-on:click="decrease(item.product.price, item.quan, item.id)"
                    >
                      -
                    </button>
                    <input
                      min="1"
                      name="quantity"
                      :value="item.quan"
                      price="0"
                      type="number"
                      class="btn btn-icon btn-soft-primary qty-btn quantity"
                      :id="'x'-item.id"
                    />
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                      class="btn btn-icon btn-soft-primary plus"
                      v-on:click="increase(item.product.price, item.id)"
                    >
                      +
                    </button>
                  </td>
                  <td class="text-end fw-bold pe-4" :id="'x-' + item.id" quan="0"> {{item.product.price * item.quan}} {{direction == 'en' ? translation.curr.en : translation.curr.ar}}</td>
                  <td><button class="btn btn-danger" v-on:click="removeThisProduct(item.id)">{{direction == 'en' ? 'Remove' : 'حذف'}}</button></td>
                </tr>
              </tbody>
            </table>
              <div :style="products.length >= 1 ? 'display:none;' : 'display:block; height: 100px; letter-spacing: 1px; margin-top: 50px;'">
                  <h5 style="text-align: center;">{{direction == 'en' ? 'There is no products in your shop cart' : 'لا يوجد منتجات في السله'}}</h5>
              </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <div class="row">
        <div class="col-lg-4 col-md-6 ms-auto mt-4 pt-2">
          <div class="table-responsive bg-white rounded shadow">
            <table class="table table-center table-padding mb-0">
              <tbody>
                <tr>
                  <td class="h6 ps-4 py-3">{{direction == 'en' ? translation.subTotal.en : translation.subTotal.ar}}</td>
                  <td class="text-end fw-bold pe-4">{{total}} {{ direction == 'en' ? translation.curr.en : translation.curr.ar}}</td>
                </tr>
                <tr>
                  <td class="h6 ps-4 py-3">{{direction == 'en' ? translation.tax.en : translation.tax.ar}}</td>
                  <td class="text-end fw-bold pe-4">0</td>
                </tr>
                <tr class="bg-light">
                  <td class="h6 ps-4 py-3">{{direction == 'en' ? translation.total.en : translation.total.ar}}</td>
                  <td class="text-end fw-bold pe-4">{{total}} {{ direction == 'en' ? translation.curr.en : translation.curr.ar}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="margin-bottom: 140px!important;" class="mt-4 pt-2 text-end" v-if="products.length != 0">
            <a style="background: #b51e30; border: 1px solid #b51e30" v-on:click="openUrl('checkout')" class="btn btn-primary"
              >{{direction == 'en' ? translation.proceed.en : translation.proceed.ar}}</a
            >
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <newFooter />
</template>

<script>
import Header2 from "../components/Header2.vue";
import Button from "../components/Button.vue";
import Footer from "../components/Footer.vue";
import img from "../assets/planet.png";
import newFooter from "../components/newFooter.vue"
import defaultImg from "../assets/default.png" 
import axios from "axios";
export default {
  name: "Cart",
  data: () => {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    return {
      img: img,
      direction: direction,
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",
      products: {},
      translation: {
        product: {
          en: 'Product',
          ar: 'المنتج'
        },
        price: {
          en: 'Price',
          ar: 'السعر'
        },
        quan: {
          en: 'Qty',
          ar: 'العدد'
        },
        total: {
          en: 'Total',
          ar: 'الاجمالي'
        },
        remove: {
          en: 'Remove',
          ar: 'اذاله'
        },
        tax: {
          en: 'Tax',
          ar: 'الضريبه'
        },
        subTotal: {
          en: 'SubTotal',
          ar: 'المجموع'
        }, 
        proceed: {
          en: 'Proceed to checkout',
          ar: 'اتمام الطلب'
        },
        curr: {
          en: ' SAR',
          ar: ' ر.س'
        }
      },
      total: 0,
      efault: defaultImg,
      clicks: 0,
    };
  },
  components: {
    Header2,
    Button,
    Footer,
    newFooter,
  },
  methods: {
    openUrl(value) {
      window.location =
        window.location.origin + "/" + this.direction + "/" + value;
    },
    async getMyCartList() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      let resp = await axios.get(this.BASEURL + "cart/get");
      this.products = resp.data.cart;
      Window.products = resp.data.cart
      resp.data.cart.map( (result) => {
        this.total += result.product.price * result.quan
      })

      console.log(this.products)
    },
    async removeThisProduct(value) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      const body = {
        productId: value,
        //userId: sessionStorage.getItem("id"),
      };
      let response = await axios.post(this.BASEURL + "cart/remove", body);

      if (response.status == 200) {
        document.getElementById("id-" + value).style.display = "none";
        location.reload();
      }
    },
    increase(price, id) {
      var target = "x-" + id
      Window.products.filter( (result) => {
        if (result.id == id) {
          result.quan++
          var lastPrice = price * ((result.quan))
          var curr = this.direction == 'en' ? this.translation.curr.en : this.translation.curr.ar
          document.getElementById(target).innerHTML = lastPrice + " " + curr          
        }
      })
      this.calculateTotal() 
    },
    decrease(price, quan, id) {
      var target = "x-" + id
      Window.products.filter( (result) => {
        if (result.id == id) {
          if (result.quan <= 1) return false;
          result.quan--
          var lastPrice = price * ((result.quan))
          var curr = this.direction == 'en' ? this.translation.curr.en : this.translation.curr.ar
          document.getElementById(target).innerHTML = lastPrice + " " + curr     
        }
      })
      this.calculateTotal()
    },    
    async calculateTotal() {
      this.total = 0
      Window.products.map( (result) => {
        this.total += result.product.price * result.quan
      })

      // call an api to update my cart
      const body = {
        products: Window.products,
      };
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      let resp = await axios.post(this.BASEURL + "cart/update", body);
    },
    async getUser() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      let res = await axios.get(this.BASEURL + "users/get-info", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      }); 
      if (res.data.locked == 0) {
        location.href =  '/'+ this.direction + '/verification'
      }
    }     
  },
  created() {
    this.getMyCartList();
    this.getUser();
  },
  beforeMount() {
    if (!sessionStorage.getItem("LoggedIn")) {
      window.location.href =
        "/" + this.direction + "/login?redirectsTo=" + this.direction + "/cart";
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,500&family=Roboto:ital@1&family=Yaldevi:wght@200&display=swap");

/*.cart-head {
  margin-top: 100px;
}

table {
  width: 100%;
  border: solid 1px rgb(211, 208, 208);
  -webkit-box-shadow: 0 0 10px rgb(199, 199, 199);
  box-shadow: 0 0 10px rgb(199, 199, 199);
  margin-bottom: 50px;
}

th {
  border-bottom: solid 1px rgb(226, 217, 217);
  color: #333333;
  font-weight: 500;
  background: rgb(243, 236, 236);
  padding-left: 30px;
  height: 60px;
}

td {
  padding-left: 30px;
  min-height: 200px;
  height: 200px;
}

table img {
  width: 170px;
  height: 170px;
  max-width: 170px;
  max-height: 170px;
}

.cart-header input {
  width: 30px;
  border: none;
  margin-left: 10px;
}
*/

.shop-features .category-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  z-index: 1;
}

.shop-list .shop-image .overlay-work,
.shop-list .shop-image .shop-icons {
  position: absolute;
  opacity: 0;
  transition: all 0.5s ease;
}

.shop-list .shop-image .overlay-work {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.shop-list .shop-image .overlay-work .out-stock {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

img {
  width: 70px!important;
}

.shop-list .shop-image .shop-icons {
  top: 15px;
  right: 15px;
  z-index: 2;
}

.shop-list .shop-image:hover .overlay-work,
.shop-list .shop-image:hover .shop-icons {
  opacity: 1;
}

.shop-list .content .product-name {
  transition: all 0.5s ease;
}

.shop-list .content .product-name:hover {
  color: #2f55d4 !important;
}

.shop-list .label {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
}

.shop-list .qty-btn {
  pointer-events: none;
  width: 65px;
  padding-left: 15px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive .table td,
.table-responsive .table th {
  vertical-align: middle;
}

.table-responsive .table.table-nowrap th,
.table-responsive .table.table-nowrap td {
  white-space: nowrap;
}

.table-responsive .table-center th {
  vertical-align: middle !important;
}

.table-responsive .table-center tbody tr:hover {
  color: #3c4858;
  background-color: #f8f9fa;
}

.table-responsive .table-center.invoice-tb th,
.table-responsive .table-center.invoice-tb td {
  text-align: end;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

[class^=uil-]:before,
[class*=" uil-"]:before {
  margin: 0;
}

.rounded {
  border-radius: 6px !important;
}

.shadow {
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.shadow-md {
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.btn.btn-icon {
  height: 36px;
  width: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.btn.btn-icon .icons {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.btn.btn-icon.btn-lg {
  height: 48px;
  width: 48px;
  line-height: 46px;
}

.btn.btn-icon.btn-lg .icons {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.btn.btn-icon.btn-sm {
  height: 30px;
  width: 30px;
  line-height: 28px;
}

.btn-soft-primary {
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  color: #c32222 !important;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-soft-primary:hover, .btn-soft-primary:focus, .btn-soft-primary:active, .btn-soft-primary.active, .btn-soft-primary.focus {
  background-color: #c32222 !important;
  border-color: #c32222 !important;
  color: #ffffff !important;
}

.shop-list .qty-btn {
  pointer-events: none;
  width: 65px;
  padding-left: 15px;
}

</style>