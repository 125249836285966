<template>
  <loading />
  <Header2 />
      <div :class="direction == 'en' ? 'box' : 'box-ar'">
           <span v-on:click="hidePopUp()">X</span> <p>{{direction == 'en' ? 'This item has been added to cart' : 'تم اضافه المنتج في عربه التسوق'}}</p>
      </div>  
  <section class="section pb-0" style="margin-top: 40px">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5">
          <div class="tiny-single-item">
            <div class="tiny-slide">
              <img
                :src="product.attachments[0].url"
                class="img-fluid rounded"
                alt=""
              />
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="section-title ms-md-4">
            <h4 class="title">{{ product.common_name }}</h4>
            <h5 class="text-muted">
              {{ product.price }}
              {{ direction == "en" ? currency.en : currency.ar }}
              <del class="text-danger ms-2"
                >{{ product.price }}
                {{ direction == "en" ? currency.en : currency.ar }}</del
              >
            </h5>
            <ul class="list-unstyled text-warning h5 mb-0">
              <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
              <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
              <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
              <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
              <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
            </ul>

            <h5 class="mt-4 py-2">
              {{ direction == "en" ? "Overview " : "نظره عامه" }} :
            </h5>
            <p class="text-muted">
              {{ product.desc }}
            </p>

            <div class="row mt-4 pt-2">
              <!--end col-->
              <div class="col-lg-6 col-12 mt-4 mt-lg-0">
                <div class="d-flex shop-list align-items-center">
                  <h6 class="mb-0">
                    {{ direction == "en" ? "Quantity" : "العدد" }}:
                  </h6>
                  <div class="qty-icons ms-3">
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                      class="btn btn-icon btn-soft-primary minus"
                    >
                      -
                    </button>
                    <input
                      min="1"
                      name="quantity"
                      value="1"
                      type="number"
                      class="btn btn-icon btn-soft-primary qty-btn quantity"
                      :style="direction == 'ar' ? 'padding-right: 10px;' : ''"
                    />
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                      class="btn btn-icon btn-soft-primary plus"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->

            <div class="mt-4 pt-2">
              <a
                v-on:click="addThisToCart(product.id)"
                href="#"
                class="btn ms-2 add-to-cart"
                >{{ direction == "en" ? "Add to Cart" : "اضف الي العربه" }}</a
              >
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
      <div class="row">
        <div class="col-12">
          <div class="tab-content mt-5" id="pills-tabContent">
            <div
              class="card border-0 tab-pane fade"
              id="additional"
              role="tabpanel"
              aria-labelledby="additional-info"
            >
              <table class="table">
                <tbody>
                  <tr>
                    <td style="width: 100px">Color</td>
                    <td class="text-muted">Red, White, Black, Orange</td>
                  </tr>

                  <tr>
                    <td>Material</td>
                    <td class="text-muted">Cotton</td>
                  </tr>

                  <!--<tr>
                    <td>Size</td>
                    <td class="text-muted">S, M, L, XL, XXL</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <hr />
      <div class="desc">
        <h5 :style="direction == 'ar' ? 'margin-right: 20px;' : ''">
          {{ direction == "en" ? "Product description" : "وصف المنتج" }}
        </h5>
        <p>
          {{ product.desc }}
        </p>
      </div>
      <hr />
      <div class="desc">
        <h5
          :style="direction == 'ar' ? 'margin-right: 20px;' : ''"
          style="margin-bottom: 20px"
        >
          {{ direction == "en" ? "Product details" : "تفاصيل المنتج" }}
        </h5>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 col-sm-12 col-md-6 col-xs-12">
              <ul style="">
                <li
                  v-for="item in productsKey"
                  v-bind:key="item"
                  style="
                    text-decoration: none;
                    display: block;
                    background: rgb(245, 241, 241);
                    padding: 10px 10px 10px 10px;
                    margin-bottom: 10px;
                  "
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 col-md-6 col-xs-12">
              <span
                v-for="item of productValue"
                v-bind:key="item"
                style="
                  min-width: 50%;
                  padding: 10px 10px 10px 10px;
                  margin-bottom: 10px;
                  display: block;
                  background: #e5e5e5;
                "
                >{{ item }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!--<hr />
      <div class="retails-products">
        <h5>Related products</h5>
        <div class="row justify-content-lg-center">
          <div
            class="col-lg-2 col-md-6"
            :class="direction == 'en' ? 'list' : 'list-ar'"
          >
            <img :src="planet" alt="" />
            <p style="margin-top: 5px; font-weight: 600">
              Product number 1 hello wrold people please
            </p>
            <p style="margin-top: -10px">In stock</p>
            <p style="margin-top: -10px">420 SAR</p>
          </div>
          <div
            class="col-lg-2 col-md-6"
            :class="direction == 'en' ? 'list' : 'list-ar'"
          >
            <img :src="planet" alt="" />
            <p style="margin-top: 5px; font-weight: 600">
              Product number 1 hello wrold people please
            </p>
            <p style="margin-top: -10px">In stock</p>
            <p style="margin-top: -10px">420 SAR</p>
          </div>
          <div
            class="col-lg-2 col-md-6"
            :class="direction == 'en' ? 'list' : 'list-ar'"
          >
            <img :src="planet" alt="" />
            <p style="margin-top: 5px; font-weight: 600">
              Product number 1 hello wrold people please
            </p>
            <p style="margin-top: -10px">In stock</p>
            <p style="margin-top: -10px">420 SAR</p>
          </div>
          <div
            class="col-lg-2 col-md-6"
            :class="direction == 'en' ? 'list' : 'list-ar'"
          >
            <img :src="planet" alt="" />
            <p style="margin-top: 5px; font-weight: 600">
              Product number 1 hello wrold people please
            </p>
            <p style="margin-top: -10px">In stock</p>
            <p style="margin-top: -10px">420 SAR</p>
          </div>
        </div> -->
    </div>
    <!--end container-->
  </section>
  <newFooter />
</template>
<script>
import Header2 from "../components/Header2.vue";
import axios from "axios";
import Footer from "../components/Footer.vue";
import Button from "../components/Button.vue";
import newFooter from "../components/newFooter.vue";
import logo from "../assets/default.png";

export default {
  name: "singleProduct",
  data: () => {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    return {
      direction: direction,
      product: "",
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",
      currency: {
        en: "SAR",
        ar: "ريال سعودي",
      },
      slider: "",
      currency: {
        en: "SAR",
        ar: "ريال سعودي",
      },
      productDetails: [],
      productsKey: [],
      productValue: [],
      logo: logo,
    };
  },
  components: {
    Header2,
    Footer,
    Button,
    newFooter,
  },
  created() {
    this.getProduct();

    this.getUser();

    if (document.getElementsByClassName("tiny-single-item").length > 0) {
      var slider = tns({
        container: ".tiny-single-item",
        items: 1,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 16,
      });
    }

    if (document.getElementsByClassName("tiny-two-item").length > 0) {
      var slider = tns({
        container: ".tiny-two-item",
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 12,
        responsive: {
          992: {
            items: 2,
          },

          767: {
            items: 2,
          },

          320: {
            items: 1,
          },
        },
      });
    }
    if (document.getElementsByClassName("tiny-three-item").length > 0) {
      var slider = tns({
        container: ".tiny-three-item",
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 12,
        responsive: {
          992: {
            items: 3,
          },
          767: {
            items: 2,
          },

          320: {
            items: 1,
          },
        },
      });
    }
    if (document.getElementsByClassName("tiny-four-item").length > 0) {
      var slider = tns({
        container: ".tiny-four-item",
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 12,
        responsive: {
          992: {
            items: 4,
          },

          767: {
            items: 2,
          },

          320: {
            items: 1,
          },
        },
      });
    }

    if (document.getElementsByClassName("roadmaps").length > 0) {
      var slider = tns({
        container: ".roadmaps",
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        nav: false,
        speed: 400,
        gutter: 12,
        responsive: {
          992: {
            items: 4,
          },
          767: {
            items: 2,
          },
          320: {
            items: 1,
          },
        },
      });
    }

    if (document.getElementsByClassName("tiny-six-item").length > 0) {
      var slider = tns({
        container: ".tiny-six-item",
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 12,
        responsive: {
          992: {
            items: 6,
          },

          767: {
            items: 3,
          },

          320: {
            items: 1,
          },
        },
      });
    }

    if (document.getElementsByClassName("tiny-twelve-item").length > 0) {
      var slider = tns({
        container: ".tiny-twelve-item",
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: [
          '<i class="mdi mdi-chevron-left "></i>',
          '<i class="mdi mdi-chevron-right"></i>',
        ],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
          1025: {
            items: 10,
          },

          992: {
            items: 8,
          },

          767: {
            items: 6,
          },

          320: {
            items: 2,
          },
        },
      });
    }
  },
  methods: {
    async getProduct() {
      let res = await axios.get(
        this.BASEURL + "products/get/" + this.$route.params.id
      );

      if (res.status == 200) {
        for (let data in res.data) {
          if (
            res.data[data] == null ||
            res.data[data] == 0 ||
            data == "id" ||
            data == "attachments" ||
            data == "created_at" ||
            data == "updated_at" ||
            data == "desc" ||
            data == "parent" ||
            data == "price" ||
            data == "discount" ||
            data == "sub"
          )
            continue;
          this.productsKey.push(data);
          this.productValue.push(res.data[data]);
        }

        for (let item in this.productsKey) {
          if (this.direction == "ar") {
            if (this.productsKey[item] == "botanical_name") {
              this.productsKey[item] = "الاسم النباتي";
            } else if (this.productsKey[item] == "common_name") {
              this.productsKey[item] = " اسم شائع";
            } else if (this.productsKey[item] == "hippeasturm") {
              this.productsKey[item] = "hippeasturm";
            } else if (this.productsKey[item] == "amaryllis") {
              this.productsKey[item] = "أمارلس";
            } else if (this.productsKey[item] == "total_height") {
              this.productsKey[item] = "الطول الكلي ";
              this.productValue[item] = this.productValue[item] + " " + " سم";
            } else if (this.productsKey[item] == "clear_trunk") {
              this.productsKey[item] = "طول الساق الصافي  ";
              this.productValue[item] = this.productValue[item] + " " + " سم";
            } else if (this.productsKey[item] == "caliper_size") {
              this.productsKey[item] = "حجم الساق ";
              this.productValue[item] = this.productValue[item] + " " + " جرام";
            } else if (this.productsKey[item] == "sq_meter") {
              this.productsKey[item] = "متر مربع ";
              this.productValue[item] =
                this.productValue[item] + " " + " متر مربع";
            } else if (this.productsKey[item] == "cubic_meter") {
              this.productsKey[item] = "متر مكعب ";
              this.productValue[item] =
                this.productValue[item] + " " + " متر مكعب";
            } else if (this.productsKey[item] == "kilogram") {
              this.productsKey[item] = "كيلو جرام ";
              this.productValue[item] =
                this.productValue[item] + " " + " كيلو جرام";
            } else if (this.productsKey[item] == "mili_Meter") {
              this.productsKey[item] = "مليليتر";
              this.productValue[item] =
                this.productValue[item] + " " + " ميلي متر ";
            } else if (this.productsKey[item] == "leter") {
              this.productsKey[item] = "لتر ";
              this.productValue[item] = this.productValue[item] + " " + " لتر";
            } else if (this.productsKey[item] == "set") {
              this.productsKey[item] = "طقم";
            } else if (this.productsKey[item] == "bag") {
              this.productsKey[item] = "كيس ";
            } else if (this.productsKey[item] == "carton") {
              this.productsKey[item] = "كرتون";
            } else if (this.productsKey[item] == "piece") {
              this.productsKey[item] = "قطعة ";
            }
          } else {
            if (this.productsKey[item] == "total_height") {
              this.productValue[item] = this.productValue[item] + " " + " cm";
            } else if (this.productsKey[item] == "clear_trunk") {
              this.productsKey[item] = "طول الساق الصافي  ";
              this.productValue[item] = this.productValue[item] + " " + " cm";
            } else if (this.productsKey[item] == "caliper_size") {
              this.productValue[item] = this.productValue[item] + " " + " gram";
            } else if (this.productsKey[item] == "sq_meter") {
              this.productValue[item] = this.productValue[item] + " " + " m2";
            } else if (this.productsKey[item] == "cubic_meter") {
              this.productValue[item] = this.productValue[item] + " " + " m3";
            } else if (this.productsKey[item] == "kilogram") {
              this.productValue[item] =
                this.productValue[item] + " " + " Kilogram";
            } else if (this.productsKey[item] == "mili_Meter") {
              this.productValue[item] =
                this.productValue[item] + " " + " mili meter";
            } else if (this.productsKey[item] == "leter") {
              this.productValue[item] =
                this.productValue[item] + " " + " leter";
            }
          }
        }

        this.product = res.data;
        console.log(this.product.attachments[0].url);
      }
    },
    async addThisToCart(value) {
      // checking if the user is logged in already
      if (!sessionStorage.getItem("LoggedIn")) {
        return (location.href =
          "/" + this.direction + "/login?redirectsTo=/cart");
      }

      //setup the body for api
      const body = {
        userId: sessionStorage.getItem("id"),
        productId: value,
      };

      // api callback
      let response = await axios
        .post(this.BASEURL + "products/addtocart", body)
        .then((result) => {
            if (this.direction == 'en') {
            document.getElementsByClassName('box')[0].style.right = '10px'
            setTimeout(() => {
              document.getElementsByClassName('box')[0].style.right = '-1000px'
            }, 4000)
          } else {
            document.getElementsByClassName('box-ar')[0].style.left = '10px'
            setTimeout(() => {
              document.getElementsByClassName('box-ar')[0].style.left = '-1000px'
            }, 4000)       
          }
        }).catch((error) => console.log(error));
    },
    async getUser() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      let res = await axios.get(this.BASEURL + "users/get-info", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      }); 
      
      if (res.data.locked == 0) {
        location.href =  '/'+ this.direction + '/verification'
      }
    } 
  },
};
</script>

<style scoped>
.box-ar {
  position: fixed;
  z-index: 9999!important;
  top: 150px;
  left: -1000px;
  background: #fff;
  overflow: hidden;
  width: 300px;
  height: 100px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px rgb(243, 241, 241);
        box-shadow: 0 0 10px rgb(243, 241, 241);
        transition: 0.5s ease;
}

.box-ar p {
  text-align:center;
  position: relative;
  top: 40%;
}

.box-ar span {
  float: left;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 22px;
  cursor: pointer;
}



.box {
  position: fixed;
  z-index: 9999!important;
  overflow: hidden;
  top: 150px;
  right: -1000px;

  background: #fff;
  width: 350px;
  height: 100px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px rgb(243, 241, 241);
        box-shadow: 0 0 10px rgb(243, 241, 241);
        transition: 0.5s ease;
}

.box p {
  text-align:center;
  position: relative;
  top: 40%;
}

.box span {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 22px;
  cursor: pointer;
}


.section-title .title {
  letter-spacing: 0.5px;
  font-size: 30px !important;
}

.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 5px;
}

.shop-list .shop-image .overlay-work,
.shop-list .shop-image .shop-icons {
  position: absolute;
  opacity: 0;
  transition: all 0.5s ease;
}

.shop-list .shop-image .overlay-work {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.shop-list .shop-image .overlay-work .out-stock {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.shop-list .shop-image .shop-icons {
  top: 15px;
  right: 15px;
  z-index: 2;
}

.shop-list .shop-image:hover .overlay-work,
.shop-list .shop-image:hover .shop-icons {
  opacity: 1;
}

.shop-list .content .product-name {
  transition: all 0.5s ease;
}

.shop-list .content .product-name:hover {
  color: #2f55d4 !important;
}

.shop-list .label {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
}

.shop-list .qty-btn {
  pointer-events: none;
  width: 65px;
  padding-left: 15px;
}
.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}
.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}
.tns-no-calc {
  position: relative;
  left: 0;
}
.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}
.tns-autowidth {
  display: inline-block;
}
.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}
.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}
.tns-ovh {
  overflow: hidden;
}
.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}
.tns-transparent {
  opacity: 0;
  visibility: hidden;
}
.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}
.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}
.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block;
}
.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.desc {
  padding-bottom: 50px;
  padding-top: 10px;
}

.desc h5 {
  color: #c32222;
  margin-left: 30px;
}

.desc p {
  width: 95%;
  text-align: left;
  margin-left: 35px;
}

.retails-products h5 {
  color: #c32222;
  margin-left: 30px;
}

.list {
  min-height: 270px;
  float: left !important;
  margin-top: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.list img {
  width: 200px;
}

.desc {
  background: s;
}

.list p {
  color: #1c1e23;
  font-weight: 100;
  text-align: center;
  font-size: 14px;
}

.add-to-cart {
  border-radius: 0;
  padding: 15px 30px 15px 30px;
  color: #fff;
  font-size: 13px;
  background: #c32222;
}

.btn.btn-icon {
  height: 36px;
  width: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.btn.btn-icon .icons {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.btn.btn-icon.btn-lg {
  height: 48px;
  width: 48px;
  line-height: 46px;
}

.btn.btn-icon.btn-lg .icons {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.btn.btn-icon.btn-sm {
  height: 30px;
  width: 30px;
  line-height: 28px;
}

.btn-soft-primary {
  background-color: rgba(47, 85, 212, 0.05) !important;
  border: 1px solid rgba(47, 85, 212, 0.05) !important;
  color: black !important;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.1);
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus {
  background-color: #c32222 !important;
  border-color: #c32222 !important;
  color: #ffffff !important;
}

.shop-list .qty-btn {
  pointer-events: none;
  width: 65px;
  padding-left: 15px;
}

/*# sourceMappingURL=tiny-slider.css.map */
</style>