<template>
  <Header />
  <section class="section" style="margin-top: 50px">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div v-if="blogInfo">
          <div class="row">
            <div class="col-md-10">
              <ul class="list-unstyled d-flex justify-content-between mt-4">
                <li class="list-inline-item user me-2">
                  <a href="javascript:void(0)" class="text-muted"
                    ><i class="uil uil-user text-dark"></i>
                    {{ direction == "en" ? "Garden Palace" : "قصر الحديقه" }}</a
                  >
                </li>
                <li class="list-inline-item date text-muted">
                  <i class="uil uil-calendar-alt text-dark"></i> Today
                </li>
              </ul>

              <img
                :src="blogInfo.attachment"
                class="img-fluid rounded-md shadow"
                alt=""
              />

              <h5 class="mt-4">{{ blogInfo.title }}</h5>

              <p class="text-muted">{{ blogInfo.desc }}</p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import axios from "axios";
import Header from "../components/Header2.vue";
import Footer from "../components/newFooter.vue";
export default {
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    return {
      direction: direction,
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",
      blogInfo: "",
    };
  },
  methods: {
    async getBlog() {
      let res = await axios.get(
        this.BASEURL + "content/single-blog/" + this.$route.params.id,
        {
          header: {
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "GET",
          },
        }
      );
      this.blogInfo = res.data;
    },
  },
  created() {
    this.getBlog();
  },
  components: {
    Header,
    Footer,
  },
};
</script>
<style scoped>
[class^="uil-"]:before,
[class*=" uil-"]:before {
  margin: 0;
}
.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}
.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}
.tns-no-calc {
  position: relative;
  left: 0;
}
.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}
.tns-autowidth {
  display: inline-block;
}
.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}
.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}
.tns-ovh {
  overflow: hidden;
}
.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}
.tns-transparent {
  opacity: 0;
  visibility: hidden;
}
.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}
.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}
.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block;
}
.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

/*# sourceMappingURL=tiny-slider.css.map */
</style>