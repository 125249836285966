import { createRouter, createWebHistory } from 'vue-router'

var url = window.location.origin
if (window.location.href.includes(url + '/en')) {
  var direction = '/en'
} else if (window.location.href.includes(url + '/ar')) {
  var direction = '/ar'
} else if (window.location.href.includes(url + '/ar/')) {
  var direction = '/ar'
} else if (window.location.href.includes(url + '/en/')) {
  var direction = '/en'
} else if (window.location.href.includes(url + '/ar') === false ||
  window.location.href.includes(url + '/ar/') === false ||
  window.location.href.includes(url + '/en') === false ||
  window.location.href.includes(url + '/en/') === false) {
    if (window.location.href.includes('8080')) {
       window.location.href = window.location.href.replace('8080/', '8080/ar/')
    } else {
      //window.location.href = window.location.href.replace('.com/', '.com/ar/')
    }
    var url = window.location.href;
    var newUrl = url.replace('.com', '.com/en')
    window.location.href = newUrl ?? url
}

import Home from '../views/Home.vue'
import Shop from '../views/Shop.vue'
import singleProduct from "../views/singleProduct.vue"
import cart from "../views/Cart.vue"
import login from "../views/Login.vue"
import register from "../views/Register.vue"
import checkout from "../views/checkout.vue"
import NotFound from "../views/NotFound.vue"
import logout from "../components/logout.vue"
import loading from "../components/loading.vue"
import myorders from "../views/myorders.vue"
import filter from "../views/filter.vue"
import blog from "../views/blog.vue"
import account from "../views/account.vue"
import verfication from "../views/verfication.vue"
import categories from "../views/Categories.vue"
import subCategories from "../views/SubCategories.vue"
import search from "../views/search.vue"

const routes = [
  {
    path: direction + '/',
    name: 'Home',
    component: Home
  },
  {
    path: direction + '/shop/:category/:id',
    name: 'Shop',
    component: Shop
  },
  {
    path: direction + '/product/:id/:name',
    name: 'Single',
    component: singleProduct
  },
  {
    path: direction + '/cart',
    name: 'Cart',
    component: cart
  },
  {
    path: direction + '/login',
    name: 'Login',
    component: login
  },
  {
    path: direction + '/register',
    name: 'Register',
    component: register
  },
  {
    path: direction + '/checkout',
    name: 'checkout',
    component: checkout
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  },
  {
    path: direction + '/logout',
    component: logout
  },
  {
    path: direction + '/loading',
    name: 'loading',
    component: loading,
  },
  {
    path: direction + '/myorders',
    name: 'myorders',
    component: myorders
  },
  {
    path: direction + '/filter/:id/:from/:to/:parent',
    name: 'filter',
    component: filter    
  },
  {
    path: direction + '/blog/:id',
    name: 'blog',
    component: blog    
  },
  {
    path: direction + '/my-account',
    name: 'account',
    component: account    
  },
  {
    path: direction + '/verification',
    name: 'verfication',
    component: verfication        
  },
  {
    path: direction + '/categories',
    name: 'categories',
    component: categories       
  },
  {
    path: direction + '/sub-categories/:id',
    name: 'subCategories',
    component: subCategories       
  },
  {
    path: direction + '/result',
    name: 'search',
    component: search       
  }      
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
