<template>
  <loading class="loader"/>
  <div class="container-fluid">
    <div class="row justify-content-lg-center">
      <div class="col-lg-12">
        <div :class="direction == 'en' ? 'for-image' :'for-image-ar'">
          <img v-on:click="openUrl('/')" :src="logo" alt="" />
          <img v-on:click="openUrl('/')" :src="logo2" alt="" />
        </div>
      </div>
        <p class="error">
          {{direction == 'en' ? error.ar : error_en}}          
        </p>      
      <div class="col-lg-6 form-body" style="">
        <h5>{{direction == 'en' ? 'Register a new Account' : 'انشا حساب جديد'}}</h5>
        <div style="text-align: center; color: red; font-size: 14px;" v-if="error">{{direction == 'en' ? error.error_en : error.error_ar}}</div>
        <form>
          <input v-model="userInput.name" :class="direction == 'en' ? 'input' : 'input-ar'" class="userName" type="text" :placeholder="direction == 'en' ? 'Full name' : 'الاسم بالكامل'" />
          <input v-model="userInput.email" :class="direction == 'en' ? 'input' : 'input-ar'" class="email" type="email" :placeholder="direction == 'en' ? 'Email' : 'البريد الاكتروني'" />
          <input v-model="userInput.phone" :class="direction == 'en' ? 'input' : 'input-ar'" class="phone" type="text" :placeholder="direction == 'en' ? 'Phone number' : 'رقم الجوال'" />
          <input v-model="userInput.password" :class="direction == 'en' ? 'input' : 'input-ar'" class="password" type="password" :placeholder="direction == 'en' ? 'Password' : 'كلمه المرور'" />
          <input v-model="userInput.passwordConfirmation" :class="direction == 'en' ? 'input' : 'input-ar'" class="passwordConfirmation" type="password" :placeholder="direction == 'en' ? 'Password confirmation' : 'تاكيد كلمه المرور'" />
          <Button
            :title="direction == 'en' ? 'Sign Up' : 'انشا حساب'"
            style="
              width: 200px;
              height: 40px;
              color: #fff;
              background: #c32222;
              position: relative;
              left: 35%;
              margin-top: 30px;
              font-size: 13px;
              border-radius: 0;
              -webkit-box-shadow: 0 0 15px rgb(216, 213, 213);
              box-shadow: 0 0 15px rgb(216, 213, 213);
            "
            :style="direction == 'en' ? '' : 'right: 35%;'"
            class="register-button"
            v-on:click="register($event)"
          />
          <p>{{direction == 'en' ? 'Do you have an account?' : 'هل لديك حساب بالفعل؟'}} <a href="#" v-on:click="openUrl('login')">{{direction == 'en' ? 'Login NOW' : 'تسجيل دخول'}}</a></p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../assets/logo.png";
import logo2 from "../assets/garden-logo.png";
import Button from "../components/Button.vue";
import axios from "axios"
import loading from "../components/loading.vue"
export default {
  name: "Register",
  data: () => {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
    }
    return {
      logo: logo,
      logo2: logo2,
      direction: direction,
      userInput: {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        phone: ''
      },
      isLoaded: false,
      error: {},
      BASEURL: window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost') ? "http://127.0.0.1:3000/" : "https://nourdiaa.com:3000/",
      isLogged: false,
      isRegisted: ''
    };
  },
  components: {
    Button,
    loading,
  },
  methods: {
    async register(event) {
      event.preventDefault();
      const button = document.getElementsByClassName('register-button')[0];
      button.disabled = true;
      this.direction == 'en' ? button.innerHTML = 'Please wait' : button.innerHTML = 'انتظر قليلا'
      if (this.userInput.name.length < 8) {
        this.error = {
          error_en: 'Full name should be greater than 8 letters',
          error_ar: 'الاسم يجب ان يكون مكون من اكثر من ٨ حروف'
        }
        let showUserError = document.getElementsByClassName('userName')[0];
        showUserError.style.borderBottom = '1px solid red'
        button.disabled = false;
        button.innerHTML = this.direction == 'en' ? 'Sign Up' : ' انشاء حساب'
        return;
      } else if (this.userInput.email.length == 0) {
        this.error = {
          error_en: 'Email is required',
          error_ar: 'البريد الاكتروني مطلوب'
        }
        let showEmailError = document.getElementsByClassName('email')[0];
        showEmailError.style.borderBottom = '1px solid red'
        button.disabled = false;
        showUserError.style.borderBottom = "1px solid grey"
        button.innerHTML = this.direction == 'en' ? 'Sign Up' : ' انشاء حساب'
        return;
      } else if (this.userInput.password != this.userInput.passwordConfirmation || this.userInput.password.length == 0) {
        this.error = {
          error_en: 'Password does not match the password confirmation',
          error_ar: 'كلمه المرور ليست صحيحه'
        }
        let showError = document.getElementsByClassName('password')[0];
        showError.style.borderBottom = '1px solid red'
        button.disabled = false;       
        button.innerHTML = this.direction == 'en' ? 'Sign Up' : ' انشاء حساب'        
        return;
      }
      this.error = {}

      //setup the body for api 

      const body = {
          name: this.userInput.name,
          password: this.userInput.password,
          passwordConfirmation: this.userInput.passwordConfirmation,
          email: this.userInput.email,
          phone: this.userInput.phone,
        }

      // api callback
      await axios.post(this.BASEURL + 'users/register', body).then( (result) => {
        if (result.status == 200) {
          this.isRegisted = result.data
          document.cookie = "LoggedIn='true';expires=" + 365 * 24 * 60 * 60 * 1000 + ";path=/"
          document.cookie = "access=" + result.data.data.token  + ";expires=" + 365 * 24 * 60 * 60 * 1000 + ";path=/"
          sessionStorage.setItem('fullName', this.userInput.name);
          sessionStorage.setItem('email', this.userInput.email);
          sessionStorage.setItem('LoggedIn', true);
          sessionStorage.setItem('id', result.data.data.id)
          sessionStorage.setItem('access', result.data.data.token)
          window.location.href = '/en/verification'
        }
      }).catch( (error) => {
        this.error = {
          error_en: 'Email address is already registered before!',
          error_ar: 'البريد الاكتروني مسجل لدينا بالفعل!'          
        }
        button.disabled = false;     
        document.getElementsByClassName('error')[0].style.display = 'block'
      });
    },
    openUrl(value) {

      window.location = window.location.origin + '/' + this.direction + '/' + value
    },
    finishedLoading() {
      setTimeout(function() {
        document.getElementsByClassName('loader')[0].style.display = 'none';
      }, 1000);
    }
  },
  created() {
    this.finishedLoading();
    if ("LoggedIn" in sessionStorage) {
      sessionStorage.clear();
      var cookies = document.cookie.split("; ");
      for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
          var cookieBase =
            encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
            "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
            d.join(".") +
            " ;path=";
          var p = location.pathname.split("/");
          document.cookie = cookieBase + "/";
          while (p.length > 0) {
            document.cookie = cookieBase + p.join("/");
            p.pop();
          }
          d.shift();
        }
      }
    } 
  },
  beforeCreate() {
  },
  mounted() {
    this.finishedLoading()
  }
};
</script>

<style scoped>
img {
  display: inline;
}

.error {
  color: #c32222;
  text-align: center;
  display: none;
}

.col-lg-12 {
  width: 100%;
  min-height: 1px;
  margin-bottom: 100px;
}

.for-image {
  margin: 0 auto;
  position: relative;
  left: 37%;
  top: 50%;
}

.for-image-ar {
  margin: 0 auto;
  position: relative;
  right: 37%;
  top: 50%;
}

.form-body form .input {
  display: block;
  border: 0;
  font-size: 14px;
  border-bottom: 1px solid rgb(192, 186, 186);
  padding-bottom: 10px;
  width: 300px;
  margin: 0 auto;
  height: 30px;
  padding-left: 20px;
  outline: none;
  margin-bottom: 20px;
  margin-top: 20px;
}

.form-body form .input-ar {
  display: block;
  border: 0;
  font-size: 14px;
  border-bottom: 1px solid rgb(192, 186, 186);
  padding-bottom: 10px;
  width: 300px;
  margin: 0 auto;
  height: 30px;
  padding-left: 20px;
  outline: none;
  margin-bottom: 20px;
  margin-top: 20px;
}

.form-body {
  -webkit-box-shadow: 0 0 10px rgb(216, 213, 213);
  box-shadow: 0 0 10px rgb(216, 213, 213);
  min-height: 400px;
  margin-bottom: 30px;
}

.form-body form .input::placeholder {
  font-size: 13px;
}

.form-body form .input-ar::placeholder {
  font-size: 13px;
  float: right;
  padding-right: 30px;
}

h5 {
  color: #c32222;
  text-align: center;
  margin-top: 40px;
  letter-spacing: 2px;
}

.forgot {
  font-size: 10px;
  float: right;
  position: relative;
  right: 25%;
}

form p {
  font-size: 13px;
  text-align: center;
  margin-top: 40px;
}
</style>