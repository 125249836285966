<template>
        <div class="back-to-home rounded d-none d-sm-block">
            <a href="index.html" class="btn btn-icon btn-soft-primary"><i data-feather="home" class="icons"></i></a>
        </div>

        <!-- ERROR PAGE -->
        <section class="bg-home d-flex align-items-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-12 text-center">
                        <img :src="e404" class="img-fluid" alt="">
                        <div class="text-uppercase mt-4 display-3">{{direction == 'en' ? 'Oh ! no' : ''}}</div>
                        <div class="text-capitalize text-dark mb-4 error-page">{{direction == 'en' ? 'Page Not Found' : 'هذه الصفحه غير موجوده'}}</div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="col-md-12 text-center">  
                        <a href="/ar/" class="btn btn-outline-primary mt-4">{{direction == "en" ? 'Go Back' : 'الرجوع'}}</a>
                        <a :href="direction == 'en' ? '/en/' : '/ar/'" class="btn btn-primary mt-4 ms-2">{{diretion == 'en' ? 'Go To Home' : 'الصفحه الرئيسيه'}}</a>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- ERROR PAGE -->
</template>

<script>
import e404 from "../assets/404.svg"
export default {
    data: () => {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
    }        
        return {
            e404: e404,
            direction: direction
        }
    }
}
</script>

<style scoped>
.back-to-home {
  position: fixed;
  top: 4%;
  right: 2%;
  z-index: 1;
}

.rounded {
  border-radius: 6px !important;
}

.bg-home {
  height: 100vh;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.btn.btn-icon {
  height: 36px;
  width: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.btn.btn-icon .icons {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.btn.btn-icon.btn-lg {
  height: 48px;
  width: 48px;
  line-height: 46px;
}

.btn.btn-icon.btn-lg .icons {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.btn.btn-icon.btn-sm {
  height: 30px;
  width: 30px;
  line-height: 28px;
}

button:not(:disabled) {
  outline: none;
}

.text-dark {
  color: #3c4858 !important;
}

.btn-outline-primary {
  border: 1px solid #2f55d4;
  color: #2f55d4;
  background-color: transparent;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.focus, .btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #2f55d4;
  border-color: #2f55d4;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.1);
}

.bg-light {
  background-color: #f8f9fc !important;
}

ul.pattern {
  list-style: none outside none;
  overflow: hidden;
  padding: 0;
  border-radius: 0px;
}

ul.pattern li.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 0;
}

ul.pattern li a {
  cursor: pointer;
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin: 3px;
}

ul.pattern .color1 {
  background-color: #2f55d4;
}

ul.pattern .color2 {
  background-color: #6dc77a;
}

ul.pattern .color3 {
  background-color: #7952B3;
}

ul.pattern .color4 {
  background-color: #ff5b69;
}

ul.pattern .color5 {
  background-color: #32c2ec;
}

ul.pattern .color6 {
  background-color: #0f7173;
}

ul.pattern .color7 {
  background-color: #00c9a7;
}

ul.pattern .color8 {
  background-color: #766df4;
}

ul.pattern .color9 {
  background-color: #f7961c;
}

ul .t-dark,
ul .t-rtl-light {
  display: inline-block;
}

ul .t-ltr-light,
ul .t-light,
ul .t-rtl-dark,
ul .t-ltr-dark {
  display: none;
}

#style-switcher .bottom a.settings {
  background: none repeat scroll 0 0 #ffffff;
  height: 41px;
  position: absolute;
  right: -41px;
  top: 0px;
  width: 40px;
  padding: 3px;
  border-radius: 0 30px 30px 0;
}

.coming-soon, .maintenance, .error-page {
  font-size: 40px;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .coming-soon, .maintenance, .error-page {
    font-size: 32px;
  }
}

</style>