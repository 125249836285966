<template >
  <div class="col-lg-12 partners-text">
    <p style="text-align: center !important">
      {{ direction == "en" ? "Partners" : "الشركاء" }}
    </p>
  </div>
  <div v-if="partners.length >= 1">
    <div class="row justify-content-lg-center">
      <div
        class="col-lg-2 col-xs-6 col-sm-6"
        :class="direction == 'en' ? 'partner-card' : 'partner-card-ar'"
        v-for="item in partners" v-bind:key="item"
      >
        <img
          :src="item.attachment"
          alt=""
        />
      </div>
    </div>
  </div>
  <div v-else>
    <p style="color: black">{{direction == 'en' ? 'No parterns are shown' : 'لا يوجد شركا في الوقت الحالي'}}</p>
  </div>
</template>

<script>
import partnerImg from "../assets/logo.png";
import axios from "axios";
export default {
  name: "PartnersComponent",
  props: {},
  data: () => {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    return {
      partnerImg: partnerImg,
      direction: direction,
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",
      partners: {}
    };
  },
  components: {},
  methods: {
    async getPartner() {
      let res = await axios.get(this.BASEURL + "content/partners", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      });
      this.partners = res.data
    },
  },
  created() {
    this.getPartner();
  },
};
</script>

<style scoped>
img {
  width: 120px;
  height: 70px;
}

.partner-card {
  margin-left: 10px;
  height: 99px;
}

.partner-card-ar {
  margin-left: 10px;
  height: 120px;
}

.partner-card img {
  z-index: 999;
  margin: 5px 0px 0px 50px;
}

p {
  color: #b51e30;
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: 700;
  text-align: center !important;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: 1px;
  margin-top: 30px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 500px) {
  .partner-card {
    margin-bottom: 20px;
    max-width: 45% !important;
    display: inline;
  }

  .partner-card-ar {
    margin-bottom: 20px;
    max-width: 45% !important;
    display: inline;
  }

  .partner-card-ar img {
    position: relative;
    left: -27px;
    top: 10px;
  }

  .partner-card img {
    position: relative;
    right: 10px;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
  .partner-card-ar {
    margin-bottom: 20px;
    max-width: 45% !important;
    display: inline-block;
  }

  .partner-card {
    margin-bottom: 20px;
    max-width: 45% !important;
    display: inline-block;
  }
  .partner-card img {
    position: relative;
    left: 75px;
  }
  .partner-card-ar img {
    position: relative;
    right: 115px;
    top: 5px;
  }
}
</style>