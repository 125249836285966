<template>
  <nav class="container">
    <div class="row">
      <div class="col-lg-2 col-xs-6 col-md-2 second-nav-section">
        <img
          v-on:click="openSideBar(direction)"
          style="
            width: 20px;
            height: 10px;
            cursor: pointer;
            z-index: 9999 !important;
          "
          :src="img"
        />
        <img v-on:click="alert('/')"
          :class="direction == 'en' ? 'second-logo' : 'second-logo-ar'"
          :src="smallFlower"
        />
      </div>
      <div class="col-lg-2 col-xs-5 col-md-2 first-nav-section">
        <img
          v-on:click="openUrl('/')"
          :class="direction == 'en' ? 'first-logo' : 'first-logo-ar'"
          :src="gardenFirstLogo"
          alt=""
        />
      </div>
      <div class="col-lg-1  third-nav-section">
        <img
          :class="direction == 'en' ? 'third-logo' : 'third-logo-ar'"
          style="z-index: 0 !important"
          :src="shape"
        />
      </div>
      <div class="col-lg-5" :class="direction == 'en' ? 'middle-nav-section' : 'middle-nav-section-ar'">
        <input
          class="search-input"
          type="text"
          v-bind:placeholder="translation.placeHolder['' + direction + '']"
          v-model="search"
        />
        <Button
          :class="direction == 'en' ? 'search-button' : 'search-button-ar'"
          :title="translation.searchButton['' + direction + '']"
          width="90px"
          height="40px"
          color="#fff"
          outline=""
          border="1px solid #d6d6d6"
          fontSize="12px"
          style="
            position: relative;
            bottom: 2px;
            background: #b51e30;
            border-radius: 0;
          "
          v-on:click="searchResult()"
        />
      </div>

      <div class="col-lg-1 last-nav-section">
        <ul :class="direction == 'en' ? 'list' : 'list-ar'">
          <a v-if="isLogged == false" v-on:click="openUrl('login?redirectTo=/')" href="#">
            <li>
              <span
                ><img style="width: 20px; height: 20px" :src="userIcon"
              /></span>
              {{ isLogged == true ? translation.account["" + direction + ""] : direction == 'en' ? 'Log in' : 'سجل دخول' }}
            </li></a
          >
          <a v-if="isLogged" v-on:click="openUrl('my-account')" href="#">
            <li>
              <span
                ><img style="width: 20px; height: 20px" :src="userIcon"
              /></span>
              {{ isLogged == true ? translation.account["" + direction + ""] : direction == 'en' ? 'Log in' : 'سجل دخول' }}
            </li></a
          >          
        </ul>
      </div>
    </div>
  </nav>

  <div :class="direction == 'en' ? 'side-bar' : 'side-bar-ar'">
    <div :class="direction == 'en' ? 'head' : 'head-ar'">
      <h6 :style="direction == 'en' ? '' : 'font-size: 20px;'">
        {{ direction == "en" ? "Hello , Sign In" : "مرحبا سجل دخولك!" }}
      </h6>
    </div>
    <div :class="direction == 'en' ? 'categories' : 'categories-ar'">
      <h5 :style="direction == 'en' ? '' : 'font-size: 24px;'">
        {{ direction == "en" ? "Categories" : "الاقسام" }}
      </h5>
      <div
        v-for="item in categories"
        :key="item"
        v-on:click="
          goToShop(item.id, direction == 'en' ? item.name_en : item.name_ar)
        "
      >
        {{ direction == "en" ? item.name_en : item.name_ar }}
        <span
          ><img
            :style="
              direction == 'en'
                ? 'width: 20px; float: right; margin-right: 20px'
                : 'width: 20px; float: left; margin-left: 20px;'
            "
            :src="arrow"
            alt=""
        /></span>
      </div>
      <hr />
    </div>
    <div :class="direction == 'en' ? 'help' : 'help-ar'">
      <h5>
        {{ direction == "en" ? "Help & Settings" : "الاعدادات و المساعده" }}
      </h5>
      <div v-on:click="openUrl(isLogged == false ? 'login' : 'my-account')">{{ direction == "en" ? "Your Account" : "الحساب الشخصي" }}</div>
      <div v-on:click="changelang(direction)">
        {{ direction == "en" ? "Arabic" : "English" }}
      </div>
      <div v-on:click="openUrl('categories')">{{ direction == 'en' ? 'Retail' : 'الافراد'}}</div>
      <div v-on:click="openUrl('categories')">{{direction == 'en' ? 'Corporate' : 'الشركات'}}</div>
      <div>{{direction == 'en' ? 'Contact us' : 'اتصل بنا'}}</div>
      <div v-if="isLogged == false" v-on:click="openUrl(isLogged == true ? 'login': '')">{{direction == 'en' ? 'Sign In' : 'تسجيل الدخول' }}</div>
    </div>
  </div>
  <div
    :class="direction == 'en' ? 'other-side' : 'other-side-ar'"
    v-on:click="closeSideBar(direction)"
  ></div>
</template>

<script>
import img from "../assets/Menu_Icon.png";
import gardenFirstLogo from "../assets/garden-logo.png";
import smallFlower from "../assets/logo.png";
import shape from "../assets/Curve_shape.png";
import search from "../assets/Search.png";
import Button from "../components/Button.vue";
import userIcon from "../assets/userIcon.png";
import arrow from "../assets/arrow1.png";
import axios from "axios";
import loading from "../components/loading.vue";

export default {
  props: {
    title: String,
  },
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }

    return {
      gardenFirstLogo: gardenFirstLogo,
      smallFlower: smallFlower,
      shape: shape,
      search: search,
      userIcon: userIcon,
      direction: direction,
      img: img,
      arrow: arrow,
      translation: {
        placeHolder: {
          en: "What are you looking for?",
          ar: "ما الذي تبحث عنه؟",
        },
        account: {
          en: "My Account",
          ar: "حسابي",
        },
        signUp: {
          ar: "تسجيل حساب",
          en: "SIGN UP",
        },
        searchButton: {
          en: "Search",
          ar: "بحث",
        },
      },
      BASEURL: window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost') ? "http://127.0.0.1:3000/" : "https://nourdiaa.com:3000/",
      isLogged: false,
      userInfo: {
        id: '',
        name: '',
        email: '',
        phone: '',
        token: ''
      },
      categories: {},
      search: ''      
    };
  },
  components: {
    Button,
    loading,
  },
  created() {
    this.isLoggedIn();
    this.getUserInfo();
    this.getCategories();
  },
  computed: {
    getParamsofCategories() {
      const params = new URLSearchParams();
      params.append("direction", this.direction);
      params.append("login", this.isLogged);
      return params;
    },
  },
  methods: {
    isLoggedIn() {
      let decodedCookie = decodeURIComponent(document.cookie);
      if (!decodedCookie.includes('LoggedIn')) return this.isLogged = false;
      let ca = decodedCookie.split(";");
      this.getCookies(['loggedIn', 'access'], ca)
    },
    getCookies(value, array) {
      let isLogged = array[0].split("=")
      if (isLogged[1].includes('true')) {
        this.isLogged = true
      } else {
        this.isLogged = false
      }
      let token = array[1].split("=")
      this.userInfo.token = token[1]

    },
    getUserInfo () {
      if (this.isLogged == true) {
        this.userInfo.name = sessionStorage.getItem('fullName')
        this.userInfo.email = sessionStorage.getItem('email')
      }
    },    
    openSideBar(direction) {
      if (direction == "en") {
        var elem = document.getElementsByClassName("side-bar");
        elem[0].style.cssText = "left: 0%;";
        var openOtherSide = document.getElementsByClassName("other-side");
        openOtherSide[0].style.cssText = "right: 0%;";
      } else {
        var elem = document.getElementsByClassName("side-bar-ar");
        elem[0].style.cssText = "right: 0%;";
        var openOtherSide = document.getElementsByClassName("other-side-ar");
        openOtherSide[0].style.cssText = "left: 0%;";
      }
    },
    closeSideBar(direction) {
      if (direction == "en") {
        var elem = document.getElementsByClassName("side-bar");
        elem[0].style.cssText = "left: -100%";
        var openOtherSide = document.getElementsByClassName("other-side");
        openOtherSide[0].style.cssText = "right: -100%;";
      } else {
        var elem = document.getElementsByClassName("side-bar-ar");
        elem[0].style.cssText = "right: -100%";
        var openOtherSide = document.getElementsByClassName("other-side-ar");
        openOtherSide[0].style.cssText = "left: -100%;";
      }
    },
    changelang(value) {
      if (value == "en") {
        if (window.location.href.includes('/en/')) {
          window.location.href = window.location.href.replace("/en/", "/ar/");
        } else if (window.location.href.includes('/en'))
          window.location.href = window.location.href.replace("/en", "/ar/");
      } else {
        if (window.location.href.includes('/ar/')) {
           window.location.href = window.location.href.replace("/ar/", "/en/");
        } else if (window.location.href.includes('/ar')) {
          window.location.href = window.location.href.replace("/ar", "/en/");
        }

      }
    },
    openUrl(value) {
      if (value == 'login') {
        if ("LoggedIn" in sessionStorage) {
          //return window.history.back()
          return;
        } 
      }
      window.location = window.location.origin + '/' + this.direction + '/' + value
    },
    async getCategories() {
      let res = await axios.get(this.BASEURL + "categories", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
        params: this.getParamsofCategories,
      });
      this.categories = res.data;
    },
    goToShop(id, name) {
      window.location =
        window.location.origin +
        "/" +
        this.direction +
        "/shop/" +
        name +
        "/" +
        id + '?page=1&current_is_sub=false';
    },   
    finishedLoading() {
      setTimeout(function() {
        document.getElementsByClassName('loader')[0].style.display = 'none';
      }, 1000);
    } ,
    searchResult() {
      return location.href = '/' + this.direction + '/result?search=' + this.search
    }
  },
    beforeCreate() {
  },
  mounted() {
    this.finishedLoading()
  }
};
</script>

<style scoped>

img:hover {
  cursor: pointer;
}
.second-nav-section {
  z-index: 9999 !important;
}
.first-logo-ar {
  width: 144px;
  height: 60px;
  display: inline;
  position: relative;
  left: 70px;
}
.second-logo-ar {
  width: 65px;
  height: 52px;
  margin-top: 6px;
  margin-right: 20px;
  z-index: 0 !important;
}
.container {
  width: 100%;
  height: 80px;
}
.first-logo {
  width: 144px;
  height: 60px;
  display: inline-block;
  position: relative;
  right: 70px;
}
.second-logo {
  width: 65px;
  height: 52px;
  margin-top: 6px;
  margin-left: 20px;
  z-index: 0 !important;
}
.third-logo {
  width: 423px;
  height: 220px;
  opacity: 0.5;
  position: absolute;
  left: 0%;
}
.third-logo-ar {
  width: 323px;
  height: 220px;
  opacity: 0.5;
  z-index: 0;
}
input {
  width: 330px;
  height: 40px;
  border: 1px solid #d6d6d6;
  border-radius: 0px;
  margin-top: 20px;
  padding-left: 10px;
}
input::placeholder {
  padding: 20px;
  font-size: 13px;
}
input:focus {
  outline: none;
  border: 1px solid #d6d6d6;
}
.last-nav-section {
  width: 190px;
  margin-top: 24px;
}
.list li {
  text-decoration: none;
  list-style: none;
  display: inline-block;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
  color: #b51e30;
  font-family: "Myriad Pro";
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: -0.06px;
  line-height: normal;
}

.list-ar li {
  text-decoration: none;
  list-style: none;
  display: inline-block;
  font-size: 13px;
  margin-right: 5px;
  cursor: pointer;
  color: #b51e30;
  font-family: "Myriad Pro";
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: -0.06px;
  line-height: normal;
}

@media only screen and (max-width: 500px) {
  body {
    overflow: hidden!important;
  }
  footer {
    display: none;
  }
  .search-input {
    display: none !important;
  }
  .first-logo-ar {
    position: absolute;
    top: 0;
    right: 120px;
  }
  .first-logo {
    position: absolute;
    top: 0;
    left: 120px;
  }
  .last-nav-section {
    display: none;
  }
  .search-button {
    display: none;
  }
  .side-bar {
    width: 80% !important;
  }

  .side-bar-ar {
    width: 80% !important;
  }
  .other-side {
    width: 20% !important;
  }

  .other-side-ar {
    width: 20%!important;
  }
  .third-nav-section {
    display: none;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
  .container {
    overflow: hidden;
  }
  .second-nav-section {
    margin-top: 20px;
  }

  .second-logo {
    position: relative;
    bottom: 40px;
    left: 20px;
  }

    .second-logo-ar {
    position: relative;
    bottom: 40px;
    right: 20px;
  }
  .first-logo {
    position: relative;
    left: 5px;
    top: 5px;
  }

  .first-logo-ar {
    position: relative;
    right: 5px;
    top: 5px;   
  }

  .side-bar {
    width: 60%!important;
  }

  .other-side {
    width: 40%!important;
  }

  .side-bar-ar {
    width: 60%!important;
  }

  .other-side-ar {
    width: 40%!important;
  }

  .middle-nav-section {
    position: relative;
    bottom: 105px;
    left: 280px;
  }
  .middle-nav-section-ar {
    position: relative;
    bottom: 325px;
    right: 300px;
  }
  .search-button {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 9999!important;
  }

  .search-button-ar {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 9999!important;    
  }
}

.side-bar {
  width: 28%;
  background: #fff;
  height: 100%;
  position: fixed;
  z-index: 9999 !important;
  left: -100%;
  top: 0;
  transition: 0.5s ease;
  margin: 0;
  padding: 0;
  overflow: scroll;
}

.side-bar-ar {
  width: 28%;
  background: #fff;
  height: 100%;
  position: fixed;
  z-index: 9999 !important;
  right: -100%;
  top: 0;
  transition: 0.5s ease;
  margin: 0;
  padding: 0;
  overflow: scroll;
}

.other-side-ar {
  width: 72%;
  height: 100%;
  background: grey;
  transition: 0.5s ease;
  opacity: 0.7;
  position: fixed;
  left: -80%;
  top: 0;
  z-index: 9999 !important;
  overflow: hidden;
}

.other-side {
  width: 72%;
  height: 100%;
  background: grey;
  transition: 0.5s ease;
  opacity: 0.7;
  position: fixed;
  right: -80%;
  top: 0;
  z-index: 9999 !important;
  overflow: hidden;
}

.head {
  width: 100%;
  height: 60px;
  background: #c32222;
  color: #fff;
}
.head-ar {
  width: 100%;
  height: 60px;
  background: #c32222;
  color: #fff;
}

.head h6,
.help h5 {
  margin-left: 50px;
  position: relative;
  top: 40%;
}

.head-ar h6,
.help-ar h5 {
  margin-right: 50px;
  position: relative;
  top: 40%;
}

.categories div,
.help div {
  width: 100%;
  padding-left: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  list-style: none;
  font-size: 15px;
  font-family: "Open-sans";
  letter-spacing: 1px;
}

.categories-ar div,
.help-ar div {
  width: 100%;
  padding-right: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  list-style: none;
  font-size: 15px;
  font-family: "Open-sans";
}

.categories div:hover,
.help div:hover {
  transition: 0.2s ease;
  background: #f0ebeb;
  cursor: pointer;
}

.categories-ar div:hover,
.help-ar div:hover {
  transition: 0.2s ease;
  background: #f0ebeb;
  cursor: pointer;
}

.categories h5 {
  margin-left: 40px;
  margin-top: 20px;
  font-weight: bold;
}

.categories-ar h5 {
  margin-right: 40px;
  margin-top: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
  body {
    overflow: hidden!important;
  }

  .side-bar {
    width: 60%!important;
  }

  .other-side {
    width: 40%!important;
  }

  .side-bar-ar {
    width: 60%!important;
  }

  .other-side-ar {
    width: 40%!important;
  }

  .search-button {
    position: absolute;
    left: 330px;
    top: -40px;
  }
    .search-button-ar {
    position: absolute;
    right: 330px;
    top: -40px;
  }
  .first-logo-ar {
    position: relative;
    right: 0px;
  }
  .first-logo {
    position: relative;
    left: 0px;
  }

  .first-logo img, .first-logo-ar img:hover {
    cursor: pointer;
  }

  .second-logo img, .second-logo-ar img:hover {
    cursor: pointer;
  }
}
</style>