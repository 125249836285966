<template>
  <button
    class="btn"
    :style="{
      color: buttonColor ?? '',
      width: buttonWidth ?? 0,
      height: buttonHeight ?? 0,
      outline: buttonOutLine,
      border: buttonBorder,
      fontSize: buttonFontSize,
      background: buttonBG,
      textAlign: buttonAlign,
    }"
    type="button"
  >
    {{ buttonName }}
  </button>
</template>

<script>
export default {
  props: ["title", "width", "height", "outline", "border", "fontSize", "color", "bg", "text-align"],
  data() {
    return {
      buttonName: this.title,
      buttonColor: this.color,
      buttonWidth: this.width,
      buttonBG: this.bg,
      buttonHeight: this.height,
      buttonOutLine: this.outline,
      buttonFontSize: this.fontSize,
      buttonBorder: this.border,
      buttonAlign: this.align
    };
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
button {
  padding: 0;
}
button:focus {
    outline: none!important;
}

.btn:focus {
    outline: none!important;
    box-shadow: none;
}
</style>

