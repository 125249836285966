<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <img class="logo1" :src="garden" alt="" />
          <img class="logo" :src="logo" alt="" />
        </div>
        <div class="col-lg-12" style="margin-top: 20px">
          <div class="row">
            <div class="col-lg-3">
              <p>{{address['' + direction +'']}}</p>
              <ul>
                <li>يي</li>
                <li></li>
              </ul>
              <p style="display: inline-block">{{telephone['' + direction + '']}}</p>
              <p style="font-size: 16px !important; display: inline">
                035 - 667 - 880
              </p><br>
              <p style="display: inline-block">{{email['' + direction +'']}}</p>
              <p style="font-size: 15px !important; display: inline">
                garden@garden.com
              </p>
            </div>
            <div class="col-lg-2 col-xs-6" style="margin-left: 30px">
              <ul>
                <li v-for="section in section2" :key="section.id">
                  {{ section.text['' + direction +''] }}
                </li>
              </ul>
            </div>
            <div class="col-lg-2 col-xs-6" style="margin-left: 30px">
              <ul>
                <li v-for="section in section3" :key="section.id">
                  {{ section.text['' + direction +''] }}
                </li>
              </ul>
            </div>
            <div class="col-lg-2 col-xs-6" style="margin-left: 30px">
              <ul>
                <li v-for="section in section4" :key="section.id">
                  {{ section.text['' + direction +''] }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-lg-12"
          style=" width: 100%; min-height: 30px"
        >
          <div class="row">
            <div class="col-lg-6">
                <ul>
                    <li v-for="section in section5" :key="section.id">{{section.text}}</li>
                </ul>
            </div>
            <div class="col-lg-6 col-sm-12 copyRight">
                <h5>{{copyRight['' + direction + '']}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import garden from "../assets/garden-logo.png";
import logo from "../assets/logo.png";

export default {
  name: "Footer",
  data: () => {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    }

    return {
      garden: garden,
      logo: logo,
            direction: direction,
      section2: [
        { id: 1, text: {
          en: 'About us',
          ar: 'عن الشركه',
          }},
        { id: 2, text: {
          en: 'Terms of use',
          ar: 'شروط الاستخدام'
        }},
        { id: 3, text: {
           en: 'Privacy and policy',
           ar: 'السياسات و الخصوصيه' 
          
          }},
        { id: 4, text: {
          en: 'Contact us',
          ar: 'اتصل بنا',
          }},
        { id: 5, text: {
          en: 'Join us',
          ar: 'انضم الينا'
          }},
      ],
      section3: [
        { id: 1, text: {
            en: 'About us',
            ar: 'عن الشركه'
          }},
        { id: 2, text: {
            en: 'Our company',
            ar: 'عن اشركه'
          } },
        { id: 3, text: {
          en: 'News letter',
          ar: 'ما هو جديد',
        } },
        { id: 3, text: 
        
       {
         en: 'Register',
         ar: 'اضافه حساب',
       }  },
        { id: 3, text: {
          en: 'Login',
          ar: 'تسجيل الدخول'
        } },
      ],
      section4: [
        { id: 1, text: {
          en: 'Our projects',
          ar: 'عن مشاريعنا'
        }  },
        { id: 2, text: {
          en: 'Our promotions',
          ar: 'عروضنا'
        } },
        { id: 3, text: {
          en: 'Retail',
          ar: 'المستخدم العادي'
        }  },
        { id: 4, text: {
          en: 'Corporate',
          ar: 'الشركات'
        }  },
        { id: 5, text: {
          en: 'Categories',
          ar: 'الاقسام'
        }},
        { id: 6, text: {
          en: 'Our partners',
          ar: 'شركانا'
        } },
      ],
      section5: [
          {id: 1, text: 'Facebook.'},
          {id: 2, text: 'Instagram.'},
          {id: 3, text: 'Twitter.'},
          {id: 4, text: 'Google Plus.'},
          {id: 5, text: 'Youtube'},

      ],
      address: {
        en: 'Address',
        ar: 'العنوان:'
      },
      telephone: {
        en: 'Telephone',
        ar: 'رقم التواصل'
      },
      email: {
        en: 'Email address',
        ar: 'البريد الاكتروني'
      },
      copyRight: {
        en: 'Copyright © 2021 Garden palace All Rights Reserved.',
        ar: 'جميع الحقوق محفوظه لدي قصر الحديقه ٢٠٢١'
      }
    }
  },
};
</script>

<style scoped>
footer {
  width: 100%;
  height: 300px;
  bottom: 0;
}

.logo1 {
  width: 150px;
}

.logo {
  width: 50px;
  margin-left: 10px;
}

p {
  /* Style for "Address: 9" */
  color: #d4182d;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 34.38px;
  text-align: left;
  /* Text style for "Address: ," */
  font-family: "Roboto - Bold";
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  /* Text style for "92 Av. des" */
  font-family: "Roboto - Regular";
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  /* Text style for "" */
  font-family: "Myriad Pro";
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  /* Text style for "+33 7 58 6" */
  font-family: "Roboto - Regular";
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  /* Text style for "" */
  font-family: "Roboto - Bold";
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  /* Text style for "" */
  font-family: "Roboto - Regular";
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

li {
  text-decoration: none;
  list-style: none;
  color: #d4182d;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 34.38px;
  text-align: left;

}

h5 {
width: 454px;
height: 18px;
color: #d3182d;
font-family: Roboto;
font-size: 15px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
line-height: 42.32px;
text-align: right;
/* Text style for "Copyright" */
font-style: normal;
letter-spacing: 0.28px;
line-height: normal;
margin-top: 7px;
}

.col-lg-6 ul li {
  text-decoration: none;
  list-style: none;
  display:inline-block;
  font-size: 15px;
  margin-left: 5px;
}

@media only screen and (max-width: 500px) {
  .copyRight h5{
    text-align: left;
    display: none;
  }
  footer {
    height: 300px;
    max-width: 80%;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
    .copyRight h5{
    text-align: left;
    display: none;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
  
}
</style>