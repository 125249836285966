<template>
  <div class="container-fluid">
    <div class="project-slider">
      <h3>Projects</h3>
      <img :src="arrow" alt="" />
    </div>
    <Button
      :title="direction == 'en' ? 'See more' : 'مشاهده المزيد'"
      style="
        color: #fdfdfb;
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: justifyLeft;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        width: 141px;
        height: 34px;
        background-color: #242b42;
        margin-bottom: 30px;
        position: relative;
        left: 44%;
        z-index: 9999!important;
      "
    />
  </div>
</template>

<script>
import arrow from "../assets/arrow-down.png";
import Button from "../components/Button.vue";

export default {
  name: "Projects",
  props: {},
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    }    
    return {
      direction: direction,
      arrow: arrow,
    };
  },
  components: {
    Button,
  },
};
</script>

<style scoped>
.project-slider {
  background-image: url("../assets/projects.png");
  background-position: center;
  background-size: cover;
  background-size: 2000px 100%;
  height: 500px;
  min-width: 100%;
  width: 100% !important;
  object-fit: contain;
  margin-bottom: 20px;
}

h3 {
  /* Style for "Projects" */
  color: #f5f0f0;
  font-family: "Open Sans - Semi Bold";
  font-size: 60px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
  /* Text style for "Projects" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  position: relative;
  top: 100px;
  transform: uppercase;
}

img {
  width: 40px;
  position: relative;
  top: 130px;
  left: 48%;
}

@media only screen and (max-width: 800px) {
  .container-fluid {
    display: none;
  }
}
</style>