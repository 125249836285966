<template >
  <div class="container-fluid" style="">
    <div class="row">
    <Header2 />
      <div :class="direction == 'en' ? 'box' : 'box-ar'">
           <span v-on:click="hidePopUp()">X</span> <p>{{direction == 'en' ? 'This item has been added to cart' : 'تم اضافه المنتج في عربه التسوق'}}</p>
      </div>
      <hr class="line" style="margin-top: 5px; height: 0.05em" />
      <div class="col-lg-12" style="display: none;">
        <ul class="cat-list">
          <li
            v-for="item in categories"
            :key="item"
            v-on:click="
              goToShop(item.id, direction == 'en' ? item.name_en : item.name_ar)
            "
          >
            {{ direction == "en" ? item.name_en : item.name_ar }}
          </li>
        </ul>
      </div>
      <div
        class="col-lg-12 hide-slider"
        style="height: 400px; background: black"
      >
        <img
          :style="direction == 'en' ? 'width: 200px; position: absolute; right: 300px' : 'width: 200px; position: absolute; left: 300px'"
          :src="Slider"
          :class="direction == 'en' ? 'slider-image' : 'slider-image-ar'"
          alt=""
        />
        <h1
          :class="direction == 'en' ? 'slider-head' : 'slider-head-ar'"
          style="
            color: #c32222;
            font-family: 'MyriadArabic-Regular,OpenSans-Bold,AdobeInvisFont,MyriadPro-Regular';
          "
        >
          Artificial Plants
        </h1>
        <small :class="direction == 'en' ? 'slider-body' : 'slider-body-ar'"
          >At first, for some time, I was not able to answer him one word; but
          as he had taken me in his arms I held fast by him, or I should have
          fallen to the ground.</small
        >
        <Button
          :class="direction == 'en' ? 'slider-button' : 'slider-button-ar'"
          :title="translation.buyNow['' + direction + '']"
          style="
            width: 150px;
            height: 30px;
            color: #fff;
            background: #b3000c;
            border-radius: 10px;
            position: relative;
            left: 210px;
            top: 20px;
          "
        />
      </div>
      <div class="col-lg-12" style="background: #eeeeee; padding-bottom: 20px;">
        <div class="sort-list" style="background: #fff;">
          <ul>
            <li>{{ translation.sortBy["" + direction + ""] }}</li>
            <li>{{ translation.bestSelling["" + direction + ""] }}</li>
            <li><img style="height: 50px" :src="line" alt="" /></li>
            <li>{{ translation.latest["" + direction + ""] }}</li>
            <li>
              {{ translation.price["" + direction + ""] }}
              <input
                v-model="price.from"
                class="margin-right: 10px;"
                type="text"
              />
              -
              <input v-model="price.to" type="text" />
            </li>
            <li style="color: #00e2aa">
              <button
                class="btn"
                v-on:click="filterProducts()"
                style="background: #b51e30; color: #fff; font-size: 10px"
              >
                {{ direction == "en" ? "Search" : "بحث" }}
              </button>
            </li>
            <li><img style="height: 50px" :src="line" alt="" /></li>
            <li>{{ translation.shipsTo["" + direction + ""] }}</li>
            <li>{{ translation.currency["" + direction + ""] }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" style="margin-top: 30px; background: #eeeeee;">
  <div class="container" style="margin-top: 30px;">
    <div class="row justify-content-lg-center">
      <div class="col-lg-3" style="margin-top: 30px; min-height: 100px; background: #fff;">
        <div class="row justify-content-lg-center" style="background: #fff;">
         <div class="col-lg-12 cat-view">
        <div class="">
         <h6 :class="direction == 'en' ? '' : 'ar-h6'">
          {{ translation.cat["" + direction + ""] }}
        </h6>
        <ul class="categories" v-if="subCat.length >= 1">
          <li
            v-on:click="
              goToShop(
                item.id,
                direction == 'en' ? item.name_en : item.name_ar,
                true
              )
            "
            v-for="item in subCat"
            :key="item"
          >
            {{ direction == "en" ? item.name_en : item.name_ar }}
          </li>
        </ul>
        <ul class="categories" v-else>
          <li
            v-on:click="
              goToShop(
                item.id,
                direction == 'en' ? item.name_en : item.name_ar,
                false
              )
            "
            v-for="item in categories"
            :key="item"
          >
            {{ direction == "en" ? item.name_en : item.name_ar }}
          </li>
        </ul>
        </div>
          </div>
          <div class="col-lg-12">
            <div class="aval">
              <h6 :class="direction == 'en' ? '' : 'ar-h6'">
                {{ translation.aval["" + direction + ""] }}
              </h6>
              <label class="container"
                >In stock
                <span class="checkmark"></span>
                <input
                  style="width: 10px; height: 10px; border-radius: 50%"
                  type="checkbox"
                  checked="checked"
                />
              </label>
            </div>
          </div>
        </div>
        <div class="container stock">
          <label class="container"
            >Out of stock
            <span class="checkmark"></span>
            <input
              style="width: 10px; height: 10px; border-radius: 50%"
              type="checkbox"
            />
          </label>          
        </div>
        <h6 :class="direction == 'en' ? '' : 'ar-h6'">
          {{ translation.type["" + direction + ""] }}
        </h6>
        <ul class="categories">
          <li>Retail</li>
          <li>Corporate</li>
        </ul>
      </div>
      <div class="col-lg-9" v-on:scroll="scroll()">
        <div class="row products-view">
          <div
            :style="
              products.length >= 1
                ? 'display: block; margin-bottom: 30px;'
                : 'display: none; margin-bottom: 30px;'
            "
            v-for="item of products"
            v-bind:key="item"
            class="col-lg-3 col-md-6 append"
            :class="direction == 'en' ? 'list' : 'list-ar'"
            style="margin-left: 15px; margin-right: 15px; background: #fff;"
          >
            <div class="over-lay">
              <button
                class="btn"
                style="
                  width: 150px;
                  font-size: 12px;
                  background: #b51e30;
                  color: #fff;
                  letter-spacing: 1px;
                  margin-bottom: 20px;
                "
                v-on:click="viewThisProduct(item.id, item.common_name)"
              >
                Review
              </button>
              <button
                class="btn"
                style="
                  width: 150px;
                  font-size: 12px;
                  background: #b51e30;
                  color: #fff;
                  letter-spacing: 1px;
                "
                v-on:click="addThisToCart(item.id)"
              >
                Add to cart
              </button>
            </div>
            <div v-if="item.attachments[0]">
              <img :src="item.attachments[0].url" alt="" />
            </div>
            <div v-else>
              <img style="width: 200px; height: 270px" :src="logo" alt="" />
            </div>
            <p style="margin-top: 5px; font-weight: 600">
              {{ item.common_name }}
            </p>
            <p style="margin-top: -10px; color: green">In stock</p>
            <p style="margin-top: -10px">{{ item.price + " SAR" }}</p>
          </div>
          <div
            :style="products.length >= 1 ? 'display: none;' : 'display: block;'"
          >
            <h5 style="text-align: center; margin-top: 40px">
              {{
                direction == "en"
                  ? "There is no products available for this cateogry"
                  : "ليس هناك اي منتجات متاحه لهذا القسم"
              }}
            </h5>
          </div>
        </div>
        <div
          class="col-lg-12"
          :style="products.length == 0 ? 'display: none' : ''"
        >
          <ul
            class="page this-page"
            :class="direction == 'en' ? '' : 'page-ar'"
          >
            <li v-on:click="goPrev">
              {{ translation.previous["" + direction + ""] }}
            </li>
            <!-- <li>1</li>
          <li
            style="
              border-radius: 50%;
              padding: 11px;
              -webkit-box-shadow: 0 0 10px grey;
              box-shadow: 0 0 8px grey;
            "
          >
            2
          </li>
          <li>3</li> -->
            <li v-on:click="goNext">
              {{ translation.next["" + direction + ""] }}
            </li>
          </ul>
        </div>
        <div
          class="col-lg-12"
          style="margin-top: 40px"
          :style="products.length == 0 ? 'display: none' : ''"
        ></div>
      </div>
      <div class="bound"></div>
      <div class="col-lg-12" style="margin-bottom: 40px">
        <div class="row justify-content-lg-center">
          <p
            style="text-align: center; color: #343e5c"
            :style="
              direction == 'en'
                ? 'letter-spacing: 2px'
                : 'letter-spacing: 0!important; font-size: 22px;'
            "
          >
            {{ translation.bestSelling["" + direction + ""] }}
          </p>
          <div
            class="col-lg-2 col-md-6"
            :class="direction == 'en' ? 'list' : 'list-ar'"
          >
            <img :src="planet" alt="" />
            <p style="margin-top: 5px; font-weight: 600">
              Product number 1 hello wrold people please
            </p>
            <p style="margin-top: -10px">In stock</p>
            <p style="margin-top: -10px">420 SAR</p>
          </div>
          <div
            class="col-lg-2 col-md-6"
            :class="direction == 'en' ? 'list' : 'list-ar'"
          >
            <img :src="planet" alt="" />
            <p style="margin-top: 5px; font-weight: 600">
              Product number 1 hello wrold people please
            </p>
            <p style="margin-top: -10px">In stock</p>
            <p style="margin-top: -10px">420 SAR</p>
          </div>
          <div
            class="col-lg-2 col-md-6"
            :class="direction == 'en' ? 'list' : 'list-ar'"
          >
            <img :src="planet" alt="" />
            <p style="margin-top: 5px; font-weight: 600">
              Product number 1 hello wrold people please
            </p>
            <p style="margin-top: -10px">In stock</p>
            <p style="margin-top: -10px">420 SAR</p>
          </div>
          <div
            class="col-lg-2 col-md-6"
            :class="direction == 'en' ? 'list' : 'list-ar'"
          >
            <img :src="planet" alt="" />
            <p style="margin-top: 5px; font-weight: 600">
              Product number 1 hello wrold people please
            </p>
            <p style="margin-top: -10px">In stock</p>
            <p style="margin-top: -10px">420 SAR</p>
          </div>
          <div
            class="col-lg-2 col-md-6"
            :class="direction == 'en' ? 'list' : 'list-ar'"
          >
            <img :src="planet" alt="" />
            <p style="margin-top: 5px; font-weight: 600">
              Product number 1 hello wrold people please
            </p>
            <p style="margin-top: -10px">In stock</p>
            <p style="margin-top: -10px">420 SAR</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <newFooter />
</template>

<script>
import axios from "axios";
import line from "../assets/line.png";
import check from "../assets/check.png";
import checkbBox from "../assets/checkbox.png";
import planet from "../assets/planet.png";
import planet2 from "../assets/planet2.png";
import Footer from "../components/Footer.vue";
import Slider from "../assets/silder-planet.png";
import Button from "../components/Button.vue";
import Header2 from "../components/Header2.vue";
import newFooter from "../components/newFooter.vue";
import defaultLogo from "../assets/default.png";

export default {
  name: "Shop",
  props: {},
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
    }
    return {
      line: line,
      check: check,
      planet: planet,
      planet2: planet2,
      checkbBox: checkbBox,
      Slider: Slider,
      logo: defaultLogo,
      categories: {},
      subCat: {},
      direction: direction,
      page: parseInt(this.$route.query.page),
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",
      isLogged: false,
      products: "",
      counter: "",
      isCalled: false,
      translation: {
        buyNow: {
          en: "Buy now",
          ar: "اشتري الان",
        },
        sortBy: {
          en: "SORT BY",
          ar: "التصنيف حسب",
        },
        bestSelling: {
          en: "Best Selling",
          ar: "الافضل مبيعآ",
        },
        latest: {
          en: "Latest",
          ar: "الاخير",
        },
        price: {
          en: "Price",
          ar: "السعر",
        },
        freeShipping: {
          en: "Free Shipping",
          ar: "التوصيل المجاني",
        },
        shipsTo: {
          en: "Ships To",
          ar: "التوصيل الي",
        },
        currency: {
          en: "SAR",
          ar: "ريال سعودي",
        },
        cat: {
          en: "Categories",
          ar: "الاقسام",
        },
        aval: {
          en: "AVAILABLE",
          ar: "المتاح",
        },
        type: {
          en: "Type",
          ar: "النوع",
        },
        previous: {
          en: "PREVIOUS",
          ar: "السابق",
        },
        next: {
          en: "NEXT",
          ar: "التالي",
        },
      },
      price: {
        from: "",
        to: "",
      },
      currentX: "",
      totalPages: "",
    };
  },
  methods: {
    goToShop(id, name, sub = false) {
      window.location =
        window.location.origin +
        "/" +
        this.direction +
        "/shop/" +
        name +
        "/" +
        id +
        "?page=1&current_is_sub=" +
        sub;
    },
    async getCategories() {
      if (location.href.includes("all-products")) {
      }
      let res = await axios.get(this.BASEURL + "categories", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
        params: this.getParamsofCategories,
      });
      this.categories = res.data;
    },
    async getSubCats() {
      let res = await axios.get(
        this.BASEURL + "categories/sub-cat/" + this.$route.params.id,
        {
          header: {
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "GET",
          },
        }
      );
      this.subCat = res.data;
    },
    async getProducts() {
      if (window.location.href.includes("/shop/All")) {
        let res = await axios.get(
          this.BASEURL + "products/" + "?page=" + this.$route.query.page,
          {
            header: {
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Allow-Methods": "GET",
            },
          }
        );
        this.products = res.data;
        this.counter = res.data.count;
        this.totalPages = res.data.totalPages;
      } else {
        let res = await axios.get(
          this.BASEURL +
            "products/" +
            this.$route.params.id +
            "?page=" +
            this.$route.query.page +
            "&sub=" +
            this.$route.query.current_is_sub,
          {
            header: {
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Allow-Methods": "GET",
            },
          }
        );
        this.products = res.data.products;
        this.counter = res.data.count;
        this.totalPages = res.data.totalPages;
      }
    },
    async addThisToCart(value) {
      // checking if the user is logged in already
      if (!sessionStorage.getItem("LoggedIn")) {
        return (location.href =
          "/" + this.direction + "/login?redirectsTo=/cart");
      }



      //setup the body for api
      const body = {
        //userId: sessionStorage.getItem("id"),
        productId: value,
      };

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      // api callback
      let response = await axios.post(
        this.BASEURL + "products/addtocart",
        body
      );

      if (response.status == 200) {
        if (this.direction == 'en') {
          document.getElementsByClassName('box')[0].style.right = '10px'
          setTimeout(() => {
            document.getElementsByClassName('box')[0].style.right = '-1000px'
          }, 4000)
        } else {
          document.getElementsByClassName('box-ar')[0].style.left = '10px'
          setTimeout(() => {
            document.getElementsByClassName('box-ar')[0].style.left = '-1000px'
          }, 4000)          
        }

      } else {
      }
    },
    viewThisProduct(value, name) {
      return (window.location.href =
        "/" + this.direction + "/product/" + value + "/" + name);
    },
    async goNext() {
      if (this.totalPages == parseInt(this.$route.query.page)) {
        return false;
      }

      this.page = this.page == 0 ? 2 : this.page + 1;
      var url = window.location.href;
      var urlSplit = url.split("?");
      console.log(urlSplit[0] + "?page=" + this.page);
      var obj = { Title: "New title", Url: urlSplit[0] + "?page=" + this.page };
      history.pushState(obj, obj.Title, obj.Url);
      window.location.reload();
    },
    async goPrev() {
      if (parseInt(this.$route.query.page) == 1) {
        return false;
      }

      this.page = this.page == 1 ? 1 : this.page - 1;
      var url = window.location.href;
      var urlSplit = url.split("?");
      console.log(urlSplit[0] + "?page=" + this.page);
      var obj = { Title: "New title", Url: urlSplit[0] + "?page=" + this.page };
      history.pushState(obj, obj.Title, obj.Url);
      window.location.reload();
    },
    async appendProducts(page) {
      let res = await axios.get(
        this.BASEURL +
          "products/" +
          this.$route.params.id +
          "?page=" +
          this.$route.query.page,
        {
          header: {
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "GET",
          },
        }
      );
    },
    async filterProducts() {
      let from = this.price.from ? this.price.from : 0;
      let to = this.price.to ? this.price.to : 0;
      let isNotParent = this.$route.query.current_is_sub;

      if (from == 0 && to == 0) {
        return false;
      }
      window.location.href =
        "/" + this.direction + "/filter/" +
        this.$route.params.id +
        "/" +
        from +
        "/" +
        to +
        "/" +
        isNotParent +
        "?page=" +
        this.$route.query.page;
    },
    hidePopUp() {
      if(this.direction == 'en') {
        document.getElementsByClassName('box')[0].style.right = "-1000px"
      } else {
        document.getElementsByClassName('box-ar')[0].style.left = "-1000px"
      }
    }
  },
  beforeCreate() {
    if (!("page" in this.$route.query)) {
      location.href = location.href.split("?")[0] + "?page=1";
    }
  },
  created() {
    this.getCategories();
    this.getSubCats();
    this.getProducts();
    //window.addEventListener("scroll", this.scroll);
  },
  computed: {
    getParamsofCategories() {
      const params = new URLSearchParams();
      params.append("direction", this.direction);
      params.append("login", this.isLogged);
      return params;
    },
  },
  components: {
    Button,
    Footer,
    Header2,
    newFooter,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Yaldevi:wght@200&display=swap");
h1 {
  word-spacing: 1px;
  letter-spacing: 1px;
  margin-top: 70px;
  position: relative;
  left: 200px;
}

small {
  font-family: "Yaldevi", sans-serif;
  color: #fff;
  width: 350px;
  display: block;
  position: relative;
  left: 210px;
}

.sort-list {
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}

.sort-list ul li {
  color: #a1a8bd;
  font-size: 14px;
  list-style: none;
  text-decoration: none;
  display: inline;
  padding: 15px 15px 15px 15px;
  margin-left: 20px;
  cursor: pointer;
}

input {
  background: #e3e8f0;
  outline: none;
  border: none;
  border-radius: 10px;
  width: 60px;
  height: 30px;
  margin-left: 5px;
}

.categories li {
  color: #a1a8bd;
  text-decoration: none;
  list-style: none;
  padding: 10px 5px 10px 5px;
  border-bottom: 0.0005em solid rgb(238, 235, 235);
  font-size: 13px;
  cursor: pointer;
}

.list {
  min-height: 320px;
  max-height: 320px;
  float: left !important;
  margin-top: 20px;
  cursor: pointer;
  width: 250px;
}

.list-ar {
  min-height: 320px;
  max-height: 320px;
  float: left !important;
  margin-top: 20px;
  cursor: pointer;
  width: 250px;
}

.over-lay {
  width: 210px;
  height: 270px !important;
  max-height: 270px;
  position: absolute;
  margin-left: 30px;
  overflow: hidden;
  z-index: 999;
  display: none;
}

.list:hover .over-lay {
  transition: 0.5 ease;
  transition-delay: 500ms;
  display: block;
}

.list-ar:hover .over-lay {
  transition: 0.5 ease;
  transition-delay: 500ms;
  margin-right: 25px;
  display: block;
}

.over-lay button {
  position: relative;
  top: 50px;
}

.list img {
  width: 250px!important;
  max-width: 250px!important;
  position: relative;
  right: 12px;
  height: 220px;
  max-height: 220px;
}

.list-ar img {
  width: 250px!important;
  max-width: 250px!important;
  position: relative;
  right: 1200px;  
  height: 220px;
  max-height: 220px;
  position: relative;
  right: -12px;
}

.list p {
  color: #1c1e23;
  font-weight: 100;
  text-align: center;
  font-size: 15px;
}

.list-ar p {
  color: #1c1e23;
  font-weight: 100;
  text-align: center;
  font-size: 14px;
}

.page {
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  left: 220px;
}

.page li {
  text-decoration: none;
  list-style: none;
  display: inline;
  margin-left: 40px;
  color: #fff;
  background: #b51e30;
  font-size: 16px;
  cursor: pointer;
  padding: 15px 30px 15px 30px;
  border-radius: 5px;
  font-weight: 300;
}

label {
  color: #343e5c;
  font-size: 13px;
  margin-left: 5px;
}

h6 {
  color: #343e5c;
  font-size: 15px;
  letter-spacing: 2px;
  margin-left: 25px;
  margin-top: 30px;
  border-bottom: 1px solid rgb(221, 216, 216);
  padding-bottom: 10px;
}

.ar-h6 {
  color: #343e5c;
  font-size: 19px;
  letter-spacing: 0 !important;
  margin-left: 25px;
  margin-top: 30px;
  border-bottom: 1px solid rgb(221, 216, 216);
  padding-bottom: 10px;
}

.cat-list {
  width: 100%;
}

.cat-list li {
  display: inline;
  text-decoration: none;
  margin: 0 auto;
  margin-left: 20px;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Yaldevi", sans-serif;
  color: #b9bed1;
  cursor: pointer;
}

.cat-list li:hover {
  transition: 0.5s ease;
  color: black;
}

.slider-image-ar {
  
}

.slider-head-ar {
  position: absolute;
  right: 300px;
}

.slider-body-ar {
  position: absolute;
  top: 300px;
  right: 300px;
}

.slider-button-ar {
  position: absolute!important;
  right: 300px;
  top: 400px!important;
}

.page-ar {
  position: relative;
  right: 450px;
}

@media only screen and (max-width: 500px) {
  .cat-list {
    display: none;
  }

  .hide-slider {
    display: none;
  }

  .sort-list {
    display: none;
  }

  .line {
    display: none;
  }

  .list img {
  }

  .list-ar img {
  }

  .list-ar {
    position: relative;
    right: 30px;
  }

 .list {
    position: relative;
    left: 30px;
  }  

  .page {
    left: 0;
    display: none;
  }
  .page li {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
  .cat-list {
    display: none;
  }

  .hide-slider {
    display: none;
  }

  .sort-list {
    display: none;
  }

  .line {
    display: none;
  }

  .page {
    display: none;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
  .line {
    display: none;
  }
  .cat-list {
    display: none;
  }

  .hide-slider {
    display: none;
  }
  .list img {
    width: 170px;
  }

  .list-ar img {
    width: 170px;
    position: relative;
    right: -5px !important;
  }
  .this-page {
    display: none;
  }
}

.box-ar {
  position: fixed;
  z-index: 9999!important;
  top: 150px;
  left: -1000px;
  background: #fff;
  overflow: hidden;
  width: 300px;
  height: 100px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px rgb(243, 241, 241);
        box-shadow: 0 0 10px rgb(243, 241, 241);
        transition: 0.5s ease;
}

.box-ar p {
  text-align:center;
  position: relative;
  top: 40%;
}

.box-ar span {
  float: left;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 22px;
  cursor: pointer;
}



.box {
  position: fixed;
  z-index: 9999!important;
  overflow: hidden;
  top: 150px;
  right: -1000px;

  background: #fff;
  width: 350px;
  height: 100px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px rgb(243, 241, 241);
        box-shadow: 0 0 10px rgb(243, 241, 241);
        transition: 0.5s ease;
}

.box p {
  text-align:center;
  position: relative;
  top: 40%;
}

.box span {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 22px;
  cursor: pointer;
}

body {
  overflow:hidden;
}

.cat-view {
  margin-bottom: 20px; 
        background: #fff;
  padding-bottom: 30px;  
}




</style>