<template>
  <loading class="loader"/>
  <Header />
  <div class="container-fluid first-level-home">
    <div class="row">
      <div class="col-lg-12">
        <ul>
          <li
            v-for="item in items"
            :class="direction == 'ar' ? 'arabic-font' : ''"
            v-on:click="openUrl(item.dir)"
            :key="item.id"
          >
            {{ item.text["" + direction + ""] }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div :class="direction == 'en' ? 'slider' : 'slider-ar'">
      <img :src="sliderImg" />
    </div>
    <div
      :class="direction == 'en' ? 'over-lay-image' : 'over-lay-image-ar'"
    ></div>
    <div class="over-lay-text">
      <h2>{{ direction == 'en' ? title1.en : title1.ar }}</h2>
      <p>{{ direction == 'en' ? subTitle1.en : subTitle1.ar }}</p>
    </div>
    <div class="over-lay-button">
      <Button
        v-on:click="openUrl('shop')"
        :class="direction == 'en' ? '' : 'arabic-font'"
        :title="direction == 'en' ? 'Corporate' : 'شركة كبرى'"
        color="#fff"
        :style="
          direction == 'en'
            ? 'width: 220px; height: 80px; box-shadow: 0 1px 0 rgba(0, 0, 0, 0.99); background-color: #000000;float: left;'
            : 'width: 220px; height: 80px; box-shadow: 0 1px 0 rgba(0, 0, 0, 0.99); background-color: #000000;float: left; letter-spacing: 0px; word-spacing: 2px; font-size: 22px;'
        "
      />
      <Button
        v-on:click="openUrl('shop')"
        :title="direction == 'en' ? 'retail' : 'الافراد'"
        :class="direction == 'en' ? '' : 'arabic-font'"
        color="#fff"
        :style="
          direction == 'en'
            ? 'width: 220px;height: 80px;box-shadow: 0 1px 5px #000000;background-color: #b51e30;float: right;'
            : 'width: 220px;height: 80px;box-shadow: 0 1px 5px #000000;background-color: #b51e30;float: right;letter-spacing: 0px; word-spacing: 2px; font-size: 22px;'
        "
      />
    </div>
  </div>
  <div class="container-fluid partners-section">
    <div class="row justify-content-center">
      <partner />
      <products />
      <GardenPalaceWithComps />
      <NewsLetter />
      <Payments />
      <newFooter />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
//import slider from "../assets/slider-1.png";
//import slider from "../assets/Androctonus bicolor (Black Fat-Tailed).PNG.jpg"
import Button from "../components/Button.vue";
import partner from "../components/PartnersComponent.vue";
import products from "../components/Agri-products.vue";
import GardenPalaceWithComps from "../components/GardenPalaceWithComps.vue";
import Projects from "../components/Projects.vue";
import NewsLetter from "../components/NewsLetter.vue";
import Payments from "../components/Payments.vue";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import loading from "../components/loading.vue";
import newFooter from "../components/newFooter.vue"
import axios from "axios"

export default {
  name: "Home",
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
    }
    return {
      items: [
        {
          id: 1,
          text: {
            en: "Categories",
            ar: "الاقسام",
          },
          dir: "categories",
        },
        {
          id: 2,
          text: {
            en: "Partners",
            ar: "شركاء",
          },
        },
        {
          id: 3,
          text: {
            en: "Projects",
            ar: "المشاريع",
          },
        },
        {
          id: 5,
          text: {
            en: "About us",
            ar: "عنا",
          },
        },
        {
          id: 6,
          text: {
            en: "Contact us",
            ar: "اتصل بنا",
          },
        },
        {
          id: 6,
          text: {
            en: "Corporate products",
            ar: "منتجات الشركات",
          },
          dir: 'categories',          
        },
        {
          id: 6,
          text: {
            en: "Retail products",
            ar: "منتجات الافراد",
          },
          dir: 'categories',          
        },                   
      ],
      direction: direction,
      //slider: slider,
      translation: {
        buyNow: {
          en: "Buy now",
          ar: "اشتري الان",
        },
      },
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",      
      content: {},
      title1: '',
      subTitle1: '',
      sliderImg: '',
    };
  },
  components: {
    Button,
    partner,
    products,
    GardenPalaceWithComps,
    Projects,
    NewsLetter,
    Payments,
    Footer,
    Header,
    loading,
    newFooter,
  },
  methods: {
    openUrl(value) {
      if (value.includes("ar/")) {
        window.location.href = window.location.origin + "/" + value;
      } else {
        window.location.href = window.location.origin + "/" + value;
      }
    },
    openUrl(value) {
      window.location =
        window.location.origin + "/" + this.direction + "/" + value;
    },
    finishedLoading() {
      setTimeout(function() {
        document.getElementsByClassName('loader')[0].style.display = 'none';
      }, 1000);
    },
    async getContent() {
      let res = await axios.get(this.BASEURL + "content/home", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      });
      res.data.filter( (result) => {
        if (result.page == 'home_slider_1') {
          this.title1 = {
            en: result.content_en,
            ar: result.content_ar
          }
        }
        if (result.page == 'home_slider_2') {
           this.subTitle1 = {
            en: result.content_en,
            ar: result.content_ar
          }         
        }

        if (result.page == 'home_slider_image') {
          this.sliderImg = result.content_en
        }

        if (result.page == 'home_aboutus') {
          this.about = {
            en: result.content_en,
            ar: result.content_ar
          }
        }

        if (result.page == 'home_history') {
          this.history = {
            en: result.content_en,
            ar: result.content_ar
          }
        }

        if (result.page == 'home_projects') {
          this.projects = {
            en: result.content_en,
            ar: result.content_ar
          }
        }
      })
    },
    async getUser() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      let res = await axios.get(this.BASEURL + "users/get-info", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      }); 
      
      if (res.data.locked == 0) {
        location.href =  '/'+ this.direction + '/verification'
      }
    }     
  },
  created() {
    this.getContent();
    this.getUser();
  },
  mounted() {
    this.finishedLoading()
  }
};
</script>

<style scoped>
.first-level-home {
  z-index: 999;
  width: 100%;
  height: 80px;
  box-shadow: 0 1px 0 rgba(1, 119, 129, 0.75);
  background-color: #b51e30;
  position: absolute;
}

.button-font {
  font-family: "Open Sans - Semi Bold";
  font-weight: 800;
}

.arabic-font {
  letter-spacing: 0!important;
  word-spacing: 0!important;
}

li {
  /* Style for "Projects" */
  height: 27px;
  color: #ffffff;
  font-family: "Open Sans - Semi Bold";
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  /* Text style for "Projects" */
  font-style: normal;
  letter-spacing: -0.08px;
  line-height: normal;
  /* Text style for "Contact us" */
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0.8px;
  line-height: 35.31px;
  list-style: none;
  text-decoration: none;
  display: inline;

  margin-left: 15px;
  cursor: pointer;
}
ul {
  width: 100%;
}
a {
  text-decoration: none;
  list-style: none;
}

.slider {
  z-index: 999;
  position: relative;
  top: 35px;
}

.slider-ar {
  z-index: 999;
  position: relative;
  top: 35px;
}

.slider img {
  z-index: 999;
  width: 110% !important;
  max-height: 500px;
  height: 500px;
  padding: 0;
}

.slider-ar img {
  z-index: 999;
  width: 120% !important;
  height: 490px;
  max-height: 490px;
  position: relative;
  left: 50px;
  padding: 0;
}

.container-fluid {
  margin: 0;
  padding: 0;
}
h2 {
  /* Style for "Green Wall" */
  color: #fcf8f8;
  font-family: "Open Sans - Semi Bold";
  font-size: 90px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 2px;
  line-height: normal;
}
p {
  /* width: 486px; */
  height: 41px;
  color: #fcf8f8;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.over-lay-image {
  width: 100%;
  height: 496px;
  background-image: url("../assets/shadow-grey.png");
  opacity: 0.46;
  position: absolute;
  top: 114px;
  z-index: 999;
}

.over-lay-image-ar {
  width: 100%;
  height: 496px;
  background-image: url("../assets/shadow-grey.png");
  opacity: 0.46;
  position: absolute;
  top: 114px;
  z-index: 999;
}
.over-lay-text {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 175px;
  text-align: center;
  z-index: 999;
}
.over-lay-text p {
  /* Style for "Enhance yo" */
  text-align: center!important;
  height: 41px;
  color: #fcf8f8;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 2px;
  line-height: 24px;
  margin-top: 20px;
}

.over-lay-button {
  width: 40%;
  height: 100px;
  z-index: 9999;
  position: absolute;
  top: 400px;
  left: 30%;
}

.over-lay-button button {
  height: 19px;
  color: #ffffff;
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 41.89px;
  text-align: center;
  text-transform: uppercase;
  /* Text style for "Retail" */
  font-style: normal;
  letter-spacing: 3px;
  line-height: normal;
}

.partners-section {
  width: 100%;
  height: 254px;
  background-color: #ececec;
  position: relative;
  top: 35px;
}

@media only screen and (max-width: 500px) {
  .first-level-home {
    display: none;
  }

  .slider img {
    max-width: 100% !important;
  }

  .slider-ar img {
    width: 100%;
    max-width: 100% !important ;
  }

  .over-lay-text {
    display: none;
  }

  .over-lat-text-ar {
    display: none;
  }

  .over-lay-button {
    display: none;
  }

  .over-lat-button-ar {
    display: none;
  }

  .over-lay-image {
    display: none;
  }

  .over-lay-image-ar {
    display: none;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
  .first-level-home {
    display: none;
  }
  .slider img {
    max-width: 100% !important;
  }

  .slider-ar img {
    width: 100%;
    max-width: 100% !important ;
  }

  .over-lay-text {
    display: none;
  }

  .over-lat-text-ar {
    display: none;
  }

  .over-lay-button {
    display: none;
  }

  .over-lat-button-ar {
    display: none;
  }

  .over-lay-image {
    display: none;
  }

  .over-lay-image-ar {
    display: none;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
  .slider img {
    max-width: 102% !important;
    overflow: hidden;
  }

  .slider-ar img {
    width: 102%;
    max-width: 102% !important ;
    overflow: hidden;
  }
  .over-lay-text {
    display: none;
  }

  .over-lat-text-ar {
    display: none;
  }

  .over-lay-button {
    display: none;
  }

  .over-lat-button-ar {
    display: none;
  }

  .over-lay-image {
    display: none;
  }

  .over-lay-image-ar {
    display: none;
  }
}
</style>
