<template >
  <div class="container-fluid" style="">
    <div class="bg-slider">
      <h2>{{ direction == 'en' ? 'THE GARDEN PALACE' : 'قصر الحديقه'}}</h2>
      <div
        class="row justify-content-center"
        style="position: relative; top: 45px"
      >
        <div
          class="col-lg-3 bg-slider-card"
        >
          <h5>About us</h5>
          <p v-if="about">{{direction == 'en' ? about.en : about.ar }}</p>
          <p v-else>...</p>
          <Button
            style="
              width: 250px;
              height: 40px;
              background-color: #b51e30;
              margin-top: 30px;
              margin-left: 25px;
              border-radius: 20px;
              color: #fcfcfc;
              font-family: Montserrat;
              font-size: 17px;
              font-weight: 700;
              font-style: normal;
              letter-spacing: normal;
              line-height: normal;
              text-align: center;
              font-style: normal;
              letter-spacing: 1px;
              line-height: normal;
            "
            :class="direction == 'en' ? 'button-en' : 'button-ar'"
            color="#fff"
            :title="direction == 'en' ? 'Read more' : 'اقرا المزيد'"
          />
        </div>
         <div
          class="col-lg-3 bg-slider-card"
        >
          <h5>History</h5>
          <p v-if="history">{{direction == 'en' ? history.en :  history.ar }}</p>
          <p v-else>...</p>
          <Button
            style="
              width: 250px;
              height: 40px;
              background-color: #b51e30;
              margin-top: 30px;
              margin-left: 25px;
              border-radius: 20px;
              color: #fcfcfc;
              font-family: Montserrat;
              font-size: 17px;
              font-weight: 700;
              font-style: normal;
              letter-spacing: normal;
              line-height: normal;
              text-align: center;
              font-style: normal;
              letter-spacing: 1px;
              line-height: normal;
            "
            :class="direction == 'en' ? 'button-en' : 'button-ar'"
            color="#fff"
            :title="direction == 'en' ? 'Read more' : 'اقرا المزيد'"
          />
        </div>
        <div
          class="col-lg-3 bg-slider-card"
        >
          <h5>Projects</h5>
          <p v-if="projects">{{direction == 'en' ? projects.en :  projects.ar }}</p>
          <p v-else>...</p>          
          <Button
            style="
              width: 250px;
              height: 40px;
              background-color: #b51e30;
              margin-top: 30px;
              margin-left: 25px;
              border-radius: 20px;
              color: #fcfcfc;
              font-family: Montserrat;
              font-size: 17px;
              font-weight: 700;
              font-style: normal;
              letter-spacing: normal;
              line-height: normal;
              text-align: center;
              font-style: normal;
              letter-spacing: 1px;
              line-height: normal;
            "
            :class="direction == 'en' ? 'button-en' : 'button-ar'"
            color="#fff"
            :title="direction == 'en' ? 'Read more' : 'اقرا المزيد'"
          />
        </div>               
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import axios from "axios"

export default {
  name: "GardenPalaceWithComps",
  props: {
    bg: String,
  },
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    }    
    return {
      items: [],
      direction: direction,
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost") 
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",      
      content: {},
      title1: '',
      subTitle1: '',
      sliderImg: '',
      about: {},
      projects: {},
      history: {},      
    };
  },
  methods: {
    async getContent() {
      let res = await axios.get(this.BASEURL + "content/home", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      });
      res.data.filter( (result) => {
        if (result.page == 'home_aboutus') {
          this.about = {
            en: result.content_en,
            ar: result.content_ar
          }
        }

        if (result.page == 'home_history') {
          this.history = {
            en: result.content_en,
            ar: result.content_ar
          }
        }

        if (result.page == 'home_projects') {
          this.projects = {
            en: result.content_en,
            ar: result.content_ar
          }
        }
      })

      return false;
    }         
  },
  created() {
    this.getContent();
  },
  components: {
    Button,
  },
};
</script>

<style scoped>
.bg-slider {
  background-image: url("../assets/slider-2.png");
  background-size: 100% 100%;
  min-height: 500px;
  margin-bottom: 40px;
  opacity: 0.8;
}

img {
  position: absolute;
  max-height: 500px;
}

.button-ar {
  margin-right: 25px!important;
}
h2 {
  /* Style for "The Garden" */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.33);
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 52px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  /* Text style for "T, he Gard" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  position: relative;
  top: 40px;
}
.bg-slider-card {
  height: 366px;
  box-shadow: 0 0 9px rgba(153, 151, 151, 0.15);
  background-color: #ffffff;
  margin-left: 15px;
  border-radius: 15px;
}
h5 {
  /* Style for "Corporate" */
  color: #b51e30;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  /* Text style for "C, orporat" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-top: 20px;
}
p {
  /* Style for "Lorem Ipsu" */

  color: #333333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  /* Text style for "L, orem Ip" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-top: 40px;
}

@media only screen and (max-width: 500px) {
  .bg-slider {
    display: none;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
  .bg-slider-card {
    width: 45%;
    float: left;
    margin-left: 5px;
    margin-bottom: 10px
  }

  .button-ar {
    position: relative;
    right: 20px;
  }

  .button-en {
    position: relative;
    left: 20px;
  }
  
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
  .button-ar {
    position: relative;
    right: -30px;
  }

  .button-en {
    position: relative;
    left: -25px;
  }
}
</style>