<template>
        <div class="container-fluid">        
            <div :class="direction == 'en' ? 'lds-dual-ring' : 'lds-dual-ring-ar'"></div>
        </div>
</template>

<script>
export default {
    name: 'loading',
    data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }      
        return {
          direction: direction
        }
    }
}
</script>

<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position:relative;
  left: 45%;
  top: 40%;
  z-index: 99999!important;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #c32222;
  border-color: #c32222 transparent #c32222 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
    z-index: 99999!important;
}

.lds-dual-ring-ar {
  display: inline-block;
  width: 80px;
  height: 80px;
  position:relative;
  right: 48%;
  top: 40%;
  z-index: 99999!important;
}

.lds-dual-ring-ar:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #c32222;
  border-color: #c32222 transparent #c32222 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
    z-index: 99999!important;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.container-fluid {
    width: 100%!important;
    height: 100%!important;
    position: fixed;
    background: #fff;
    z-index: 99999!important;
}

</style>