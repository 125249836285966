<template>
    <div class="box">
        <p >{{ direction == 'en' ? 'We Accept' : 'نحن نقبل  :'}}</p>
        <img :src="payments" alt="">
    </div>
</template>

<script>
import payments from "../assets/payments.png"

export default {
    data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr')
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl')
    }  
        return {
            payments: payments,
            direction: direction
        }
    }
}
</script>

<style scoped>
.box {
    width: 700px;
    height: 80px;
}

p {
/* Style for "We accept" */
opacity: 0.5;
color: #414141;
font-family: "Arial MT";
font-size: 20px;
font-weight: 400;
font-style: normal;
letter-spacing: normal;
line-height: normal;
text-align: center;
/* Text style for "We accept" */
font-style: normal;
letter-spacing: -0.12px;
line-height: normal;
display: inline;
margin-left: 120px;
}

img {
    height: 25px;
    width: 300px;
    padding-left: 10px;
}

@media only screen and (max-width: 500px) {
    .box {
        display: none;
    }
}
</style>