<template>
  <Header />
  <div class="col-lg-12 col-md-6 col-xs-12 product-card">
    <p>{{ Categories[direction] }}</p>
  </div>
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  </div>

  <section class="section">
    <div class="container">
      <div class="row justify-content-lg-center">
        <div
          class="col-lg-3 col-md-6 mb-4 pb-2"
          v-for="item in catList"
          v-bind:key="item"
          v-on:click="openUrl(item.id)"
          style="cursor: pointer;"
        >
          <div class="card blog rounded border-0 shadow overflow-hidden">
            <div class="position-relative">
              <img :src="photo" class="card-img-top" alt="..." />
              <div class="overlay rounded-top"></div>
            </div>
            <div class="card-body content">
              <h5 style="text-align: center!important;">
                <a
                  href="javascript:void(0)"
                  class="card-title title text-dark"
                  >{{ direction == 'en' ? item.name_en : item.name_ar }}</a
                >
              </h5>
              <div class="post-meta d-flex justify-content-between mt-3">
                <ul class="list-unstyled mb-0"></ul>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import img from "../assets/image-1.png";
import axios from "axios";
import Header from "../components/Header2.vue";
import Footer from "../components/newFooter.vue";
import photo from "../assets/What-Are-Plants.jpg"

export default {
  name: "products",
  props: {},
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    return {
      direction: direction,
      img: img,
      blog: direction == "en" ? "Our blog" : "بعض المواضيع المميزه",
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",
      blogs: {},
      Categories: {
        ar: "الاقسام",
        en: "Categories",
      },
      catList: {},
      subCats: {},
      photo: photo
    };
  },
  methods: {
    async getCategories() {
      let res = await axios.get(this.BASEURL + "categories/", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      });

      let response = await axios.get(this.BASEURL + "categories/all-subcats", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      });
      this.subCats = response.data
      this.catList = res.data
    },
    openUrl(id) {
        let checkSubCat = this.subCats.filter(res => res.child_to == id)
        if (checkSubCat.length > 0) {
            window.location.href =  '/' + this.direction + '/sub-categories/' + id
        } else {
            // redirect to shop of the parent category
            let getCategoryInfo = this.catList.filter(res => res.id == id)
            window.location.href = '/' + this.direction + '/shop/' + getCategoryInfo[0].name_en + '/' + getCategoryInfo[0].id + '/?page=1&current_is_sub=false'
        }
        return;
    }
  },
  created() {
    this.getCategories();
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.blog {
  transition: all 0.3s ease;
}

.blog .content h4 {
  line-height: 1.2;
}

.blog .content .title {
  transition: all 0.5s ease;
}

.blog .content .title:hover {
  color: #941707 !important;
}

.blog .content .post-meta .like,
.blog .content .post-meta .comments,
.blog .content .post-meta .readmore {
  font-size: 15px;
  transition: all 0.5s ease;
}

.blog .content .post-meta .like:hover {
  color: #e43f52 !important;
}

.blog .content .post-meta .comments:hover {
  color: #2eca8b !important;
}

.blog .content .post-meta .readmore:hover {
  color: #2f55d4 !important;
}

.blog .content .link {
  color: #2f55d4 !important;
}

.blog .author,
.blog .teacher,
.blog .course-fee {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: all 0.5s ease;
}

.blog .author {
  top: 5%;
  left: 5%;
}

.blog .teacher {
  bottom: 6%;
  left: 5%;
}

.blog .course-fee {
  bottom: -5%;
  right: 5%;
  width: 50px;
  height: 50px;
}

.blog .course-fee .fee {
  line-height: 50px;
}

.blog.blog-detail:hover {
  transform: translateY(0px) !important;
}

.blog .overlay {
  background: #3c4858;
}

.blog:hover {
  transform: translateY(-10px);
}

.blog:hover .overlay {
  opacity: 0.4;
}

.blog:hover .author,
.blog:hover .teacher,
.blog:hover .course-fee {
  opacity: 1;
}

.blog .blog-list-meta {
  position: absolute;
  bottom: 24px;
}

.sidebar .widget .blog-categories li {
  padding-bottom: 10px;
}

.sidebar .widget .blog-categories li:last-child {
  padding-bottom: 0;
}

.sidebar .widget .blog-categories li a,
.sidebar .widget .blog-categories li span {
  font-size: 15px;
}

.sidebar .widget .blog-categories li a {
  color: #3c4858;
  transition: all 0.5s ease;
}

.sidebar .widget .blog-categories li a:hover {
  color: #2f55d4;
}

.sidebar .widget .post-recent {
  padding-bottom: 15px;
}

.sidebar .widget .post-recent:last-child {
  padding-bottom: 0;
}

.sidebar .widget .post-recent .post-recent-thumb {
  width: 25%;
}

.sidebar .widget .post-recent .post-recent-content {
  width: 75%;
  padding-left: 10px;
}

.sidebar .widget .post-recent .post-recent-content a {
  display: block;
  color: #3c4858;
  font-size: 15px;
  transition: all 0.5s ease;
}

.sidebar .widget .post-recent .post-recent-content a:hover {
  color: #2f55d4;
}

.sidebar .widget .post-recent .post-recent-content span {
  font-size: 13px;
}

.tagcloud > a {
  background: #e9ecef;
  color: #3c4858;
  display: inline-block;
  font-size: 9px;
  letter-spacing: 1px;
  margin: 5px 10px 5px 0;
  padding: 8px 12px 7px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  font-weight: 600;
}

.tagcloud > a:hover {
  background: #2f55d4;
  color: #ffffff;
}

.media-list .media .media-heading:hover {
  color: #2f55d4 !important;
}

.media-list .sub-comment {
  border-left: 2px dashed #dee2e6;
}

.blog .overlay,
.job-box .job-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.job-box {
  transition: all 0.5s ease;
}

.job-box .job-overlay {
  opacity: 0.6;
}

.job-box .head {
  position: absolute;
  top: 15px;
  right: 15px;
}

.job-box .position {
  position: absolute;
  top: 15px;
  left: 15px;
}

.job-box .position a {
  transition: all 0.5s ease;
}

.job-box .position a:hover {
  color: #2f55d4 !important;
}

.job-box .firm-logo {
  position: absolute;
  left: 0;
  right: 0;
  height: 60px;
  width: 60px;
  line-height: 60px;
  margin: 0 auto;
  top: -30px;
}

.job-box .company-name {
  transition: all 0.5s ease;
}

.job-box .company-name:hover {
  color: #2f55d4 !important;
}

.job-box:hover {
  transform: translateY(-5px);
}

.candidate-list .like {
  -webkit-text-stroke: 1px #8492a6;
  -webkit-text-fill-color: transparent;
}

.candidate-list .like:hover,
.candidate-list .like:focus {
  -webkit-text-stroke: #e43f52;
  -webkit-text-fill-color: #e43f52;
}

.candidate-list,
.company-list {
  transition: all 0.5s ease;
}

.candidate-list .name:hover,
.company-list .name:hover {
  color: #2f55d4 !important;
}

.candidate-list:hover,
.company-list:hover {
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
  transform: translateY(-5px);
}

p {
  /* Style for "Agricultur" */
  color: #b51e30;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 700;
  text-align: center !important;
  text-transform: uppercase;
  margin-top: 50px;
}

.product-card {
  margin-bottom: 70px;
  background: #fff;
  position: relative;
  top: 35px;
}

h5 {
  text-decoration: none;
  list-style: none;
  font-size: 13px;
}
</style>