<template>
  <loading class="loader"/>
  <div class="container-fluid">
    <div class="row justify-content-lg-center">
      <div class="col-lg-12 col-xl-12 col-sm-12">
        <div :class="direction == 'en' ? 'for-image' : 'for-image-ar'">
          <img v-on:click="openUrl('/')" :src="logo" alt="" />
          <img v-on:click="openUrl('/')" :src="logo2" alt="" />
        </div>
      </div>
        <p class="error">
          {{direction == 'en' ? 'Email or password is incorrect' : 'البريد الالكتروني و كلمه السر غير صحيحه'}}          
        </p>
      <div class="col-lg-6 col-md-12 form-body" style="">
        <h5>{{direction == 'en' ? 'Sign In to your session' : 'سجل الدخول'}}</h5>
        <div style="text-align: center; color: red; font-size: 14px;" v-if="error">{{direction == 'en' ? error.error_en : error.error_ar}}</div>        
        <form>
          <input v-model="userInput.email" class="email" type="email" :class="direction == 'en' ? 'input' : 'input-ar'" :placeholder="direction =='en' ? 'User name': 'اسم المستخدم'" />
          <input v-model="userInput.password" class="passwords" type="password" :class="direction == 'en' ? 'input' : 'input-ar'" :placeholder="direction == 'en' ? 'Password' : 'كلمه المرور'" />
          <a class="forgot" href="#">{{direction == 'en' ? 'Forgot your password?' : 'نسيت كلمه السر؟'}}</a><br>
          <Button
            :class="smallScreen"
            :title="direction =='en' ? 'Sign In' : 'تسجيل دخول'"
            style="
              width: 200px;
              height: 40px;
              color: #fff;
              background: #c32222;
              position: relative;
              left: 35%;
              margin-top: 30px;
              font-size: 13px;
              border-radius: 0;
                -webkit-box-shadow: 0 0 15px rgb(216, 213, 213);
                box-shadow: 0 0 15px rgb(216, 213, 213);
                @media screen only and (max-width: 500) {
                    color: red;
                }
            "
            :style="direction == 'en' ? '' : 'right: 35%;'" 
            class="login-button"
            v-on:click="login($event)"
          />
          <p>{{direction =='en' ? 'Do not have an account?' : 'ليس دليك حساب بالفعل؟'}} <a href="#" v-on:click="openUrl('register')">{{direction =='en' ?'Register NOW' : 'ضف حساب جديد'}}</a></p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../assets/logo.png";
import logo2 from "../assets/garden-logo.png";
import Button from "../components/Button.vue";
import axios from "axios";
import loading from "../components/loading.vue"
export default {
  name: "Login",
  data: () => {
        var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
    }    
    return {
      logo: logo,
      logo2: logo2,
      smallScreen: 'smallScreen',
      direction: direction,
      userInput: {
        email: '',
        password: ''
      },
      isLoaded: false,
      error: {},
      BASEURL: window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost') ? "http://127.0.0.1:3000/" : "https://nourdiaa.com:3000/",
      isLogged: false,
    };
  },
  components: {
    Button,
    loading,
  },
  methods: {
    openUrl(value) {
      window.location = window.location.origin + '/' + this.direction + '/' + value
    },
    async login(event) {
      event.preventDefault()
      const button = document.getElementsByClassName('login-button')[0];
      button.disabled = true;
      this.direction == 'en' ? button.innerHTML = 'Please wait' : button.innerHTML = 'انتظر قليلا'

      if (this.userInput.email.length == 0) {
        this.error = {
          error_en: 'email is required',
          error_ar: 'البريد الالكتروني مطلوب'
        }
        let showUserError = document.getElementsByClassName('email')[0];
        showUserError.style.borderBottom = '1px solid red'
        button.disabled = false;
        button.innerHTML = this.direction == 'en' ? 'Sign in' : ' تسجيل الدخول'
        return;
      } 
      if (this.userInput.email.length == 0) {
        this.error = {
          error_en: 'Email is required',
          error_ar: 'البريد الاكتروني مطلوب'
        }
        let showEmailError = document.getElementsByClassName('passwords')[0];
        showEmailError.style.borderBottom = '1px solid red'
        button.disabled = false;
        showUserError.style.borderBottom = "1px solid grey"
        button.innerHTML = this.direction == 'en' ? 'Sign in' : ' تسجيل الدخول'
        return;
      }      
      this.error = {}

      //setup the body for api 
      const body = {
          email: this.userInput.email,
          password: this.userInput.password,
      }

      // api callback
      await axios.post(this.BASEURL + 'users/login', body).then( (result) => {
        if (result.status === 200) {
          document.cookie = "LoggedIn='true';expires=" + 365 * 24 * 60 * 60 * 1000 + ";path=/"
          document.cookie = "access=" + result.data.data.token  + ";expires=" + 365 * 24 * 60 * 60 * 1000 + ";path=/"
          sessionStorage.setItem('fullName', result.data.data.first_name);
          sessionStorage.setItem('email', result.data.data.email);
          sessionStorage.setItem('LoggedIn', true);
          sessionStorage.setItem('id', result.data.data.id)
          sessionStorage.setItem('access', result.data.data.token)

          let redirects = this.$route.query.redirectsTo ?? this.$route.query.redirectTo
          
          if (redirects && redirects.includes('/shop/')) {
            return window.location.href = '/' + this.direction + '/shop/All/1?page=1'
          }

          if (redirects && redirects.includes('/cart')) {
            return window.location.href = '/' + this.direction + '/cart'
          }

          if (redirects && redirects.includes('/account'))

            return window.location.href = '/' + this.direction + '/'

          if (redirects) {
            return window.location.href = "/"
          } else {
            return window.location.href =  '/' + this.direction + '/'
          }
        } 
      }).catch( (error) => {
        console.log(error)
        document.getElementsByClassName('error')[0].style.display = 'block';
        const button = document.getElementsByClassName('login-button')[0];
        button.disabled = false;
        button.innerHTML = this.direction == 'en' ? 'Sign in' : ' تسجيل الدخول'
      })  
    },
    finishedLoading() {
      setTimeout(function() {
        document.getElementsByClassName('loader')[0].style.display = 'none';
      }, 1000);
    }
  },
  created() {
    this.finishedLoading()
  },
  beforeCreate() {
    if ("LoggedIn" in sessionStorage) {
      return window.history.back()
    } 
  },
  mounted() {
    this.finishedLoading()
  }
};
</script>

<style scoped>
img {
  display: inline;
}

.error {
  color: #c32222;
  text-align: center;
  display: none;
}

[v-cloak] {
  display: none;
}

.col-lg-12 {
  width: 100%;
  min-height: 1px;
  margin-bottom: 100px;
}

.for-image {
  margin: 0 auto;
  position: relative;
  left: 37%;
  top: 50%;
}

.for-image-ar {
  margin: 0 auto;
  position: relative;
  right: 37%;
  top: 50%;  
}

.form-body form .input {
  display: block;
  border: 0;
  font-size: 14px;
  border-bottom: 1px solid rgb(192, 186, 186);
  padding-bottom: 10px;
  width: 300px;
  margin: 0 auto;
  height: 30px;
  padding-left: 20px;
  outline: none;
  margin-bottom: 20px;
  margin-top: 20px;
}

.form-body form .input-ar {
  display: block;
  border: 0;
  font-size: 14px;
  border-bottom: 1px solid rgb(192, 186, 186);
  padding-bottom: 10px;
  width: 300px;
  margin: 0 auto;
  height: 30px;
  padding-left: 20px;
  outline: none;
  margin-bottom: 20px;
  margin-top: 20px;
}

.form-body {
  -webkit-box-shadow: 0 0 10px rgb(216, 213, 213);
  box-shadow: 0 0 10px rgb(216, 213, 213);
  min-height: 400px;
}

.form-body form .input::placeholder {
  font-size: 13px;
}

.form-body form .input-ar::placeholder {
  font-size: 13px;
  float: right;
  padding-right: 30px;
}

h5 {
  color: #c32222;
  text-align: center;
  margin-top: 40px;
  letter-spacing: 2px;
}

.forgot {
    font-size: 10px;
    float: right;
    position: relative;
    right: 25%;
}

form p {
    font-size: 13px;
    text-align: center;
    margin-top: 40px;
}

@media only screen and (max-width: 500px)  and (min-width: 300px) {
    .for-image {
        left: 0%;
    }
    .forgot {
        right: 10%;
    }   
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
  .container-fluid {
    overflow: hidden;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {

}

</style>