<template>
  <router-view />
</template>

<script>
import Header from "./components/Header.vue"
import Footer from "./components/Footer.vue";

export default {
  name: 'Garden',
  components: {
    Header,
    Footer,
  }
}
</script>
