<template>
  <div
    style="
      display: none;
      font-size: 1px;
      color: #b51e30;
      line-height: 1px;
      font-family: 'Lato', Helvetica, Arial, sans-serif;
      max-height: 0px;
      max-width: 0px;
      opacity: 0;
      overflow: hidden;
    "
  >
    We're thrilled to have you here! Get ready to dive into your new account.
  </div>
  <table border="0" cellpadding="0" cellspacing="0" width="100%">
    <!-- LOGO -->
    <tr>
      <td bgcolor="#b51e30" align="center">
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style="max-width: 600px"
        >
          <tr>
            <td
              align="center"
              valign="top"
              style="padding: 40px 10px 40px 10px"
            ></td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td bgcolor="#b51e30" align="center" style="padding: 0px 10px 0px 10px">
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style="max-width: 600px"
        >
          <tr>
            <td
              bgcolor="#ffffff"
              align="center"
              valign="top"
              style="
                padding: 40px 20px 20px 20px;
                border-radius: 4px 4px 0px 0px;
                color: #111111;
                font-family: 'Lato', Helvetica, Arial, sans-serif;
                font-size: 48px;
                font-weight: 400;
                letter-spacing: 4px;
                line-height: 48px;
              "
            >
              <h1 style="font-size: 48px; font-weight: 400; margin: 2">
                {{ translation[direction].welcome }}
              </h1>
              <img
                src=" https://img.icons8.com/clouds/100/000000/handshake.png"
                width="125"
                height="120"
                style="display: block; border: 0px"
              />
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td bgcolor="#f4f4f4" align="center" style="padding: 0px 10px 0px 10px">
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style="max-width: 600px"
        >
          <tr>
            <td
              bgcolor="#ffffff"
              align="left"
              style="
                padding: 20px 30px 40px 30px;
                color: #666666;
                font-family: 'Lato', Helvetica, Arial, sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
              "
            >
              <p style="margin: 0">{{ translation[direction].text1 }}</p>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ffffff" align="left">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td
                    bgcolor="#ffffff"
                    align="center"
                    style="padding: 20px 30px 60px 30px"
                  >
                    <h5 style="color: green;" class="success"></h5>
                    <h5 style="color: red; padding-bottom: 30px;" class="error"></h5>
                    
                    <table border="0" cellspacing="0" cellpadding="0">
                      <tr style="position: relative; bottom: 20px">
                        <td
                          align="center"
                          style="border-radius: 3px"
                          bgcolor="#b51e30"
                        >
                          <input
                            v-model="code"
                            style="
                              font-size: 20px;
                              font-family: Helvetica, Arial, sans-serif;
                              text-decoration: none;
                              padding: 15px 25px;
                              border-radius: 2px;
                              border: 1px solid #ffa73b;
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="center"
                          style="border-radius: 3px"
                          bgcolor="#b51e30"
                        >
                          <a
                            href="#"
                            style="
                              font-size: 20px;
                              font-family: Helvetica, Arial, sans-serif;
                              color: #ffffff;
                              text-decoration: none;
                              color: #ffffff;
                              text-decoration: none;
                              padding: 15px 25px;
                              border-radius: 2px;
                              border: 1px solid #b51e30;
                              display: inline-block;
                            "
                            v-on:click="verify()"
                            >{{ translation[direction].text2 }}</a
                          >
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <!-- COPY -->
          <tr>
            <td
              bgcolor="#ffffff"
              align="left"
              style="
                padding: 0px 30px 0px 30px;
                color: #666666;
                font-family: 'Lato', Helvetica, Arial, sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
              "
            >
              <p style="margin: 0">{{ translation[direction].text4 }}</p>
            </td>
          </tr>
          <!-- COPY -->
          <tr>
            <td
              bgcolor="#ffffff"
              align="left"
              style="
                padding: 20px 30px 20px 30px;
                color: #666666;
                font-family: 'Lato', Helvetica, Arial, sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
              "
            >
              <p style="margin: 0">
                <a href="#" style="color: #b51e30" v-on:click="reSendCode()">{{
                  translation[direction].text5
                }}</a>
              </p>
            </td>
          </tr>
          <tr>
            <td
              bgcolor="#ffffff"
              align="left"
              style="
                padding: 0px 30px 20px 30px;
                color: #666666;
                font-family: 'Lato', Helvetica, Arial, sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
              "
            >
              <p style="margin: 0">
                {{ translation[direction].text6 }}
                <a href="/en/logout">{{ translation[direction].text7 }}</a>
              </p>
            </td>
          </tr>
          <tr>
            <td
              bgcolor="#ffffff"
              align="left"
              style="
                padding: 0px 30px 40px 30px;
                border-radius: 0px 0px 4px 4px;
                color: #666666;
                font-family: 'Lato', Helvetica, Arial, sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
              "
            >
              <p style="margin: 0; color: black">
                {{ translation[direction].text8 }}<br />{{
                  translation[direction].text9
                }}
              </p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr></tr>
    <tr>
      <td bgcolor="#f4f4f4" align="center" style="padding: 0px 10px 0px 10px">
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style="max-width: 600px"
        >
          <tr>
            <td
              bgcolor="#f4f4f4"
              align="left"
              style="
                padding: 0px 30px 30px 30px;
                color: #666666;
                font-family: 'Lato', Helvetica, Arial, sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
              "
            >
              <br />
              <p style="margin: 0">
                {{ translation[direction].text10 }}
                <a href="/en/logout" style="color: #111111; font-weight: 700">{{
                  translation[direction].text11
                }}</a
                >.
              </p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</template>

<script>
import axios from "axios";
export default {
  name: "verfication",
  data: () => {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    return {
      direction: direction,
      code: "",
      error: {
        ar: 'هذا الكود غير صحيح',
        en: 'This verfication code is not vaild'
      },
      message: {
          ar: 'تم ارسال الكود بنجاح الي البريد الالكتروني',
          en: 'Verification code has been sent to your E-mail address'
      },
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",
      translation: {
        ar: {
          welcome: "اهلا و سهلا!",
          text1:
            "نحن متحمسون لك لكي تبدا معنا رحله جديده ، انت فقط تحتاج الي تفعيل الحساب الخاص بكم ",
          text2: "تفعيل الحساب",
          text4: "انا لم استلم كود التفعيل الخاص بحسابي",
          text5: "اعاده ارسال كود التفعيل الي البريد الالكتروني",
          text6: "اذا كنت ترغب في تسجيل الخروج من هذا الحساب اضغط",
          text7: "هنا",
          text8: "مع تحيات",
          text9: "فريق قصر الحديقه",
          text10: "اذا كنت تواجه اي مشاكل",
          text11: "تسجيل خروج",
        },
        en: {
          welcome: "Welcome!",
          text1:
            "We're excited to have you get started. First, you need to confirm your account. Just press the button below.",
          text2: "Verify account",
          text4:
            "If that doesn't work, Please ask for another code sending to your email address",
          text5: "Re-send the verfication code",
          text6: "If you wish to log out this account just click",
          text7: "Here",
          text8: "Cheers,",
          text9: "The Garden Palace team",
          text10: "If this does not seem fine to you just , ",
          text11: "Logout",
        },
      },
    };
  },
  methods: {
    async verify() {
      if (this.code == "") {
        return false;
      }
      const body = {
        code: this.code,
      };
      // api callback
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      let response = await axios
        .post(this.BASEURL + "users/verify", body)
        .then((result) => {
            window.location.href = '/' + this.direction
        })
        .catch((error) => {
            if (this.direction == 'ar') {
                document.getElementsByClassName('error')[0].innerHTML = this.error.ar
            } else {
                document.getElementsByClassName('error')[0].innerHTML = this.error.en          
            }
        });
    },
    async reSendCode() {
        let res = await axios.get(this.BASEURL + "users/resend", {
            header: {
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Methods": "GET",
            },
        });
            if (this.direction == 'ar') {
                document.getElementsByClassName('success')[0].innerHTML = this.message.ar
            } else {
                document.getElementsByClassName('success')[0].innerHTML = this.message.en          
            }
    },
    async getUser() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("access");
      let res = await axios.get(this.BASEURL + "users/get-info", {
        header: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
      }); 

      if (res.data.user.locked != 0) {
        return window.location.href =  '/' +this.direction + '/my-account'
      }
    }    
  },
  created() {
      this.getUser()
  },
};
</script>

<style scoped>
@media screen {
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"),
      url(https://fonts.gstatic.com/s/lato/v11/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff)
        format("woff");
  }

  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: local("Lato Bold"), local("Lato-Bold"),
      url(https://fonts.gstatic.com/s/lato/v11/qdgUG4U09HnJwhYI-uK18wLUuEpTyoUstqEm5AMlJo4.woff)
        format("woff");
  }

  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 400;
    src: local("Lato Italic"), local("Lato-Italic"),
      url(https://fonts.gstatic.com/s/lato/v11/RYyZNoeFgb0l7W3Vu1aSWOvvDin1pK8aKteLpeZ5c0A.woff)
        format("woff");
  }

  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 700;
    src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
      url(https://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYELO3LdcAZYWl9Si6vvxL-qU.woff)
        format("woff");
  }
}

/* CLIENT-SPECIFIC STYLES */
body,
table,
td,
a {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

table,
td {
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
}

img {
  -ms-interpolation-mode: bicubic;
}

/* RESET STYLES */
img {
  border: 0;
  height: auto;
  line-height: 100%;
  outline: none;
  text-decoration: none;
}

table {
  border-collapse: collapse !important;
}

body {
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

/* iOS BLUE LINKS */
a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

/* MOBILE STYLES */
@media screen and (max-width: 600px) {
  h1 {
    font-size: 32px !important;
    line-height: 32px !important;
  }
}

/* ANDROID CENTER FIX */
div[style*="margin: 16px 0;"] {
  margin: 0 !important;
}

p {
  text-align: center !important;
}
</style>