<template>
  <div class="position-relative" style="margin-top: 140px!important;">
    <div class="shape overflow-hidden text-footer">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  </div>
  <!-- App End -->

  <!-- Footer Start -->
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="footer-py-60">
            <div class="row">
              <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                <h3>{{ direction == "en" ? "Garden palace" : "قصر الحديقه" }}</h3>
                <ul
                  class="list-unstyled social-icon foot-social-icon mb-0 mt-4"
                >
                <li>{{direction == 'en' ? 'Address of gardenpalace should be noted here' : 'عنوان قصر الحديقه هنا'}}</li>
                <li><p style="display: inline-block">{{telephone['' + direction + '']}}</p>
              <p style="font-size: 16px !important; display: inline">
                035 - 667 - 880
              </p><br></li>
              <li> <p style="display: inline-block">{{email['' + direction +'']}}</p>
              <p style="font-size: 15px !important; display: inline">
                garden@garden.com
              </p></li>
                </ul>
                <!--end icon-->
              </div>
              <!--end col-->

              <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h5 class="footer-head">{{ direction == "en" ? "Main links" : "روابط اساسيه" }}</h5>
                <ul class="list-unstyled footer-list mt-4">
                  <li>
                    <a href="javascript:void(0)" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i> {{ direction == "en" ? "About us" : "عن الشركه" }}</a
                    >
                  </li>
                  <!---<li>
                    <a href="" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i> {{ direction == "en" ? "Services" : "الخدمات" }}</a
                    >
                  </li> -->
                  <li>
                    <a :href="'/' + direction + '/categories'" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i> {{ direction == "en" ? "Categories" : "الاقسام" }}</a
                    >
                  </li>
                  <li>
                    <a :href="'/' + direction + '/my-account'" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i> {{ direction == "en" ? "My orders" : "طلباتي" }}</a
                    >
                  </li>
                  <li>
                    <a :href="'/' + direction + '/cart'" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i> {{ direction == "en" ? "My cart" : "سله التسوق" }}</a
                    >
                  </li>
                  <li>
                    <a :href=" '/' + direction + '/categories'" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i> {{ direction == "en" ? "Retail products" : "منتجات الافراد" }}</a
                    >
                  </li>
                  <li>
                    <a :href=" '/' + direction + '/categories'" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i> {{ direction == "en" ? "Corporate products" : "منتجات الشركات" }}</a
                    >
                  </li>
                </ul>
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h5 class="footer-head">{{ direction == "en" ? "Useful links" : "لينكات مفيده" }}</h5>
                <ul class="list-unstyled footer-list mt-4">
                  <li>
                    <a href="javascript:void(0)" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i>
                      {{
                        direction == "en" ? "Terms of use" : "الشروط و الاحكام"
                      }}</a
                    >
                  </li>
                  <!--<li>
                    <a href="javascript:void(0)" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i>
                      {{ direction == "en" ? "Privacy" : "السياسات" }}</a
                    >
                  </li> -->
                  <li>
                    <a :href="'/' + direction + '/register'" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i>
                      {{
                        direction == "en" ? "Become a member" : "انضم الينا"
                      }}</a
                    >
                  </li>
                  <li>
                    <a :href="'/' + direction + '/login'" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i>
                      {{ direction == "en" ? "Login" : "تسجيل الدخول" }}</a
                    >
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="text-foot"
                      ><i class="uil uil-angle-right-b me-1"></i>
                      {{ direction == "en" ? "Contact us" : "اتصل بنا" }}</a
                    >
                  </li>
                </ul>
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h5 class="footer-head">Newsletter</h5>
                <p class="mt-4">
                  {{ direction == "en" ? "Sign up and receive the latest tips via email." : "انضم الينا لتصلك اخر العروض الي البريد الالكتروني" }}
                </p>
                <form>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="foot-subscribe mb-3">
                        <label class="form-label"
                          >{{ direction == "en" ? 'Write your email' : 'ادخل البريد الالكتروني'}}
                          <span class="text-danger">*</span></label
                        >
                        <div class="form-icon position-relative">
                          <i data-feather="mail" class="fea icon-sm icons"></i>
                          <input
                            type="email"
                            name="email"
                            class="form-control ps-5 rounded"
                            placeholder=""
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="d-grid">
                        <input
                          type="submit"
                          name="send"
                          class="btn btn-soft-primary"
                          :value="direction == 'en' ? 'Subscribe' : 'اشترك'"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->

    <div class="footer-py-30 footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-start">
              <p class="mb-0">
                {{direction == 'en' ? '© 2021 GardenPalace. Design with' : ' ٢٠٢١ شركه قصر الحديقه .. صمم من قبل '}}
                <i class="mdi mdi-heart text-danger"></i> {{ direction == 'en' ? 'Syntax-Team' : 'فريق عمل سينتاكس'}}
              </p>
            </div>
          </div>
          <!--end col-->

          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul class="list-unstyled text-sm-end mb-0">
              <li class="list-inline-item">
                <a href="javascript:void(0)"
                  ><img
                    :src="img1"
                    class="avatar avatar-ex-sm"
                    title="American Express"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item">
                <a href="javascript:void(0)"
                  ><img
                    :src="img2"
                    class="avatar avatar-ex-sm"
                    title="Paypal"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item">
                <a href="javascript:void(0)"
                  ><img
                    :src="img3"
                    class="avatar avatar-ex-sm"
                    title="Visa"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div>
  </footer>
  <!--end footer-->
</template>

<script>
import img1 from "../assets/images/master-card.png";
import img2 from "../assets/images/paypal.png";
import img3 from "../assets/images/visa.png";
export default {
  data() {
    var url = window.location.origin;
    if (window.location.href.includes(url + "/en")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (window.location.href.includes(url + "/ar")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/ar/")) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    } else if (window.location.href.includes(url + "/en/")) {
      var direction = "en";
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    } else if (
      window.location.href.includes(url + "/ar") === false ||
      window.location.href.includes(url + "/ar/") === false ||
      window.location.href.includes(url + "/en") === false ||
      window.location.href.includes(url + "/en/") === false
    ) {
      var direction = "ar";
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    return {
      img1: img1,
      img2: img2,
      img3: img3,
      direction: direction,
      BASEURL:
        window.location.href.includes("127.0.0.1") ||
        window.location.href.includes("localhost")
          ? "http://127.0.0.1:3000/"
          : "https://nourdiaa.com:3000/",
            address: {
        en: 'Address',
        ar: 'العنوان:'
      },
      telephone: {
        en: 'Telephone:',
        ar: 'رقم التواصل:'
      },
      email: {
        en: 'Email address:',
        ar: 'البريد الاكتروني:'
      },
      copyRight: {
        en: 'Copyright © 2021 Garden palace All Rights Reserved.',
        ar: 'جميع الحقوق محفوظه لدي قصر الحديقه ٢٠٢١'
      }    
    };
  },
};
</script>

<style scoped>
.btn-footer {
  background-color: #202942 !important;
  border: 1px solid #202942 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.1);
}

.btn-footer:hover,
.btn-footer:focus,
.btn-footer:active,
.btn-footer.active,
.btn-footer.focus {
  background-color: #0f131f !important;
  border-color: #0f131f !important;
  color: #ffffff !important;
}

.btn-soft-footer {
  background-color: rgba(32, 41, 66, 0.05) !important;
  border: 1px solid rgba(32, 41, 66, 0.05) !important;
  color: #202942 !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.1);
}

.btn-soft-footer:hover,
.btn-soft-footer:focus,
.btn-soft-footer:active,
.btn-soft-footer.active,
.btn-soft-footer.focus {
  background-color: #202942 !important;
  border-color: #202942 !important;
  color: #ffffff !important;
}

.btn-outline-footer {
  border: 1px solid #202942;
  color: #202942;
  background-color: transparent;
}

.btn-outline-footer:hover,
.btn-outline-footer:focus,
.btn-outline-footer:active,
.btn-outline-footer.active,
.btn-outline-footer.focus,
.btn-outline-footer:not(:disabled):not(.disabled):active {
  background-color: #202942;
  border-color: #202942;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.1);
}

.alert-footer {
  background-color: rgba(32, 41, 66, 0.9);
  color: #ffffff;
  border-color: #202942;
}

.alert-footer .alert-link {
  color: black;
}

.alert-outline-footer {
  background-color: #ffffff;
  color: #202942;
  border-color: #202942;
}

.footer {
  background: #202942;
  position: relative;
  color: #adb5bd;
}

.footer .footer-py-60 {
  padding: 60px 0;
}

.footer .footer-py-30 {
  padding: 30px 0;
}

.footer .logo-footer {
  font-size: 22px;
}

.footer .logo-footer:focus {
  outline: none;
}

.footer .footer-head {
  letter-spacing: 1px;
  font-weight: 500;
  color: #f8f9fc;
}

.footer .foot-subscribe .form-control {
  background-color: #27314f;
  border: 1px solid #27314f;
  color: #f8f9fc;
}

.footer .foot-subscribe .form-control:focus {
  box-shadow: none;
}

.footer .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}

.footer .foot-subscribe input::placeholder {
  color: #adb5bd;
}

.footer .text-foot {
  color: #adb5bd;
}

.footer .footer-list {
  margin-bottom: 0;
}

.footer .footer-list li {
  margin-bottom: 10px;
}

.footer .footer-list li a {
  transition: all 0.5s ease;
}

.footer .footer-list li a:hover {
  color: #e6e8ea;
}

.footer .footer-list li:last-child {
  margin-bottom: 0;
}

.footer .footer-border,
.footer .footer-bar {
  border-top: 1px solid #283353;
}

.footer .border {
  border-color: #283353 !important;
}

.footer.footer-light {
  background: #f8f9fc;
  color: #8492a6;
}

.footer.footer-light .footer-head {
  color: #3c4858;
}

.footer.footer-light .foot-subscribe .form-control {
  background-color: #f8f9fc;
  border: 1px solid #e9ecef;
  color: #f8f9fc;
}

.footer.footer-light .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}

.footer.footer-light .foot-subscribe input::placeholder {
  color: #adb5bd;
}

.footer.footer-light .border {
  border-color: #e9ecef !important;
}

.footer.footer-light .text-foot {
  color: #8492a6;
}

.footer.footer-light .footer-list li a:hover {
  color: #495057;
}

.rounded {
  border-radius: 6px !important;
}

.rounded-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.rounded-left {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-right {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-lg {
  border-radius: 30px !important;
}

.social-icon li a {
  color: #3c4858;
  border: 1px solid #3c4858;
  display: inline-block;
  height: 32px;
  width: 32px;
  line-height: 28px;
  text-align: center;
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;
}

.social-icon li a .fea-social {
  stroke-width: 2;
}

.social-icon li a:hover {
  background-color: #2f55d4;
  border-color: #2f55d4 !important;
  color: #ffffff !important;
}

.social-icon li a:hover .fea-social {
  fill: #2f55d4;
}

.social-icon.social li a {
  color: #adb5bd;
  border-color: #adb5bd;
}

.social-icon.foot-social-icon li a {
  color: #adb5bd;
  border-color: #283353;
}

.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: -2px;
  left: 0;
}

.shape > svg {
  transform: scale(2);
  width: 100%;
  height: auto;
  transform-origin: top center;
}

.shape.integration-hero {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  height: 250px;
  background: #ffffff;
}

ul.pattern li.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 0;
}

.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 5px;
}

[class^="uil-"]:before,
[class*=" uil-"]:before {
  margin: 0;
}

.footer .foot-subscribe .form-control {
  background-color: #27314f;
  border: 1px solid #27314f;
  color: #f8f9fc;
}

.footer .foot-subscribe .form-control:focus {
  box-shadow: none;
}

.footer .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}

.footer .foot-subscribe input::placeholder {
  color: #adb5bd;
}

.footer.footer-light .foot-subscribe .form-control {
  background-color: #f8f9fc;
  border: 1px solid #e9ecef;
  color: #f8f9fc;
}

.footer.footer-light .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}

.footer.footer-light .foot-subscribe input::placeholder {
  color: #adb5bd;
}

.avatar.avatar-coin {
  height: 36px;
  width: 36px;
}

.avatar.avatar-ex-sm {
  max-height: 25px;
}

.avatar.avatar-ex-small {
  height: 36px;
  width: 36px;
}

.avatar.avatar-md-sm {
  height: 45px;
  width: 45px;
}

.avatar.avatar-small {
  height: 65px;
  width: 65px;
}

.avatar.avatar-md-md {
  height: 80px;
  width: 80px;
}

.avatar.avatar-medium {
  height: 110px;
  width: 110px;
}

.avatar.avatar-large {
  height: 140px;
  width: 140px;
}

.avatar.avatar-ex-large {
  height: 180px;
  width: 180px;
}

.list-inline-item {
  margin-left: 10px;
  margin-right: 10px;
}

.btn-soft-primary {
  background-color: rgba(47, 85, 212, 0.05) !important;
  border: 1px solid rgba(47, 85, 212, 0.05) !important;
  color: #2f55d4 !important;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.1);
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus {
  background-color: #2f55d4 !important;
  border-color: #2f55d4 !important;
  color: #ffffff !important;
}

.text-footer {
  color: #202942 !important;
}

a.text-footer:hover,
a.text-footer:focus {
  color: #181e30 !important;
}
</style>